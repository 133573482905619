import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useDispatch } from "react-redux";
import "./style/addUpdate.css";
import Backdrop from "../Backdrop/loader";
import { useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  FormLabel,
  MenuItem,
  Grid,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import camera from "../../assets/camera.svg";
import { useLocation } from "react-router-dom";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));
const StyledInput = styled("input")({
  display: "none",
});
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
  width: "100%",
}));

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  productGroup: "abc",
  productCode: "",
  productName: "",
  productDescription: "",
  productPrice: "",
  productSalesPrice: "",
  discountType: "",
  discountRate: "",
  productSpecification: "",
  discount: "",
  taxType: "",
  taxRate: "",
  currency: "",
};

export default function CustomizedInputs(props) {
  const [image, setImage] = useState([]);
  const [categorylist, setcategorylist] = useState([]);
  const [uomli, setUomli] = useState([]);
  const [taxData, settaxData] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [view, setView] = useState([]);
  const [productinput, setproductInput] = useState({});
  const [taxdetails, settaxDetails] = useState({});
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [iconFlag, setIconFlag] = useState(0);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [pic, setPic] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let id = location.state;
  const { auth } = store.getState();
  const userid = auth.user.id;
  const button = id ? t("Save") : t("Add_Product");
  const headder = id ? t("Edit_Product") : t("Add_Product");
  useEffect(() => {
    getTaxDropdown();
    getCurrencyDropdown();
    getCategoryDropdown();
    getUOM();
    getSingleProduct();
  }, []);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const StyledRemoveButton = styled(Box)({
    color: "red",
    display: "inline",
    "&:hover": {
      cursor: "pointer",
    },
  });
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("productPrice" in fieldValues) {
      if (fieldValues.productPrice !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.productPrice)) {
          temp.productPrice = "";
        } else {
          temp.productPrice = "Only Number allow";
        }
      } else {
        temp.productPrice = "This field is required.";
      }
    }
    if ("taxRate" in fieldValues) {
      if (fieldValues.taxRate !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.taxRate)) {
          temp.taxRate = "";
        } else {
          temp.taxRate = "Only Number allow";
        }
      } else {
        temp.taxRate = "This field is required.";
      }
    }
    if ("discountRate" in fieldValues) {
      if (fieldValues.discountRate !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.discountRate)) {
          temp.discountRate = "";
        } else {
          temp.discountRate = "Only Number allow";
        }
      } else {
        temp.discountRate = "This field is required.";
      }
    }
    if ("productCode" in fieldValues) {
      if (fieldValues.productCode !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.productCode)) {
          temp.productCode = "";
        } else {
          temp.productCode = "Only Number allow";
        }
      } else {
        temp.productCode = "This field is required.";
      }
    }

    if ("productName" in fieldValues)
      temp.productName = fieldValues.productName
        ? ""
        : "This field is required.";
    if ("productDescription" in fieldValues)
      temp.productDescription = fieldValues.productDescription
        ? ""
        : "This field is required.";
    if ("discountType" in fieldValues)
      temp.discountType = fieldValues.discountType
        ? ""
        : "This field is required.";
    if ("productSpecification" in fieldValues)
      temp.productSpecification = fieldValues.productSpecification
        ? ""
        : "This field is required.";
    if ("taxType" in fieldValues)
      temp.taxType = fieldValues.taxType ? "" : "This field is required.";
    if ("currency" in fieldValues)
      temp.currency = fieldValues.currency ? "" : "This field is required.";
    if ("productImage" in fieldValues)
      temp.productImage = fieldValues.productImage
        ? ""
        : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    calculateSalesPrice();
  }, [
    taxdetails,
    values.discountRate,
    values.discountType,
    values.productPrice,
  ]);
  async function deleteImage(index, val) {
    if (files.length > 1) {
      if (files[index]) {
        setFiles((preval) => {
          return preval.filter((val, id) => id !== index);
        });
      }
      //remove image api call here
      if (val.id) {
        await axiosInstance.get(`/api/v1/removeImage/${val.id}`).then((res) => {
          console.log(res.data);
        });
      }
    } else {
      console.log("you can not delete this image");
      setNotify({
        isOpen: true,
        message: "You can not delete all images",
        type: "error",
      });
    }
  }

  const handleSubmit = async (e, body) => {
    e.preventDefault();

    if (validate()) {
      var formData = new FormData();
      let cat_id;
      categorylist.forEach((val) => {
        if (val.productCategory == values.productCategory) {
          cat_id = val.id;
        }
      });
      formData.append("created_by", userid);
      formData.append("productDescription", values.productDescription);
      formData.append("productName", values.productName);
      formData.append("productCode", values.productCode);
      formData.append("productCategory", values.productCategory);
      formData.append("productPrice", values.productPrice);
      formData.append("uom", values.uom);
      formData.append("productSalesPrice", values.productSalesPrice);
      formData.append("discountType", values.discountType);
      formData.append("productSpecification", values.productSpecification);
      formData.append("discountRate", values.discountRate);
      formData.append("taxType", values.taxType);
      formData.append("taxRate", values.taxRate);
      formData.append("currency", values.currency);
      images.forEach((val, key) => {
        formData.append("productImage", val);
      });

      formData.append("iconFlag", iconFlag);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      await axiosInstance
        .put(`api/v1/productupdate/${id}`, formData, config)
        .then((res) => {
          setproductInput(res.data);
          if (res.data.status == true) {
            setNotify({
              isOpen: true,
              message: "Product Updated Successfully",
              type: "success",
            });
            setTimeout(() => {
              navigate("/product");
            }, 500);
          }
        })
        .catch((e) => {
          console.log("error found");
        });
    }
  };
  useEffect(() => {
    getTaxDetails(values.taxType);
  }, []);

  const getSingleProduct = async (e) => {
    setOpenBreakdrop(true);

    await axiosInstance
      .get(`api/v1/productdetail/${id}`)
      .then((res) => {
        setValues(res.data.data);
        setFiles(res.data.data.productImage);
      })
      .catch((e) => {
        console.log("error found");
      });
    setOpenBreakdrop(false);
  };

  const calculateTotal = (e, value) => {
    if (e.target.value) {
      getTaxDetails(e.target.value);
    }
    getTaxDetails(values.taxRate);
  };
  const getCurrencyDropdown = async (e) => {
    await axiosInstance
      .get(`/api/v1/getcurrency`)
      .then((res) => {
        setcurrencyData(res.data.data);
      })
      .catch((e) => {
        // console.log("error found")
      });
  };
  const tempState = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const tempUOM = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const getCategoryDropdown = async (e) => {
    await axiosInstance

      .get(`api/v1/categorydropdown`)
      .then((res) => {
        setcategorylist(res.data.data);
      })
      .catch((e) => {
        //  console.log("error found")
      });
  };
  const getUOM = async (e) => {
    await axiosInstance
      .get(`/api/v1/uomlist`)
      .then((res) => {
        setUomli(res.data.data);
      })
      .catch((e) => {
        //  console.log("error found")
      });
  };
  const getTaxDropdown = async (e) => {
    await axiosInstance
      .get(`api/v1/taxDropdown`)
      .then((res) => {
        settaxData(res.data.data);
      })
      .catch((e) => {
        // console.log("error found")
      });
  };
  const getTaxDetails = async (id) => {
    if (id) {
      await axiosInstance
        .get(`api/v1/taxdetails/${id}`)
        .then((res) => {
          settaxDetails(res.data.data);
          const texDatas = res.data.data;
          setValues({
            ...values,
            taxType: id,
            taxRate: texDatas.taxValue,
          });
        })
        .catch((e) => {
          // console.log("error found")
        });
    }
  };
  const calculateSalesPrice = (e) => {
    let salesPrice = 0;
    let discountRate = values.discountRate ? values.discountRate : 0;
    let discountType = values.discountType ? values.discountType : 0;
    let ProductPrice = values.productPrice ? values.productPrice : 0;
    let taxValue = values.taxRate ? values.taxRate : 0;
    let taxType = values.taxType ? values.taxType : 0;

    if (ProductPrice && discountRate && discountType && taxValue) {
      if (discountType == 2) {
        salesPrice =
          ProductPrice -
          (ProductPrice * discountRate) / 100 +
          (ProductPrice * taxValue) / 100;
      } else {
        salesPrice =
          ProductPrice - discountRate + (ProductPrice * taxValue) / 100;
      }
      setValues({
        ...values,
        productSalesPrice: salesPrice,
      });
    }
  };

  const getCurrency = (currData) => {
    setValues({ ...values, currency: currData.id });
  };
  async function handleImageItem(e) {
    const selectedFiles = e.target.files;
    var localFiles = [...Array.from(selectedFiles)];

    setImages((preval) => {
      return [...preval, ...localFiles];
    });

    let newImagearr = localFiles.map((file) => ({
      file: file,
      productImage: URL.createObjectURL(file),
    }));
    const newFiles = [...files, ...newImagearr];
    setFiles([...files, ...newImagearr]);
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
  };
  const resetImage = () => {
    setImage("");
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontWeight: "600",
              fontSize: "12px",
              marginBottom: "16px",
            }}
          >
            {" "}
            {t("Edit_product")}
          </h1>

          <p
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "34px",
              marginTop: "0px",
            }}
          >
            {t("Product")} |{" "}
            <span style={{ color: "black" }}>{t("Edit_Product_Details")}</span>
          </p>
        </div>
        <Box marginTop="20px" borderRadius="12px"></Box>
      </div>
      <Form onSubmit={handleSubmit}>
        <Box id="mainbox" style={{}}>
          <Grid item md={12} sm={8} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productName">
                {t("Product_Name")}
              </StyledFormLabel>
              <StyledTextField
                name={t("productName")}
                fullWidth
                inputProps={{ maxLength: 200 }}
                value={values.productName}
                onChange={handleInputChange}
                error={Boolean(errors.productName)}
                helperText={errors.productName}
              ></StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productCategory">
                {t("Category")}
                {/* {Boolean(errors.productcategory) && */}
                {/* } */}
              </StyledFormLabel>
              <StyledTextField
                id="productCategory"
                name={t("productCategory")}
                fullWidth
                select
                value={parseInt(values.productCategory)}
                onChange={tempState}
                error={Boolean(errors.productCategory)}
                helperText={errors.productCategory}
              >
                {categorylist ? (
                  categorylist.map((val) => {
                    return (
                      <MenuItem key={val.id} value={val.id}>
                        {val.productCategory}
                      </MenuItem>
                    );
                  })
                ) : (
                  <h1>{t("Data_Loading")}</h1>
                )}
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productCode">
                {t("Product_Code")}
              </StyledFormLabel>
              <StyledTextField
                name={t("productCode")}
                fullWidth
                value={values.productCode}
                onChange={handleInputChange}
                error={Boolean(errors.productCode)}
                helperText={errors.productCode}
              ></StyledTextField>
            </Grid>

            <Grid item xs={11.8}>
              <StyledFormLabel htmlFor="productDescription">
                {t("Description")}
              </StyledFormLabel>
              <TextField
                className="description"
                name={t("productDescription")}
                fullWidth
                multiline
                rows={3}
                value={values.productDescription}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              ></TextField>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="uom">{t("UOM")}</StyledFormLabel>
              <StyledTextField
                id="uom"
                name={t("uom")}
                fullWidth
                select
                value={parseInt(values.uom)}
                onChange={handleInputChange}
                error={Boolean(errors.uom)}
                helperText={errors.uom}
              >
                {uomli ? (
                  uomli.map((val) => {
                    return (
                      <MenuItem key={val.id} value={val.id}>
                        {val.name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <h1>{t("Data_Loading")}</h1>
                )}
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productSpecification">
                {t("Specification")}
              </StyledFormLabel>
              <StyledTextField
                name="productSpecification"
                fullWidth
                value={values.productSpecification}
                onChange={handleInputChange}
                error={Boolean(errors.productSpecification)}
                helperText={errors.productSpecification}
              ></StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="currency">{t("Currency")}</StyledFormLabel>
              <StyledTextField
                id="currency"
                name={t("currency")}
                fullWidth
                select
                value={parseInt(values.currency)}
                onChange={handleInputChange}
                error={Boolean(errors.currency)}
                helperText={errors.currency}
              >
                {currencyData.map((val) => {
                  return (
                    <MenuItem key={val.id} value={val.id}>
                      {val.name}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productPrice">
                {t("ProductPrice")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                rows={2}
                name={t("productPrice")}
                value={values.productPrice}
                onChange={handleInputChange}
                error={Boolean(errors.productPrice)}
                helperText={errors.productPrice}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="discountType">
                {t("Discount_Type")}
              </StyledFormLabel>
              <StyledTextField
                name={t("discountType")}
                fullWidth
                select
                value={values.discountType}
                onChange={handleInputChange}
                error={Boolean(errors.discountType)}
                helperText={errors.discountType}
              >
                <MenuItem key="1" value="1">
                  {t("Flat")}
                </MenuItem>
                <MenuItem key="2" value="2">
                  {t("Percentage")}
                </MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="discountRate">{t("Discount")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                rows={2}
                name={t("discountRate")}
                value={values.discountRate}
                onChange={handleInputChange}
                error={Boolean(errors.discountRate)}
                helperText={errors.discountRate}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="taxType">{t("Tax")}</StyledFormLabel>
              <StyledTextField
                id="taxType"
                name={t("taxType")}
                fullWidth
                select
                value={values.taxType}
                onChange={calculateTotal}
                error={Boolean(errors.taxType)}
                helperText={errors.taxType}
              >
                {taxData.map((val) => {
                  return (
                    <MenuItem key={val.id} value={val.id}>
                      {val.taxType}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="tax">{t("Tax_Amount")}</StyledFormLabel>
              <StyledTextField
                id="taxRate"
                name={t("taxRate")}
                fullWidth
                value={values.taxRate}
                onChange={handleInputChange}
                error={Boolean(errors.taxRate)}
                helperText={errors.taxRate}
              ></StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productSalesPrice">
                {t("Sales_Price")}
              </StyledFormLabel>
              <StyledTextField
                name={t("productSalesPrice")}
                fullWidth
                value={values.productSalesPrice}
                error={Boolean(errors.productSalesPrice)}
                helperText={errors.productSalesPrice}
              ></StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}></Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}></Grid>

            <Grid item={true} md={6} sm={6} xs={12}>
              <StyledImageUploadWrapper>
                <label htmlFor="image-upload">
                  <StyledInput
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    multiple
                    onChange={handleImageItem}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                  <StyledIconWrapper>
                    <img src={camera} alt="Camera" />
                  </StyledIconWrapper>
                </label>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  {t("Add_Photo")}
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(15, 15, 15, 0.5)",
                    mx: 2,
                    fontSize: "12px",
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                </Typography>
              </StyledImageUploadWrapper>
              <Grid container item={true} xs={12}>
                {files.map((file, id) => {
                  return (
                    <StyledIconWrapper
                      sx={{
                        position: "relative",
                        margin: "25px 5px 5px 5px",
                        width: "147px",
                        height: "140px",
                        backgroundImage: `url(${file.productImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      key={id + 1}
                      onClick={() => {
                        setIconFlag(id);
                      }}
                    >
                      <IconButton
                        size="large"
                        sx={{
                          position: "absolute",
                          right: "2%",
                          top: "3%",
                          width: "36px",
                          height: "36px",
                          background: "rgba(15, 15, 15, 0.5)",
                          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                          borderRadius: "10px",

                          color: "white",
                          "&:hover": {
                            backgroundColor: "#0957DD",
                          },
                        }}
                      >
                        <CloseIcon
                          sx={{ width: "20px", height: "20px" }}
                          onClick={() => {
                            deleteImage(id, file);
                          }}
                        />
                      </IconButton>
                    </StyledIconWrapper>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Box id="button-box">
            <Link to="/product/" className="link" id="linkcancel">
              <Button variant="outlined" size="large" id="b1">
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
            </Link>
            <Button
              variant="contained"
              size="large"
              id="submitButton"
              type="submit"
            >
              <strong>{button}</strong>
            </Button>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
