import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../customerPage/style/customerlist.css";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Avatar,
} from "@mui/material";
import eye from "../../assets/eye.svg";
import filterIcon from "../../assets/filter.svg";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useForm, Form } from "../../utils/useForms";
import { tableCellClasses } from "@mui/material/TableCell";
import { getAllCustomerList } from "../../state/action/customerAction";
import { useTranslation } from "react-i18next";
import UploadButton from "../Lead/UploadButton";

import { getContactList } from "../../state/action/contactAction";
import { getAdminsList, roleDropdown } from "../../state/action/adminAction";
import edit from "../../assets/edit.svg";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
}));

const validate = (values) => {
  const errors = {};
};
const initialValues = {
  contactName: "",
  email: "",
  phone: "",
  contactSource: "",
  contactOwner: "",
};

const UserList = (props) => {
  const { t } = useTranslation();

  const [role, setRole] = useState([]);
  const [adminlist, setAdminList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [search1, setsearch1] = useState("");
  const [records, setRecords] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const roleDropDownList = async () => {
    const res = await roleDropdown();
    if (res && res.status === true) {
      setRole(res.data);
    }
  };
  useEffect(() => {
    roleDropDownList();
  }, []);

  //get list

  const getadminListss = async () => {
    const res = await getAdminsList();
    if (res && res.status === true) {
      setAdminList(res.data);
    }
  };
  useEffect(() => {
    getadminListss();
  }, []);

  const navigate = useNavigate();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  return (
    <>
      <div>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              fontWeight: "600",
              paddingLeft: "10px",
            }}
          >
            {t("User List")}
          </Typography>
          {readPermissions && (
            <Box marginTop="20px" borderRadius="12px">
              <Button
                sx={{
                  height: "34px",
                  backgroundColor: "#F06237",
                  color: "white",
                  borderRadius: "10px",
                  padding: "5px 25px",
                  "&:hover": {
                    backgroundColor: "#cf5025",
                  },
                }}
                startIcon={<Add />}
                color="primary"
                onClick={() => {
                  navigate("/addUsers");
                }}
              >
                <span style={{ fontSize: "14px" }}>{t("Add_User")}</span>
              </Button>
            </Box>
          )}
        </Stack>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "30px",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Form>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{
                  height: "30px",
                }}
                placeholder={t("Search…")}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Search>
          </Form>
        </Stack>
        <TableContainer component={Paper} sx={{ height: "auto" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead style={{ fontSize: "14px" }}>
              <TableRow>
                <StyledTableCell>{t("Admin")}</StyledTableCell>
                <StyledTableCell>{t("Email")}</StyledTableCell>
                <StyledTableCell>{t("Role")}</StyledTableCell>
                <StyledTableCell>{t("ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminlist.length == 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    {t("No_records_to_display")}
                  </TableCell>
                </TableRow>
              ) : (
                adminlist.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        cursor: "pointer",
                      },
                    }}
                  >
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.firstName + "  " + row.lastName}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.role}
                    </StyledTableCell>

                    <StyledTableCell
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Box>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <div>
                              <MoreVertIcon {...bindTrigger(popupState)} />
                              <Menu
                                {...bindMenu(popupState)}
                                PaperProps={{
                                  sx: {
                                    marginTop: "13px",
                                    minWidth: "10em",
                                  },
                                }}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                              >
                                {editPermissions && (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close();

                                      navigate(`/addUsers`, {
                                        state: {
                                          user: row,
                                        },
                                      });
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={edit} alt="Edit/" />
                                    </ListItemIcon>
                                    {t("Edit")}
                                  </MenuItem>
                                )}
                              </Menu>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {" "}
          Showing {page * rowsPerPage > records
            ? records
            : page * rowsPerPage}{" "}
          of {records} Results
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default UserList;
