import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import axiosInstance from "../../utils/AxiosInstace";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Pagination,
} from "@mui/material";
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import filterIcon from "../../assets/filter.svg";
import StockDetailsModal from "./stockDetails";
import AddStock from "./stockAdd";
import { stockListApi } from "../../state/action/stockAction";
import { useForm } from "../../utils/useForms";
import Backdrop from "../Backdrop/loader";
import { tableCellClasses } from "@mui/material/TableCell";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "13px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const initialValues = {
  productName: "",
  productCode: "",
};

export default function CategoryList(props) {
  const { refresh, refreshScreen } = props;
  const dispatch = useDispatch();
  const [stocks, setStocks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentStock, setCurrentStock] = useState(-1);
  const [stockView, setStockView] = useState(false);
  const [deleteStock, setDeleteStock] = useState(false);
  const [addStock, setAddStock] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [pagePerSize, setPagePerSize] = useState(10);

  const location = useLocation();

  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  const handleChangePage = (event, value) => {
    console.log("value", value);
    setPage(value);
  };

  const { t } = useTranslation();

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const getAllstock = async () => {
    console.log("getAllstock", values);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      if (i == 0) {
        string += `&${key}=${value}`;
      } else {
        string += `&${key}=${value}`;
      }

      i++;
    }
    await axiosInstance
      .get(`api/v1/stock?limit=10&page=${page}&${string}&search=${search}`)
      .then((res) => {
        setStocks(res.data.data);
        let pagecount = Math.ceil(res.data.totalProduct / 10);
        setTotalRecords(pagecount);
        setTotalProducts(res.data.totalProduct);
        setFilter(false);
      })
      .catch((e) => {
        console.log("error found", e);
      });
  };

  useEffect(() => {
    getAllstock();
  }, [refresh, page, filterData, search]);

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentStock(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    // how to reset form
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    getAllstock();
  };
  const handlestockView = () => {
    setStockView(true);
  };
  const closestockView = () => {
    setStockView(false);
  };

  const handledeleteStock = () => {
    setDeleteStock(true);
  };
  const closedeleteStock = () => {
    setDeleteStock(false);
  };

  const openStockModal = () => {
    setAddStock(true);
  };
  const closeStockModal = () => {
    setAddStock(false);
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <ToastContainer limit={1} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "30px",
              }}
              placeholder={t("Search…")}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
            }}
          >
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Filter")}
                </Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="productName">
                  {t("Product_Name")}
                </StyledFormLabel>
                <StyledTextField
                  type="name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleInputChange}
                  placeholder={t("Search_Name")}
                  fullWidth
                  id="productName"
                  name={t("productName")}
                  value={values.productName}
                />
              </Box>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="productCode">
                  {t("Product_Code")}
                </StyledFormLabel>
                <StyledTextField
                  type="name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleInputChange}
                  placeholder={t("Search_Code")}
                  fullWidth
                  id="productCode"
                  name={t("productCode")}
                  value={values.productCode}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              style={{ position: "absolute", bottom: "0px", width: "100%" }}
              flexDirection="row"
              justifyContent="space-around"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  margin: "10px 10px",
                }}
                onClick={() => {
                  closeFilterDrawer();
                }}
              >
                {t("Reset")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  margin: "10px 10px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </form>
        </Drawer>
        {/* table */}
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("PRODUCT_CODE")}</StyledTableCell>
                <StyledTableCell>{t("PRODUCT_NAME")}</StyledTableCell>
                <StyledTableCell>{t("OPENING_STOCK")}</StyledTableCell>
                <StyledTableCell> {t("STOCK_ON_HAND")}</StyledTableCell>
                <StyledTableCell>{t("COMMITTED_STOCK")}</StyledTableCell>
                <StyledTableCell>{t("AVAILABLE_FOR_SALE")}</StyledTableCell>
                <StyledTableCell style={{ fontSize: "15px" }}>
                  {t("Action")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stocks.map((row, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/product/details`, { state: row.productId });
                    }}
                  >
                    <StyledTableCell align="left">
                      {row.productCode}
                    </StyledTableCell>

                    <StyledTableCell component="th" align="left" scope="row">
                      <Stack flexDirection="row" alignItems="left">
                        <Typography
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {row.productName}
                        </Typography>
                      </Stack>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {row.openingStock}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.quantity}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.commitedStock}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.quantity}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={(e) => {
                          const currentStock = stocks.find(
                            (role) => role.id === row.id
                          );
                          handleClick(e, currentStock);
                          e.stopPropagation();
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={(e) => {
            handleClose();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {readPermissions && (
            <MenuItem
              sx={{ mt: 1 }}
              onClick={() => {
                navigate(`/product/details`, { state: currentStock.productId });
              }}
            >
              <ListItemIcon>
                <img src={eye} alt="View Details" />
              </ListItemIcon>
              <ListItemText>{t("View_Details")}</ListItemText>
            </MenuItem>
          )}
          {editPermissions && (
            <MenuItem
              sx={{ mt: 1 }}
              onClick={() => {
                openStockModal();
              }}
            >
              <ListItemIcon>
                <img src={edit} alt="Edit" />
              </ListItemIcon>
              <ListItemText>{t("Edit")}</ListItemText>
            </MenuItem>
          )}
        </Menu>
        {stockView && (
          <StockDetailsModal
            stock={currentStock}
            open={stockView}
            setOpen={setStockView}
            handleClose={closestockView}
          />
        )}
        {addStock && (
          <AddStock
            stock={currentStock}
            open={addStock}
            setOpen={setAddStock}
            handleClickOpen={openStockModal}
            handleClose={closeStockModal}
            refreshScreen={refreshScreen}
          />
        )}
        {/* <DeleteCategory
          refreshScreen={refreshScreen}
          category={currentCategory}
          open={deleteCategory}
          handleClose={closedeleteCategory}
        /> */}
      </div>

      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {t("Showing")}{" "}
          {page * pagePerSize > totalProducts
            ? totalProducts
            : page * pagePerSize}{" "}
          {t("of")} {totalProducts} {t("Results")}
        </div>
        <Pagination
          count={totalRecords}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
