import * as React from 'react';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Grid, Button, Stack, Typography, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import axiosInstance from "../../utils/AxiosInstace";
import { Routes, Route, useParams } from 'react-router-dom';
import product_image from "../../assets/Rectangle753.png";
import Notification from "../../utils/Notification"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {

    Table,
    TableHead,
    TableBody,
    TableRow,
    Chip,
} from "@mui/material";
import "./purchaseOrderStyle/purdchaseOrderDetails.css";
const strong = {
    color: 'black',
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "white",
        color: theme.palette.common.black,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "12px",
        paddingTop: "5px",
        paddingBottom: "5px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },

}));
const FlexBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))


const StyledChip = styled(Chip)(() => ({
    borderRadius: "10px",
    fontSize: "14px",
}));


export default function FormPropsTextFields() {
    let supplierNames = "";
    const [deleteUser, setDeleteUser] = useState(false);
    const { t } = useTranslation();
    const [orderProductData, setorderProductData] = useState([]);
    const [orderData, setorderData] = useState([]);
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });

    const [allUsers, setAllUsers] = useState([]);

    const location = useLocation();
    const [supplierData, setsupplierData] = useState({});
    const [supplshippingAddress, setshippingAddress] = useState({});
    const [billingAddress, setbillingAddress] = useState({});
    const [productdetails, setProductDetails] = useState({});
    // let id = location.state ? location.id : "";
    const { state, pathname } = useLocation()
    const navigate = useNavigate();

    const GetpurchaseOrder = async () => {
        console.log("state", state)
        await axiosInstance
            .get(`api/v1/purchaseorderdetail/${state}`).then((res) => {

                setProductDetails(res.data.data.products[0])
                setorderProductData(res.data.data.products);
                setsupplierData(res.data.data.supplier);
                setshippingAddress(res.data.data.supplier.address)
                setbillingAddress(res.data.data.supplier.billingAddress)
                setorderData(res.data.data);

            }).catch((e) => {
                console.log("error found")
            })
    }
    useEffect(() => {
        GetpurchaseOrder();
    }, [])
    const handleDeleteUser = () => {
        setDeleteUser(true);
    };
    const closeDeleteUser = () => {
        setDeleteUser(false);
    };

    const orderStatusChange = async (event) => {
        let toastType = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        };
        //api call
        await axiosInstance
            .post(`/api/v1/purchaseorderstatus/${state}`, {
                status: 1
            })
            .then((res) => {
                if (res && res.status) {
                    setNotify({
                        isOpen: true,
                        message: "order Status Change successfully",
                        type: "success",
                    });
                    setTimeout(() => {
                        // navigate('orderPage/orderdetails')
                        // same page in navigate with out refresh
                        navigate('/purchaseorder', { state: state })

                    }, 1000);
                } else {
                    setNotify({
                        isOpen: true,
                        message: res.message,
                        type: "error",
                    });
                }
            })

            .catch((err) => {
                // toast.error(err.message, toastType);
                setNotify({
                    isOpen: true,
                    message: err.message,
                    type: "error",
                });
            }
            );
    };

    const productStatus = (productstatus) => {
        const statusColors = {
            0: {
                key: "Closed",
                backgroundColor: "rgba(240, 68, 56, 0.05)",
                color: "#f05252;",
            },
            1: {
                key: "Partial",
                backgroundColor: "#ede7dd",
                color: "#fc7e00",
            },
            2: {
                key: "Pending",
                backgroundColor: "#fdf6b2",
                color: "#c27803",
            },
            3: {
                key: "Paid",
                backgroundColor: "#def7ec",
                color: "#0e9f6e",
            },
        };

        return <StyledChip style={{
            width: "30%",
            height: "23px",
            fontSize: "10px",
        }} sx={statusColors[productstatus]} label={statusColors[productstatus] ? statusColors[productstatus].key : ""} />;
    }

    const orderStatus = (status) => {
        const statusColors = {
            0: {

                key: "Draft",
                backgroundColor: "#ede7dd",
                color: "#fc7e00",
            },
            1: {

                key: "Confirmed",
                backgroundColor: "#def7ec",
                color: "#0e9f6e",
            },
            2: {
                key: "Closed",
                backgroundColor: "rgba(240, 68, 56, 0.05)",
                color: "#f05252;",
            },
        };
        return <StyledChip style={{
            width: "30%",
            height: "23px",
            fontSize: "10px",
        }} sx={statusColors[status]} label={statusColors[status] ? statusColors[status].key : ""} />;
    }
    return (

        < >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <h1 style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "22px",
                        marginBottom: "8px",
                    }}> Order </h1>
                </div>
                <Box borderRadius="12px"
                >

                    {orderData.status == 0 ? <Button variant="contained" size="large"
                        style={{
                            backgroundColor: '#f06237',
                            borderRadius: '10px',
                            marginRight: '10px',
                        }}
                        onClick={(e) => {
                            orderStatusChange(e);
                        }}
                    >
                        <strong style={{ color: "white", fontWeight: '500', fontSize: "13px" }}>MARK AS CONFIRMED</strong>
                    </Button>
                        : null}

                    {orderData.status != 0 ?
                        <Button variant="contained" size="large"
                            style={{
                                backgroundColor: '#f06237',
                                borderRadius: '10px',
                            }}
                            onClick={() => {
                                console.log("sssss", orderData.id)
                                navigate(`/purchaseOrder/invoice`, { state: orderData.id })

                            }}


                        >
                            <strong style={{ color: "white", fontWeight: '500', fontSize: "13px" }}>{orderData.billId == 0 ? 'Convert to Bill' : 'View Bill'}</strong>
                        </Button>
                        : null}

                </Box>

            </div>
            <Card style={{
                padding: "20px",
                borderRadius: "12px",
                marginTop: "20px",

            }}>
                <Grid item md={12} sm={8} xs={12} container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                className="purchase-order-details-title">
                                {t("PURCHASE_ORDER")}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id="purchase-order-details-number" >
                                {orderData.orderNumber}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>


                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                id='purchase-order-details-status'
                            >
                                {t("Status")}
                            </Typography>
                        </Grid>


                        <Grid container md={12} sm={12} xs={12}>
                            <Grid item md={3} sm={12} xs={12}
                                style={{
                                    borderLeft: "4px solid rgba(240, 98, 55, 1)",
                                }}
                            >
                                <Typography variant="h6" sx={{ mt: 0.5, ml: 1, }}
                                    id='purchase-order-details-status-value'>
                                    {t("Order_Status")}
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                                <Typography variant="h6"
                                    id='purchase-order-details-status-value2'>
                                    {orderStatus(orderData.status)}
                                </Typography>

                            </Grid>

                            <Grid item md={3} sm={12} xs={12}
                                style={{
                                    borderLeft: "4px solid rgba(240, 98, 55, 1)",
                                }}>
                                <Typography variant="h6" id='purchase-order-details-status-value'>
                                    {t("Payment_Status")}
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                                <Typography variant="h6"
                                    id='purchase-order-details-status-value2'>
                                    {productStatus(orderData.paymentStatus)}
                                </Typography>
                            </Grid>
                            <Grid item md={3.3} sm={12} xs={12} >
                                <Typography variant="h6"
                                    id='purchase-order-details-status-value33'>
                                    {t("Order_Date")}
                                </Typography>
                            </Grid>

                            <Grid item md={8.5} sm={12} xs={12}>
                                <Typography variant="h6"
                                    id="purchase-order-details-status-value3">
                                    {orderData.orderDate}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={6} xs={12} id="purchase-order-details-right-side" >
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id="title-vendor-name" >
                                {supplierData.supplierName}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id='text-right'>
                                {supplierData.mobileNumber}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id="text-right1">
                                {supplierData.companyEmail}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id='text-right2'>
                                {billingAddress.address}
                            </Typography>
                            <Typography variant="h6" id='text-right3'>
                                {billingAddress.city},
                                {billingAddress.state},
                            </Typography>
                            <Typography variant="h6" id='text-right4'>
                                {billingAddress.country}
                            </Typography>
                            <Typography variant="h6" id='text-right4'>
                                {billingAddress.zipCode}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id="title-vendor-name" >
                                Shipping Address
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id='text-right2'>
                                {supplshippingAddress.address}
                            </Typography>
                            <Typography variant="h6" id='text-right3'>
                                {supplshippingAddress.city},
                                {supplshippingAddress.state},
                            </Typography>
                            <Typography variant="h6" id='text-right4'>
                                {supplshippingAddress.country}
                            </Typography>
                            <Typography variant="h6" id='text-right4'>
                                {supplshippingAddress.zipCode}
                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>

                <Table stickyHeader aria-label="simple table"
                    style={{ marginTop: "25px" }}
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t("PROUDUCT_NAME")}</StyledTableCell>
                            <StyledTableCell align="center">{t("QUANTITY")}</StyledTableCell>
                            <StyledTableCell align="center">{t("RATE")}</StyledTableCell>
                            <StyledTableCell align="right">{t("AMOUNT")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderProductData.map((row, index) => (

                            <StyledTableRow
                                key={row.name}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    // hover color add
                                }} onClick={() => {

                                    navigate(`/product/details`, { state: row.productId })


                                }}

                            >

                                <StyledTableCell component="th" scope="row">
                                    <Stack flexDirection="row" alignItems="center">
                                        <Avatar
                                            component="span"
                                            variant="rounded"
                                            alt="profile_image"
                                            src={row.productImage}
                                        />
                                        <Typography sx={{ ml: 2, fontSize: "12px" }}>{row.productName}</Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                                <StyledTableCell align="center">{row.price}</StyledTableCell>
                                <StyledTableCell align='right'>{row.amount}</StyledTableCell>
                            </StyledTableRow >
                        ))}

                    </TableBody>
                </Table>
                <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}></div>
                <FlexBox px={2} id="purchase-order-details-footer" >
                    <Box display="flex">
                        <Box pr={6}
                            style={{
                                paddingRight: "43px"
                            }}
                        >
                            <Typography id="purchase-order-details-footer-subtotal" sx={{ my: 2 }}>{t("Subtotal")}:</Typography>
                            <Typography id="purchase-order-details-footer-tax" sx={{ mb: 2 }} >{t("Other_Charges")}</Typography>
                            <Typography id="purchase-order-details-footer-shipping" sx={{ mb: 2 }} style={{ marginTop: "10px" }}>{t("Discount")}</Typography>
                            <Typography id="purchase-order-details-footer-shipping" sx={{ mb: 2, }}>{t("Adjustment")}</Typography>
                        </Box>
                        <div
                            style={{
                                marginLeft: "21px"
                            }}
                        >
                            <Typography id="purchase-order-details-footer-subtotal-value" sx={{ my: 2 }}><b>{orderData.subtotal}</b></Typography>
                            <Typography id="purchase-order-details-footer-tax-value" sx={{ mb: 2 }} style={{ marginTop: "18px" }}>{orderData.otherCharges}</Typography>
                            <Typography id="purchase-order-details-footer-shipping-value" sx={{ mb: 2 }} style={{ marginTop: "14px" }}>{orderData.discountValue}</Typography>
                            <Typography id="purchase-order-details-footer-shipping-value" sx={{ mb: 2, }} style={{ marginTop: "22px" }}>{orderData.adjustment}</Typography>
                        </div>
                    </Box>
                </FlexBox>

                <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}></div>

                <FlexBox px={2} id="purchase-order-details-footer" >
                    <Box display="flex">
                        <Box pr={6}>
                            <Typography sx={{ mb: 2 }}>Total</Typography>
                        </Box>
                        <div id="purchase-order-details-footer-total" style={{ marginLeft: "30px" }} >
                            <Typography sx={{ mb: 2 }}><b>{orderData.payableAmount - parseInt(orderData.adjustment) - parseInt(orderData.discountValue)} </b></Typography>

                        </div>
                    </Box>
                </FlexBox>
            </Card>
            <Notification notify={notify} setNotify={setNotify} />

        </>
    );
}