import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../customerPage/style/customerlist.css";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Grid,
  Modal,
} from "@mui/material";
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import filterIcon from "../../assets/filter.svg";
// import UserDeleteModal from "./customerDelete";
import axiosInstance from "../../utils/AxiosInstace";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useForm, Form } from "../../utils/useForms";
import { tableCellClasses } from "@mui/material/TableCell";
import { getAllCustomerList } from "../../state/action/customerAction";
import { useTranslation } from "react-i18next";

import {
  getAccountList,
  deleteAccountss,
} from "../../state/action/accountaction";
import DeleteIcon from "@mui/icons-material/Delete";
import Notification from "../../utils/Notification";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
}));

const validate = (values) => {
  const errors = {};
};
const initialValues = {};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const strong = {
  color: "black",
};

const AccountList = (props) => {
  const { t } = useTranslation();

  const [accountList, setAccountList] = useState([]);

  const [allUsers, setAllUsers] = useState([]);

  const [deleteUser, setDeleteUser] = useState(false);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [user, setUser] = useState([]);
  const [search1, setsearch1] = useState("");
  const [records, setRecords] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [refresh, setRefresh] = useState(false);

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlesubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  //delete
  const [deleteaccount, setDeleteAccount] = useState(false);

  const handleDeleteAccountOpen = () => setDeleteAccount(true);
  const handleDeleteAccountClose = () => setDeleteAccount(false);

  const handleDelete = async (id) => {
    const res = await deleteAccountss(id);
    if (res && res.status) {
      handleDeleteAccountClose();
      refreshScreen();
      setNotify({
        isOpen: true,
        message: res.message || "Delete Successfully.",
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  //account list

  const getaccountList = async (e) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let queryString = `?page=${page}&limit=${rowsPerPage}&search=${search1}${string}`;

    const res = await getAccountList(queryString);
    if (res.status === true) {
      setAccountList(res.data);
      setRecords(res.total);
      setTotalPages(Math.ceil(res.total / rowsPerPage));
      setFilter(false);
    }
  };
  useEffect(() => {
    getaccountList();
  }, [page, search1, filterData]);

  const navigate = useNavigate();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };

  const getAccountListss = async (e) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&limit=${rowsPerPage}&search=${search1}${string}`;

    const res = await getAccountList(queryString);
    if (res.status === true) {
      setAccountList(res.data);
      setRecords(res.total);
      setTotalPages(Math.ceil(res.total / rowsPerPage));
      setFilter(false);
    }
  };

  useEffect(() => {
    getAccountListss();
  }, [page, search1, filterData]);

  return (
    <>
      <div>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              fontWeight: "600",
              paddingLeft: "10px",
            }}
          >
            {t("Accounts")}
          </Typography>

          {editPermissions && (
            <Button
              sx={{
                height: "34px",
                backgroundColor: "#F06237",
                color: "white",
                borderRadius: "10px",
                padding: "5px 25px",
                "&:hover": {
                  backgroundColor: "#cf5025",
                },
              }}
              startIcon={<Add />}
              color="primary"
              onClick={() => {
                navigate("/addaccount");
              }}
            >
              <span style={{ fontSize: "14px" }}>{t("Add_Account")}</span>
            </Button>
          )}
        </Stack>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "30px",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Form>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{
                  height: "30px",
                }}
                placeholder={t("Search…")}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setsearch1(e.target.value)}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Search>
          </Form>
          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <Form onSubmit={handlesubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Filter")}
                </Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              {/* <Box sx={{ margin: "8px auto" }}> */}
              <StyledFormLabel htmlFor="accountName">
                {" "}
                {t("accountName")}
              </StyledFormLabel>
              <StyledTextField
                placeholder={t("accountName")}
                fullWidth
                id="accountName"
                name="accountName"
                value={values.accountName}
                onChange={handleInputChange}
              />

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="phone">{t("phone")}</StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("phone")}
                  fullWidth
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="website	">
                  {t("website	")}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("website	")}
                  fullWidth
                  id="website	"
                  name="website"
                  value={values.website}
                  onChange={handleInputChange}
                />
              </Box>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="leadOwner">
                  {t("leadOwner")}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("leadOwner")}
                  fullWidth
                  id="leadOwner"
                  name="leadOwner"
                  value={values.leadOwner}
                  onChange={handleInputChange}
                />
              </Box>
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {},
                }}
                onClick={closeFilterDrawer}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </Form>
        </Drawer>
        <TableContainer component={Paper} sx={{ height: "auto" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead style={{ fontSize: "14px" }}>
              <TableRow>
                <StyledTableCell>{t("accountName")}</StyledTableCell>
                <StyledTableCell>{t("phone")}</StyledTableCell>
                <StyledTableCell> {t("website")}</StyledTableCell>
                <StyledTableCell>{t("leadOwner")}</StyledTableCell>
                <StyledTableCell>{t("ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountList.length == 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    {t("No_records_to_display")}
                  </TableCell>
                </TableRow>
              ) : (
                accountList.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      navigate(`/accountdetails`, {
                        state: {
                          account: row.id,
                        },
                      });
                    }}
                  >
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.accountName}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.phone}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.website}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.leadOwnerFirstName + "  " + row.leadOwnerLastName}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Box>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <div>
                              <MoreVertIcon {...bindTrigger(popupState)} />
                              <Menu
                                {...bindMenu(popupState)}
                                PaperProps={{
                                  sx: {
                                    marginTop: "13px",
                                    minWidth: "10em",
                                  },
                                }}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                              >
                                {readPermissions && (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close();
                                      setUser(row);
                                      navigate(`/accountdetails`, {
                                        state: {
                                          account: row.id,
                                        },
                                      });
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={eye} alt="View Details/" />
                                    </ListItemIcon>
                                    {t("View_Account")}
                                  </MenuItem>
                                )}
                                {editPermissions && (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close();
                                      setUser(row);
                                      navigate(`/addaccount`, {
                                        state: {
                                          account: row.id,
                                        },
                                      });
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={edit} alt="Edit/" />
                                    </ListItemIcon>
                                    {t("Edit")}
                                  </MenuItem>
                                )}

                                {deletePermissions && (
                                  <MenuItem
                                    onClick={() => {
                                      setDeleteId(row.id);
                                      handleDeleteAccountOpen();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <DeleteIcon />
                                    </ListItemIcon>
                                    {t("Delete")}
                                  </MenuItem>
                                )}
                              </Menu>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={deleteaccount}
          onClose={handleDeleteAccountClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ marginTop: "-25px" }}>
              <StyledFormLabel htmlFor="zipCode">
                Are you sure you want to delete this Account ?
              </StyledFormLabel>
            </Box>

            <Grid
              container
              gap={1}
              style={{
                marginTop: "1rem",
                justifyContent: "end",
                display: "flex",
              }}
            >
              <Grid
                item
                className="text-end"
                sx={{ justifyContent: "end", display: "flex" }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  className="buttonCancel"
                  onClick={handleDeleteAccountClose}
                  style={{
                    borderRadius: "10px",
                    width: "73%",
                    height: "94%",
                  }}
                >
                  <strong style={strong}>{t("Cancel")}</strong>
                </Button>{" "}
              </Grid>
              <Grid item className="text-end">
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    backgroundColor: "#f06237",
                    borderRadius: "10px",
                  }}
                  onClick={() => handleDelete(deleteId)}
                >
                  <strong className="purchaseOrderheadder-box-button2-strong">
                    {t("Delete")}
                  </strong>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {" "}
          Showing {page * rowsPerPage > records
            ? records
            : page * rowsPerPage}{" "}
          of {records} Results
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default AccountList;
