import { Box } from "@mui/material";
import React from "react";
import RolesList from "../AddRole/RolesList";

const Roles = () => {
  return (
    <Box>
      <RolesList />
    </Box>
  );
};

export default Roles;
