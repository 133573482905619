export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMERS = "DELETE_CUSTOMERS"
export const GET_CUSTOMER = "GET_CUSTOMER"
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"

export const GET_ADDRESS = "GET_ADDRESS"
export const GET_DOCUMENTS = "GET_DOCUMENTS"
export const GET_ORDERS = "GET_ORDERS"
export const UPDATE_DOCUMENTS_STATUS = "UPDATE_DOCUMENTS_STATUS"

export const UPDATE_CUSTOMER_BUSINESS_DETAILS='UPDATE_CUSTOMER_BUSINESS_DETAILS'
