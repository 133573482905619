import React, { useState, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import invoice from "../../assets/Icon.svg";
import { useTranslation } from 'react-i18next';

import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
} from "@mui/material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Avatar,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import {
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
// icons for user menu
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import block from "../../assets/block.svg";
//  import { GetSuppliers } from "../../../state/action/supplierAction"
import { useSelector, useDispatch } from "react-redux"
//  filter icons
import filterIcon from "../../assets/filter.svg";
//  modals with respect to menu
// import UserViewModal from "./AddCustomer/addcustomer";
// import UserDeleteModal from "./customerDelete/customerDelete";
import { useFormik } from "formik";
import axiosInstance from "../../utils/AxiosInstace";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useForm, Form } from "../../utils/useForms";
import { tableCellClasses } from '@mui/material/TableCell';


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add 
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",

}));
const StyledChip = styled(Chip)(() => ({

  fontSize: "14px",
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  // },
  '&:last-child td, &:last-child th': {
    border: 0,
  },

}));
const validate = (values) => {
  const errors = {};
}
const initialValues = {
  customerName: "",
  companyName: '',
  orderNumber: '',
  paymentStatus: '',
  invoiceNo: ''

};

export default function Invoicelist(props) {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);




  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const [userView, setUserView] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [allInvoice, setAllInvoice] = useState([]);
  const [user, setUser] = useState([]);
  const [search1, setsearch1] = useState(" ");
  const [records, setRecords] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const { t } = useTranslation();

  const handleChangePage = (event, value) => {
    setPage(value);
    getAllSalesOrderInvoice()
  };
  useEffect(() => {
    getAllSalesOrderInvoice();
  }, [page, search1, filterData])
  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
    getAllSalesOrderInvoice()
  }

  console.log("allInvoice", values)

  const getAllSalesOrderInvoice = async () => {

    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      if (i == 0) {
        string += `&${key}=${value}`;
      } else {
        string += `&${key}=${value}`;
      }
      i++;
    }
    await axiosInstance
      .get(`api/v1/salesorderinvoicelist?limit=10&page=${page}${string}&search=${search1}`).then((res) => {
        setAllInvoice(res.data.data)
        setRecords(res.data.data.length);
        setTotalorder(res.data.totalInvoice)
        let pagecount = Math.ceil((res.data.totalInvoice) / 10)
        setFilter(false);


        setTotalPages(pagecount)
      }).catch((e) => {
        setAllInvoice([])
        console.log("error found", e)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData)
    getAllSalesOrderInvoice()

  }
  const viewItemDetails = (rowData) => {

  }
  const { openEditmodel } = props;


  const selectCurrentUser = (index) => {
    setCurrentUser(index);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentUser(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentUser(-1);
  }
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };



  const handleDeleteUser = () => {

    setDeleteUser(true);
  };
  const closeDeleteUser = () => {
    setDeleteUser(false);
  };
  const navigate = useNavigate();
  const productStatus = (productstatus) => {
    const statusColors = {
      0: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
      1: {
        key: "Partial",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: "Paid",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
    };

    return <StyledChip style={{
      width: "73%",
      height: "23px",
      fontSize: "12px",
    }} sx={statusColors[productstatus]} label={statusColors[productstatus].key} />;
  }


  return (
    <>
      <div>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography sx={{ fontSize: "22px", fontWeight: "bold", fontWeight: '600', paddingLeft: "10px" }}>
            {t("Invoice")}
          </Typography>

        </Stack>

      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "30px",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >

          <Form>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{
                  height: "30px",
                }}
                placeholder={t("Search…")}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Search>
          </Form>
          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",

            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt={t("Filter_Options")} />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black"
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>{t("Filter")}</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer1}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <StyledFormLabel htmlFor="name"> {t("customer_Name")}</StyledFormLabel>
              <StyledTextField
                placeholder={t("Search_customer")}
                fullWidth
                id="customerName"
                name={t("customerName")}
                value={values.customerName}
                onChange={handleInputChange}
              />

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">{t("company_Name")}</StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Enter_email")}
                  fullWidth
                  id="companyName"
                  name={t("companyName")}
                  value={values.companyName}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="orderNumber">
                  {t("Order_Number")}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("orderNumber")}
                  fullWidth
                  id="orderNumber"
                  name={t("orderNumber")}
                  value={values.orderNumber}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="invoiceNo">
                  {t("Invoice_Number")}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_Invoice_No")}
                  fullWidth
                  id="invoiceNo"
                  name="invoiceNo"
                  value={values.invoiceNo}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel>{t("Payment_Status")}</StyledFormLabel>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <input
                        id="myInput"
                        type="radio"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={0}
                      />
                    }
                    label={t("Closed")}
                  />
                  <FormControlLabel
                    control={
                      <input
                        id="myInput"
                        type="radio"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={1}
                      />
                    }
                    label={t("Partial")}
                  />
                  <FormControlLabel
                    control={
                      <input
                        id="myInput"
                        type="radio"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={2}
                      />
                    }
                    label={t("Pending")}
                  />
                  <FormControlLabel
                    control={
                      <input
                        id="myInput"
                        type="radio"
                        onChange={handleInputChange}
                        name={t("paymentStatus")}
                        value={3}
                      />
                    }
                    label={t("Paid")}
                  />
                </FormGroup>
              </Box>


            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer
          component={Paper}
          sx={{ height: "auto" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("CUSTOMER_NAME")}</StyledTableCell>
                <StyledTableCell>{t("COMPANY_NAME")}</StyledTableCell>
                <StyledTableCell> {t("INVOICE_NO")}</StyledTableCell>
                <StyledTableCell> {t("ORDER_NUMBER")}</StyledTableCell>
                <StyledTableCell> {t("ADJUSTMENT")}</StyledTableCell>
                <StyledTableCell> {t("PAYMENT_STATUS")}</StyledTableCell>
                <StyledTableCell>{t("ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                allInvoice.length === 0 ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="7"
                    >
                      {t("No_records_to_display")}
                    </TableCell>
                  </TableRow>
                ) : (
                  allInvoice.map((row, index) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      onClick={() => {
                        navigate(`/orderPage/orderInvoice`, { state: row.orderId })

                      }} >
                      <StyledTableCell component="th" scope="row" >
                        <Typography sx={{ ml: 2, fontSize: "14px" }}>{row.customerName}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>{row.companyName}</StyledTableCell>
                      <StyledTableCell>{row.invoiceNo}</StyledTableCell>
                      <StyledTableCell>{row.orderNumber}</StyledTableCell>
                      <StyledTableCell>{row.adjustment}</StyledTableCell>
                      <StyledTableCell>{productStatus(row.paymentStatus)}</StyledTableCell>
                      <StyledTableCell onClick={(e) => {
                        e.stopPropagation();
                      }}>

                        <Box>
                          <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                              <div>
                                <MoreVertIcon
                                  {...bindTrigger(popupState)} />
                                <Menu
                                  {...bindMenu(popupState)}
                                  PaperProps={{
                                    sx: {
                                      marginTop: "13px",
                                      minWidth: "10em",
                                    },
                                  }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                  }}
                                >
                                  < MenuItem
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "none",
                                      fontSize: "14px",
                                    }}
                                    onClick={() => {
                                      viewItemDetails(row)
                                      popupState.close();
                                      setUser(row);
                                      console.log("1111", row)
                                      navigate(`/orderPage/orderInvoice`, { state: row.orderId })
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={invoice} alt="View Details/" />
                                    </ListItemIcon>
                                    {t("Sale_Order_Invoice")}
                                  </MenuItem>

                                  <MenuItem
                                    style={{ color: "red" }}
                                    onClick={() => {
                                      // DeleteItem(item);
                                      handleDeleteUser();
                                      setUser(row);
                                      popupState.close();
                                    }}
                                  >

                                    <ListItemIcon>
                                      <img src={deleteIcon} alt="Delete" />
                                    </ListItemIcon>
                                    {t("Delete")}
                                  </MenuItem>
                                </Menu>
                              </div>
                            )}
                          </PopupState>
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  ))
                )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <UserDeleteModal
        userlist={getAllCustomerPayment}
        page={page}
        userid={user.id}
        user={setUser}
        open={deleteUser}
        handleClose={closeDeleteUser}
      /> */}
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {t("Showing")} {records} {t("of")} {totalorder} {t("Results")}
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  )
}