import { applyMiddleware, compose, createStore } from "redux"
import { configureStore } from '@reduxjs/toolkit'
import thunk from "redux-thunk"
import reducers from "./reducers/index"
import authReducer from "../state/reducers/authReducer";
import suppliersReducer from "../state/reducers/supplierReducer";
import customerReducer from "../state/reducers/customerReducer";
const initialState = {};
// const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(thunk)))

export const store = configureStore({
  reducer: {
    suppliers: suppliersReducer,
    customer: customerReducer,
    auth: authReducer,
    //   filters: filtersReducer
  }

})