import axiosInstance from "../../utils/AxiosInstace";


export const MeetingAdd = async (payload) => {
    try {
        const response = await axiosInstance.post(`/api/v1/addMeeting`, payload);
        return response.data;
    } catch (error) {
        return error;
    }
}
