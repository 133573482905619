import { Box } from "@mui/system";
import React from "react";
import CampaginList from "./CampaginList";

const BulkEmail = () => {
  return (
    <Box>
      <CampaginList />
    </Box>
  );
};

export default BulkEmail;
