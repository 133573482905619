import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import CategoryList from "./CategoryList";
import AddCategory from "./AddCategory";
import Notification from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";
import { useLocation } from "react-router-dom";

const CategoryPage = () => {
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const location = useLocation();

  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [addCategory, setAddCategory] = useState(false);
  const openCategoryModal = () => {
    setAddCategory(true);
  };
  const closeCategoryModal = () => {
    setAddCategory(false);
  };
  const { t } = useTranslation();
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
          {t("Category")}
        </Typography>
        {editPermissions && (
          <Button
            sx={{
              backgroundColor: "#F06237",
              color: "white",
              borderRadius: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              "&:hover": {
                backgroundColor: "#F06237",
              },
            }}
            onClick={openCategoryModal}
            startIcon={<Add />}
            color="primary"
          >
            {t("Add_Category")}
          </Button>
        )}
      </Stack>
      <AddCategory
        open={addCategory}
        setOpen={setAddCategory}
        handleClickOpen={openCategoryModal}
        handleClose={closeCategoryModal}
        refreshScreen={refreshScreen}
        setNotify={setNotify}
      />
      <Box sx={{ mt: 4 }}>
        <CategoryList
          refresh={refresh}
          refreshScreen={refreshScreen}
          setNotify={setNotify}
        />
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
export default CategoryPage;
