import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';
import "./purchaseOrderStyle/invoice.css";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Chip,
    TableContainer
} from "@mui/material";
import { useState, useEffect } from 'react';
import { Grid, Button, Stack, Typography, IconButton } from '@mui/material';
import axiosInstance from "../../utils/AxiosInstace";
import Notification from "../../utils/Notification";
import { Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import { tableCellClasses } from '@mui/material/TableCell';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import moment from "moment";
const StyledChip = styled(Chip)(() => ({
    borderRadius: "10px",
    fontSize: "14px",
}));

const strong = {
    color: 'black',
}

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))
const StyledRequired = styled("span")({
    color: "rgba(240, 98, 55, 1)",
    marginLeft: "2px",
    fontSize: "16px",
});
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(224, 224, 224, 1)",
        color: theme.palette.common.black,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "12px",
        paddingTop: "5px",
        paddingBottom: "5px",
        height: "30px",
    },
}));


const orderStatus = (status) => {
    const statusColors = {
        0: {

            key: "Draft",
            backgroundColor: "#ede7dd",
            color: "#fc7e00",
        },
        1: {

            key: "Confirmed",
            backgroundColor: "#def7ec",
            color: "#0e9f6e",
        },
        2: {
            key: "Closed",
            backgroundColor: "rgba(240, 68, 56, 0.05)",
            color: "#f05252;",
        },
    };

    return <StyledChip style={{
        width: "81%",
        height: "23px",
        fontSize: "12px",
    }} sx={statusColors[status]} label={statusColors[status].key} />;
}

export default function FormPropsTextFields() {
    const navigate = useNavigate();
    const location = useLocation();
    const {t }=useTranslation();
    const { state, pathname } = useLocation()
    const pdfExportComponent = React.useRef(null);
    const [deleteUser, setDeleteUser] = useState(false);
    const [billdata, setBilldata] = useState({});
    const [billId, setBillId] = useState({});
    const [productData, serProductData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [address, setAddress] = useState([]);
    const [adjustment, setAdjustment] = useState(0);
    const [Payable, setPayable] = useState(0);
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });




    let supplierName = "";
    const GetBill = async () => {


        console.log('id', state);
        await axiosInstance
            .get(`api/v1/purchaseorderdetail/${state}`).then((res) => {
                console.log("data----------------------", res.data.data);
                let da = res.data.data.billId;
                console.log("lll", da)

                setBillId(da);
                setBilldata(res.data.data)
                setPayable(res.data.data.payableAmount)
                serProductData(res.data.data.products)
                setSupplierData(res.data.data.supplier)
                setAddress(res.data.data.supplier.address);


            }).catch((e) => {
                console.log("error found")
            })
    }

    useEffect(() => {
        GetBill();
    }, []);

    console.log("bilData", billdata);
    const downloadPdf = async () => {
        if (pdfExportComponent.current) {
            await pdfExportComponent.current.save("Invoice.pdf" + ".pdf" + "?download=true");
        }
    }
    const handleDeleteUser = () => {
        setDeleteUser(true);
    };
    const closeDeleteUser = () => {
        setDeleteUser(false);
    };
    const handleSubmit = async () => {
        const payload = {
            "adjustment": adjustment
        }
        if (state) {

            await axiosInstance
                .post(`api/v1/purchaseorderbill/${state}`, payload,).then((res) => {

                    if (res.data.status === true) {
                        setNotify({
                            isOpen: true,
                            message: "order Added Successfully",
                            type: "success",

                        });
                        setTimeout(() => {
                            navigate('/purchaseorder');
                        }, 500);


                    }

                }).catch((e) => {
                    console.log("error found", e.response.data.message)
                    let msg = e.response.data.message


                })
        }



    };
    return (
        < >
            <div id="invoice-container">
                <div>
                    <h1 id='headder-left-side-first'> {t("Bill")} </h1>
                    <p id="headder-left-side-second" >{t("Order")} |  <span style={{ color: "black", fontSize: "16px" }}>{t("View_Bill")}</span></p>
                </div>
                {billId != "0" && (
                    <Box
                        marginTop="20px"
                        borderRadius="12px"
                    >
                        <Button variant="contained" onClick={() => {
                            downloadPdf()
                        }} size="large" id="download-invoice-button" >
                            <strong style={{ color: "white", fontSize: "13px", fontWeight: "500" }}>{t("Download_Bill")}</strong>
                        </Button>
                    </Box>
                )
                }

            </div>

            <PDFExport ref={pdfExportComponent} paperSize="A4" scale={0.7}>

                <Card style={{
                    padding: "20px",
                    borderRadius: "12px",
                }}>

                    <Grid item md={12} sm={8} xs={12} container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Grid item md={12} sm={12} xs={12}>
                                <Typography variant="h6" id="invoive-headder" >
                                    {t("Bill")}
                                </Typography>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <Typography variant="h6"
                                    id="invoive-headder-second"
                                >{t("Status")}
                                    <StyledRequired id='invoive-headder-second-panding' >  {t("Pending")} </StyledRequired>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item md={6} xs={12}
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Grid item md={12} sm={12} xs={12}>
                                <img src={logo} alt="logo" id='invoice-headder-image' />
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} style={{
                                marginTop: "20px",
                            }} >
                                <Typography variant="h6" id="invoive-headder-second" >
                                    1901 Thornridge Cir. Shiloh, Hawaii 81063
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid md={12} sm={12} xs={12} container spacing={1}>
                        <Grid item md={8.7} xs={12}
                            style={{
                                marginTop: "20px",
                            }} >
                            <Grid item md={3} xs={12}>
                                <Typography variant="h6" id="invoice-date" >{t("Bill_Date")} </Typography>
                                <Typography variant="h6" id="invoice-date-value" >{moment(billdata.billDate).format("LLL")} </Typography>
                            </Grid>
                            <Grid item md={4.5} xs={12}>
                                <Typography variant="h6" id="invoice-number" >{t("Bill_Number")} </Typography>
                                <Typography variant="h6" id="invoice-number-value" > {billdata.billNo} </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={3.3} xs={12} style={{ marginTop: "10px" }}>
                            <Typography variant="h6" id="invoice-to" >Bill TO </Typography>
                            <Typography variant="h6" id="invoice-to-name" >{supplierData.supplierName}</Typography>
                            <Typography variant="h6" id="invoice-to-address" >
                                {address.address},{address.city} ,{address.state} ,{address.zipCode}
                            </Typography>
                        </Grid>

                    </Grid>
                    <TableContainer id="table-container">
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell> #</StyledTableCell>
                                    <StyledTableCell>{t("Product_Name")}</StyledTableCell>
                                    <StyledTableCell>{t("Qty")}</StyledTableCell>
                                    <StyledTableCell>{t("Rate")}</StyledTableCell>
                                    <StyledTableCell>{t("AMOUNT")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productData.map((row, index) => (
                                    <StyledTableRow
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <StyledTableCell component="th" scope="row">
                                            <Typography sx={{ ml: 2 }}>{index + 1}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>{row.productName}</StyledTableCell>
                                        <StyledTableCell>{row.quantity}</StyledTableCell>
                                        <StyledTableCell>{row.price}</StyledTableCell>
                                        <StyledTableCell>{row.quantity * row.price}</StyledTableCell>
                                    </StyledTableRow>

                                ))}

                            </TableBody>
                        </Table>
                        <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}></div>
                        <FlexBox px={2} id="purchase-order-invoice-footer" >
                            <Box display="flex">
                                <Box pr={6}>
                                    <Typography id="purchase-order-details-footer-subtotal" sx={{ my: 2 }}>{t("Subtotal")}:</Typography>
                                    <Typography id="purchase-order-details-footer-tax" sx={{ mb: 2 }}>{t("Other_Charges")}</Typography>
                                    <Typography id="purchase-order-details-footer-shipping" sx={{ mb: 2 }}>{t("Discount")}</Typography>
                                    {billId != "0" && (
                                        <Typography id="purchase-order-details-footer-shipping" sx={{ mb: 2 }} style={{ marginTop: "28px" }}> {t("Adjustment")}</Typography>
                                    )
                                    }
                                    {billId == "0" && (
                                        <Typography id="purchase-order-details-footer-shipping" sx={{ mb: 2 }}>
                                            <TextField
                                                id="standard-basic"
                                                style={{
                                                    width: "70px",
                                                    marginLeft: "0px",
                                                    color: "black",

                                                }}
                                                InputProps={{ style: { height: "23px", fontSize: "12px", width: "100px" } }}

                                                placeholder={t("Adjustment")}
                                                size="small"
                                            />
                                        </Typography>
                                    )}
                                </Box>
                                <div
                                    style={{
                                        marginLeft: "21px"
                                    }}
                                >
                                    <Typography id="purchase-order-details-footer-subtotal-value" sx={{ my: 2 }}><b>{billdata.subtotal}</b></Typography>
                                    <Typography id="purchase-order-details-footer-tax-value" sx={{ mb: 2 }} style={{ marginTop: "18px" }}>{billdata.otherCharges}</Typography>
                                    <Typography id="purchase-order-details-footer-shipping-value" sx={{ mb: 2 }} style={{ marginTop: "11px" }}> {billdata.discountValue}</Typography>
                                    <Typography id="purchase-order-details-footer-shipping-value" sx={{ mb: 2 }}>
                                        {billId == "0" && (
                                            <TextField
                                                id="standard-basic"
                                                style={{
                                                    width: "71px",
                                                    marginLeft: "0px",
                                                    marginTop: "1px",
                                                    color: "black"

                                                }}
                                                InputProps={{ style: { height: "23px", fontSize: "12px", width: "70px" } }}

                                                onChange={(e) => {
                                                    setAdjustment(e.target.value);
                                                }
                                                }
                                                value={adjustment}
                                                size="small"
                                            />
                                        )}
                                    </Typography>
                                    {billId != "0" && (
                                        <Typography id="purchase-order-details-footer-shipping-value" sx={{ mb: 2 }}> {billdata.adjustment}</Typography>
                                    )
                                    }
                                </div>
                            </Box>
                        </FlexBox>

                        <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}></div>

                        <FlexBox px={2} id="purchase-order-invoice-footer" >
                            <Box display="flex" >
                                <Box pr={6}>
                                    <Typography sx={{ mb: 2 }}>{t("Total")}</Typography>
                                </Box>
                                <div id="purchase-order-details-footer-total-value" >
                                    <Typography sx={{ mb: 2 }} ><b>{parseInt(billdata.payableAmount) - parseInt(billdata.adjustment)}</b></Typography>
                                </div>
                            </Box>
                        </FlexBox>
                        {billId == "0" && (
                            <Button variant="contained" size="large"

                                style=
                                {{
                                    backgroundColor: '#f06237',
                                    borderRadius: '10px',
                                }}
                                onClick={(e) => {
                                    handleSubmit(e)

                                }}

                            >


                                <strong className='purchaseOrderheadder-box-button2-strong'>{t("Save")}</strong>
                            </Button>
                        )}

                    </TableContainer>
                </Card>
            </PDFExport>
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
}
