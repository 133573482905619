import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "../productPage/productList";
import { useState } from "react";
import "../customerPage/style/addCustomer.css";
import { Link, useNavigate } from "react-router-dom";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MeetingAdd } from "../../state/action/meetingAction";

import {
  TextField,
  FormLabel,
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getCustomerDetails,
  getCountry,
  getStateList,
  addCustomer,
  updateCustomer,
} from "../../state/action/customerAction";
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));



const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  customerName: "",
  customerEmail: "",
  mobileNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  vatNumber: "",
  creditLimit: "",
  creditDays: "",
  advancePayment: "",
  accountName: "",
  accountNumber: "",
  bankName: "",
  createdBy: "",
  ifscCode: "",
  swiftCode: "",
};

const leadType = [
  {
    id: 1,
    name: "xyz",
  },
];

const hosts = [
  {
    id: 1,
    name: "xyz",
  },
  {
    id: 2,
    name: "abc",
  },
];

const AddMeeting = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { auth } = store.getState();
  const userid = auth?.user?.id;
  const [countrylist, setCountrylist] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [edit, setIsEdit] = useState(false);
  const [id, setId] = useState();
  const [count, setCount] = useState();
  const [type, setType] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [value, setValue] = useState(dayjs("2022-04-17"));
  const [tovalue, setToValue] = useState(dayjs("2022-04-17"));

  const [age, setAge] = React.useState("");

  const handleChangesss = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    if (state) {
      // getCustomerDetailss(state.user.id);
      // setIsEdit(true);
      // setId(state.user.id);
      console.log("state", state);
      setCount(state.id);
      setType(state.type);
    }
  }, [state]);

  const getCustomerDetailss = async (id) => {
    const response = await getCustomerDetails(id);
    if (response.status === true) {
      getStateListData(response.data.address.country);
      setValues({
        customerName: response.data.customerName,
        customerEmail: response.data.customerEmail,
        mobileNumber: response.data.mobileNumber,
        address: response.data.address.address,
        city: response.data.address.city,
        state: Number(response.data.address.state),
        country: Number(response.data.address.country),
        zipCode: response.data.address.zipCode,
        vatNumber: response.data.vatNumber,
        creditLimit: response.data.creditLimit,
        creditDays: response.data.creditDays,
        advancePayment: response.data.advancePayment,
        accountName: response.data.accountName,
        accountNumber: response.data.accountNumber,
        bankName: response.data.bankName,
        createdBy: response.data.createdBy,
        ifscCode: response.data.ifscCode,
        swiftCode: response.data.swiftCode,
      });
    }
  };
  const validate = (fieldValues = values) => {
  }




  const getCountryList = async (e) => {
    const res = await getCountry();
    if (res.status === true) {
      setCountrylist(res.data);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      createdBy: userid,
      title: values.meetingtitle,
      host: values.host,
      location: values.meetinglocation,
      type: type,
      fromDateTime: values.startDate,
      toDateTime: values.endDate,
      description: "description",
      participent: JSON.stringify(count),
    };

    const addMeeting = await MeetingAdd(payload);
    if (addMeeting.status === true) {
      setNotify({
        isOpen: true,
        message: "Meeting Added Successfully",
        type: "success",
      });
      // resetForm();
      // navigate("/meeting");
    }

  };

  const getStateListData = async (e) => {
    setValues({
      ...values,
      country: e,
    });
    const res = await getStateList(e);
    if (res.status === true) {
      setStatelist(res.data);
    }
  };


  return (
    <>
      <div className="purchaseOrderheadder">
        <div>
          <h1 className="purchaseOrderheadder-left">
            {" "}
            {edit ? t("Edit_Lead") : t("Add Meeting")}
          </h1>
          <p
            className="purchaseOrderheadder-right"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/meeting");
            }}
          >
            {" "}
            {t("Meetings")} |
            <span
              className="purchaseOrderheadder-right-span"
              style={{ color: "black" }}
            >
              {" "}
              {edit ? t("Edit_Lead_Details") : t("Add_Meeting_Details")}
            </span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Box className="b1">
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Typography>{t("MeetingInformation")}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadfirstName">
                {t("meeting_title")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("meetingtitle")}
                inputProps={{ maxLength: 50 }}
                value={values.meetingtitle}
                onChange={handleInputChange}
                error={Boolean(errors.meetingtitle)}
                helperText={errors.meetingtitle}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadfirstName">
                {t("meeting_location")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("meetinglocation")}
                inputProps={{ maxLength: 50 }}
                value={values.meetinglocation}
                onChange={handleInputChange}
                error={Boolean(errors.meetinglocation)}
                helperText={errors.meetinglocation}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <StyledFormLabel htmlFor="leadfirstName">
                    {t("Form Date")}
                  </StyledFormLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={(values.endDate)}
                      name="endDate"
                      onChange={(newValue) => {
                        setValues({
                          ...values,
                          startDate: dayjs(newValue).toDate(),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} />
                      )}
                    />
                  </LocalizationProvider>

                </Grid>
                <Grid item sm={6} xs={12}>
                  {/* <StyledFormLabel htmlFor="leadfirstName">
                    {t("Time")}
                  </StyledFormLabel> */}
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      value={value}
                      onChange={(newValue) => setValue(newValue)}
                      renderInput={(params) => <StyledTextField {...params} />}
                    />
                  </LocalizationProvider> */}
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <StyledFormLabel htmlFor="leadfirstName">
                    {t("To Date")}
                  </StyledFormLabel>
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      value={tovalue}
                      onChange={(newValue) => setToValue(newValue)}
                      renderInput={(params) => <StyledTextField {...params} />}
                    />
                  </LocalizationProvider> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={(values.endDate)}
                      name="endDate"
                      onChange={(newValue) => {
                        setValues({
                          ...values,
                          endDate: dayjs(newValue).toDate(),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={6} xs={12}>
                  {/* <StyledFormLabel htmlFor="leadfirstName">
                    {t("Time")}
                  </StyledFormLabel> */}
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      value={tovalue}
                      onChange={(newValue) => setToValue(newValue)}
                      renderInput={(params) => <StyledTextField {...params} />}
                    />
                  </LocalizationProvider> */}
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadfirstName">
                {t("host")}
              </StyledFormLabel>
              {/* <StyledTextField fullWidth name={t("meetingtitle")} select> */}
              {/* {hosts.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))} */}
              <select
                className="input"
                id="host"
                name="host"
                style={
                  {
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    background: "#fff",
                    fontSize: "14px",
                  }
                }
                value={values.type}
                onChange={(e) => { handleInputChange(e) }}
              >
                <option value="">Select </option>
                {hosts && hosts.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              {/* </StyledTextField> */}
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadfirstName">
                {t("Participants")}
              </StyledFormLabel>
              <StyledTextField
                disabled
                fullWidth
                name={t("meetinglocation")}
                inputProps={{ maxLength: 50 }}
                value={values.meetinglocation}
                onChange={handleInputChange}
                error={Boolean(errors.meetinglocation)}
                helperText={errors.meetinglocation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/participant");
                      }}
                    >
                      <AddIcon /> ADD {count && <span style={{ color: "red" }}>{`(${count.length}) Selected`}</span>}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Box className="customerBox">
            <div>
              <Button
                variant="outlined"
                size="large"
                className="buttonCancel"
                onClick={() => {
                  navigate("/meeting");
                }}
                style={{
                  borderRadius: "10px",
                  width: "40%",
                  height: "94%",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Save")}
                </strong>
              </Button>
            </div>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default AddMeeting;
