import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axiosInstance from "../../utils/AxiosInstace";
import { Form, useForm } from "../../utils/useForms";
import Autocomplete from '@mui/material/Autocomplete';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Card,
    TextField,
    FormLabel,
    MenuItem,
    Grid,
    Button,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
} from "@mui/material";
import Notification from "../../utils/Notification";
import Backdrop from '../Backdrop/loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tableCellClasses } from '@mui/material/TableCell';
//import "./purchaseOrderStyle/purchaseOrderAdd.css"

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))

const StyledTextField = styled(TextField)(() => ({
    marginTop: "3px",
    ".MuiOutlinedInput-root": {
        borderRadius: "10px",
        height: "33px",
        paddingTop: "0px",

    },
    ".MuiOutlinedInput-input": {
        fontSize: "13px",
    },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
    display: "block",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(40, 45, 38, 0.05)",
        color: "rgba(15, 15, 15, 0.5)",
        fontSize: "12px",
        paddingTop: "6px",
        paddingBottom: "6px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: "rgba(40, 45, 38, 0.5)",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const initialValues = {
    customerId: '',
    orderId: '',
    creditNoteNumber: '',
    creditNoteDate: '',
    creditNoteAmount: '',
    creditNoteStatus: '',
    creditNoteReason: '',
};

export default function CreditAdd(props) {
    useEffect(() => {
        getCustomers()
        getProducts()

    }, [])

    useEffect(() => {
        let creditNumber = "CN-" + Math.floor(Math.random() * 1000000000);
        setValues({
            ...values,
            creditNoteNumber: creditNumber,
        });


    }, [])
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true);
    const navigate = useNavigate();
    const [customerlist, setCustomerlist] = useState([]);
    const [customerdata, setCustomerdata] = useState([]);
    const [productdata, setProductdata] = useState([]);
    const [orderlist, setOrderlist] = useState([]);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [date, setDate] = useState(new Date().toLocaleDateString('en-CA'));
    const [customerAddress, setCustomerAddress] = useState([]);
    const [orderData, setOrderData] = useState([]);

    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [confirmDialog, setConfirmDialog] = useState(false);
    const orderDetails = async (e) => {
        setValues({
            ...values,
            orderId: e.id,
        });

        await axiosInstance

            .get(`api/v1/salesorderdetail/${e.id}`).then((res) => {
                setProductdata(res.data.data.products);
                console.log("--------------------------------===-==-=-=-=-=-=-=", res.data.data.products)
                setOrderData(res.data.data)
            }
            ).catch((e) => {
                console.log("error found")
            }

            )
    }

    const getOrderListData = (e) => {
        setValues({
            ...values,
            customerId: e.target.value,
        });
        axiosInstance
            .get(`/api/v1/customerOrder/${e.target.value}`).then((res) => {
                console.log("res", res.data.data)
                setOrderlist(res.data.data)
            }).catch((e) => {
                console.log("error found")
            })
    }

    const getCustomers = async () => {
        await axiosInstance
            .get(`/api/v1/customerdropdown`).then((res) => {
                setCustomerlist(res.data.data);
            }).catch((e) => {
                console.log("error found")
            })
    }

    const getProducts = async (e) => {

        await axiosInstance
            .get(`api/v1/productdropdown`).then((res) => {
                // setOrderList(res.data.data)
            }).catch((e) => {
                console.log("error found")
            })
    }



    let deleteItem = (id) => {
        setProductdata(productdata.filter((item) => item.id !== id))
    }


    const openConfirmDialog = () => {
        setConfirmDialog(true);
    };
    const closeConfirmDialog = () => {
        setConfirmDialog(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            "customerId": values.customerId,
            "orderId": values.orderId,
            "total": orderData.payableAmount,
            "creditNoteNumber": values.creditNoteNumber,
            "creditNoteDate": date,
            "creditNoteAmount": orderData.payableAmount,
            "creditNoteReason": values.creditNoteReason,
            "creditNoteStatus": 1,
            "products": productdata
        }
        console.log("payload", payload)

        //api call 
        await axiosInstance
            .post(`/api/v1/creditNotes`, payload).then((res) => {
                setNotify({
                    isOpen: true,
                    message: "Credit Note Added Successfully",
                    type: "success",
                });
                setTimeout(() => {
                    navigate("/credit");
                }, 2000);
            }).catch((e) => {
                console.log("error found")
            })


    };
    const changeHandler1 = (e, index) => {
        if (e.target.value > productdata[index].quantity) {
            setNotify({
                isOpen: true,
                message: "Quantity not greater than default quantity",
                type: "error",
            });
            return false;
        }
        //product quantity not change below 0
        else if (e.target.value < 0) {
            setNotify({
                isOpen: true,
                message: "Quantity not less than 0",
                type: "error",
            });
            return false;
        }
        else {
            let newArr = [...productdata];
            newArr[index][e.target.name] = e.target.value;
            setProductdata(newArr);
        }




    };
    const { t } = useTranslation();

    return (
        <>
            <Backdrop open={openBackdrop} />

            <div
                className="purchaseOrderheadder"
            >
                <div>
                    <h1 className='purchaseOrderheadder-left'> {t("Add_Credit")}</h1>
                    <p className='purchaseOrderheadder-right'> {t("Credit")} |
                        <span className='purchaseOrderheadder-right-span' style={{ color: "black" }}>  {t("Add_Credit")}</span>
                    </p>
                </div>
                <Box className="purchaseOrderheadder-box">
                    <Link to="/purchaseorder" style={{
                        textDecoration: "none",
                    }}>
                        <Button className="purchaseOrderheadder-box-button1" variant="outlined" size="large"
                            style=
                            {{
                                border: '1px solid  #282822',
                                borderRadius: '10px',
                                marginRight: '15px',
                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                            }}
                        >
                            <strong className='purchaseOrderheadder-box-button1-strong' >{t("Cancel")}</strong>
                        </Button>
                    </Link>

                    <Button variant="contained" size="large"
                        style=
                        {{
                            backgroundColor: '#f06237',
                            borderRadius: '10px',
                        }}
                        onClick={handleSubmit}
                    >
                        <strong className='purchaseOrderheadder-box-button2-strong'>{t("Save")}</strong>
                    </Button>
                </Box>
            </div>

            <Card
                id="purchaseOrder-card"
            >


                <Form>
                    <Grid item md={12} sm={8} xs={12} container spacing={1}>
                        <Grid item md={12} sm={8} xs={12} container spacing={1}>
                            <Grid item sx={{ m: 1 }} md={12} xs={12}>
                                <StyledFormLabel htmlFor="country">
                                    {t("Customer")}
                                </StyledFormLabel>
                                <StyledTextField
                                    id="customer"
                                    name={t("customer")}
                                    fullWidth
                                    select
                                    value={values.customer}
                                    onChange={getOrderListData}
                                    error={Boolean(errors.customer)}
                                    helperText={errors.customer}
                                >
                                    {customerlist.map((val) => {
                                        return (
                                            <MenuItem key={val.id} value={val.id}>
                                                {val.name}
                                            </MenuItem>
                                        )
                                    })}
                                </StyledTextField>

                            </Grid>

                        </Grid>
                    </Grid>


                    {customerdata.companyName ? <Grid item sx={{ m: 1 }} container md={11.7} spacing={1} xs={12}
                        className="purchaseOrder-card-supplierdata-grid"
                    >
                        <Grid item md={3.5} xs={12}>
                            <Grid item sx={{ m: 1, mt: 1 }} md={12} xs={12}>
                                <h1 className="purchaseOrder-card-supplierdata-grid-h1">{t("Name:")} </h1>
                                <span id='purchaseOrder-card-supplierdata-grid-span'>{customerdata.customerName}</span>
                            </Grid>
                        </Grid>

                        <Grid item md={3.5} xs={12}>
                            <Grid item sx={{ m: 1, mt: 1 }} md={12} xs={12} >
                                <h1 className="purchaseOrder-card-supplierdata-grid-h1"
                                > {t("Address")}:</h1>
                                <Typography variant="h6" id='text-right2'>
                                    {customerAddress.address}
                                </Typography>
                                <Typography variant="h6" id='text-right3'>
                                    {customerAddress.city},
                                    {customerAddress.state},
                                </Typography>
                                <Typography variant="h6" id='text-right4'>
                                    {customerAddress.country}
                                </Typography>
                                <Typography variant="h6" id='text-right4'>
                                    {customerAddress.zipCode}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md={3.5} xs={12}>
                            <Grid item sx={{ m: 1, mt: 1 }} md={12} xs={12}>
                                <h1 className="purchaseOrder-card-supplierdata-grid-h1"
                                >{t("Phone_Number:")}  </h1>
                                <span id='purchaseOrder-card-supplierdata-grid-span'
                                >{customerdata.companyPhoneNumber}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                        : <div></div>}
                    <Grid item md={12} sm={8} xs={12} container spacing={1}>

                        <Grid item sx={{ m: 1 }} md={5.8} xs={12}>
                            <StyledFormLabel htmlFor="creditNote">
                                {t("Credit_Note")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("creditNote")}
                                value={values.creditNoteNumber}
                                onChange={handleInputChange}
                                error={Boolean(errors.creditNote)}
                                helperText={errors.creditNote}
                            />
                        </Grid>

                        <Grid item sx={{ m: 1 }} md={5.8} xs={12}>
                            <StyledFormLabel htmlFor="creditNoteReason">
                                {t("Reason")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("creditNoteReason")}
                                value={values.creditNoteReason}
                                onChange={handleInputChange}
                                error={Boolean(errors.creditNoteReason)}
                                helperText={errors.creditNoteReason}
                            />
                        </Grid>
                        <Grid item sx={{ m: 1 }} md={5.8} xs={12}>
                            <StyledFormLabel htmlFor="productGroup"
                            >
                                {t("Credit_Note_Date")}
                            </StyledFormLabel>
                            <StyledTextField
                                style={{
                                    height: "33px",
                                    width: "100%",
                                    borderRadius: "10px",
                                    fontSize: "13px",

                                }}
                                id="date"
                                type="date"
                                defaultValue="2017-05-24"
                                onChange={(e) => {
                                    setDate(e.target.value)
                                }
                                }
                                error={errors.date}
                                helperText={errors.date}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>


                        <Grid item sx={{ m: 1 }} md={5.8} xs={12}>
                            <StyledFormLabel htmlFor="productGroup">
                                {t("Order_No")}
                            </StyledFormLabel>

                            <Autocomplete
                                disablePortal
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                placeholder={t('Search_Vendor')}
                                closeIcon={<closeIcon />}
                                labelId="customer-input"
                                options={orderlist}
                                value={values.orderNumber}
                                onChange={(e, value) => {
                                    orderDetails(value)

                                }}

                                getOptionLabel={(option, key) =>
                                    option ? option.orderNumber : ""
                                }
                                renderInput={(params) => (
                                    <StyledTextField
                                        sx={{ paddingTop: "0px" }}
                                        name={params.orderNumber}
                                        labelId="budget-input"
                                        fullWidth
                                        {...params}
                                        error={errors.orderNumber}
                                        helperText={errors.orderNumber}
                                    />
                                )}
                                popupIcon={<ExpandMoreIcon />}
                            />
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper} style={{
                        marginTop: "40px",
                    }}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead
                                STYLE={{ backgroundColor: "#f06237" }}
                            >
                                <TableRow>
                                    <StyledTableCell>{t('Product_Name')}</StyledTableCell>
                                    <StyledTableCell align="center" >{t('Quantity')}</StyledTableCell>
                                    <StyledTableCell align="center" >{t('Sales_Price')}</StyledTableCell>
                                    <StyledTableCell align="center" >{t('amount')}</StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ color: "black" }}>
                                {productdata.map((row, index) => (
                                    <StyledTableRow key={row.id} >
                                        <StyledTableCell component="th" scope="row" style={{ cursor: "pointer" }} onClick={() => {
                                            navigate(`/product/details`, {
                                                state: {
                                                    user: row,
                                                }
                                            })
                                        }}>
                                            {row.productName}

                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <TextField
                                                id="standard-basic"
                                                style={{
                                                    width: "71px",
                                                    marginLeft: "10px",
                                                    color: "black",

                                                }}
                                                InputProps={{ style: { height: "23px", fontSize: "12px", width: "70px" } }}
                                                defaultValue={row.quantity}
                                                onChange={(event) => changeHandler1(event, index)}
                                                name={t("productQuantity")}
                                                value={row.productQuantity}
                                                size="small"
                                            />
                                        </StyledTableCell>
                                        {/* <StyledTableCell align="center">{row.quantity}
                                        </StyledTableCell> */}
                                        <StyledTableCell align="center"
                                        >{row.price}</StyledTableCell>
                                        <StyledTableCell align="center"
                                        >{row.quantity * row.price}</StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            onClick={() => { openConfirmDialog() }}
                                        >
                                            <IconButton aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                        <Dialog
                                            id="confirm-dialog"
                                            open={confirmDialog}
                                            onClose={() => closeConfirmDialog()}
                                            sx={{
                                                "&#confirm-dialog .MuiPaper-root": {
                                                    borderRadius: "15px",
                                                },
                                            }}
                                        >
                                            <DialogContent>
                                                Are you sure you want to Remove this item ?
                                            </DialogContent>
                                            <DialogActions
                                                sx={{
                                                    margin: "15px",
                                                    marginRight: "auto",
                                                    marginLeft: "auto",
                                                }}
                                            >
                                                <Button
                                                    sx={{
                                                        backgroundColor: "white",
                                                        color: "black",
                                                        border: "1px solid #0F0F0F80",
                                                        borderRadius: "10px",
                                                        padding: "10px 25px",
                                                        "&:hover": {
                                                        },
                                                    }}
                                                    onClick={() => closeConfirmDialog()}
                                                >
                                                    {t('Cancel')}
                                                </Button>
                                                <Button
                                                    sx={{
                                                        backgroundColor: "#e64a08",
                                                        color: "white",
                                                        borderRadius: "10px",
                                                        padding: "10px 25px",
                                                        "&:hover": {
                                                            backgroundColor: "#e64a08",
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        closeConfirmDialog();
                                                        // close();
                                                        deleteItem(row.id);
                                                    }}
                                                >
                                                    {t('Confirm')}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>







                    <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)", marginTop: "20px" }}></div>

                    <FlexBox px={2}
                        style={{
                            marginLeft: '60%',
                        }}
                    >
                        <Box display="flex">
                            <Box pr={6}>
                                <Typography sx={{ my: 2, }} style={{
                                    fontSize: "13px",
                                }}>{t("Subtotal")}:</Typography>
                                <Typography sx={{ mb: 2 }} style={{
                                    fontSize: "13px",
                                }}>{t("TAX")}</Typography>
                                <Typography sx={{ mb: 1 }} style={{
                                    fontSize: "13px",
                                }}>{t("Discount")}</Typography>
                            </Box>
                            <div
                            >
                                <Typography sx={{ my: 2, marginLeft: "105px", fontSize: "13px" }}><b>{orderData.subtotal}</b></Typography>
                                <Typography sx={{ mb: 2, marginLeft: "105px", fontSize: "13px" }}><b>{orderData.otherCharges}</b></Typography>
                                <Typography sx={{ mb: 2, marginLeft: "105px", fontSize: "13px" }}><b>{orderData.discountValue}</b>  </Typography>
                            </div>
                        </Box>
                    </FlexBox>
                    <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)", marginTop: "20px" }}></div>

                    <FlexBox px={2}
                        style={{
                            marginTop: "20px",
                            marginLeft: '60%',
                        }}
                    >
                        <Box display="flex">
                            <Box pr={4}
                            >
                                <Typography sx={{ mb: 4 }}>{t("Total")}</Typography>
                            </Box>
                            <div
                                style={{ marginLeft: "141px" }}
                            >
                                <Typography sx={{ mb: 6, color: "red" }}><b>{orderData.payableAmount} </b></Typography>
                            </div>
                        </Box>
                    </FlexBox>






                </Form>
            </Card>
            {/* </Form> */}
            <Notification notify={notify} setNotify={setNotify} />

        </>
    );
}