import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "../productPage/productList";
import { useState } from "react";
import "../customerPage/style/addCustomer.css";
import { Link, useNavigate } from "react-router-dom";
import { TextField, FormLabel, Grid, Button, Typography } from "@mui/material";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IsWrite, IsDelete, IsRead } from "../../utils/handlePermission";
import {
  AddUser,
  countryDropdown,
  leadTypeDropdown,
  getStateList,
  leadOwnerDropdown,
  updateLead,
  getLeadDetails,
} from "../../state/action/leadAction";

const leadsource = [
  {
    id: 1,
    sourceName: "facebook",
  },
  {
    id: 2,
    sourceName: "instagram",
  },
  {
    id: 3,
    sourceName: "website",
  },
  {
    id: 4,
    sourceName: "partner",
  },
];

const leadstatus = [
  {
    id: 1,
    leadStatuss: "hot",
  },
  {
    id: 2,
    leadStatuss: "cold",
  },
  {
    id: 3,
    leadStatuss: "natural",
  },
];

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  firstName: "",
  leadOwnerId: "",
  lastName: "",
  email: "",
  phone: "",
  leadType: "",
  leadSource: "",
  leadStatus: "",
  company: "",
  street: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  description: "",
  createdBy: "1",
};

export default function CustomizedInputs(props) {
  const { t } = useTranslation();
  const { state } = useLocation();

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);

  const navigate = useNavigate();
  const [countrylist, setCountrylist] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [leadTypess, setLeadTypess] = useState([]);
  const [leadOwner, setLeadOwner] = useState([]);
  const [edit, setIsEdit] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("firstName" in fieldValues)
      temp.firstName = fieldValues.firstName ? "" : "This field is required.";
    if ("lastName" in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "This field is required.";
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if ("phone" in fieldValues) {
      //only numbers allow regex
      var regex = /^[0-9]+$/;
      if (!regex.test(fieldValues.phone)) {
        temp.phone = " Only numbers are allowed";
      } else {
        temp.phone = "";
      }
    }

    if ("leadOwnerId" in fieldValues)
      temp.leadOwnerId = fieldValues.leadOwnerId
        ? ""
        : "This field is required.";

    if ("leadType" in fieldValues)
      temp.leadType = fieldValues.leadType ? "" : "This field is required.";

    if ("leadSource" in fieldValues)
      temp.leadSource = fieldValues.leadSource ? "" : "This field is required.";

    if ("leadStatus" in fieldValues)
      temp.leadStatus = fieldValues.leadStatus ? "" : "This field is required.";

    if ("company" in fieldValues)
      temp.company = fieldValues.company ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //Country
  const getCountryList = async (e) => {
    const res = await countryDropdown();
    if (res.status === true) {
      setCountrylist(res.data);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  //state
  const getStateListData = async (id) => {
    const res = await getStateList(id);
    if (res.status === true) {
      setStatelist(res.data);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        leadSource: values.leadSource,
        company: values.company,
        street: values.street,
        description: values.description,
        leadType: values.leadType,
        country: values.country,
        state: values.state,
        city: values.city,
        leadOwnerId: values.leadOwnerId,
        zipCode: values.zipCode,
        leadStatus: values.leadStatus,
        createdBy: "1",
      };

      if (edit) {
        const res = await updateLead(data, state?.lead);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          setTimeout(() => {
            navigate("/lead");
          }, 1000);
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "warning",
          });
        }
      } else {
        const res = await AddUser(data);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          setTimeout(() => {
            navigate("/lead");
          }, 1000);
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "warning",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (state) {
      getLeadDetailss(state?.lead);
      setIsEdit(true);
    }
  }, [state]);

  const getLeadDetailss = async (id) => {
    const res = await getLeadDetails(state?.lead);
    if (res.status === true) {
      setValues({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        phone: res.data.phone,
        leadType: res?.data?.leadType?.id,
        leadStatus: res.data.leadStatus,
        leadOwnerId: res.data.leadOwnerId,
        company: res.data.company,
        leadSource: res.data.leadSource,
        street: res.data.street,
        city: res.data.city,
        state: res.data.state?.id,
        country: res?.data?.country?.id,
        zipCode: res.data.zipCode,
        description: res.data.description,
      });
      getStateListData(res.data.state?.id);
    }
  };

  //lead Type
  const getLeadTypeList = async (e) => {
    const res = await leadTypeDropdown();
    if (res.status === true) {
      setLeadTypess(res.data);
    }
  };

  useEffect(() => {
    getLeadTypeList();
  }, []);

  //lead Owner
  const getLeadOwnerList = async (e) => {
    const res = await leadOwnerDropdown();
    if (res.status === true) {
      setLeadOwner(res.data);
    }
  };

  useEffect(() => {
    getLeadOwnerList();
  }, []);

  return (
    <>
      <div className="purchaseOrderheadder">
        <div>
          <h1 className="purchaseOrderheadder-left">
            {" "}
            {edit ? t("Edit_Lead") : t("Add_Lead")}
          </h1>
          <p
            className="purchaseOrderheadder-right"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/lead");
            }}
          >
            {" "}
            {t("Lead")} |
            <span
              className="purchaseOrderheadder-right-span"
              style={{ color: "black" }}
            >
              {" "}
              {edit ? t("Edit_Lead_Details") : t("Add_Lead_Details")}
            </span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Box className="b1">
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Typography>{t("leadInformation")}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="firstName">
                {t("lead_first_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={"firstName"}
                inputProps={{ maxLength: 50 }}
                value={values.firstName}
                onChange={handleInputChange}
                error={errors.firstName}
                helperText={errors.firstName}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="lastName">
                {t("lead_last_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={"lastName"}
                value={values.lastName}
                onChange={handleInputChange}
                error={errors.lastName}
                helperText={errors.lastName}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="email">{t("Email")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={"email"}
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="phone">
                {t("Lead_Phone_Number")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={"phone"}
                value={values.phone}
                onChange={handleInputChange}
                error={errors.phone}
                helperText={errors.phone}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadOwnerId">
                {t("Lead_Type")}
              </StyledFormLabel>
              <select
                id="leadType"
                name="leadType"
                fullWidth
                select
                value={values.leadType}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Lead_Type")}</option>

                {leadTypess.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.typeName}
                    </option>
                  );
                })}

                {Boolean(errors.leadType) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.leadType}
                  </p>
                ) : (
                  ""
                )}
              </select>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadStatus">
                {t("Lead_Status")}
              </StyledFormLabel>
              <select
                id="leadStatus"
                name="leadStatus"
                fullWidth
                select
                value={values.leadStatus}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Lead_Status")}</option>

                {leadstatus.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.leadStatuss}
                    </option>
                  );
                })}

                {Boolean(errors.leadStatus) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.leadStatus}
                  </p>
                ) : (
                  ""
                )}
              </select>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadOwnerId">
                {t("Lead_owner")}
              </StyledFormLabel>
              <select
                id="leadOwnerId"
                name="leadOwnerId"
                fullWidth
                select
                value={values.leadOwnerId}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Lead_Owner")}</option>

                {leadOwner.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}

                {Boolean(errors.leadOwnerId) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.leadOwnerId}
                  </p>
                ) : (
                  ""
                )}
              </select>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="company">
                {t("Lead_Company")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={"company"}
                value={values.company}
                onChange={handleInputChange}
                error={errors.company}
                helperText={errors.company}
              />
            </Grid>{" "}
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadSource">
                {t("Lead_Source")}
              </StyledFormLabel>
              <select
                id="leadSource"
                name="leadSource"
                fullWidth
                select
                value={values.leadSource}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Lead_Source")}</option>

                {leadsource.map((val) => {
                  return (
                    <option key={val.sourceName} value={val.sourceName}>
                      {val.sourceName}
                    </option>
                  );
                })}

                {Boolean(errors.leadSource) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.leadSource}
                  </p>
                ) : (
                  ""
                )}
              </select>
            </Grid>
          </Grid>

          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <hr />
              <Typography>{t("addressInformation")}</Typography>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="street">{t("Street")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name="street"
                value={values.street}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="country">
                {t("Country")}
              </StyledFormLabel>
              <select
                id="country"
                name="country"
                fullWidth
                select
                value={values.country}
                onChange={(e) => {
                  getStateListData(e.target.value);
                  handleInputChange(e);
                }}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Country")}</option>

                {countrylist.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}

                {Boolean(errors.country) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.country}
                  </p>
                ) : (
                  ""
                )}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="state">{t("State")}</StyledFormLabel>
              <select
                id="state"
                name="state"
                fullWidth
                select
                value={values.state}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_State")}</option>

                {statelist.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="city">{t("City")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name="city"
                value={values.city}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="zipCode">
                {t("ZipCode")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                inputProps={{ maxLength: 6 }}
                name="zipCode"
                value={values.zipCode}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <hr />
              <Typography>{t("description")}</Typography>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="description">
                {t("lead_description")}
              </StyledFormLabel>
              <StyledTextFields
                id="outlined-multiline-flexible"
                multiline
                fullWidth
                maxRows={3}
                name="description"
                value={values.description}
                onChange={handleInputChange}
              />
            </Grid>{" "}
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "81px",
              borderRadius: "12px",
              padding: "20px",
              marginLeft: "258px",
            }}
          >
            <div>
              <Button
                variant="outlined"
                size="large"
                className="buttonCancel"
                onClick={() => {
                  navigate("/lead");
                }}
                style={{
                  borderRadius: "10px",
                  width: "40%",
                  height: "94%",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Save")}
                </strong>
              </Button>
            </div>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
