import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axiosInstance from "../../utils/AxiosInstace";
import { Form, useForm } from "../../utils/useForms";
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Card,
    TextField,
    FormLabel,
    MenuItem,
    Grid,
    Button,
    Typography,
} from "@mui/material";
import Notification from "../../utils/Notification";
import Backdrop from '../Backdrop/loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tableCellClasses } from '@mui/material/TableCell';
import { store } from "../../state/store";
import "./purchaseOrderStyle/purchaseOrderAdd.css"
import { useTranslation } from "react-i18next";

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))

const StyledTextField = styled(TextField)(() => ({
    marginTop: "3px",
    ".MuiOutlinedInput-root": {
        borderRadius: "10px",
        height: "33px",
        paddingTop: "0px",

    },
    ".MuiOutlinedInput-input": {
        fontSize: "13px",
    },
}));


const StyledFormLabel = styled(FormLabel)(() => ({
    display: "block",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
}));

const initialValues = {

};

export default function CustomizedInputs(props) {
    useEffect(() => {
        getCustomers()
    }, [])
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true);
    const navigate = useNavigate();
    const [supplierlist, setSupplierlist] = useState([]);
    const [purchaseOrderdata, setOrderData] = useState([]);
    const [customerdata, setCustomerdata] = useState([]);
    const [orderlist, setOrderlist] = useState([]);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [tax, setTax] = useState();
    const [showhide, setShowHide] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [customerAddress, setCustomerAddress] = useState([]);
    const [date, setDate] = useState(new Date());

    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [specificationarr, setSpecificationArr] = useState([{ type: "", value: "", amount: "", }]);
    const [refresh, setRefresh] = useState(false);
    const [addAdmin, setAddAdmin] = useState(false);
    const { t } = useTranslation();
    let { id } = useParams();
    const button = id ? t("Save") : t("Add_Payment")
    const headder = id ? t("Edit_Product") : t("Add_Product")
    var someDate = new Date();
    var date1 = someDate.setDate(someDate.getDate());
    var defaultValue = new Date(date1).toISOString().split("T")[0];

    const openAdminModal = () => {
        setAddAdmin(true);
    };
    const closeAdminModal = () => {
        setAddAdmin(false);
    };
    const addClick = async (event) => {
        setSpecificationArr((s) => {
            return [
                ...s,
                {
                    taxType: "",
                    taxPercentage: "",
                    taxValue: "",
                },
            ];
        });
    };
    const strong = {
        color: 'black',
    }
    const customerDetails = async (e) => {
        if (e) {
            await axiosInstance
                .get(`api/v1/customerdetails/${e.id}`).then((res) => {
                    setCustomerdata(res.data.data)
                    console.log("---------------------------------", res.data.data.address)
                    setCustomerAddress(res.data.data.address)
                }).catch((e) => {
                    console.log("error found")
                })
        }
        else {
            setCustomerdata({})
        }
    }
    const getCustomers = async (e) => {
        await axiosInstance
            .get(`/api/v1/supplierdropdown`).then((res) => {
                setSupplierlist(res.data.data)

            }).catch((e) => {
                console.log("error found")
            })
    }
    const getOrderListData = (e) => {
        setValues({
            ...values,
            orderId: e.target.value,
        });
        axiosInstance
            .get(`/api/v1/supplierOrder/${e.target.value}`).then((res) => {
                if (res.data.data[0].paymentStatus != "3") {
                    setOrderlist(res.data.data)
                }
            }).catch((e) => {
                console.log("error found")
            })
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {

            backgroundColor: "rgba(40, 45, 38, 0.05)",
            color: "rgba(15, 15, 15, 0.5)",
            fontSize: "12px",
            paddingTop: "6px",
            paddingBottom: "6px",

        },
        [`&.${tableCellClasses.body}`]: {

            fontSize: 14,
            color: "rgba(40, 45, 38, 0.5)",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:last-child td, &:last-child th': {

            border: 0,
            display: "flex"
        },
    }));

    const openConfirmDialog = () => {
        setConfirmDialog(true);
    };
    const closeConfirmDialog = () => {
        setConfirmDialog(false);
    };


    const { auth } = store.getState()
    const userid = auth?.user?.id;
    console.log("auth", userid)





    const handleSubmit = async (e) => {

        let pay_amt = document.getElementById("paymentAmount").value;
        e.preventDefault();
        const payload = {
            "purchaseOrderId": purchaseOrderdata.id,
            "supplierId": String(orderlist[0].supplierId),
            "transactionId": String("123"),
            "paymentAmount": pay_amt,
            "paymentMode": values.paymentMode,
            "chequeNumber": values.chequeNumber,
            "accountNumber": values.accountNumber,
            "bankName": values.bankName,
            "transactionNumber": values.transactionNumber,
            "recivedBy": userid,
        }

        if (userid) {
            await axiosInstance
                .post(`/api/v1/purchaseorderpayment`, payload).then((res) => {
                    if (res.data.status === true) {
                        setNotify({
                            isOpen: true,
                            message: "Payment Added Successfully",
                            type: "success",

                        });
                        setTimeout(() => {
                            navigate('/PurchaseOrder/payment');
                        }, 500);
                    }
                }).catch((e) => {
                    let msg = e.response.data.message
                    setErrorMessage(msg)
                    setNotify({

                        isOpen: true,
                        message: e.response.data.message,
                        type: "warning",

                    });


                })
        }

    };
    const handleShowHide = (e) => {
        setValues({
            ...values,
            paymentMode: e.target.value,
        });
        const getuser = e.target.value;
        console.log(getuser)
        setShowHide(getuser);

    }

    return (
        <>
            <Backdrop open={openBackdrop} />

            <div
                className="purchaseOrderheadder"
            >
                <div>
                    <h1 className='purchase' style={{ fontSize: "20px" }}> {t("Payment")} </h1>
                    <p className='purchase' style={{ fontSize: "12px" }}> {t("Payment")}|
                        <span className='purchaseOrder' style={{ color: "black" }}>  {t("payment_Details")}</span>
                    </p>
                </div>

            </div>

            <Card style={{
                padding: "20px",
                borderRadius: "12px",
                marginTop: "20px",

            }}>
                <Form onSubmit={handleSubmit}>
                    <Grid item md={12} sm={8} xs={12} container spacing={1}>
                        <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                            <StyledFormLabel htmlFor="country">
                                {t("Supplier")}
                            </StyledFormLabel>
                            <StyledTextField
                                id="supplier"
                                name="supplier"
                                fullWidth
                                select
                                value={values.supplier}
                                onChange={getOrderListData}
                                error={Boolean(errors.supplier)}
                                helperText={errors.supplier}
                            >
                                {supplierlist.map((val) => {
                                    return (
                                        <MenuItem key={val.id} value={val.id}>
                                            {val.name}
                                        </MenuItem>
                                    )
                                })}
                            </StyledTextField>
                        </Grid>
                        <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                            <StyledFormLabel htmlFor="productGroup">
                                {t("Order_No")}
                            </StyledFormLabel>

                            <Autocomplete
                                disablePortal
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                placeholder={t("Search_Vendor")}
                                closeIcon={<closeIcon />}
                                labelId="customer-input"
                                options={orderlist}
                                value={values.orderNumber}
                                onChange={(e, value) => {
                                    console.log("111111111", value)
                                    setOrderData(value)


                                }}

                                getOptionLabel={(option, key) =>
                                    option ? option.orderNumber : ""
                                }
                                renderInput={(params) => (
                                    <StyledTextField
                                        sx={{ paddingTop: "0px" }}
                                        name={params.orderNumber}
                                        labelId="budget-input"
                                        fullWidth
                                        {...params}
                                        error={errors.orderNumber}
                                        helperText={errors.orderNumber}
                                    />
                                )}
                                popupIcon={<ExpandMoreIcon />}
                            />
                        </Grid>
                    </Grid>

                    <Grid item md={12} sm={8} xs={12} container spacing={1}>
                        <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                            <StyledFormLabel htmlFor="productGroup"
                            >
                                {t("Date")}
                            </StyledFormLabel>
                            <StyledTextField
                                style={{
                                    height: "33px",
                                    borderRadius: "10px",
                                    fontSize: "13px",

                                }}
                                id="date"
                                type="date"
                                defaultValue={defaultValue}
                                onChange={(e) => {
                                    setDate(e.target.value)
                                }
                                }
                                error={errors.date}
                                helperText={errors.date}
                                sx={{ width: 480 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />


                        </Grid>
                        <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                            <StyledFormLabel htmlFor="paymentMode">
                                {t("Payment_Mode")}
                            </StyledFormLabel>
                            <StyledTextField
                                name={t("paymentMode")}
                                fullWidth
                                select
                                value={values.paymentMode}
                                onChange={(e) => (handleShowHide(e))}
                                error={Boolean(errors.paymentMode)}
                                helperText={errors.paymentMode}
                            >
                                <MenuItem key="1" value="1">
                                    {t("Cash")}
                                </MenuItem>
                                <MenuItem key="2" value="2">
                                    {t("Cheque")}
                                </MenuItem>
                                <MenuItem key="3" value="3">
                                    {t("Online_Tranaction")}
                                </MenuItem>
                            </StyledTextField>
                        </Grid>
                        {showhide === "2" && (
                            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                                <StyledFormLabel htmlFor="bankName">
                                    {t("Bank_Name")}
                                </StyledFormLabel>
                                <StyledTextField
                                    fullWidth
                                    name="bankName"
                                    value={values.bankName}
                                    onChange={handleInputChange}
                                    error={Boolean(errors.bankName)}
                                    helperText={errors.bankName}
                                />
                            </Grid>
                        )
                        }
                        {showhide === "2" && (
                            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                                <StyledFormLabel htmlFor="accountNumber">
                                    {t("Account_Number")}
                                </StyledFormLabel>
                                <StyledTextField
                                    fullWidth
                                    name={t("accountNumber")}
                                    value={values.accountNumber}
                                    onChange={handleInputChange}
                                    error={Boolean(errors.accountNumber)}
                                    helperText={errors.accountNumber}
                                />
                            </Grid>)
                        }
                        {showhide === "2" && (
                            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                                <StyledFormLabel htmlFor="chequeNumber">
                                    {t("Cheque_Number")}
                                </StyledFormLabel>
                                <StyledTextField
                                    fullWidth
                                    name={t("chequeNumber")}
                                    value={values.chequeNumber}
                                    onChange={handleInputChange}
                                    error={Boolean(errors.chequeNumber)}
                                    helperText={errors.chequeNumber}
                                />
                            </Grid>
                        )

                        }
                        {showhide === "3" && (
                            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                                <StyledFormLabel htmlFor="transactionNumber">
                                    {t("Transaction_Number")}
                                </StyledFormLabel>
                                <StyledTextField
                                    fullWidth
                                    name={t("transactionNumber")}
                                    value={values.transactionNumber}
                                    onChange={handleInputChange}
                                    error={Boolean(errors.transactionNumber)}
                                    helperText={errors.transactionNumber}
                                />
                            </Grid>

                        )

                        }
                    </Grid>

                    <TableContainer
                        component={Paper}
                        sx={{ height: "auto", marginTop: "20px" }}
                    >
                        <Table stickyHeader aria-label="simple table">
                            <TableHead >
                                <TableRow >
                                    <StyledTableCell >{t("ORDER_DATE")}</StyledTableCell>
                                    <StyledTableCell align="center">{t("ORDER_NUMBER")}</StyledTableCell>
                                    <StyledTableCell align="center">{t("PAYABLE_AMOUNT")}</StyledTableCell>
                                    <StyledTableCell align="center">{t("DU_AMOUNT")}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                <TableRow>
                                    <StyledTableCell>{purchaseOrderdata.orderDate}</StyledTableCell>
                                    <StyledTableCell align="center">{purchaseOrderdata.orderNumber}</StyledTableCell>
                                    <StyledTableCell align="center">{purchaseOrderdata.payableAmount}</StyledTableCell>
                                    <StyledTableCell align="center">{purchaseOrderdata.pendingAmount}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid item md={2.3} xs={12}>

                    </Grid>

                    <FlexBox px={2}
                        style={{
                            marginLeft: '58%',
                        }}
                    >
                        <Box display="flex">
                            <Box pr={6}>
                                <Typography sx={{ my: 4 }} style={{
                                    fontSize: "13px",
                                }}>{t("PAYMENT_AMOUNT")}</Typography>
                            </Box>
                            <div
                            >
                                <Typography sx={{ my: 4, marginLeft: "90px", fontSize: "13px" }}><b>
                                    <input type="text" style={{ borderRadius: "4px" }} value={values.paymentAmount} id="paymentAmount" /> </b></Typography>
                                <Typography sx={{ mb: 2, marginLeft: "105px", fontSize: "13px" }}><b></b></Typography>
                            </div>
                        </Box>
                    </FlexBox>
                    <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)", marginTop: "20px" }}></div>

                    <FlexBox px={2}
                        style={{
                            marginTop: "20px",
                            marginLeft: '60%',
                        }}
                    >
                        <Box display="flex">
                            <div style={{ marginLeft: "141px" }}>
                                <Typography sx={{ mb: 6, color: "red" }}><b> </b></Typography>
                            </div>
                        </Box>
                    </FlexBox>

                    <Box id="button-box">
                        <Button variant="outlined" size="large" id='b1'
                            onClick={() => { navigate('/purchaseOrder/payment') }}
                        >
                            <strong style={strong}>{t("Cancel")}</strong>
                        </Button>
                        <Button variant="contained" size="large" id="submitButton" type='submit'>
                            <strong >{button}</strong>
                        </Button>
                    </Box>

                </Form>
            </Card>
            <Notification notify={notify} setNotify={setNotify} />

        </>
    );
}
