import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

import { styled } from "@mui/material/styles";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Person as PersonIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  CircularProgress,
  Pagination,
  Avatar,
} from "@mui/material";
import moment from "moment";
import { tableCellClasses } from "@mui/material/TableCell";
import eye from "../../assets/eye.svg";
// import edit from "../../Assets/edit.svg";
// import deleteIcon from "../../Assets/delete.svg";
import filterIcon from "../../assets/filter.svg";
import EditHSN from "./addTax";
import UsersDetailsModal from "./TaxDetails";

import { getTax } from "../../state/action/taxAction";
import { useForm } from "../../utils/useForms";
import Backdrop from "../Backdrop/loader";
import Notification from "../../utils/Notification";
import axiosInstance from "../../utils/AxiosInstace";
import { useTranslation } from "react-i18next";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";
import { useLocation } from "react-router-dom";

// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  // },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   color: "#0F0F0F80",
// }));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const hsnList = [
  {
    id: 1,
    hsn_name: "Dummy name 1",
    hsn_code: "dummy code 1",
    gst_rate: "30",
    category: "dummy category 1",
    status: 1,
  },
  {
    id: 2,
    hsn_name: "Dummy name 2",
    hsn_code: "dummy code 2",
    gst_rate: "50",
    category: "dummy category 2",
    status: 0,
  },
];
export default function HSNList(props) {
  const { refresh, refreshScreen } = props;
  const dispatch = useDispatch();
  const [hsns, setHSNS] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentHSN, setCurrentHSN] = useState(-1);
  const [userView, setUserView] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [page, setPage] = useState(1);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(true);

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  const [editHsn, setEditHsn] = useState(false);
  const openTaxModal = () => {
    setEditHsn(true);
  };
  const closeTaxModal = () => {
    setEditHsn(false);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  let getTaxList = async () => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      if (i == 0) {
        string += `&${key}=${value}`;
      } else {
        string += `&${key}=${value}`;
      }
      i++;
    }
    console.log(string);

    let response = await axiosInstance.get(
      `api/v1/gettax?limit=10&page=${page}${string}&search=${search}`
    );

    if (response.data.data) {
      setHSNS(response.data.data);
      let pagecount = Math.ceil(response.data.totalTax / 10);
      setTotalRecords(pagecount);
      setFilter(false);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    getTaxList();
  };

  useEffect(() => {
    handleClose();
    getTaxList();
  }, [refresh, filterData, search, page]);

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentHSN(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const initialValues = {
    taxType: "",
    taxValue: "",
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const [filter, setFilter] = useState(false);
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    // how to reset form
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  return (
    <>
      <Backdrop open={openBackdrop} />
      <ToastContainer limit={1} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "30px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Filter")}
                </Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="taxType">
                  {t("Tax_Name")}
                </StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_Tax_Name")}
                  fullWidth
                  id="taxType"
                  name="taxType"
                  value={values.taxType}
                  onChange={handleInputChange}
                />
              </Box>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">
                  {t("Tax_Value")}
                </StyledFormLabel>
                <StyledTextField
                  type="test"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_Tax_Value")}
                  fullWidth
                  id="taxValue"
                  name={t("taxValue")}
                  value={values.taxValue}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </form>
        </Drawer>
        {/* table */}
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">{t("No")}</StyledTableCell>
                <StyledTableCell align="center">
                  {t("Tax_Name")}
                </StyledTableCell>
                {/* <StyledTableCell>HSN Code</StyledTableCell> */}
                <StyledTableCell align="center">{t("Rate")}</StyledTableCell>
                <StyledTableCell align="right">{t("Action")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody id="accordian">
              {hsns.map((row, index) => {
                var { id, taxValue, taxType } = row;
                return (
                  <>
                    <StyledTableRow>
                      <StyledTableCell align="left" component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.taxType}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {taxValue}%
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton
                          onClick={(e) => {
                            const currentHSN = hsns.find(
                              (role) => role.id === row.id
                            );
                            handleClick(e, currentHSN);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {/* <MenuItem
            sx={{ mt: 1 }}
            onClick={() => {
              handleUserView();
            }}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem> */}
          {readPermissions && (
            <MenuItem sx={{ mt: 1 }} onClick={openTaxModal}>
              <ListItemIcon>
                <img src={eye} alt="View Details" />
              </ListItemIcon>
              <ListItemText>{t("Edit_Details")}</ListItemText>
            </MenuItem>
          )}
        </Menu>
        {/* <UsersDetailsModal
          user={currentUser}
          open={userView}
          setOpen={setUserView}
          handleClose={closeUserView}
        /> */}

        {editHsn && (
          <EditHSN
            hsn={currentHSN}
            open={editHsn}
            setOpen={setEditHsn}
            handleClickOpen={openTaxModal}
            handleClose={closeTaxModal}
            refreshScreen={refreshScreen}
          />
        )}
      </div>
      <Notification notify={notify} setNotify={setNotify} />

      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {/* Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results */}
        </div>
        <Pagination
          count={totalRecords}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
