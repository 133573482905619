import axiosInstance from "../../utils/AxiosInstace";

import axios from "axios";
//currency list
export const currencyList = async () => {
  try {
    const res = await axiosInstance.get("/api/v1/getcurrency");
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//add product
export const productAdd = async (data) => {
  try {
    const res = await axiosInstance.post("/api/v1/productadd", data);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//category Dropdown
export const categoryDropdown = async () => {
  try {
    const res = await axiosInstance.get("/api/v1/categorydropdown");
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//get product list
export const getProductList = async (payload) => {

  try {
    const res = await axios.post(`https://www.bioitaly.me/API/V1HkljJHGasdhllasdgjhJHhjds/PRBOIDOUICTTLYIST`, payload
    );
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
