import { AccountBoxOutlined } from "@mui/icons-material";
import axiosInstance from "../../utils/AxiosInstace";

//getAcoountTypeDropdown

export const AccountTypedropdown = async () => {
  try {
    const response = await axiosInstance.get();
    return response.data;
  } catch (error) {
    return error;
  }
};
//add account

export const addaccount = async (data) => {
  try {
    const response = await axiosInstance.post(`/api/v1/createAccount`, data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//list
export const getAccountList = async (queryString) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/getAccount` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//update account

export const updateAcount = async (id, data) => {
  try {
    const res = await axiosInstance.put(`/api/v1/updateAccount/${id}`, data);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//acountdelete
export const deleteAccountss = async (id) => {
  try {
    const res = await axiosInstance.delete(`/api/v1/deleteAccount/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//owner
export const leadOwnerDropdown = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/adminDropDown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//account details
export const getAccountDetails = async (id) => {
  try {
    const res = await axiosInstance.get(`api/v1/getAccountById/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};
