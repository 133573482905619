import * as React from "react";
import Box from "@mui/material/Box";
import { TextField, FormLabel, Grid, Button } from "@mui/material";
import { Card } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import UserDeleteModal from "./customerDelete";
import axiosInstance from "../../utils/AxiosInstace";
import { useNavigate, useLocation } from "react-router-dom";
import { getCustomerDetails } from "../../state/action/customerAction";
import { useTranslation } from "react-i18next";

export default function FormPropsTextFields() {
  const location = useLocation();
  const [deleteUser, setDeleteUser] = useState(false);
  const [customerdata, setCustomerdata] = useState([]);
  const [addressdata, setAddressdata] = useState([]);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { t } = useTranslation();
  const GetCustomer = async () => {
    const res = await getCustomerDetails(state);
    if (res && res.status === true) {
      setCustomerdata(res.data);
      setAddressdata(res.data.address);
    }
  };

  useEffect(() => {
    GetCustomer();
  }, []);

  const handleDeleteUser = () => {
    setDeleteUser(true);
  };
  const closeDeleteUser = () => {
    setDeleteUser(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "4px",
            }}
          >
            {" "}
            {t("View_Customers_Detail")}{" "}
          </h1>

          <p
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "34px",
              marginTop: "0px",
              cursor: "pointer",
              paddingTop: "5px",
              paddingBottom: "5px",
              hover: {
                color: "#E64A08",
              },
            }}
            onClick={() => {
              navigate("/customer");
            }}
          >
            {t("Customer")} |{" "}
            <span style={{ color: "black", cursor: "pointer" }}>
              {" "}
              {t("View_Customers_Detail")}
            </span>
          </p>
        </div>
        <Box marginTop="20px" borderRadius="12px">
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#f06237",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate(`/customer/add`, { state: customerdata.id });
            }}
          >
            <strong style={{ color: "white" }}>{t("Edit")}</strong>
          </Button>
        </Box>
      </div>
      <UserDeleteModal
        open={deleteUser}
        // id={id}
        handleClose={closeDeleteUser}
      />
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        ></Box>
        <Box
          component="form"
          noValidate
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr 1fr" },
            gap: 3,
            marginTop: "35px",
            hover: {
              backgroundColor: "red",
              borderRadius: " 10px 10px 0px 0px",
            },
          }}
        >
          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {" "}
                {t("Name")}
              </span>
            }
            value={customerdata.customerName}
            defaultValue="customerName"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />

          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("Customer_Email")}
              </span>
            }
            value={customerdata.customerEmail}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />

          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("Mobile_Number")}
              </span>
            }
            value={customerdata.mobileNumber}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("VAT_Number")}
              </span>
            }
            value={customerdata.vatNumber}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("Credit_days")}
              </span>
            }
            value={customerdata.creditDays}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("Credit_Limit")}
              </span>
            }
            value={customerdata.creditLimit}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("Advance_Amount")}
              </span>
            }
            value={customerdata.advancePayment}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </Box>
        <hr
          style={{
            marginTop: "20px",
            marginBottom: "30px",
          }}
        />
        <box>
          <div>
            <span
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              {t("Address_Details:")}
            </span>
          </div>
          <TextField
            style={{
              width: "100%",
            }}
            id="standard-read-only-input"
            value={
              addressdata.address +
              ", " +
              addressdata.countryName +
              ", " +
              addressdata.stateName +
              ", " +
              addressdata.city +
              ", " +
              addressdata.zipCode
            }
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </box>
        <hr
          style={{
            marginTop: "20px",
            marginBottom: "30px",
          }}
        />

        <strong style={{ color: "black" }}>{t("Bank_Account_Detail")}:</strong>
        <Box
          component="form"
          noValidate
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr " },
            gap: 3,
            marginTop: "35px",
          }}
        >
          {/* <Grid container></Grid> */}
          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                {" "}
                {t("Account_Name")}
              </span>
            }
            value={customerdata.accountName}
            defaultValue="Curtis Weaver"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                {" "}
                {t("Bank_Name")}
              </span>
            }
            value={customerdata.bankName}
            defaultValue="Curtis Weaver"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </Box>
        <Box
          component="form"
          noValidate
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr " },
            gap: 3,
            marginTop: "35px",
            marginBottom: "100px",
          }}
        >
          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                {" "}
                {t("Account_Number")}
              </span>
            }
            value={customerdata.accountNumber}
            defaultValue="877"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />

          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}> IFSC/MICR</span>
            }
            value={customerdata.ifscCode}
            defaultValue="4140 Parker Rd. Allentown, New Mexico 31134"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                {" "}
                {t("Swift_Code")}
              </span>
            }
            value={customerdata.swiftCode}
            defaultValue="4140 Parker Rd. Allentown, New Mexico 31134"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </Box>
      </Card>
    </>
  );
}
