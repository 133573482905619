import * as React from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  FormLabel,
  Button,
  Tab,
  Grid,
  Typography,
  CardHeader,
} from "@mui/material";

import { Card } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { getCustomerDetails } from "../../state/action/customerAction";
import { useTranslation } from "react-i18next";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const strong = {
  color: "black",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const MeetingDetails = () => {
  const location = useLocation();
  const [deleteUser, setDeleteUser] = useState(false);
  const [customerdata, setCustomerdata] = useState([]);
  const [addressdata, setAddressdata] = useState([]);
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { t } = useTranslation();
  const GetCustomer = async () => {
    const res = await getCustomerDetails(state);
    if (res && res.status === true) {
      setCustomerdata(res.data);
      setAddressdata(res.data.address);
    }
  };

  useEffect(() => {
    GetCustomer();
  }, []);

  return (
    <>
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h1
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "4px",
              }}
            >
              {" "}
              {t("View_Meeting_Detail")}{" "}
            </h1>

            <p
              style={{
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "34px",
                marginTop: "0px",
                cursor: "pointer",
                paddingTop: "5px",
                paddingBottom: "5px",
                hover: {
                  color: "#E64A08",
                },
              }}
              onClick={() => {
                navigate("/meeting");
              }}
            >
              {t("Meetings")} |{" "}
              <span style={{ color: "black", cursor: "pointer" }}>
                {" "}
                {t("View_Meeting_Detail")}
              </span>
            </p>
          </div>
          <Box marginTop="20px" borderRadius="12px">
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#F4F6FF",
                borderRadius: "10px",
                marginRight: "10px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <strong style={{ color: "black" }}>{t("Back")}</strong>
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#f06237",
                borderRadius: "10px",
              }}
              onClick={() => {
                navigate(`/addMeeting`, { state: customerdata.id });
              }}
            >
              <strong style={{ color: "white" }}>{t("Edit")}</strong>
            </Button>
          </Box>
        </div>

        <Card
          style={{
            padding: "20px",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Grid item md={12} sm={12} xs={12} container spacing={1}>
              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <Typography> {t("MeetingInformation")}</Typography>
              </Grid>

              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <CalendarMonthIcon />
                  <span sx={{ marginLeft: "10px" }}> Friday, Mar 24</span>
                </div>
              </Grid>

              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <AccessTimeIcon />
                  <span sx={{ marginLeft: "10px" }}>04:00 PM - 05:00 PM</span>
                </div>
              </Grid>
              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <AccessAlarmsIcon />
                  <span sx={{ marginLeft: "10px" }}>24/03/2023 03:45 PM</span>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default MeetingDetails;
