import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormLabel,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const strong = {
  color: "black",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const Meeting = () => {
  const [uploadFile, setUploadFile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleUploadOpen = () => setUploadFile(true);
  const handleUploadClose = () => setUploadFile(false);

  const opens = Boolean(anchorEl);
  const handleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
              <Grid item sm={7} xs={12}>
                <Box display="flex">
                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      Open Activities
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={5} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    id="basic-button"
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "#f06237",
                      borderRadius: "10px",
                    }}
                    aria-controls={opens ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={opens ? "true" : undefined}
                    onClick={() => navigate("/addMeeting")}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Add New
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={opens}
                    onClose={handleCloses}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleUploadOpen}>
                      <ListItemText>Add Meeting</ListItemText>
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>

              <Grid item md={5.5} xs={12}>
                <Card>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 18, color: "black" }}
                      gutterBottom
                    >
                      Open Meeting
                    </Typography>
                    <Typography variant="h5" component="div"></Typography>
                    <Typography sx={{ mb: 1.5, color: "black" }}>
                      New Meeting
                    </Typography>
                    <Typography variant="body2">
                      24/03/2023 06:00 PM - 07:00 PM
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default Meeting;
