import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "../productPage/productList";
import { useState } from "react";
import "../customerPage/style/addCustomer.css";
import { Link, useNavigate } from "react-router-dom";
import draftToHtml from "draftjs-to-html";

import {
  TextField,
  FormLabel,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Stack,
  Autocomplete,
  Checkbox,
  FormGroup,
  Menu,
  MenuItem,
  ListItemIcon,
  Modal,
  ListItemText,
} from "@mui/material";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getCustomerDetails,
  getCountry,
  addCustomer,
  updateCustomer,
} from "../../state/action/customerAction";
import {
  AddUser,
  countryDropdown,
  leadTypeDropdown,
  getStateList,
  leadOwnerDropdown,
  updateLead,
  getLeadDetails,
  AddImage,
} from "../../state/action/leadAction";
import {
  AddCampagin,
  getLeadContactList,
} from "../../state/action/campaginAction";
import DOMPurify from "dompurify";
import { ToastContainer, toast } from "react-toastify";
import "./editor.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";
import Controls from "../control/Control";
import Search from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import camera from "../../assets/camera.svg";

const strong = {
  color: "black",
};

const initialValues = {
  eid: "",
  type: "",
  htmlData: "",
  bulkEmailFile: "",
};

const types = [
  { id: "1", title: "Lead" },
  { id: "2", title: "Contact" },
];

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CustomizedInputs(props) {
  const { t } = useTranslation();
  const { state } = useLocation();

  const [selected, setSelected] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const navigate = useNavigate();
  const [leadList, setLeadType] = useState([]);
  const [leadContactlist, setLeadContactList] = useState([]);
  const [explanationeditorState, setExplanationEditorState] = useState();
  const [explanationContent, setExplanationContent] = useState(null);

  const [questionContent, setQuestionContent] = useState(null);

  const [emailId, setEmailId] = useState("");

  const [leadOwner, setLeadOwner] = useState([]);
  const [editorState, setEditorState] = useState();
  const [uploadFile, setUploadFile] = useState(false);

  const [edit, setIsEdit] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [convertedContent, setConvertedContent] = useState(null);
  const [policyId, setpolicyId] = useState(0);
  // const [editorState, setEditorState] = useState();
  const [itemss, setItemss] = useState([]);
  const [checked, setChecked] = useState(true);

  let properties = {};

  let datass1 = leadContactlist.map((car) => {
    properties = {
      id: car.id,
    };

    return properties;
  });

  const handleUploadOpen = () => setUploadFile(true);
  const handleUploadClose = () => setUploadFile(false);

  const handleChangeSelect = (e) => {
    setSelected(e.target.value);
    // setChecked(e.target.checked);
    // setHideContact(true);
  };

  const convertContentToHTML = (i) => {
    if (i === 2) {
      let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
      setQuestionContent(currentContentAsHTML);
    } else {
      let currentContentAsHTMLs = convertToHTML(
        explanationeditorState.getCurrentContent()
      );
      setExplanationContent(currentContentAsHTMLs);
    }
  };

  const handleEditorChange = (state) => {
    setExplanationEditorState(state);
    convertContentToHTML(1);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const opens = Boolean(anchorEl);
  const handleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEl(null);
  };

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  //leadcontact email list
  const getleadContactListss = async (e) => {
    const datass = {
      type: values.type,
    };

    const res = await getLeadContactList(datass);
    if (res.status === true) {
      setLeadContactList(res.data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (validate()) {
    // const htmlContent = draftToHtml(
    //   convertToRaw(editorState.getCurrentContent())
    // );

    const formData = new FormData();
    formData.append("type", values.type);
    formData.append("htmlData", explanationContent);
    formData.append("eid", JSON.stringify([emailId]));
    formData.append("subject", values.subject);
    formData.append("bulkEmailFile", values.bulkEmailFile);

    const res = await AddCampagin(formData);
    if (res && res.status === true) {
      setNotify({
        isOpen: true,
        message: res.message,
        type: "success",
      });
      setTimeout(() => {
        navigate("/campaginList");
      }, 1000);
    } else {
      setNotify({
        isOpen: true,
        message: res.message,
        type: "warning",
      });
    }
    // }
  };

  //lead dropdown

  const getSelectedItems = (data) => {
    if (data) {
      const rendomNum = Math.floor(100000 + Math.random() * 900000);
      const selectedItem = {
        name: data?.email,
      };
      itemss.push(selectedItem);
      const neItems = [...itemss];
      setItemss(neItems);
      setEmailId(data?.id);
    }
  };

  //image
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["bulkEmailFile"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      ".doc",
      ".docx",
      "application/pdf",
    ];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, bulkEmailFile: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleAddImage = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("leadId", state?.lead);
    formData.append("file", values.file);
    formData.append("type", 2);
    formData.append("status", 1);
    formData.append("createdBy", 1);
    formData.append("editedBy", 1);
    formData.append("accountId", 1);
    formData.append("contactId", 1);

    const res = await AddImage(formData);

    if (res && res.status === true) {
      // navigate("/buyer");
      handleUploadClose();
      setNotify({
        isOpen: true,
        message: res.message,
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="purchaseOrderheadder">
        <div>
          <h1 className="purchaseOrderheadder-left"> {t("Add_Campagin")}</h1>
          <p
            className="purchaseOrderheadder-right"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/campaginList");
            }}
          >
            {" "}
            {t("Campagin")} |
            <span
              className="purchaseOrderheadder-right-span"
              style={{ color: "black" }}
            >
              {" "}
              {t("Add_Campagin_Details")}
            </span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Box className="b1">
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Typography>{t("Campagin_Information")}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="All Mail Send"
                  name="eid"
                  onChange={handleInputChange}
                  value={values.eid}
                />
              </FormGroup>
            </Grid>

            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Controls.RadioGroup
                name="type"
                onChange={(e) => {
                  handleInputChange(e);
                  handleChangeSelect(e);
                  getleadContactListss(e);
                }}
                value={values.type}
                items={types}
                // defaultChecked={values.type}
                isRowDir={true}
              />

              {selected ? (
                <Autocomplete
                  disablePortal
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  labelId="category-input"
                  options={leadContactlist}
                  placeholder="lead"
                  sx={{
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                      color: "#000",
                    },
                    width: "100%",
                    "& .MuiAutocomplete-popupIndicator": {
                      transform: "none",
                    },
                  }}
                  openOnFocus={true}
                  onChange={(event, value) => getSelectedItems(value)}
                  getOptionLabel={(option) => (option ? option.email : "")}
                  renderInput={(params) => (
                    <Controls.Input fullWidth {...params} />
                  )}
                  popupIcon={<Search />}
                />
              ) : null}
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="subject">
                {t("Subject")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="subject"
                inputProps={{ maxLength: 50 }}
                value={values.subject}
                onChange={handleInputChange}
                error={errors.subject}
                helperText={errors.subject}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Box>
                <ToastContainer limit={1} />
                <Stack flexDirection="row" justifyContent="space-between">
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                    Body
                  </Typography>
                </Stack>

                <div className="App">
                  <Editor
                    editorState={explanationeditorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      fontFamily: {
                        //disable fontFamily dropdown in toolbar
                        options: ["Montserrat, Inter"],
                      },
                    }}
                  />
                </div>
              </Box>
            </Grid>

            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
                id="basic-button"
                aria-controls={opens ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={opens ? "true" : undefined}
                onClick={handleClicks}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Attach
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={opens}
                onClose={handleCloses}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleUploadOpen}>
                  <ListItemIcon>
                    <UploadFileIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Upload File</ListItemText>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <Box className="customerBox">
            <div>
              <Button
                variant="outlined"
                size="large"
                className="buttonCancel"
                onClick={() => {
                  navigate("/campaginList");
                }}
                style={{
                  borderRadius: "10px",
                  width: "40%",
                  height: "94%",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Save")}
                </strong>
              </Button>
            </div>
          </Box>
        </Box>
        <Modal
          open={uploadFile}
          onClose={handleUploadClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <StyledImageUploadWrapper>
              <label htmlFor="image-upload">
                <StyledInput
                  accept="image/*"
                  id="image-upload"
                  type="file"
                  onChange={handleImageItem}
                  onClick={(event) => {
                    event.currentTarget.value = null;
                  }}
                />
                <StyledIconWrapper
                  sx={
                    image == ""
                      ? {}
                      : {
                          backgroundImage: `url(${image})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }
                  }
                >
                  {image === "" && <img src={camera} alt="Camera" />}
                </StyledIconWrapper>
              </label>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Add Photo
              </Typography>

              {image != "" && (
                <StyledRemoveButton
                  onClick={(e) => {
                    e.preventDefault();
                    removeImage();
                  }}
                >
                  Remove
                </StyledRemoveButton>
              )}
            </StyledImageUploadWrapper>
          </Box>
        </Modal>
      </Form>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
