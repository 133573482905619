import * as React from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  FormLabel,
  Grid,
  Button,
  Typography,
  Divider,
  Collapse,
  CardHeader,
  Avatar,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  Modal,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  Tabs,
  Tab,
  CardContent,
  IconButton,
  tableCellClasses,
} from "@mui/material";

import { Card } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import axiosInstance from "../../utils/AxiosInstace";
import { useNavigate, useLocation } from "react-router-dom";
import { getCustomerDetails } from "../../state/action/customerAction";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArticleIcon from "@mui/icons-material/Article";
import LinkIcon from "@mui/icons-material/Link";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Meeting from "../Lead/Meeting";
import { getContactDetails } from "../../state/action/contactAction";
import edit from "../../assets/edit.svg";
import {
  AddImage,
  AddNotes,
  getImageList,
  getLeadNotes,
  notesDelete,
} from "../../state/action/leadAction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { Form, useForm } from "../../utils/useForms";
import moment from "moment";
import camera from "../../assets/camera.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Notification from "../../utils/Notification";

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const strong = {
  color: "black",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const initialValues = {
  notes: "",
  leadId: "",
  createdBy: "1",
};

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const ContactInformationDetails = () => {
  const [open, setOpen] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [link, setLink] = useState(null);
  const [leadNoteList, setLeadNoteList] = useState([]);
  const [contactdata, setContactdata] = useState([]);
  const [statedata, setStatedata] = useState([]);
  const [country, setCountry] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [contactOwner, setContactOwner] = useState([]);
  const [accountNames, setAccountNames] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [imageListing, setImageListing] = useState([]);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { t } = useTranslation();

  const handleUploadOpen = () => setUploadFile(true);
  const handleUploadClose = () => setUploadFile(false);

  const handleLinkOpen = () => setLink(true);
  const handleLinkClose = () => setLink(false);

  const opens = Boolean(anchorEl);
  const handleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    setOpen(!open);
    setHideButton(true);
  };

  const handleClose = () => {
    setOpen(false);
    setHideButton(false);
  };

  const GetContact = async () => {
    const res = await getContactDetails(state?.user);
    if (res && res.status === true) {
      setContactdata(res.data);
      setStatedata(res.data.state);
      setCountry(res.data.country);
      setAccountList(res.data.account);
      setLeadNoteList(res.data.notes);
      setContactOwner(res.data.contactOwnerId);
      setAccountNames(res.data.accountId);
    }
  };

  useEffect(() => {
    GetContact(state?.user);
  }, [state?.user]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("notes" in fieldValues)
      temp.notes = fieldValues.notes ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        notes: values.notes,
        createdBy: "1",
        contactId: state?.user,
      };
      const res = await AddNotes(data);
      if (res && res.status === true) {
        setNotify({
          isOpen: true,
          message: res.message,
          type: "success",
        });
        handleClose();
      } else {
        setNotify({
          isOpen: true,
          message: res.message,
          type: "warning",
        });
      }
    }
  };

  //menu item for notes
  const [anchorElNote, setAnchorElsNote] = useState(null);
  const openNote = Boolean(anchorElNote);
  const handleClickNote = (event) => {
    setAnchorElsNote(event.currentTarget);
  };
  const handleCloseNote = () => {
    setAnchorElsNote(null);
  };

  //image
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };

  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["file"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      ".doc",
      ".docx",
      "application/pdf",
    ];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, file: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  //addimage

  const handleAddImage = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("contactId", state?.user);
    formData.append("file", values.file);
    formData.append("type", 1);
    formData.append("status", 1);
    formData.append("createdBy", 1);
    formData.append("editedBy", 1);
    formData.append("accountId", 1);
    formData.append("leadId", 1);

    const res = await AddImage(formData);

    if (res && res.status === true) {
      // navigate("/buyer");
      handleUploadClose();
    } else {
      setNotify({
        isOpen: true,
        type: "error",
      });
    }
  };

  //imagelist

  const ImageLists = async (e) => {
    const res = await getImageList();
    if (res.status === true) {
      setImageListing(res.data);
    }
  };

  useEffect(() => {
    ImageLists();
  }, []);

  //delete the notes
  const [deleteNotess, setDeleteNotess] = useState(false);

  const handleDeleteNoteOpen = () => setDeleteNotess(true);
  const handleDeleteNoteClose = () => setDeleteNotess(false);

  const onSubmit = async () => {
    const notesdelete = {
      contactId: state?.user,
      createdBy: 2,
    };
    const res = await notesDelete(state?.user, notesdelete);

    if (res && res.status) {
      handleDeleteNoteClose();
      setNotify({
        isOpen: true,
        message: res.message,
        type: "success",
      });
    } else {
      setNotify({
        isOpen: true,
        message: res.message,
        type: "warning",
      });
    }
  };

  //edit the notes
  const [editNotess, setEditNotess] = useState(false);

  const handleEditNoteOpen = () => setEditNotess(true);
  const handleEditNoteClose = () => setEditNotess(false);

  return (
    <Box>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography> {t("contactInformation")}</Typography>
            </Grid>

            <Grid
              item
              sx={{ m: 1, display: "flex", justifyContent: "end" }}
              md={5.5}
              xs={12}
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("contact_first_Name")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{contactdata?.firstName}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("contact_last_Name")} :
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{contactdata?.lastName}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sx={{ m: 1, display: "flex", justifyContent: "end" }}
              md={5.5}
              xs={12}
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Email")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{contactdata?.email}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sx={{ m: 1, display: "flex", justifyContent: "end" }}
              md={5.5}
              xs={12}
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Contact Owner")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>
                    {contactOwner?.firstName + " " + contactOwner?.lastName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              sx={{ m: 1, display: "flex", justifyContent: "end" }}
              md={5.5}
              xs={12}
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Account Name")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountNames?.accountName}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Phone_Number")} :
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{contactdata?.phone}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Contact_Source")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{contactdata?.leadSource}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography> {t("addressInformation")}</Typography>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Street")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{contactdata?.street}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("City")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{contactdata?.city}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("State")} :
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{statedata?.name}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Country")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{country?.name}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("ZipCode")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{contactdata?.zipCode}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography> {t("description")}</Typography>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("contact_description")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{contactdata?.description}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>{t("Notes")}</Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              {!hideButton ? (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "#f06237",
                      borderRadius: "10px",
                      width: "8%",
                    }}
                    startIcon={<AddIcon />}
                    fullWidth
                    name="notes"
                    onClick={handleClick}
                  />
                </Box>
              ) : null}
            </Grid>

            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              handleClose={handleClose}
            >
              <Card
                style={{
                  padding: "20px",
                  borderRadius: "12px",
                  marginTop: "15px",
                  boxShadow: "none",
                  width: "232%",
                  justifyContent: "center",
                  marginLeft: "75px",
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                      <StyledTextFields
                        id="outlined-multiline-flexible"
                        multiline
                        fullWidth
                        maxRows={3}
                        name="notes"
                        sx={{ width: "193%" }}
                        placeholder="Add a note"
                        value={values.notes}
                        onChange={handleInputChange}
                        error={errors.notes}
                        helperText={errors.notes}
                      />
                    </Grid>{" "}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                      borderRadius: "12px",
                    }}
                  >
                    <div>
                      <Button
                        variant="outlined"
                        size="large"
                        className="buttonCancel"
                        onClick={handleClose}
                        style={{
                          borderRadius: "10px",
                          width: "40%",
                          height: "94%",
                        }}
                      >
                        <strong style={strong}>{t("Cancel")}</strong>
                      </Button>{" "}
                      <Button
                        variant="contained"
                        size="large"
                        type="submit"
                        style={{
                          backgroundColor: "#f06237",
                          borderRadius: "10px",
                        }}
                      >
                        <strong className="purchaseOrderheadder-box-button2-strong">
                          {t("Save")}
                        </strong>
                      </Button>
                    </div>
                  </Box>
                </Form>
              </Card>
            </Collapse>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              {leadNoteList?.map((row) => {
                return (
                  <Card sx={{ maxWidth: 345, boxShadow: "none" }}>
                    <CardContent>
                      <Box>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openNote ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openNote ? "true" : undefined}
                          onClick={handleDeleteNoteOpen}
                          sx={{ marginBottom: "-75px", marginLeft: "241px" }}
                        >
                          <DeleteIcon />
                        </IconButton>

                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openNote ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={openNote ? "true" : undefined}
                          onClick={() => {
                            // setEditNote(row);
                            handleEditNoteOpen();
                          }}
                          sx={{ marginLeft: "277px", marginBottom: "-30px" }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                      <Typography gutterBottom variant="h5" component="div">
                        Note: {row.notes}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Lead Name : {row.leadName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Admin Name : {row.adminName}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
              <Grid item sm={7} xs={12}>
                <Box display="flex">
                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      Attachments
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={5} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      backgroundColor: "#f06237",
                      borderRadius: "10px",
                    }}
                    id="basic-button"
                    aria-controls={opens ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={opens ? "true" : undefined}
                    onClick={handleClicks}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Attach
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={opens}
                    onClose={handleCloses}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleUploadOpen}>
                      <ListItemIcon>
                        <UploadFileIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Upload File</ListItemText>
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
            </Grid>

            <Grid item md={5.5} xs={12}>
              <Card
                style={{
                  padding: "20px",
                  borderRadius: "12px",
                  marginTop: "15px",
                  // boxShadow: "none",
                  width: "212%",
                  justifyContent: "center",
                  // marginLeft: "75px",
                  backgroundColor: "white",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: {
                        lg: "1250px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: "40px",
                        marginBottom: "20px",
                        borderTopRightRadius: "20px",
                        borderTopLeftRadius: "20px",
                        border: 1,
                        borderColor: "#eaeaea",
                        marginRight: "20px",
                        width: "218%",
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                      >
                        <Table
                          aria-label="simple table"
                          sx={{ paddingLeft: "0!important" }}
                        >
                          <TableHead sx={{ paddingLeft: "0!important" }}>
                            <TableRow
                              sx={{
                                backgroundColor: "#f6f9fb",
                                paddingLeft: "0!important",
                              }}
                            >
                              <StyledTableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Lead Name
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Admin Name
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                File Name
                              </StyledTableCell>

                              <StyledTableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Attached By
                              </StyledTableCell>

                              <StyledTableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Date Added
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              backgroundColor: "#fff",
                              paddingLeft: "0!important",
                            }}
                          >
                            {imageListing?.map((row) => {
                              return (
                                <>
                                  <TableRow>
                                    <StyledTableCell>
                                      {row?.leadName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row?.adminName}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {row?.file}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {moment(row?.createdAt).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </StyledTableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box
                      sx={{
                        marginRight: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          marginLeft: "auto",
                          maxWidth: "250px",
                        }}
                      ></Box>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Meeting />
      <Modal
        open={uploadFile}
        onClose={handleUploadClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <label className="fontLight">
            Click here or drop files here to attach
          </label>
          <Grid
            container
            gap={1}
            style={{
              marginTop: "1rem",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <Grid
              item
              className="text-end"
              sx={{ justifyContent: "end", display: "flex" }}
            >
              <Button
                variant="outlined"
                size="large"
                className="buttonCancel"
                onClose={handleCloses}
                style={{
                  borderRadius: "10px",
                  width: "73%",
                  height: "94%",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
            </Grid>
            <Grid item className="text-end">
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Save")}
                </strong>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={uploadFile}
        onClose={handleUploadClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledImageUploadWrapper>
            <label htmlFor="image-upload">
              <StyledInput
                accept="image/*"
                id="image-upload"
                type="file"
                onChange={handleImageItem}
                onClick={(event) => {
                  event.currentTarget.value = null;
                }}
              />
              <StyledIconWrapper
                sx={
                  image == ""
                    ? {}
                    : {
                        backgroundImage: `url(${image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }
                }
              >
                {image === "" && <img src={camera} alt="Camera" />}
              </StyledIconWrapper>
            </label>
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              Add Photo
            </Typography>

            {image != "" && (
              <StyledRemoveButton
                onClick={(e) => {
                  e.preventDefault();
                  removeImage();
                }}
              >
                Remove
              </StyledRemoveButton>
            )}
          </StyledImageUploadWrapper>

          <Grid
            container
            gap={1}
            style={{
              marginTop: "1rem",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <Grid
              item
              className="text-end"
              sx={{ justifyContent: "end", display: "flex" }}
            >
              <Button
                variant="outlined"
                size="large"
                className="buttonCancel"
                onClose={handleCloses}
                style={{
                  borderRadius: "10px",
                  width: "73%",
                  height: "94%",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
            </Grid>
            <Grid item className="text-end">
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
                onClick={handleAddImage}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Save")}
                </strong>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={link}
        onClose={handleLinkClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ marginTop: "-25px" }}>
            <StyledFormLabel htmlFor="zipCode">
              {t("ZipCode")} Enter URL
            </StyledFormLabel>
            <StyledTextField
              fullWidth
              inputProps={{ maxLength: 10 }}
              name={t("zipCode")}
            />
          </Box>

          <Grid
            container
            gap={1}
            style={{
              marginTop: "1rem",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <Grid
              item
              className="text-end"
              sx={{ justifyContent: "end", display: "flex" }}
            >
              <Button
                variant="outlined"
                size="large"
                className="buttonCancel"
                onClose={handleCloses}
                style={{
                  borderRadius: "10px",
                  width: "73%",
                  height: "94%",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
            </Grid>
            <Grid item className="text-end">
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Save")}
                </strong>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      //delete notes
      <Modal
        open={deleteNotess}
        onClose={handleDeleteNoteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ marginTop: "-25px" }}>
            <StyledFormLabel htmlFor="zipCode">
              Are you sure you want to delete this Notes ?
            </StyledFormLabel>
          </Box>
          <Grid
            container
            gap={1}
            style={{
              marginTop: "1rem",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <Grid
              item
              className="text-end"
              sx={{ justifyContent: "end", display: "flex" }}
            >
              <Button
                variant="outlined"
                size="large"
                className="buttonCancel"
                onClick={handleDeleteNoteClose}
                style={{
                  borderRadius: "10px",
                  width: "73%",
                  height: "94%",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
            </Grid>
            <Grid item className="text-end">
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
                onClick={onSubmit}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Delete")}
                </strong>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      //notes edit
      <Modal
        open={editNotess}
        onClose={handleEditNoteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Form onSubmit={handleSubmit}>
            <Box sx={{ marginTop: "-25px" }}>
              <StyledFormLabel htmlFor="notes">Notes</StyledFormLabel>
              <StyledTextField
                fullWidth
                name="notes"
                id="notes"
                // onChange={handleChange}
                // value={editNote}
              />
            </Box>

            <Grid
              container
              gap={1}
              style={{
                marginTop: "1rem",
                justifyContent: "end",
                display: "flex",
              }}
            >
              <Grid
                item
                className="text-end"
                sx={{ justifyContent: "end", display: "flex" }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  className="buttonCancel"
                  onClick={handleEditNoteClose}
                  style={{
                    borderRadius: "10px",
                    width: "73%",
                    height: "94%",
                  }}
                >
                  <strong style={strong}>{t("Cancel")}</strong>
                </Button>{" "}
              </Grid>
              <Grid item className="text-end">
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    backgroundColor: "#f06237",
                    borderRadius: "10px",
                  }}
                >
                  <strong className="purchaseOrderheadder-box-button2-strong">
                    {t("Save")}
                  </strong>
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Modal>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default ContactInformationDetails;
