import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axiosInstance from "../../utils/AxiosInstace";
import { Form, useForm } from "../../utils/useForms";
import Autocomplete from '@mui/material/Autocomplete';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './payment.css';


import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Card,
    TextField,
    FormLabel,
    MenuItem,
    Grid,
    Button,
    Typography,
} from "@mui/material";
import Notification from "../../utils/Notification";
import Backdrop from '../Backdrop/loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tableCellClasses } from '@mui/material/TableCell';
import { store } from "../../state/store";


const FlexBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))

const StyledTextField = styled(TextField)(() => ({
    marginTop: "3px",
    ".MuiOutlinedInput-root": {
        borderRadius: "10px",
        height: "33px",
        paddingTop: "0px",

    },
    ".MuiOutlinedInput-input": {
        fontSize: "13px",
    },
}));


const StyledFormLabel = styled(FormLabel)(() => ({
    display: "block",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
}));

const initialValues = {

};

export default function CustomizedInputs(props) {
    useEffect(() => {

        getCustomers()
    }, [])
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true);
    const navigate = useNavigate();
    const [customerlist, setCustomerlist] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [customerdata, setCustomerdata] = useState([]);
    const [orderlist, setOrderlist] = useState([]);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { t } = useTranslation();

    const [customerAddress, setCustomerAddress] = useState([]);
    const [date, setDate] = useState(new Date());
    const [showhide, setShowHide] = useState("");


    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [specificationarr, setSpecificationArr] = useState([{ type: "", value: "", amount: "", }]);
    const [refresh, setRefresh] = useState(false);
    const [addAdmin, setAddAdmin] = useState(false);
    let { id } = useParams();
    const button = id ? t("Save") : t("Add_Payment")
    const headder = id ? t("Edit_Product") : t("Add_Product")
    const openAdminModal = () => {
        setAddAdmin(true);
    };
    const closeAdminModal = () => {
        setAddAdmin(false);
    };
    const addClick = async (event) => {
        setSpecificationArr((s) => {
            return [
                ...s,
                {
                    taxType: "",
                    taxPercentage: "",
                    taxValue: "",
                },
            ];
        });
    };

    const strong = {
        color: 'black',
    }
    const customerDetails = async (e) => {
        if (e) {
            await axiosInstance
                .get(`api/v1/customerdetails/${e.id}`).then((res) => {
                    setCustomerdata(res.data.data)
                    setCustomerAddress(res.data.data.address)
                }).catch((e) => {
                    // console.log("error found")
                })
        }
        else {
            setCustomerdata({})
        }
    }




    const getCustomers = async (e) => {
        console.log('working')
        await axiosInstance
            .get(`/api/v1/customerpaymentlist`).then((res) => {
                console.log(res.data.data)
                setCustomerlist(res.data.data)
            }).catch((e) => {
                console.log("error found")
            })
    }
    const getOrderListData = (e) => {

        setValues({
            ...values,
            orderId: e.target.value,
        });
        axiosInstance
            .get(`/api/v1/customerOrder/${e.target.value}`).then((res) => {

                if (res.data.data[0].paymentId != "3") {
                    setOrderlist(res.data.data)

                }
            }).catch((e) => {
                console.log("error found")
            })

    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {

            backgroundColor: "rgba(40, 45, 38, 0.05)",
            color: "rgba(15, 15, 15, 0.5)",
            fontSize: "12px",
            paddingTop: "6px",
            paddingBottom: "6px",

        },
        [`&.${tableCellClasses.body}`]: {

            fontSize: 14,
            color: "rgba(40, 45, 38, 0.5)",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:last-child td, &:last-child th': {

            border: 0,
            display: "flex"
        },
    }));

    const openConfirmDialog = () => {
        setConfirmDialog(true);
    };
    const closeConfirmDialog = () => {
        setConfirmDialog(false);
    };


    const { auth } = store.getState()
    const userid = auth.user.id;

    const handleSubmit = async (e) => {
        let pay_amt = document.getElementById("paymentAmount").value;
        e.preventDefault();
        const payload = {
            "salesOrderId": orderData.id,
            "customerId": String(orderlist[0].customerId),
            "transactionId": String("123"),
            "paymentDate": date,
            "payment": values.payment,
            "paymentAmount": pay_amt,

            "reference": values.reference,
            "paymentMode": values.paymentMode,
            "chequeNumber": values.chequeNumber,
            "accountNumber": values.accountNumber,
            "bankName": values.bankName,
            "transactionNumber": values.transactionNumber,
            "recivedBy": userid,
        }

        if (userid) {
            await axiosInstance
                .post(`/api/v1/salesOrderPayment`, payload).then((res) => {
                    if (res.data.status === true) {
                        if (res.data.status === true) {
                            setNotify({

                                isOpen: true,
                                message: "Payment Added Successfully",
                                type: "success",

                            });
                            setTimeout(() => {
                                navigate('/payment');
                            }, 500);



                        }
                    }
                }).catch((e) => {
                    let msg = e.response.data.message
                    setErrorMessage(msg)
                    setNotify({

                        isOpen: true,
                        message: e.response.data.message,
                        type: "warning",

                    });
                    setTimeout(() => {
                        navigate('/payment/add');
                    }, 10000);

                })

        }
    };
    const handleShowHide = (e) => {
        setValues({
            ...values,
            paymentMode: e.target.value,
        });

        const getuser = e.target.value;
        console.log(getuser)
        setShowHide(getuser);

    }
    return (
        <>
            <Backdrop open={openBackdrop} />

            <div
                className="purchaseOrderheadder"
            >
                <div>
                    <h1 className='purchaseOrderheadder-left' style={{ fontSize: "16px" }}> {t("Payment")} </h1>
                    <p className='purchaseOrderheadder-right' style={{ fontSize: "12px" }}> {t("Payment")}|
                        <span className='purchaseOrderheadder-right-span' style={{ color: "black" }}>  {t("Payment_Add")}</span>
                    </p>
                </div>

            </div>

            <Card

            >
                <Form onSubmit={handleSubmit}>
                    <Grid item md={12} sm={8} xs={12} container spacing={1}>
                        <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                            <StyledFormLabel htmlFor="country">
                                {t("Customer")}

                            </StyledFormLabel>
                            <StyledTextField
                                id="customer"
                                name={t("customer")}
                                fullWidth
                                select
                                value={values.customer}
                                onChange={getOrderListData}
                                error={Boolean(errors.customer)}
                                helperText={errors.customer}
                            >
                                {customerlist.map((val) => {
                                    return (
                                        <MenuItem key={val.id} value={val.id}>
                                            {val.customerName}
                                        </MenuItem>
                                    )
                                })}
                            </StyledTextField>

                        </Grid>

                        <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                            <StyledFormLabel htmlFor="productGroup">
                                {t("Order_No")}
                            </StyledFormLabel>

                            <Autocomplete
                                disablePortal
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                placeholder={t('Search_Vendor')}
                                closeIcon={<closeIcon />}
                                labelId="customer-input"
                                options={orderlist}
                                value={values.orderNumber}
                                onChange={(e, value) => {
                                    console.log("111111111", value)
                                    setOrderData(value)


                                }}

                                getOptionLabel={(option, key) =>
                                    option ? option.orderNumber : ""
                                }
                                renderInput={(params) => (
                                    <StyledTextField
                                        sx={{ paddingTop: "0px" }}
                                        name={params.orderNumber}
                                        labelId="budget-input"
                                        fullWidth
                                        {...params}
                                        error={errors.orderNumber}
                                        helperText={errors.orderNumber}
                                    />
                                )}
                                popupIcon={<ExpandMoreIcon />}
                            />
                        </Grid>
                    </Grid>


                    {customerdata.companyName ? <Grid item sx={{ m: 1 }} container md={11.7} spacing={1} xs={12}
                        className="purchaseOrder-card-supplierdata-grid"

                    >
                        <Grid item md={3.5} xs={12}>
                            <Grid item sx={{ m: 1, mt: 1 }} md={12} xs={12}>
                                <h1 className="purchaseOrder-card-supplierdata-grid-h1">{t("Name:")} </h1>
                                <span id='purchaseOrder-card-supplierdata-grid-span'>{customerdata.customerName}</span>
                            </Grid>
                        </Grid>

                        <Grid item md={3.5} xs={12}>
                            <Grid item sx={{ m: 1, mt: 1 }} md={12} xs={12} >
                                <h1 className="purchaseOrder-card-supplierdata-grid-h1" >{t("Address:")}</h1>
                                <Typography variant="h6" id='text-right2'>
                                    {customerAddress.address}
                                </Typography>
                                <Typography variant="h6" id='text-right3'>
                                    {customerAddress.city},
                                    {customerAddress.state},
                                </Typography>
                                <Typography variant="h6" id='text-right4'>
                                    {customerAddress.country}
                                </Typography>
                                <Typography variant="h6" id='text-right4'>
                                    {customerAddress.zipCode}
                                </Typography>

                            </Grid>
                        </Grid>
                        <Grid item md={3.5} xs={12}>
                            <Grid item sx={{ m: 1, mt: 1 }} md={12} xs={12}>
                                <h1 className="purchaseOrder-card-supplierdata-grid-h1">{t("Phone_Number:")}  </h1>
                                <span id='purchaseOrder-card-supplierdata-grid-span' >{customerdata.companyPhoneNumber}</span>

                            </Grid>
                        </Grid>
                    </Grid>
                        : <div></div>}

                    <Grid item md={12} sm={8} xs={12} container spacing={1}>
                        <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                            <StyledFormLabel htmlFor="productGroup"
                            >
                                {t("Date")}
                            </StyledFormLabel>
                            <StyledTextField
                                style={{
                                    height: "33px",
                                    borderRadius: "10px",
                                    fontSize: "13px",

                                }}
                                id="date"
                                type="date"
                                defaultValue="2017-05-24"
                                onChange={(e) => {
                                    setDate(e.target.value)
                                }
                                }
                                error={errors.date}
                                helperText={errors.date}
                                sx={{ width: 480 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                            <StyledFormLabel htmlFor="paymentMode">
                                {t("Payment_Mode")}
                            </StyledFormLabel>
                            <StyledTextField
                                name={t('paymentMode')}
                                fullWidth
                                select
                                value={values.paymentMode}

                                onChange={(e) => (handleShowHide(e))}
                                error={Boolean(errors.paymentMode)}
                                helperText={errors.paymentMode}
                            >
                                <MenuItem key="1" value="1">
                                    {t("Cash")}
                                </MenuItem>
                                <MenuItem key="2" value="2">
                                    {t("Cheque")}
                                </MenuItem>
                                <MenuItem key="3" value="3">
                                    {t("Online_Tranaction")}
                                </MenuItem>
                            </StyledTextField>
                        </Grid>
                        {showhide === "2" && (
                            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                                <StyledFormLabel htmlFor="bankName">
                                    {t("Bank_Name")}

                                </StyledFormLabel>
                                <StyledTextField
                                    fullWidth
                                    name={t("bankName")}
                                    value={values.bankName}
                                    onChange={handleInputChange}
                                    error={Boolean(errors.bankName)}
                                    helperText={errors.bankName}
                                />
                            </Grid>

                        )
                        }
                        {showhide === "2" && (
                            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                                <StyledFormLabel htmlFor="accountNumber">
                                    {t("Account_Number")}

                                </StyledFormLabel>
                                <StyledTextField
                                    fullWidth
                                    name={t("accountNumber")}
                                    value={values.accountNumber}
                                    onChange={handleInputChange}
                                    error={Boolean(errors.accountNumber)}
                                    helperText={errors.accountNumber}
                                />
                            </Grid>

                        )

                        }
                        {showhide === "2" && (
                            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                                <StyledFormLabel htmlFor="chequeNumber">
                                    {t("Cheque_Number")}

                                </StyledFormLabel>
                                <StyledTextField
                                    fullWidth
                                    name={t("chequeNumber")}
                                    value={values.chequeNumber}
                                    onChange={handleInputChange}
                                    error={Boolean(errors.chequeNumber)}
                                    helperText={errors.chequeNumber}
                                />

                            </Grid>)

                        }
                        {showhide === "3" && (
                            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
                                <StyledFormLabel htmlFor="transactionNumber">
                                    {t("Transaction_Number")}

                                </StyledFormLabel>
                                <StyledTextField
                                    fullWidth
                                    name={t("transactionNumber")}
                                    value={values.transactionNumber}
                                    onChange={handleInputChange}
                                    error={Boolean(errors.transactionNumber)}
                                    helperText={errors.transactionNumber}
                                />
                            </Grid>

                        )

                        }




                    </Grid>


                    <TableContainer
                        component={Paper}
                        sx={{ height: "auto", marginTop: "20px" }}
                    >
                        <Table stickyHeader aria-label="simple table">
                            <TableHead sx={{ textAlign: "center" }}>
                                <TableRow >
                                    <StyledTableCell sx={{ paddingLeft: "2px" }}>{t("ORDER_DATE")}</StyledTableCell>
                                    <StyledTableCell sx={{ paddingLeft: "3px" }} align="center">{t("ORDER_NUMBER")}</StyledTableCell>

                                    <StyledTableCell sx={{ paddingLeft: "60px" }} align="center">{t("PAYABLE_AMOUNT")}</StyledTableCell>
                                    <StyledTableCell sx={{ paddingLeft: "60px" }} align="center">{t("DUE_AMOUNT")}</StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >

                                <TableRow className="tbl"

                                    sx={{ border: 0, display: "flex", height: "40px", marginTop: "30px" }}

                                ><StyledTableRow sx={{ paddingLeft: "10%", textAlign: "center", fontSize: "14px" }}>{orderData.orderDate}
                                    </StyledTableRow>
                                    <StyledTableRow sx={{ paddingLeft: "90%", textAlign: "center", fontSize: "14px" }}> {orderData.orderNumber}</StyledTableRow>
                                    <StyledTableRow sx={{ paddingLeft: "70%", fontSize: "14px" }}> {orderData.payableAmount}</StyledTableRow>
                                    <StyledTableRow sx={{ paddingLeft: "70%", fontSize: "14px" }}> {orderData.pendingAmount}</StyledTableRow>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid item md={2.3} xs={12}>
                    </Grid>

                    <FlexBox px={2}
                        style={{
                            marginLeft: '60%',
                        }}
                    >
                        <Box display="flex">
                            <Box pr={6}>
                                <Typography sx={{ my: 2, }} style={{
                                    fontSize: "13px",
                                }}>{t("PAYMENT_AMOUNT")}</Typography>
                            </Box>
                            <div
                            >
                                <Typography sx={{ my: 2, marginLeft: "90px", fontSize: "13px" }}><b>
                                    <input type="text" value={values.paymentAmount} id="paymentAmount" /> </b></Typography>
                                <Typography sx={{ mb: 2, marginLeft: "105px", fontSize: "13px" }}><b></b></Typography>
                            </div>
                        </Box>
                    </FlexBox>
                    <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)", marginTop: "20px" }}></div>

                    <FlexBox px={2}
                        style={{
                            marginTop: "20px",
                            marginLeft: '60%',
                        }}
                    >
                        <Box display="flex">
                            <div
                                style={{ marginLeft: "141px" }}
                            >
                                <Typography sx={{ mb: 6, color: "red" }}><b> </b></Typography>
                            </div>
                        </Box>
                    </FlexBox>
                    <Box
                        id="button-box"
                    >
                        <Link to="/product/" className='link' id="linkcancel" >
                            <Button variant="outlined" size="large" id='b1'

                            >
                                <strong style={strong}>{t("Cancel")}</strong>
                            </Button> </Link>
                        <Button variant="contained" size="large" id="submitButton"
                            type='submit'

                        >
                            <strong >{button}</strong>
                        </Button>
                    </Box>
                </Form>
            </Card>

            <Notification notify={notify} setNotify={setNotify} />

        </>
    );
}
