import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Add } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Spin } from "antd";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputBase,
  Typography,
  IconButton,
  Stack,
  Pagination,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Switch,
  Modal,
  Tooltip,
  Grid,
} from "@mui/material";
import filterIcon from "../../../assets/filter.svg";
import { useForm, Form } from "../../../utils/useForms";
import { tableCellClasses } from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import {
  createRole,
  getAllRoles,
  updateRoleStatus,
} from "../../../state/action/roleAction";
import { IsDelete, IsRead, IsWrite } from "../../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
}));

const strong = {
  color: "black",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validate = (values) => {
  const errors = {};
};
const initialValues = {};

const RoleListPage = (props) => {
  const { t } = useTranslation();
  const [roleList, setRoleList] = useState([]);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [search1, setsearch1] = useState("");
  const [records, setRecords] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checked, setChecked] = useState(true);
  const [roleData, setRoleData] = useState({ index: "", value: "" });
  const [statusModal, setStatusModal] = useState(false);
  const [status, setStatus] = useState();
  const [roleName, setRoleName] = useState("");
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openSwitchModels, setOpenSwitchModels] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [spinStatus, setSpinStatus] = useState({ loading: true, error: false });

  const location = useLocation();
  const deletePermissions = IsDelete(["/roleListss"]);
  const editPermissions = IsWrite(["/roleListss"]);
  const readPermissions = IsRead(["/roleListss"]);

  const resetRoleName = () => {
    setRoleName("");
  };

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  //add permission
  const handleSwitchModels = () => setOpenSwitchModels(true);
  const handleCloseSwitchs = () => setOpenSwitchModels(false);

  const openStatusModal = () => {
    setStatusModal(true);
  };
  const closeStatusModal = () => {
    setStatusModal(false);
  };

  const resetRoleId = () => {
    setRoleId("");
  };
  const handleRoleId = (value) => {
    setRoleId(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const getroleList = async (e) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&limit=${rowsPerPage}&search=${search1}${string}`;

    const res = await getAllRoles(queryString);
    if (res.status === true) {
      setRoleList(res.data);
      setRecords(res.totalRole);
      setTotalPages(Math.ceil(res.totalRole / rowsPerPage));
      setFilter(false);
      setSpinStatus({ loading: true, error: false });
    }
  };

  useEffect(() => {
    getroleList();
  }, [page, filterData, search1]);

  const navigate = useNavigate();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };

  //status change

  const handleChangeStatus = async (id) => {
    const payload = {
      status: checked ? "approved" : "inactive",
      userId: id,
    };

    // const res = await changeBuyerStatus(payload);
    // if (res && res.status === true) {
    //   getBuyerApiCall(page);
    handleCloseSwitch();
    // }
  };

  //add role

  const addRoleApi = async () => {
    try {
      if (roleName === "") {
      } else {
        const body = { roleName: roleName };
        const response = await createRole(body);
        if (response && response.status) {
          resetRoleName();
          handleCloseSwitchs();
          //call role list api
          getroleList();
        } else {
        }
      }
    } catch (error) {
      resetRoleName();
    }
  };

  const onUpdateStatus = async () => {
    const { index, value } = roleData;
    const body = {
      status: value,
    };
    const response = await updateRoleStatus(index, body);
    if (response && response.status) {
      setRoleList((previousState) => {
        var newState = [...previousState];
        const arr_index = newState.findIndex((list) => list.id === index);
        if (arr_index !== -1) {
          newState[arr_index].status = value;
        }
        return newState;
      });
    } else {
    }
    handleCloseSwitchs();
  };

  return (
    <>
      <div>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              fontWeight: "600",
              paddingLeft: "10px",
            }}
          >
            {t("Role")}
          </Typography>

          <Box marginTop="20px" borderRadius="12px">
            <Button
              sx={{
                height: "34px",
                backgroundColor: "#F06237",
                color: "white",
                borderRadius: "10px",
                padding: "5px 25px",
                "&:hover": {
                  backgroundColor: "#cf5025",
                },
              }}
              startIcon={<Add />}
              color="primary"
              onClick={handleSwitchModels}
            >
              <span style={{ fontSize: "14px" }}>{t("Add Role")}</span>
            </Button>
          </Box>
        </Stack>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "30px",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Form>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{
                  height: "30px",
                }}
                placeholder={t("Search…")}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Search>
          </Form>
          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Filter")}
                </Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              {/* <Box sx={{ margin: "8px auto" }}> */}

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">
                  {t("RoleName")}
                </StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("roleName")}
                  fullWidth
                  id="roleName"
                  name="roleName"
                  value={values.roleName}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {},
                }}
                onClick={closeFilterDrawer}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </Form>
        </Drawer>
        <TableContainer
          component={Paper}
          sx={{ height: "auto" }}
          //add antd spinner
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead style={{ fontSize: "14px" }}>
              <TableRow>
                <StyledTableCell>{t("RoleName")}</StyledTableCell>
                <StyledTableCell>{t("Status")}</StyledTableCell>
                <StyledTableCell>{t("ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
            {/* {status && status.loading ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="7"
                >
                  <Spin size="large" />
                </TableCell>
              </TableRow>
            ) : ( */}
            <TableBody>
              {roleList.length == 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    {t("No_records_to_display")}
                  </TableCell>
                </TableRow>
              ) : (
                roleList.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        cursor: "pointer",
                      },
                    }}
                  >
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.roleName}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      <Switch
                        checked={row.status == 1 ? true : false}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          handleSwitchModel();
                          setStatus(
                            e.target.checked == true ? "Active" : "Inactive"
                          );
                          // setSwitchId(buyer.id);
                          setChecked(e.target.checked);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box size="middle">
                        <Tooltip
                          placement="bottom"
                          title="Click to add permission"
                        >
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/addpermission`, {
                                state: {
                                  id: row.id,
                                },
                              });
                            }}
                          >
                            {/* <RiEditLine /> */}
                            Add Permission
                          </Button>
                        </Tooltip>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            {/* )} */}
          </Table>
        </TableContainer>

        <Modal
          open={openSwitchModel}
          // onClose={handleClose}
          disableAutoFocus={true}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 450,
              bgcolor: "#FFFFFF",
              padding: "20px",
              borderRadius: "10px",
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "15px",
              lineHeight: "30px",
            }}
          >
            <p className="modal-title"> Update Status </p>
            <p style={{ fontSize: "16px" }}>
              Are you sure you want to Update Status ?
            </p>

            <br />
            <div className="form-button-container">
              <button
                className="form-action-button"
                onClick={handleCloseSwitch}
              >
                Cancel
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className="form-action-button-submit"
                // onClick={() => handleChangeStatus(switchId)}
              >
                Yes
              </button>
            </div>
          </Box>
        </Modal>

        <Modal
          open={openSwitchModels}
          onClose={handleCloseSwitchs}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ marginTop: "-7px" }}>
              <StyledFormLabel htmlFor="roleName">Role Name</StyledFormLabel>
              <StyledTextField
                fullWidth
                inputProps={{ maxLength: 10 }}
                name="roleName"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </Box>

            <Grid
              container
              gap={1}
              style={{
                marginTop: "1rem",
                justifyContent: "end",
                display: "flex",
              }}
            >
              <Grid
                item
                className="text-end"
                sx={{ justifyContent: "end", display: "flex" }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  className="buttonCancel"
                  onClose={handleCloseSwitchs}
                  style={{
                    borderRadius: "10px",
                    width: "73%",
                    height: "94%",
                  }}
                >
                  <strong style={strong}>{t("Cancel")}</strong>
                </Button>{" "}
              </Grid>
              <Grid item className="text-end">
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    backgroundColor: "#f06237",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    addRoleApi();
                  }}
                >
                  <strong className="purchaseOrderheadder-box-button2-strong">
                    {t("Save")}
                  </strong>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {" "}
          Showing {page * rowsPerPage > records
            ? records
            : page * rowsPerPage}{" "}
          of {records} Results
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default RoleListPage;
