import React, { useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import "./historylinedetails.css";
import LinkIcon from "@mui/icons-material/Link";
import DescriptionIcon from "@mui/icons-material/Description";
import { getLeadDetails } from "../../state/action/leadAction";
import { useEffect } from "react";
import moment from "moment";

const HistoryDetails = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { t } = useTranslation();
  const [historyline, sethistoryline] = useState([]);



  const GetHistoryList = async () => {
    const res = await getLeadDetails(state?.lead);
    if (res && res.status === true) {
      sethistoryline(res.data.history);
 
    }
  };
  useEffect(() => {
    GetHistoryList(state?.lead);
  }, [state?.lead]);

  return (
    <Box>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          // width: "160%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography> {t("history")}</Typography>
            </Grid>

            <Grid
              item
              sx={{ m: 1, display: "flex", justifyContent: "end" }}
              md={12}
              xs={12}
            >
              <Timeline position="alternate">
                <Typography sx={{ display: "flex", justifyContent: "center" }}>

                  {historyline && historyline[0] && moment(historyline[0].createdAt).format("MM-DD-YYYY")}
                </Typography>
                {historyline?.map((row) => {
                  return (
                    <>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "auto 0", padding: "40px 10px" }}
                          align="right"
                          variant="body2"
                          color="text.secondary"
                          className="MuiTimelineOppositeContent-root"
                        >
                          {moment(row.createdAt).format("LT")}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot>
                            <LinkIcon />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                          sx={{ py: "15px", px: 2 }}
                          className="MuiTimelineContent-root "
                        >
                          <Typography variant="h6" component="span">
                            {row.log}
                          </Typography>
                          <Typography>{row.createdBy}</Typography>
                          <Typography>
                            {moment(row.createdAt).format("MM-DD-YYYY LT")}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </>
                  );
                })}
              </Timeline>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default HistoryDetails;
