import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import { Link, useLocation } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../customerPage/style/customerlist.css";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  Drawer,
  Grid,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import { Delete } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

import filterIcon from "../../assets/filter.svg";
// import UserDeleteModal from "./customerDelete";
import axiosInstance from "../../utils/AxiosInstace";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useForm, Form } from "../../utils/useForms";
import { tableCellClasses } from "@mui/material/TableCell";
import { getAllCustomerList } from "../../state/action/customerAction";
import { useTranslation } from "react-i18next";
import { getLeadList } from "../../state/action/leadAction";
import UploadButton from "./UploadButton";
import { fromUnixTime } from "date-fns";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const leadsource = [
  {
    id: 1,
    sourceName: "facebook",
  },
  {
    id: 2,
    sourceName: "instagram",
  },
  {
    id: 3,
    sourceName: "website",
  },
  {
    id: 4,
    sourceName: "partner",
  },
];

const leadstatus = [
  {
    id: 1,
    leadStatuss: "hot",
  },
  {
    id: 2,
    leadStatuss: "cold",
  },
  {
    id: 3,
    leadStatuss: "natural",
  },
];

const validate = (values) => {
  const errors = {};
};
const initialValues = {};

const LeadList = (props) => {
  const { t } = useTranslation();

  const [allUsers, setAllUsers] = useState([]);
  const [leadList, setLeadList] = useState([]);
  const [deleteUser, setDeleteUser] = useState(false);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [user, setUser] = useState([]);
  const [search1, setsearch1] = useState("");
  const [records, setRecords] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  //lead List
  const getleadList = async (e) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&limit=${rowsPerPage}&search=${search1}${string}`;

    const res = await getLeadList(queryString);
    if (res.status === true) {
      setLeadList(res.data);
      setRecords(res.total);
      setTotalPages(Math.ceil(res.total / rowsPerPage));
      setFilter(false);
    }
  };

  useEffect(() => {
    getleadList();
  }, [page, search1, filterData]);

  const navigate = useNavigate();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };

  const LeadStatus = (status) => {
    const statusColors = {
      1: {
        key: "Hot",
        color: "black",
        backgroundColor: "white",
      },
      2: {
        key: "Cold",
        color: "black",
        backgroundColor: "white",
      },
      3: {
        key: "Natural",
        color: "black",
        backgroundColor: "white",
      },
    };

    // //lead Type
    // const getLeadTypeList = async (e) => {
    //   const res = await leadTypeDropdown();
    //   if (res.status === true) {
    //     setLeadType(res.data);
    //   }
    // };

    // useEffect(() => {
    //   getLeadTypeList();
    // }, []);

    return (
      <StyledChip
        style={{
          width: "85%",
          height: "23px",
          fontSize: "12px",
        }}
        sx={statusColors[status]}
        label={statusColors[status]?.key}
      />
    );
  };

  return (
    <>
      <div>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              fontWeight: "600",
              paddingLeft: "10px",
            }}
          >
            {t("Lead")}
          </Typography>
          <Box
            marginTop="20px"
            borderRadius="12px"
            sx={{ marginLeft: "536px" }}
          >
            <UploadButton getleadList={getleadList} />
          </Box>
          {editPermissions && (
            <Box marginTop="20px" borderRadius="12px">
              <Button
                sx={{
                  height: "34px",
                  backgroundColor: "#F06237",
                  color: "white",
                  borderRadius: "10px",
                  padding: "5px 25px",
                  "&:hover": {
                    backgroundColor: "#cf5025",
                  },
                }}
                startIcon={<Add />}
                color="primary"
                onClick={() => {
                  navigate("/addLead");
                }}
              >
                <span style={{ fontSize: "14px" }}>{t("Add_Lead")}</span>
              </Button>
            </Box>
          )}
        </Stack>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "30px",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Form>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{
                  height: "30px",
                }}
                placeholder={t("Search…")}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => {
                  setsearch1(e.target.value);
                  setPage(1);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Search>
          </Form>
          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Filter")}
                </Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              {/* <Box sx={{ margin: "8px auto" }}> */}

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">
                  {t("Company")}
                </StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Company")}
                  fullWidth
                  id="company"
                  name="company"
                  value={values.company}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="phone">
                  {t("Phone_Number")}
                </StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Phone_Number")}
                  fullWidth
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="type">
                  {t("Lead_type")}
                </StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Lead_type")}
                  fullWidth
                  id="type"
                  name="type"
                  value={values.type}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel>{t("Lead_status")}</StyledFormLabel>

                <select
                  name="leadStatus"
                  id="leadStatus"
                  value={values.leadStatus}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    height: "43px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "8px",
                    marginTop: "8px",
                    fontSize: "14px",
                    backgroundColor: "white",
                  }}
                >
                  <option value="">Select Lead Status</option>
                  {leadstatus.map((item) => (
                    <option value={item.id}>{item.leadStatuss}</option>
                  ))}
                </select>
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel>{t("Lead_source")}</StyledFormLabel>

                <select
                  name="leadSource"
                  placeholder={t("Lead_source")}
                  id="leadSource"
                  value={values.leadSource}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    height: "43px",
                    border: "1px solid #D9D9D9",
                    borderRadius: "8px",
                    marginTop: "8px",
                    fontSize: "14px",
                    backgroundColor: "white",
                  }}
                >
                  <option value="">Select Lead Status</option>
                  {leadsource.map((item) => (
                    <option value={item.sourceName}>{item.sourceName}</option>
                  ))}
                </select>
              </Box>
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {},
                }}
                onClick={closeFilterDrawer}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </Form>
        </Drawer>
        <TableContainer component={Paper} sx={{ height: "auto" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead style={{ fontSize: "14px" }}>
              <TableRow>
                <StyledTableCell>{t("Lead_Name")}</StyledTableCell>
                <StyledTableCell>{t("Company")}</StyledTableCell>
                <StyledTableCell> {t("Lead_status")}</StyledTableCell>
                <StyledTableCell>{t("Email")}</StyledTableCell>
                <StyledTableCell>{t("Phone_Number")}</StyledTableCell>
                <StyledTableCell> {t("Lead_type")}</StyledTableCell>
                <StyledTableCell>{t("Lead_source")}</StyledTableCell>
                <StyledTableCell>{t("Lead_owner")}</StyledTableCell>
                <StyledTableCell>{t("ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leadList.length == 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    {t("No_records_to_display")}
                  </TableCell>
                </TableRow>
              ) : (
                leadList.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      navigate(`/leaddetails`, {
                        state: {
                          lead: row.id,
                        },
                      });
                    }}
                  >
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.firstName + "   " + row.lastName}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.company}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {LeadStatus(row.leadStatus)}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.phone}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.type}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.leadSource}
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.leadOwnerFirstName + "   " + row.leadOwnerLastName}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Box>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <div>
                              <MoreVertIcon {...bindTrigger(popupState)} />
                              <Menu
                                {...bindMenu(popupState)}
                                PaperProps={{
                                  sx: {
                                    marginTop: "13px",
                                    minWidth: "10em",
                                  },
                                }}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                              >
                                {readPermissions && (
                                  <MenuItem
                                    onClick={() => {
                                      navigate(`/leaddetails`, {
                                        state: {
                                          lead: row.id,
                                        },
                                      });
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={eye} alt="View Details/" />
                                    </ListItemIcon>
                                    {t("View_Lead")}
                                  </MenuItem>
                                )}

                                {editPermissions && (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close();
                                      setUser(row);
                                      navigate(`/addLead`, {
                                        state: {
                                          lead: row.id,
                                        },
                                      });
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={edit} alt="Edit/" />
                                    </ListItemIcon>
                                    {t("Edit")}
                                  </MenuItem>
                                )}
                                {deletePermissions && (
                                  <MenuItem>
                                    <ListItemIcon>
                                      <DeleteIcon />
                                    </ListItemIcon>
                                    {t("Delete")}
                                  </MenuItem>
                                )}
                              </Menu>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {" "}
          Showing {page * rowsPerPage > records
            ? records
            : page * rowsPerPage}{" "}
          of {records} Results
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default LeadList;
