import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Collapse,
} from "@mui/material";
import { ExpandMore, ExpandLess, Person } from "@mui/icons-material";
import SummarizeIcon from "@mui/icons-material/SummarizeOutlined";
import logo from "../../../assets/logo.png";
import customer from "../../../assets/Users.svg";
import supplier from "../../../assets/Supplier.svg";
import product from "../../../assets/product.svg";
import Dashboard from "../../../assets/dashboard.svg";
import inventory from "../../../assets/Stock.svg";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import orders from "../../../assets/order.svg";
import payment from "../../../assets/order.svg";
import setting from "../../../assets/order.svg";
import locationIcon from "../../../assets/maplocation.svg";
import { useTranslation } from "react-i18next";
import { IsRead } from "../../../utils/handlePermission";

const SelectedStyles = {
  ".MuiListItemButton-root": {
    backgroundColor: "#E64A08",
    fonSize: "12px",
    ".MuiListItemIcon-root img": {
      filter: " brightness(0) invert(1)",
    },

    ".MuiSvgIcon-root": {
      color: "white",
    },
    ".MuiListItemText-root": {
      color: "white",
    },
  },
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.substr(1);
  const { t } = useTranslation();

  //  if unknown route is found then redirect it to dashboard page
  const [active, setActive] = useState(pathname);

  //  state for sub menus

  const [salesOrderMenu, setSalesOrderMenu] = useState(false);
  const [purchaseOrderMenu, setPurchaseOrderMenu] = useState(false);
  const [categoryMenu, setCategoryMenu] = useState(false);
  const [settingMenu, setSettingMenu] = useState(false);

  const toggleSalesOrderMenu = () => {
    setSalesOrderMenu(!salesOrderMenu);
  };
  const closeSalesOrderMenu = () => {
    setSalesOrderMenu(false);
  };
  const togglePurchaseOrderMenu = () => {
    setPurchaseOrderMenu(!purchaseOrderMenu);
  };
  const closePurchaseOrderMenu = () => {
    setPurchaseOrderMenu(false);
  };

  const toggleCategoryMenu = () => {
    setCategoryMenu(!categoryMenu);
  };
  const closeCategoryMenu = () => {
    setCategoryMenu(false);
  };
  const toggleSettingMenu = () => {
    setSettingMenu(!settingMenu);
  };
  const closeSettingMenu = () => {
    setSettingMenu(false);
  };
  const menus = [
    { menu: "Dashboard", subMenu: [] },
    { menu: "customer", subMenu: [] },
    { menu: "supplier", subMenu: [] },

    {
      menu: "Inventory",
      subMenu: ["product", "category", "Stock"],
      toggle: toggleCategoryMenu,
    },

    {
      menu: "Sales",
      subMenu: ["order", "invoice", "payment"],
      toggle: toggleSalesOrderMenu,
    },
    {
      menu: "Purchases",
      subMenu: ["order", "PurchaseOrder/invoiceList", "Payments"],
      toggle: togglePurchaseOrderMenu,
    },

    { menu: "Reports", subMenu: [] },

    {
      menu: "Settings",
      subMenu: ["users", "role", "tax", "setting", "warehouse"],
      toggle: toggleSettingMenu,
    },
  ];

  //  after refreshing the page select the respective tab
  const getCurrentTab = () => {
    if (pathname === "") {
      navigate("/dashboard");
    }
    var flag = false;
    //  matching at menu level
    for (let index = 0; index < menus.length; index++) {
      const element = menus[index];
      if (element.menu === pathname) {
        setActive(pathname);
        flag = true;
        break;
      }
    }
    if (!flag) {
      //  matching at sub-menus level
      for (let index = 0; index < menus.length; index++) {
        const element = menus[index];
        if (element.subMenu.includes(pathname)) {
          element.toggle();
          setActive(pathname);
          flag = true;
          break;
        }
      }
    }
    //  found unknown route then redirect to dashboard page
    // if (!flag) {
    //   navigate("/dashboard");
    // }
  };

  useEffect(() => {
    getCurrentTab();
  }, []);

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  //  we have to close all sub menus when we click on other menu items
  const closeAllMenus = (key) => {
    const subMenusList = [
      { key: "salesOrderMenu", close: closeSalesOrderMenu },
      { key: "purchaseOrderMenu", close: closePurchaseOrderMenu },
      { key: "categoryMenu", close: closeCategoryMenu },
      { key: "settingMenu", close: closeSettingMenu },
    ];

    subMenusList.forEach((value) => {
      if (key === value.key) {
        //  if matches we dont have to do anything
      } else {
        //  if not match then close all other open sub menus
        value.close();
      }
    });
  };

  const MenusList = [
    {
      name: "Dashboard",
      img: Dashboard,
      path: "dashboard",
      visible: IsRead(["/", "/dashboard"]),

    },
    {
      name: "Lead",
      img: Dashboard,
      path: "lead",
      visible: IsRead(["/lead"]),

    },
    {
      name: "Meetings",
      img: Dashboard,
      path: "meeting",
      visible: IsRead(["/meeting"])
    },
    {
      name: "Contacts",
      img: Dashboard,
      path: "contact",
      visible: IsRead(["/contact"]),

    },
    {
      name: "Bulk Email Marketing",
      img: Dashboard,
      path: "campaginList",
      visible: IsRead(["/campagin"]),

    },

    {
      name: "Accounts",
      img: Dashboard,
      path: "account",
      visible: IsRead(["/account"]),


    },
    {
      name: "Customers",
      img: customer,
      path: "customer",
      visible: IsRead(["/customer"]),
    },
    {
      name: "Suppliers",
      img: supplier,
      path: "supplier",
      visible: IsRead(["/supplier"]),
    },
    {
      name: "Inventory",
      img: inventory,
      isSubMenu: true,
      visible: IsRead(["/product", "/category", "/stock"]),
      subMenus: [
        {
          name: "Products",
          img: product,
          path: "product",
          visible: IsRead(["/product"]),
        },
        {
          name: "Categories",
          img: Dashboard,
          path: "category",
          visible: IsRead(["/category"]),
        },
        {
          name: "Stock",
          img: Dashboard,
          path: "stock",
          visible: IsRead(["/stock"]),
        },
      ],
      key: "categoryMenu",
      state: categoryMenu,
      toggle: toggleCategoryMenu,
      alternatePath: ["product", "category", "stock"],
    },
    {
      name: "Sales",
      img: orders,
      isSubMenu: true,
      visible: IsRead(["/selesOrder", "/invoice", "/selesOrderPayment", "/credit"]),
      subMenus: [
        {
          name: "Sales_Orders",
          img: Dashboard,
          path: "order",
          visible: IsRead(["/selesOrder"]),
        },
        {
          name: "Invoices",
          icon: DescriptionOutlinedIcon,
          path: "invoice/invoicelist",
          visible: IsRead(["/invoice"]),
        },
        {
          name: "Payments_Received",
          img: payment,
          path: "payment",
          visible: IsRead(["/selesOrderPayment"]),
        },
        {
          name: "Credit_Note",

          img: payment,
          path: "credit",
          visible: IsRead(["/credit"]),
        },
      ],
      key: "salesOrderMenu",
      state: salesOrderMenu,
      toggle: toggleSalesOrderMenu,
      alternatePath: ["order", "invoice/invoicelist", "payment", "credit"],
    },
    {
      name: "Purchase",
      img: orders,
      isSubMenu: true,
      visible: IsRead(["/purchaseOrder", "/bill", "/purchaseOrderPayment"]),
      subMenus: [
        {
          name: "Purchase_Orders",
          img: Dashboard,
          path: "purchaseorder",
          visible: IsRead(["/purchaseOrder"]),
        },
        {
          name: "Bills",
          icon: DescriptionOutlinedIcon,
          path: "PurchaseOrder/invoiceList",
          visible: IsRead(["/bill"]),
        },
        {
          name: "Payments_Made",

          icon: DescriptionOutlinedIcon,
          path: "PurchaseOrder/payment",
          visible: IsRead(["/purchaseOrderPayment"]),
        },
      ],
      key: "purchaseOrderMenu",
      state: purchaseOrderMenu,
      toggle: togglePurchaseOrderMenu,
      alternatePath: [
        "purchaseorder",
        "PurchaseOrder/invoiceList",
        "PurchaseOrder/payment",
      ],
    },

    {
      name: "Reports",
      icon: SummarizeIcon,
      path: "reports",
      visible: IsRead(["/report"]),
    },

    {
      name: "Settings",
      img: setting,
      isSubMenu: true,
      visible: IsRead(["/user", "/role", "/tax", "/setting", "/warehouse"]),
      subMenus: [
        {
          name: "User Management",
          img: Dashboard,
          path: "users",
          visible: IsRead(["/user"]),
        },
        {
          name: "Security Control",
          img: Dashboard,
          path: "roleListss",
          visible: IsRead(["/role"]),
        },
        {
          name: "Taxes",
          img: Dashboard,
          path: "tax",
          visible: IsRead(["/tax"]),
        },
        {
          name: "Users",
          img: setting,
          path: "setting",
          visible: IsRead(["/setting"]),
        },
        {
          name: "Warehouses",
          img: setting,
          path: "warehouse",
          visible: IsRead(["/warehouse"]),
        },
      ],
      key: "settingMenu",
      state: settingMenu,
      toggle: toggleSettingMenu,
      alternatePath: ["roleListss", "users", "tax", "setting", "warehouse"],
    },
  ];

  return (
    <>
      <Box
        sx={{ textAlign: "center", marginTop: "25px", marginBottom: "20px" }}
      >
        <img
          src={logo}
          alt="LOGO"
          style={{
            width: "140px",
            height: "64px",
          }}
        />
      </Box>
      <Box className="PE_sidebar" sx={{ height: "80%", overflowY: "auto" }}>
        <List
          sx={{
            ".MuiListItemButton-root": {
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#E64A08",
                ".MuiListItemIcon-root img": {
                  filter: " brightness(0) invert(1)",
                },
                ".MuiSvgIcon-root": {
                  color: "white",
                },
                ".MuiListItemText-root": {
                  color: "white",
                },
              },
            },
          }}
        >
          {MenusList.map((value, i) => {
            const flag = value.isSubMenu;
            if (value.visible) {
              // console.log("value",)
              //  if it is not submenu then it is only menu no sub menus
              if (!flag) {
                return (
                  <ListItem
                    key={i + 1}
                    sx={
                      active === value.path ||
                        value?.alternatePath?.includes(active)
                        ? SelectedStyles
                        : {}
                    }
                    onClick={() => {
                      navigate("/" + value.path);
                      setActive(value.path);
                      closeAllMenus();
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        {value.img ? (
                          <img src={value.img} alt={t(value.name)} />
                        ) : (
                          <value.icon />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={t(value.name)} />
                    </ListItemButton>
                  </ListItem>
                );
              }
            }
            //  it means it is a submenus
            if (value.visible) {
              if (flag) {
                return (
                  <>
                    <ListItem
                      key={i}
                      onClick={() => {
                        value.toggle();
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          {value.img ? (
                            <img src={value.img} alt={t(value.name)} />
                          ) : (
                            <value.icon />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={t(value.name)} />
                        {value.state ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </ListItem>
                    {/*  our submenus */}
                    <Collapse in={value.state} timeout="auto" unmountOnExit>
                      <List component="div" sx={{ margin: "0px 5px 0px 20px" }}>
                        {/*  our submenus list */}
                        {value.subMenus.map((subMenuButton, i) => {
                          if (subMenuButton.visible)
                            return (
                              <ListItem
                                key={i}
                                sx={
                                  active === subMenuButton.path
                                    ? SelectedStyles
                                    : {}
                                }
                                onClick={() => {
                                  navigate("/" + subMenuButton.path);
                                  setActive(subMenuButton.path);
                                  //  close all the sub menus except this sub menu that's why passed
                                  //  a key to recognize that
                                  closeAllMenus(value.key);
                                }}
                              >
                                <ListItemButton>
                                  <ListItemIcon>
                                    {subMenuButton.img ? (
                                      <img
                                        src={subMenuButton.img}
                                        alt={t(subMenuButton.name)}
                                      />
                                    ) : (
                                      <subMenuButton.icon />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText primary={t(subMenuButton.name)} />
                                </ListItemButton>
                              </ListItem>
                            );
                        })}
                      </List>
                    </Collapse>
                  </>
                );
              }
            }
          })}
        </List>
      </Box>
    </>
  );
};

export default Sidebar;
