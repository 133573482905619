import auth from "./authReducer";
import { combineReducers } from "redux";
import suppliers from "./supplierReducer";
import customers from "./customerReducer";



const reducers = combineReducers({
    auth,
    suppliers,
    customers
});

export default reducers;
