import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
} from "@mui/material";
import {
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Pagination,
} from "@mui/material";
import filterIcon from "../../assets/filter.svg";
import axiosInstance from "../../utils/AxiosInstace";
import { useForm, Form } from "../../utils/useForms";
import { tableCellClasses } from "@mui/material/TableCell";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
}));

const validate = (values) => {
  const errors = {};
};
const initialValues = {
  search: "",
  name: "",
  email: "",
  phone_number: "",
  buyer_type: false,
  supplier_type: false,
  approved_status: false,
  pending_status: false,
  rejected_status: false,
};

const Credit = (props) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [allCredit, setAllCredit] = useState([]);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [search1, setsearch1] = useState("");
  const [records, setRecords] = useState(0);
  const [totalorder, setTotalorder] = useState(0);

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  useEffect(() => {
    getAllCredit();
  }, [search1]);

  const { t } = useTranslation();

  const handleChangePage = (event, value) => {
    setPage(value);
    getAllCredit();
  };

  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
    getAllCredit();
  };
  const getAllCredit = async () => {
    await axiosInstance
      .get(`api/v1/creditNoteList?limit=10&page=${page}&search=${search1}`)
      .then((res) => {
        setAllCredit(res.data.data);
      })
      .catch((e) => {
        setAllCredit([]);
        console.log("error found", e);
      });
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };

  return (
    <>
      <>
        <div>
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: "bold",
                fontWeight: "600",
                paddingLeft: "10px",
              }}
            >
              {t("Credit")}
            </Typography>
            {editPermissions && (
              <Link
                to="/Credit/creditAdd"
                style={{
                  color: "white",
                  textDecoration: "none",
                }}
              >
                <Button
                  sx={{
                    height: "34px",
                    backgroundColor: "#F06237",
                    color: "white",
                    borderRadius: "10px",
                    padding: "5px 25px",
                    "&:hover": {
                      backgroundColor: "#cf5025",
                    },
                  }}
                  startIcon={<Add />}
                  color="primary"
                >
                  <span style={{ fontSize: "14px" }}>{t("Add_Credit")}</span>
                </Button>
              </Link>
            )}
          </Stack>
        </div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            color: "black",
            marginTop: "30px",
          }}
        >
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ padding: "20px 0px" }}
          >
            <Form>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{
                    height: "30px",
                  }}
                  placeholder={t("Search…")}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => onSearch(e.target.value)}
                />
              </Search>
            </Form>
            <Button
              sx={{
                border: "1px solid rgba(15, 15, 15, 0.15)",
                borderRadius: "10px",
                mr: 2,
                padding: "5px 20px",
                color: "black",
                fontWeight: "400",
                fontSize: "12px",
                height: "30px",
              }}
              endIcon={
                <IconButton sx={{ ml: 3 }}>
                  <img src={filterIcon} alt="Filter Options" />
                </IconButton>
              }
              onClick={openFilterDrawer}
            >
              {t("Filter")}
            </Button>
          </Stack>
          <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
            <form>
              <Box
                sx={{
                  width: 220,
                  padding: "10px 20px",
                  color: "black",
                }}
                // onClick={toggleDrawer(anchor, false)}
                // onKeyDown={toggleDrawer(anchor, false)}
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ color: "black" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("Filter")}
                  </Typography>
                  <IconButton
                    sx={{ color: "black" }}
                    onClick={closeFilterDrawer}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>

                {/* <Box sx={{ margin: "8px auto" }}> */}
                <StyledFormLabel htmlFor="name"> {t("Name")}</StyledFormLabel>
                <StyledTextField
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <SearchIcon />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  placeholder={t("Search_Name")}
                  fullWidth
                  id="name"
                  name={t("name")}
                  // value={formik.values.name}
                  // onChange={formik.handleChange}
                />

                <Divider sx={{ mt: 2 }} />
                <Box sx={{ margin: "8px auto" }}>
                  <StyledFormLabel htmlFor="email">
                    {t("Email")}
                  </StyledFormLabel>
                  <StyledTextField
                    type="email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("Enter_email")}
                    fullWidth
                    id="email"
                    name={t("email")}
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                  />
                </Box>
                <Divider sx={{ mt: 2 }} />

                <Box sx={{ margin: "8px auto" }}>
                  <StyledFormLabel htmlFor="phone_number">
                    {t("Mobile_Number")}{" "}
                  </StyledFormLabel>
                  <StyledTextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("Mobile_Number")}
                    fullWidth
                    id="phone_number"
                    name={t("phone_number")}
                    // value={formik.values.phone_number}
                    // onChange={formik.handleChange}
                  />
                </Box>
                <Divider sx={{ mt: 2 }} />
              </Box>
              <Stack
                sx={{ margin: "100px 20px 10px 20px" }}
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid #0F0F0F80",
                    borderRadius: "10px",
                    padding: "10px 25px",
                    "&:hover": {
                      // backgroundColor: "#60579A",
                    },
                  }}
                  onClick={closeFilterDrawer}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#F06237",
                    borderRadius: "10px",
                    color: "white",
                    padding: "10px 25px",
                    "&:hover": {
                      backgroundColor: "#F06237",
                    },
                  }}
                  type="submit"
                >
                  {t("Filter")}
                </Button>
              </Stack>
            </form>
          </Drawer>
          <TableContainer component={Paper} sx={{ height: "auto" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("CREDIT_NOTE_NUMBER")}</StyledTableCell>
                  <StyledTableCell>{t("ORDER_NUMBER")}</StyledTableCell>
                  <StyledTableCell> {t("DATE")}</StyledTableCell>
                  <StyledTableCell>{t("AMOUNT")} </StyledTableCell>
                  <StyledTableCell> {t("REASON")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allCredit.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      <Typography sx={{ ml: 2, fontSize: "14px" }}>
                        {row.creditNoteNumber}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>{row.orderNumber}</StyledTableCell>
                    <StyledTableCell>{row.creditNoteDate}</StyledTableCell>
                    <StyledTableCell>{row.creditNoteAmount}</StyledTableCell>
                    <StyledTableCell>{row.creditNoteReason}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Stack
          sx={{ mt: 4, pb: 2 }}
          flexDirection="row"
          justifyContent="space-between"
        >
          <div>
            {t("Showing")} {records} {t("of")} {totalorder} {t("Results")}
          </div>
          <Pagination
            count={totalPage}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </>
    </>
  );
};

export default Credit;
