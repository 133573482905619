import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "../productPage/productList";
import { useState } from "react";
import "../customerPage/style/addCustomer.css";
import { Link, useNavigate } from "react-router-dom";
import Search from "@mui/icons-material/Search";
import { Multiselect } from "multiselect-react-dropdown";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import {
  getLeadContactList,
} from "../../state/action/campaginAction";

import {
  TextField,
  FormLabel,
  Grid,
  Button,
  Autocomplete,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getCustomerDetails,
  getCountry,
  getStateList,
  addCustomer,
  updateCustomer,
} from "../../state/action/customerAction";
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));



const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  customerName: "",
  customerEmail: "",
  mobileNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  vatNumber: "",
  creditLimit: "",
  creditDays: "",
  advancePayment: "",
  accountName: "",
  accountNumber: "",
  bankName: "",
  createdBy: "",
  ifscCode: "",
  swiftCode: "",
};

const leadType = [
  {
    id: 1,
    name: "xyz",
  },
];

const participants = [
  {
    id: 1,
    name: "Leads",
  },
  {
    id: 2,
    name: "Contacts",
  },
];

const getSelectedItems = (data) => {
  // if (data) {
  //   const rendomNum = Math.floor(100000 + Math.random() * 900000);
  //   const selectedItem = {
  //     name: data?.email,
  //   };
  //   itemss.push(selectedItem);
  //   const neItems = [...itemss];
  //   setItemss(neItems);
  //   // setProductId(data?.id);
  // }
};

const Participant = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { auth } = store.getState();
  const userid = auth?.user?.id;
  const [countrylist, setCountrylist] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [leadContactlist, setLeadContactList] = useState([]);

  const [edit, setIsEdit] = useState(false);
  const [id, setId] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [value, setValue] = useState(dayjs("2022-04-17"));
  const [tovalue, setToValue] = useState(dayjs("2022-04-17"));

  const [age, setAge] = React.useState("");

  const handleChangesss = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    if (state) {
      getCustomerDetailss(state.user.id);
      setIsEdit(true);
      setId(state.user.id);
    }
  }, [state]);

  const getCustomerDetailss = async (id) => {
    const response = await getCustomerDetails(id);
    if (response.status === true) {
      getStateListData(response.data.address.country);
      setValues({
        customerName: response.data.customerName,
        customerEmail: response.data.customerEmail,
        mobileNumber: response.data.mobileNumber,
        address: response.data.address.address,
        city: response.data.address.city,
        state: Number(response.data.address.state),
        country: Number(response.data.address.country),
        zipCode: response.data.address.zipCode,
        vatNumber: response.data.vatNumber,
        creditLimit: response.data.creditLimit,
        creditDays: response.data.creditDays,
        advancePayment: response.data.advancePayment,
        accountName: response.data.accountName,
        accountNumber: response.data.accountNumber,
        bankName: response.data.bankName,
        createdBy: response.data.createdBy,
        ifscCode: response.data.ifscCode,
        swiftCode: response.data.swiftCode,
      });
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("customerName" in fieldValues) {
      if (fieldValues.customerName !== "") {
        if (/^[a-zA-Z0-9\s]{1,50}$/.test(fieldValues.customerName)) {
          temp.customerName = "";
        } else {
          temp.customerName = "number or characters and 50 digits allow only";
        }
      } else {
        temp.customerName = "This field is required.";
      }
    }
    if ("vatNumber" in fieldValues) {
      if (fieldValues.vatNumber !== "") {
        if (/^[a-zA-Z0-9]{1,14}$/.test(fieldValues.vatNumber)) {
          temp.vatNumber = "";
        } else {
          temp.vatNumber = " alphanumeric & maximum 14 digits allow";
        }
      } else {
        temp.vatNumber = "This field is required.";
      }
    }
    if ("swiftCode" in fieldValues) {
      if (fieldValues.swiftCode !== "") {
        temp.swiftCode = "";
      } else {
        temp.swiftCode = "This field is required.";
      }
    }

    if ("mobileNumber" in fieldValues) {
      if (fieldValues.mobileNumber !== "") {
        if (/^\d{10,15}$/.test(fieldValues.mobileNumber)) {
          temp.mobileNumber = "";
        } else {
          temp.mobileNumber = "10 to 15 digits Number allow";
        }
      } else {
        temp.mobileNumber = "This field is required.";
      }
    }
    if ("zipCode" in fieldValues) {
      if (fieldValues.zipCode !== "") {
        if (/^[0-9]{4,10}$/.test(fieldValues.zipCode)) {
          temp.zipCode = "";
        } else {
          temp.zipCode = "4  to 10 digits Only Number allow";
        }
      } else {
        temp.zipCode = "This field is required.";
      }
    }

    if ("accountNumber" in fieldValues) {
      if (fieldValues.accountNumber !== "") {
        temp.accountNumber = "";
      } else {
        temp.accountNumber = "This field is required.";
      }
    }

    if ("ifscCode" in fieldValues) {
      if (fieldValues.ifscCode !== "") {
        temp.ifscCode = "";
      } else {
        temp.ifscCode = "This field is required.";
      }
    }

    if ("customerEmail" in fieldValues) {
      if (fieldValues.customerEmail !== "") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            fieldValues.customerEmail
          )
        ) {
          temp.customerEmail = "";
        } else {
          temp.customerEmail = "Enter valid Email";
        }
      } else {
        temp.customerEmail = "This field is required.";
      }
    }

    if ("accountName" in fieldValues) {
      if (fieldValues.accountName !== "") {
        temp.accountName = "";
      } else {
        temp.accountName = "This field is required.";
      }
    }
    if ("bankName" in fieldValues) {
      if (fieldValues.bankName !== "") {
        temp.bankName = "";
      } else {
        temp.bankName = "This field is required.";
      }
    }

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : "This field is required.";
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "This field is required.";
    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    console.log("temp", temp);

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const getCountryList = async (e) => {
    const res = await getCountry();
    if (res.status === true) {
      setCountrylist(res.data);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      customerName: values.customerName,
      customerEmail: values.customerEmail,
      mobileNumber: values.mobileNumber,
      address: {
        addressType: 1,
        address: values.address,
        state: values.state,
        city: values.city,
        zipCode: Number(values.zipCode),
        country: values.country,
      },
      creditLimit: Number(values.creditLimit),
      creditDays: Number(values.creditDays),
      advancePayment: Number(values.advancePayment),
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      bankName: values.bankName,
      swiftCode: values.swiftCode,
      ifscCode: values.ifscCode,
      vatNumber: values.vatNumber,
      createdBy: userid,
    };

    try {
      if (validate()) {
        if (edit) {
          const res = await updateCustomer(payload, id);
          if (res && res.status === true) {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "success",
            });
            setTimeout(() => {
              navigate("/customer");
            }, 1000);
          } else {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "warning",
            });
          }
        } else {
          const res = await addCustomer(payload);
          if (res && res.status === true) {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "success",
            });
            setTimeout(() => {
              navigate("/customer");
            }, 1000);
          } else {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "warning",
            });
          }
        }
      }
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "Something went wrong",
        type: "error",
      });
    }
  };

  const getStateListData = async (e) => {
    setValues({
      ...values,
      country: e,
    });
    const res = await getStateList(e);
    if (res.status === true) {
      setStatelist(res.data);
    }
  };


  const getData = async (e) => {
    setValues({
      ...values,
      id: "",
      type: e.target.value,
    });


    const selectedTypes = {
      type: e.target.value
    };

    const res = await getLeadContactList(selectedTypes);
    if (res.status === true) {
      setLeadContactList(res.data);
    }
  }


  console.log("leadContactList", values);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box className="b1">
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Typography>{t("Add Participants")}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadfirstName">
                {t("meeting_Contact")}
              </StyledFormLabel>
              {/* <StyledTextField fullWidth name={t("meetingtitle")} select>
                {participants.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </StyledTextField> */}
              <select
                className="input"
                id="type"
                name="type"
                style={
                  {
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    background: "#fff",
                    fontSize: "14px",
                  }
                }
                value={values.type}
                onChange={(e) => {
                  getData(e);

                }}
              >
                <option value="">Select </option>
                {participants.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadfirstName">Search</StyledFormLabel>
              <Multiselect
                options={leadContactlist}
                displayValue="name"
                closeOnSelect={true}
                showArrow={true}
                selectedValues={values.name}
                onSelect={(e) => {
                  setValues({
                    ...values,
                    id: e.map((item) => item.id),
                  });



                }}
                onRemove={(e) => {
                  setValues({
                    ...values,
                    id: e.map((item) => item.id),
                  });
                }}
                placeholder="Select Participents"
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="email">
                {t("Invite by Email Address ")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("email")}
                inputProps={{ maxLength: 50 }}
                value={values.email}
                onChange={handleInputChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>
          </Grid>

          <Box className="customerBox">
            <div>
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  navigate("/addmeeting", {
                    state: {
                      id: values?.id,
                      type: values?.type,
                    },
                  }
                  );
                }}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Done")}
                </strong>
              </Button>
            </div>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default Participant;
