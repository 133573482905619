import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";
import Backdrop from "../Backdrop/loader";

import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  setRef,
} from "@mui/material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Switch,
} from "@mui/material";
import {
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
// icons for user menu
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import invoice from "../../assets/Icon.svg";
import { GetSuppliers } from "../../state/action/supplierAction";
import { useSelector, useDispatch } from "react-redux";
import filterIcon from "../../assets/filter.svg";
import productViewModel from "./orderUpdate";
import productDeleteModel from "./orderDelete";
import { useFormik } from "formik";
import axiosInstance from "../../utils/AxiosInstace";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import user1 from "../../assets/user1.png";
import user2 from "../../assets/user2.png";
import { useNavigate, useLocation } from "react-router-dom";
import { tableCellClasses } from "@mui/material/TableCell";
import { useForm, Form } from "../../utils/useForms";
import { useTranslation } from "react-i18next";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  // height: "30px",
  // ".MuiOutlinedInput-root": {
  //     borderRadius: "10px",
  // },
}));
const StyledChip = styled(Chip)(() => ({
  // borderRadius: "10px",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const validate = (values) => {
  const errors = {};
};
const initialValues = {
  tAmount: "",
  fAmount: "",
  supplierName: "",
  orderNumber: "",
  orderDate: "",
  paymentStatus: "",
};

const UsersList = (props) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const [allUsers, setAllUsers] = useState([]);
  const [userView, setUserView] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [filter, setFilter] = useState(false);
  const [search1, setsearch1] = useState([]);
  const [filter1, setFilter1] = useState([]);
  const [AllPurchaseOrder, setAllPurchaseOrder] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [user, setUser] = useState([]);
  const [records, setRecords] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [filterData, setFilterData] = useState(true);

  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  useEffect(() => {
    setOpenBreakdrop(true);
    getAllProduct();
  }, [filterData, page, search1]);
  let id = location;

  const handleChangePage = (event, value) => {
    setPage(value);
    getAllProduct();
  };
  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
    getAllProduct();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    getAllProduct();
  };

  const getAllProduct = async () => {
    console.log("search1", values);

    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      if (i == 0) {
        string += `&${key}=${value}`;
      } else {
        string += `&${key}=${value}`;
      }
      i++;
    }
    await axiosInstance
      .get(
        `api/v1/purchaseorderlist?limit=10&page=${page}${string}&search=${search1}`
      )
      .then((res) => {
        console.log("sss", res.data);
        setAllUsers(res.data.data);
        setRecords(res.data.data.length);
        setTotalorder(res.data.totalorder);
        let pagecount = Math.ceil(res.data.totalorder / 10);
        setTotalPages(pagecount);
        setOpenBreakdrop(false);
        setFilter(false);
      })
      .catch((e) => {
        console.log("error found");
        setOpenBreakdrop(false);
      });
  };

  const selectCurrentUser = (index) => {
    setCurrentUser(index);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentUser(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentUser(-1);
  };

  const rows = [];
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleDeleteUser = () => {
    setDeleteUser(true);
  };
  const closeDeleteUser = () => {
    setDeleteUser(false);
  };
  const getStatus = (status) => {
    const statusColors = {
      Approved: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },
      Pending: {
        backgroundColor: "#ffff0040",
        color: "orange",
      },
      Rejected: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
    };
    return <StyledChip sx={statusColors[status]} label={status} />;
  };
  const filterpurchaseOrder = async (event) => {
    event.preventDefault();
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    await axiosInstance
      .get(`api/v1/purchaseorderlist?limit=10&page=${1}${string}`)
      .then((res) => {
        setFilter1(res.data.data);
        setAllPurchaseOrder(res.data.data);
        setAllUsers(res.data.data);
        setFilter(false);
      })
      .catch((e) => {
        getAllProduct([]);
        console.log("error found", e);
      });
  };
  const productStatus = (productstatus) => {
    const statusColors = {
      0: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
      1: {
        key: "Partial",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: "Paid",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
    };
    return (
      <StyledChip
        style={{
          width: "73%",
          height: "23px",
          fontSize: "12px",
        }}
        sx={statusColors[productstatus]}
        label={statusColors[productstatus].key}
      />
    );
  };
  const orderStatus = (status) => {
    const statusColors = {
      0: {
        key: "Draft",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Confirmed",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      2: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
    };

    // pending(yellow)  partial(orange) paid cancelled
    return (
      <StyledChip
        style={{
          width: "81%",
          height: "23px",
          fontSize: "12px",
        }}
        sx={statusColors[status]}
        label={statusColors[status].key}
      />
    );
  };

  const viewItemDetails = (rowData) => {};
  return (
    <>
      <Backdrop open={openBackdrop} />

      <div>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              fontWeight: "600",
              paddingLeft: "10px",
            }}
          >
            {t("Order")}
          </Typography>
          {editPermissions && (
            <Link
              to="/purchaseorder/add"
              style={{
                color: "white",
                textDecoration: "none",
              }}
            >
              <Button
                sx={{
                  height: "34px",
                  backgroundColor: "#F06237",
                  color: "white",
                  borderRadius: "10px",
                  padding: "5px 25px",
                  "&:hover": {
                    backgroundColor: "#cf5025",
                  },
                }}
                startIcon={<Add />}
                color="primary"
              >
                <span style={{ fontSize: "14px" }}>{t("Add_Order")}</span>
              </Button>
            </Link>
          )}
        </Stack>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "30px",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "30px",
              }}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => onSearch(e.target.value)}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Filter")}
                </Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              {/* <Box sx={{ margin: "8px auto" }}> */}
              <StyledFormLabel htmlFor="name">
                {" "}
                {t("Supplier_Name")}
              </StyledFormLabel>
              <StyledTextField
                // InputProps={{
                //     startAdornment: (
                //         <InputAdornment position="start">
                //             <SearchIcon />
                //         </InputAdornment>
                //     ),
                // }}
                placeholder={t("Search_Name")}
                fullWidth
                id="supplierName"
                name="supplierName"
                value={values.supplierName}
                onChange={handleInputChange}
              />
              {/* </Box> */}
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">
                  {t("Order_No")}
                </StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_Code")}
                  fullWidth
                  id="orderNumber"
                  name={t("orderNumber")}
                  value={values.orderNumber}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />

              {/* <Box sx={{ margin: "8px auto" }}>
                                <StyledFormLabel htmlFor="orderDate">
                                    Order Date
                                </StyledFormLabel>
                                <StyledTextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Search Product Name"
                                    fullWidth
                                    id="orderDate"
                                    name="orderDate"
                                    value={values.orderDate}
                                    onChange={handleInputChange}
                                />
                            </Box> */}
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="fAmount">
                  {t("Minimum_Amount")}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_Amount")}
                  fullWidth
                  id="fAmount"
                  name={t("fAmount")}
                  value={values.fAmount}
                  onChange={handleInputChange}
                />
              </Box>
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="tAmount">
                  {t("Maximum_Amount")}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_Amount")}
                  fullWidth
                  id="tAmount"
                  name={t("tAmount")}
                  value={values.tAmount}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel>{t("Status")}</StyledFormLabel>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <input
                        id="myInput"
                        type="checkbox"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={0}
                      />
                    }
                    label="Closed"
                  />
                  <FormControlLabel
                    control={
                      <input
                        id="myInput"
                        type="checkbox"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={1}
                      />
                    }
                    label={t("Partial")}
                  />
                  <FormControlLabel
                    control={
                      <input
                        id="myInput"
                        type="checkbox"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={2}
                      />
                    }
                    label={t("Pending")}
                  />
                  <FormControlLabel
                    control={
                      <input
                        id="myInput"
                        type="checkbox"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={3}
                      />
                    }
                    label={t("Paid")}
                  />
                </FormGroup>
              </Box>
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={() => {
                  closeFilterDrawer();
                }}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </form>
        </Drawer>
        {/* <TableContainer component={Paper} id="tableContainer"> */}
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("SUPPLIER_NAME")}</StyledTableCell>
              <StyledTableCell>{t("ORDER_NO")}.</StyledTableCell>
              <StyledTableCell>{t("ORDER_AMOUNT")}</StyledTableCell>
              <StyledTableCell>{t("TOTAL_PRODUCT")}</StyledTableCell>
              <StyledTableCell>{t("ORDER_STATUS")}</StyledTableCell>
              <StyledTableCell>{t("PAYMENT_STATUS")}</StyledTableCell>
              <StyledTableCell>{t("ORDER_DATE")}</StyledTableCell>
              <StyledTableCell>{t("ACTION")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers.length === 0 ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="7"
                >
                  {t("No_records_to_display")}
                </TableCell>
              </TableRow>
            ) : (
              allUsers.map((row, index) => (
                <StyledTableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    navigate(`/purchaseOrder/details`, { state: row.id });
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    <Typography sx={{ ml: 2, fontSize: "14px" }}>
                      {row.supplierName}
                    </Typography>
                  </StyledTableCell>
                  {/* <Link href={`/order-details/${row.orderId}`}> */}
                  <StyledTableCell>{row.orderNumber}</StyledTableCell>
                  {/* </Link> */}
                  <StyledTableCell>{row.payableAmount}</StyledTableCell>
                  <StyledTableCell>{row.products}</StyledTableCell>
                  <StyledTableCell align="center">
                    {orderStatus(row.status)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {productStatus(row.paymentStatus)}
                  </StyledTableCell>
                  <StyledTableCell>{row.orderDate}</StyledTableCell>
                  <StyledTableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Box>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <div>
                            <MoreVertIcon {...bindTrigger(popupState)} />
                            <Menu
                              {...bindMenu(popupState)}
                              PaperProps={{
                                sx: {
                                  marginTop: "13px",
                                  minWidth: "10em",
                                },
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                            >
                              {/* <Link to={`/purchaseOrder/details`} */}
                              {readPermissions && (
                                <MenuItem
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  onClick={() => {
                                    viewItemDetails(row);
                                    popupState.close();
                                    setUser(row);
                                    navigate(`/purchaseOrder/details`, {
                                      state: row.id,
                                    });
                                  }}
                                >
                                  <ListItemIcon>
                                    <img src={eye} alt="View Details/" />
                                  </ListItemIcon>
                                  {t("Purchase_Order_details")}
                                </MenuItem>
                              )}
                              {/* </Link> */}
                              {/* <Link to={`/order/edit/9`}
                                                            style={Linkstyle}>
                                                            <MenuItem
                                                                onClick={() => {
                                                                    viewItemDetails(row)
                                                                    popupState.close();
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <img src={edit} alt="Edit" />
                                                                </ListItemIcon>
                                                                Edit
                                                            </MenuItem>
                                                        </Link> */}
                              {readPermissions && (
                                <MenuItem
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                  }}
                                  onClick={() => {
                                    viewItemDetails(row);
                                    popupState.close();
                                    setUser(row);
                                    console.log("===", row);
                                    navigate(`/purchaseOrder/invoice`, {
                                      state: row.id,
                                    });
                                  }}
                                >
                                  <ListItemIcon>
                                    <img src={invoice} alt="View Details/" />
                                  </ListItemIcon>
                                  {t("View_Bill")}
                                </MenuItem>
                              )}

                              {/* <MenuItem
                                                            style={{ color: "red" }}
                                                            onClick={() => {
                                                                // DeleteItem(item);
                                                                handleDeleteUser();
                                                                setUser(row);
                                                                popupState.close();
                                                            }}
                                                        >

                                                            <ListItemIcon>
                                                                <img src={deleteIcon} alt="Delete" />
                                                            </ListItemIcon>
                                                            Delete
                                                        </MenuItem> */}
                            </Menu>
                          </div>
                        )}
                      </PopupState>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
        {/* </TableContainer> */}

        <productDeleteModel
          userlist={getAllProduct}
          page={page}
          userid={user.id}
          user={setUser}
          open={deleteUser}
          handleClose={closeDeleteUser}
        />
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {t("Showing")} {records} {t("of")} {totalorder} {t("Results")}
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default UsersList;
