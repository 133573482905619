import React, { useState, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";
import moment from 'moment';
import { tableCellClasses } from '@mui/material/TableCell';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';


import { useNavigate, useLocation } from "react-router-dom";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  Tooltip,
} from "@mui/material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Switch
} from "@mui/material";
import {
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
// icons for user menu
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import invoice from "../../assets/Icon.svg";
// import block from "../../../assets/block.svg";
import { GetSuppliers } from "../../state/action/supplierAction"
import { useSelector, useDispatch } from "react-redux"
//  filter icons
import filterIcon from "../../assets/filter.svg";
//  modals with respect to menu
import productViewModel from "./orderUpdate";
import productDeleteModel from "./orderDelete";
import { useFormik } from "formik";
import axiosInstance from "../../utils/AxiosInstace";
import axios from "axios";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import user1 from "../../assets/user1.png";
import user2 from "../../assets/user2.png";
// import { Route, Switch } from "";
import { useForm, Form } from "../../utils/useForms";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add 
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const validate = (values) => {
  const errors = {};
}
const initialValues = {
  customerName: "",
  orderNumber: '',
  fAmount: "",
  tAmount: '',
  paymentStatus: ""
};



const UsersList = (props) => {

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState(-1);
  const [AllSaleOrder, setAllSaleOrder] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userView, setUserView] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [filter1, setFilter1] = useState([]);
  const [totalPage, setTotalPages] = useState(1);
  const [user, setUser] = useState([]);
  const [search1, setsearch1] = useState([]);
  const [records, setRecords] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState(true);
  useEffect(() => {
    getAllSalesOrder();
  }, [page, search1, filterData])

  const handleChangePage = (event, value) => {
    setPage(value);

  };
  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
  }

  const getAllSalesOrder = async () => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      if (i == 0) {
        string += `&${key}=${value}`;
      } else {
        string += `&${key}=${value}`;
      }
      i++;
    }
    var formdata = new FormData();
    formdata.append("PZIJWJSWDID", page);
    formdata.append("token", "12HGVFRGHK");
    await axios
      .post(`https://www.bioitaly.me/API/V1HkljJHGasdhllasdgjhJHhjds/BOORIDIETRLLYIST`, formdata)
      .then((res) => {
        setAllSaleOrder(res.data.Data)
        setRecords(res.data.Per_Page);
        setTotalorder(res.data.Total_Record)
        let pagecount = Math.ceil((res.data.Total_Record) / 10)
        setFilter(false);
        setTotalPages(pagecount)
      }).catch((e) => {
        console.log("error found")
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData)

  }

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };


  const handleUserView = () => {
    setUserView(true);
  };
  const closeUserView = () => {
    setUserView(false);
  };

  const handleDeleteUser = () => {
    setDeleteUser(true);
  };
  const closeDeleteUser = () => {
    setDeleteUser(false);
  };
  const productStatus = (productstatus) => {
    const statusColors = {
      closed: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
      partial: {
        key: "Partial",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      pending: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      paid: {
        key: "Paid",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
    };

    return <StyledChip style={{
      width: "73%",
      height: "23px",
      fontSize: "12px",
    }} sx={statusColors[productstatus]} label={statusColors[productstatus].key} />;
  }
  const orderStatus = (status) => {
    const statusColors = {
      draft: {

        key: "Draft",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      shipped: {

        key: "Shipped",
        backgroundColor: "#def7ec",
        color: "#fc7e00",
      },
      confirmed: {


        key: "Confirmed",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      deliveried: {

        key: "Deliveried",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
      pending: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      closed: {
        key: "Closed",
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#f05252;",
      },
    };
    return <StyledChip style={{
      width: "81%",
      height: "23px",
      fontSize: "12px",
    }} sx={statusColors[status]} label={statusColors[status].key} />;
  }
  const viewItemDetails = (rowData) => {

  }
  return (

    <>
      <div style={{
        marginTop: "15px"
      }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography sx={{ fontSize: "22px", fontWeight: "bold", fontWeight: '600' }}>
            {t("Sales_Order")}
          </Typography>
          {/* <Link to="/order/add" style={{
            color: "white",
            textDecoration: "none",
          }}>
            <Button
              sx={{
                height: "34px",
                backgroundColor: "#F06237",
                color: "white",
                borderRadius: "10px",
                padding: "5px 25px",
                "&:hover": {
                  backgroundColor: "#cf5025",
                },
              }}
              startIcon={<Add />}
              color="primary"
            >
              {t("Add_Order")}
            </Button>
          </Link> */}
        </Stack>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "15px",
        }}
      >
        {/* <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "30px",
              }}
              placeholder={t("Search…")}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => onSearch(e.target.value)}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px"
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack> */}
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black"
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold", }}>{t("Filter")}</Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer1}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name"> {t("Customer_Name")}</StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_Name")}
                  fullWidth
                  id="customerName"
                  name={t("customerName")}
                  value={values.customerName}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">{t("Order_Number")}</StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_No")}
                  fullWidth
                  id="orderNumber"
                  name={t("orderNumber")}
                  value={values.orderNumber}
                  onChange={handleInputChange}
                />
              </Box>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="fAmount">{t("Minimum_Amount")}</StyledFormLabel>
                <StyledTextField
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_No")}
                  fullWidth
                  id="fAmount"
                  name={t("fAmount")}
                  value={values.fAmount}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />


              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="tAmount">
                  {t("Maximum_Amount")}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_Amount")}
                  fullWidth
                  id="tAmount"
                  name={t("tAmount")}
                  value={values.tAmount}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel>{t("Status")}</StyledFormLabel>
                <FormGroup sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <input
                        id="paymentStatus"
                        type="radio"
                        onChange={handleInputChange}
                        name={t("paymentStatus")}
                        value={0}
                      />
                    }

                    label={t("cancelled")}
                  />
                  <FormControlLabel
                    control={
                      <input
                        id="paymentStatus"
                        type="radio"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={1}
                      />
                    }

                    label={t("partial")}
                  />
                  <FormControlLabel
                    control={
                      <input
                        id="mpaymentStatusyInput"
                        type="radio"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={2}
                      />
                    }
                    label={t("Pending")}
                  />
                  <FormControlLabel
                    control={
                      <input
                        id="paymentStatus"
                        type="radio"
                        onChange={handleInputChange}
                        name="paymentStatus"
                        value={3}
                      />
                    }
                    label={t("paid")}
                  />
                </FormGroup>
              </Box>
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer
          component={Paper}
          sx={{ height: "auto" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>

                <StyledTableCell> {t("CUSTOMER_NAME")}</StyledTableCell>
                <StyledTableCell> {t("ORDER_N0")}</StyledTableCell>
                <StyledTableCell>{t("ORDER_AMOUNT")}</StyledTableCell>
                <StyledTableCell>{t("ORDER_STATUS")}</StyledTableCell>
                <StyledTableCell>{t("PAYMENT_STATUS")}</StyledTableCell>
                <StyledTableCell>{t("ORDER_DATE")}</StyledTableCell>
                <StyledTableCell>{t("ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {


                AllSaleOrder.length === 0 ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "silver",
                        textAlign: "center",
                        paddingTop: "90px",
                        borderBottom: "none",
                        fontSize: "30px",
                      }}
                      colSpan="7"
                    >
                      {t("No_records_to_display")}
                    </TableCell>
                  </TableRow>
                ) : (

                  AllSaleOrder.map((row, index) =>
                  (


                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        // hover color add
                      }}

                      onClick={() => {

                        navigate(`/orderPage/orderdetails`, { state: row.order_number })

                      }} >
                      <StyledTableCell component="th" scope="row">
                        <Typography sx={{ ml: 2, fontSize: "14px" }}>{row.customer}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>{row.order_number}</StyledTableCell>
                      <StyledTableCell>{row.item_amount}</StyledTableCell>
                      {/* <StyledTableCell>{row.salesOrderItems}</StyledTableCell> */}
                      <StyledTableCell>{orderStatus(row.order_status)}</StyledTableCell>
                      <StyledTableCell>{productStatus(row.payment_status)}</StyledTableCell>
                      <StyledTableCell>{moment(row.createdAt).format('LLL')}</StyledTableCell>
                      {/* <StyledTableCell onClick={(e) => {
                        e.stopPropagation();
                      }}>
                        <Box>
                          <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                              <div>
                                <MoreVertIcon
                                  {...bindTrigger(popupState)} />
                                <Menu
                                  {...bindMenu(popupState)}
                                  PaperProps={{
                                    sx: {
                                      marginTop: "13px",
                                      minWidth: "10em",
                                    },
                                  }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                  }}
                                >
                                  
                                  < MenuItem
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "none",

                                    }}
                                    onClick={() => {
                                      viewItemDetails(row)
                                      popupState.close();
                                      setUser(row);
                                      console.log("aaaa", row)
                                      navigate(`/orderPage/orderDetails`, { state: row.id })
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={eye} alt="View Details/" />
                                    </ListItemIcon>
                                    {t("Sale_Order_details")}
                                  </MenuItem>
                                 
                                  < MenuItem
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "none",

                                    }}
                                    onClick={() => {
                                      viewItemDetails(row)
                                      popupState.close();
                                      setUser(row);
                                      navigate(`/orderPage/orderInvoice`, { state: row.id })
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={invoice} alt="View Details/" />
                                    </ListItemIcon>
                                    {t("Sale_Order_Invoice")}
                                  </MenuItem>
                                 
                                </Menu>
                              </div>
                            )}
                          </PopupState>
                        </Box>
                      </StyledTableCell> */}
                      {/* <StyledTableCell>
                    <Tooltip title="payment">
                  <AddCircleIcon style={{ fill: '#F06237' }} 
                   onClick={() => {
                    
                    navigate(`/orderPage/orderPayment`, { state: row.id })
                  }}/>
                  </Tooltip>

                  </StyledTableCell> */}
                    </TableRow>
                  ))
                )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <productDeleteModel
          userlist={getAllCustomer}
          page={page}
          userid={user.id}
          user={setUser}
          open={deleteUser}
          handleClose={closeDeleteUser}
        /> */}
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {t("Showing")} {records} {t("of")} {totalorder} {t("Results")}
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default UsersList;
