import React from "react";
import { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
// import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { TextField, IconButton, Tooltip, Button, Stack } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled, useTheme, useMediaQuery } from "@mui/material";
import RectangleGlass from "../../assets/Asia-13.jpg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  backgroundColor: "#f06237",
  height: "88vh",
  position: "relative",
}));
const StyledRightSide = styled("div")(() => ({
  borderRadius: "10px",
  height: "88vh",
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#f06237",
  borderRadius: "10px",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#cf5025",
    color: "white",
  },
}));
const StyledBack = styled(Button)(() => ({
  backgroundColor: "white",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  color: "black",
  fontSize: "16px",
  padding: "8px 20px",
  fontWeight: "bold",
  "&:hover": {},
}));
const ForgetPassword = () => {
  const lg = useMediaQuery("@media (max-width:900px)");
  const xs = useMediaQuery("@media (max-width:600px)");
  const navigate = useNavigate();
  const { t}=useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    //    here starts our validation part
    const body = {
      email,
      password,
      rememberMe,
    };
  };
  return (
    <div>
      <Grid container spacing={1} sx={{ backgroundColor: "#F8F8F8;" }}>
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledLeftSide>
            <Box
              sx={{
                position: "absolute",
                borderRadius: "10px",
                height: "-webkit-fill-available",
                backgroundColor: "rgba(255, 255, 255, 0.2);",
                margin: lg ? "20px" : "40px",
                color: "white",
                padding: lg ? "0px 15px" : "0px 50px",
              }}
            >
              <h1>Welcome to  <br /> Asia pacific</h1>
              <p>
                “Our approach to sourcing is simple… We offer the finest quality
                materials and a final product that delivers with impact !”
              </p>
            </Box>
            <img
              src={RectangleGlass}
              style={{ width: "100%", height: "100%" }}
              alt={t("Login_Background")}
            />
          </StyledLeftSide>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledRightSide>
            <Container sx={xs ? {} : { pt: "20%" }}>
              <Box sx={{ mb: 3, mt: 7 }}>
                <Typography
                  component={"h4"}
                  sx={{
                    display: "inline-block",
                    fontSize: "36px",
                    fontWeight: "600",
                  }}
                >
                  {t("Forgot_your_password?")}
                </Typography>
                <br />
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Please enter the email address associated with your account
                  and We will email you a link to reset your password.
                </Typography>
              </Box>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Box sx={{ mt: 2 }}>
                  <StyledLabel>{t("Email")}</StyledLabel>
                  <TextField
                    name={t("email")}
                    value={email}
                    onChange={handleEmailChange}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                  />
                </Box>
                <Stack flexDirection="row" gap={3}>
                  <StyledLogin sx={{ mt: 3 }} type="submit">
                    {t("Send_Request")}
                  </StyledLogin>
                  <StyledBack
                    onClick={() => {
                      navigate("/login");
                    }}
                    sx={{ mt: 3 }}
                  >
                    {t("Back")}
                  </StyledBack>
                </Stack>
              </form>
            </Container>
          </StyledRightSide>
        </Grid>
      </Grid>
    </div>
  );
};
export default ForgetPassword;
