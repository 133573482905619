import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import "./style/addUpdate.css";
import { Close as CloseIcon } from "@mui/icons-material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  FormLabel,
  MenuItem,
  Grid,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";
import camera from "../../assets/camera.svg";
import { currencyList } from "../../state/action/productAction";
import { useTranslation } from "react-i18next";

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));
const StyledInput = styled("input")({
  display: "none",
});
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
  width: "100%",
}));

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  productGroup: "abc",
  productCode: "",
  productName: "",
  productDescription: "",
  productPrice: "",
  productSalesPrice: "",
  discountType: "",
  discountRate: "",
  productSpecification: "",
  discount: "",
  taxType: "",
  taxRate: "",
  currency: "",
};

export default function CustomizedInputs(props) {
  const [image, setImage] = useState([]);
  const [files, setFiles] = useState([]);
  const [categorylist, setcategorylist] = useState({});
  const [uomlist, setuomList] = useState({});
  const [taxData, settaxData] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [productinput, setproductInput] = useState({});
  const [totalItemPrice, setTotalItemPrice] = useState(0);
  const [totalTax, settotalTax] = useState();
  const [code, setCode] = useState(0);
  const [iconFlag, setIconFlag] = useState(0);
  const [images, setImages] = useState([]);
  const { t } = useTranslation();

  const [taxdetails, settaxDetails] = useState({});
  const navigate = useNavigate();
  const { auth } = store.getState();

  const userid = auth?.user?.id;

  let { id } = useParams();
  const button = id ? t("Save") : t("Add_Product");
  const headder = id ? t("Edit_Product") : t("Add_Product");
  useEffect(() => {
    getTaxDropdown();
    getCurrencyDropdown();
    getCategoryDropdown();
    getUOM();
    getTaxDetails(values.taxType);
    const uniqueId = () =>
      Math.floor(100000 + Math.random() * 900000) ||
      Math.floor(10000000 + Math.random() * 90000000);
    setCode(uniqueId);
  }, []);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("productPrice" in fieldValues) {
      if (fieldValues.productPrice !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.productPrice)) {
          temp.productPrice = "";
        } else {
          temp.productPrice = "Only Number allow";
        }
      } else {
        temp.productPrice = "This field is required.";
      }
    }
    // if ("taxRate" in fieldValues) {
    //     if (fieldValues.taxRate !== "") {
    //         if (/^[0-9\s]*$/.test(fieldValues.taxRate)) {
    //             temp.taxRate = "";
    //         } else {
    //             temp.taxRate = "Only Number allow";
    //         }
    //     } else {
    //         temp.taxRate = "This field is required.";
    //     }
    // }
    if ("discountRate" in fieldValues) {
      if (fieldValues.discountRate !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.discountRate)) {
          temp.discountRate = "";
        } else {
          temp.discountRate = "Only Number allow";
        }
      } else {
        temp.discountRate = "";
      }
    }

    if ("productName" in fieldValues) {
      if (fieldValues.productName !== "") {
        if (/^[a-zA-Z0-9\s]*$/.test(fieldValues.productName)) {
          temp.productName = "";
        } else {
          temp.productName = "Only Alphanumeric allow";
        }
      } else {
        temp.productName = "This field is required.";
      }
    }

    if ("productDescription" in fieldValues)
      temp.productDescription = fieldValues.productDescription
        ? ""
        : "This field is required.";

    if ("productSpecification" in fieldValues)
      temp.productSpecification = fieldValues.productSpecification
        ? ""
        : "This field is required.";
    if ("taxType" in fieldValues)
      temp.taxType = fieldValues.taxType ? "" : "This field is required.";

    if ("currency" in fieldValues)
      temp.currency = fieldValues.currency ? "" : "This field is required.";

    if ("productImage" in fieldValues)
      temp.productImage = fieldValues.productImage
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    calculateSalesPrice();
  }, [
    taxdetails,
    values.discountRate,
    values.discountType,
    values.productPrice,
  ]);
  async function deleteImage(index, val) {
    if (images[index]) {
      setImages((preval) => {
        return preval.filter((val, id) => id !== index);
      });
    }
    if (files[index]) {
      setFiles((preval) => {
        return preval.filter((val, id) => id !== index);
      });
    }
  }

  const handleSubmit = async (e, body) => {
    e.preventDefault();
    if (validate()) {
      var formData = new FormData();
      let cat_id;
      categorylist.forEach((val) => {
        if (val.productCategory == values.productCategory) {
          cat_id = val.id;
        }
      });
      let uom_id;
      uomlist.forEach((temp) => {
        if (temp.name == values.uom) {
          uom_id = temp.id;
        }
      });

      formData.append("created_by", userid);
      formData.append("productDescription", values.productDescription);
      formData.append("productName", values.productName);
      formData.append("productCode", code);
      formData.append("productCategory", cat_id);
      formData.append("productPrice", values.productPrice);
      formData.append("uom", uom_id);
      formData.append("productSalesPrice", totalItemPrice);
      formData.append("discountType", values.discountType);
      formData.append("productSpecification", values.productSpecification);
      formData.append("discountRate", values.discountRate);
      formData.append("taxType", values.taxType);
      formData.append("taxRate", taxdetails.taxValue);
      formData.append("currency", values.currency);
      images.forEach((val, key) => {
        formData.append("productImage", val);
      });

      formData.append("iconFlag", iconFlag);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await axiosInstance
        .post(`api/v1/productadd`, formData, config)
        .then((res) => {
          setproductInput(res.data);

          if (res.data.status === true) {
            setNotify({
              isOpen: true,
              message: "Product Added Successfully",
              type: "success",
            });
            setTimeout(() => {
              navigate("/product");
            }, 500);
          }
        })
        .catch((e) => {
          setNotify({
            isOpen: true,
            message: e.response.data.message,
            type: "error",
          });
          console.log("error found", e.response.data.message);
        });
      // navigate('/product');
    }
  };

  const calculateTotal = (e) => {
    if (e.target.value) {
      getTaxDetails(e.target.value);
    }
  };

  const getCurrencyDropdown = async (e) => {
    const res = await currencyList();
    console.log("object", res);

    if (res.status === true) {
      setcurrencyData(res.data);
    }
    // await axiosInstance
    //   .get(`/api/v1/getcurrency`)
    //   .then((res) => {
    //     console.log("currency", res.data.data);
    //     setcurrencyData(res.data.data);
    //   })
    //   .catch((e) => {
    //     // console.log("error found")
    //   });
  };

  const getCategoryDropdown = async (e) => {
    await axiosInstance
      .get(`api/v1/categorydropdown`)
      .then((res) => {
        setcategorylist(res.data.data);
      })
      .catch((e) => { });
  };

  const getUOM = async (e) => {
    await axiosInstance
      .get(`api/v1/uomlist`)
      .then((res) => {
        setuomList(res.data.data);
      })
      .catch((e) => { });
  };
  const getTaxDropdown = async (e) => {
    await axiosInstance
      .get(`api/v1/taxDropdown`)
      .then((res) => {
        settaxData(res.data.data);
      })
      .catch((e) => {
        // console.log("error found")
      });
  };

  const getTaxDetails = async (id) => {
    if (id) {
      await axiosInstance
        .get(`api/v1/taxdetails/${id}`)
        .then((res) => {
          settaxDetails(res.data.data);
          const texDatas = res.data.data;
          console.log("texDatas", texDatas);
          console.log("texDatas", texDatas.taxValue);
          setValues({
            ...values,
            taxType: id,
            taxRate: texDatas.taxValue,
          });
        })
        .catch((e) => {
          // console.log("error found")
        });
    }
  };

  const calculateSalesPrice = (e) => {
    console.log("valuessasadqad", values);
    let salesPrice = 0;
    let discountRate = values.discountRate ? values.discountRate : 0;
    let discountType = values.discountType ? values.discountType : 0;
    let ProductPrice = values.productPrice ? values.productPrice : 0;
    let taxValue = values.taxRate ? values.taxRate : 0;
    let taxType = values.taxType ? values.taxType : 0;

    if (ProductPrice && discountRate && discountType && taxValue) {
      if (discountType == 2) {
        console.log(
          "ProductPrice * discountRate / 100",
          (ProductPrice * discountRate) / 100
        );
        salesPrice =
          ProductPrice -
          (ProductPrice * discountRate) / 100 +
          (ProductPrice * taxValue) / 100;
      } else {
        salesPrice =
          ProductPrice - discountRate + (ProductPrice * taxValue) / 100;
      }
      setValues({
        ...values,
        productSalesPrice: salesPrice,
      });
    }
  };

  async function handleImageItem(e) {
    const selectedFiles = e.target.files;
    var localFiles = [...Array.from(selectedFiles)];

    setImages((preval) => {
      return [...preval, ...localFiles];
    });

    let newImagearr = localFiles.map((file) => ({
      file: file,
      image: URL.createObjectURL(file),
    }));
    setFiles([...files, ...newImagearr]);
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
  };
  const resetImage = () => {
    setImage("");
  };

  return (
    <>
      <h1 className="headerProduct" style={{ fontSize: "20px" }}>
        {headder}
      </h1>

      <p className="productName" style={{ fontSize: "12px" }}>
        {t("Products")} | <span style={{ color: "black" }}>{headder}</span>
      </p>

      <Form onSubmit={handleSubmit}>
        <Box id="mainbox" style={{}}>
          <Grid item md={12} sm={8} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="category">
                {t("Category_Name")}
              </StyledFormLabel>
              <Autocomplete
                disablePortal
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                placeholder={t("Search_category")}
                name={t("productCategory")}
                value={values.productCategory}
                labelId="productCategory"
                options={categorylist}
                error={Boolean(errors.productCategory)}
                helperText={errors.productCategory}
                onChange={(e) => {
                  setValues({ ...values, productCategory: e.target.innerText });
                }}
                getOptionLabel={(option) =>
                  option ? option.productCategory : ""
                }
                renderInput={(params) => (
                  <StyledTextField
                    name={t("budget")}
                    labelId="budget-input"
                    fullWidth
                    value={params.name}
                    {...params}
                    required
                    sx={{ justifyContent: "center" }}
                  />
                )}
                Icon={<SearchIcon />}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productName">
                {t("Product_Name")}
              </StyledFormLabel>
              <StyledTextField
                name={t("productName")}
                fullWidth
                value={values.productName}
                inputProps={{ maxLength: 200 }}
                onChange={handleInputChange}
                error={Boolean(errors.productName)}
                helperText={errors.productName}
              ></StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productSize">
                {t("Product_Size")}
              </StyledFormLabel>
              <StyledTextField
                name={t("productSize")}
                fullWidth
                value={values.productSize}
                inputProps={{ maxLength: 200 }}
                onChange={handleInputChange}
                error={Boolean(errors.productSize)}
                helperText={errors.productSize}
              ></StyledTextField>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productCode">
                {t("Product_Code")}
              </StyledFormLabel>
              <StyledTextField
                name={t("productCode")}
                fullWidth
                value={code}
                onChange={handleInputChange}
                error={Boolean(errors.productCode)}
                helperText={errors.productCode}
              ></StyledTextField>
            </Grid>

            <Grid item sx={{ m: 1 }} xs={11.8}>
              <StyledFormLabel htmlFor="productDescription">
                {t("Description")}
              </StyledFormLabel>
              <TextField
                name={t("productDescription")}
                fullWidth
                multiline
                rows={3}
                value={values.productDescription}
                inputProps={{ maxLength: 500 }}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              ></TextField>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="uom">
                {t("Unit_of_Measurement")}
              </StyledFormLabel>
              <Autocomplete
                disablePortal
                selectOnFocus
                select
                clearOnBlur
                handleHomeEndKeys
                name="uom"
                value={values.uom}
                labelId="uom"
                options={uomlist}
                error={Boolean(errors.uom)}
                helperText={errors.uom}
                onChange={(e) => {
                  setValues({ ...values, uom: e.target.innerText });
                }}
                getOptionLabel={(option) => (option ? option.name : "")}
                renderInput={(params) => (
                  <StyledTextField
                    name={t("budget")}
                    labelId="budget-input"
                    fullWidth
                    sx={{ justifyContent: "center" }}
                    value={params.name}
                    {...params}
                    required
                  />
                )}
                Icon={<SearchIcon />}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productSpecification">
                {t("Specification")}
              </StyledFormLabel>
              <StyledTextField
                name={t("productSpecification")}
                fullWidth
                value={values.productSpecification}
                onChange={handleInputChange}
                error={Boolean(errors.productSpecification)}
                helperText={errors.productSpecification}
              ></StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="currency">{t("Currency")}</StyledFormLabel>
              <select
                id="currency"
                name={t("currency")}
                fullWidth
                value={values.currency}
                onChange={handleInputChange}
                error={Boolean(errors.currency)}
                helperText={errors.currency}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">Select Currency</option>
                {currencyData.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productPrice">
                {t("ProductPrice")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                rows={2}
                name={t("productPrice")}
                value={values.productPrice}
                inputProps={{ maxLength: 10 }}
                onChange={handleInputChange}
                error={Boolean(errors.productPrice)}
                helperText={errors.productPrice}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="discountType">
                {t("Discount_Type")}
              </StyledFormLabel>
              <StyledTextField
                name={t("discountType")}
                fullWidth
                select
                value={values.discountType}
                onChange={handleInputChange}
                error={Boolean(errors.discountType)}
                helperText={errors.discountType}
              >
                <MenuItem key="1" value="1">
                  {t(" Flat")}
                </MenuItem>
                <MenuItem key="2" value="2">
                  {t("Percentage")}
                </MenuItem>
              </StyledTextField>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="discountRate">{t("Discount")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                rows={2}
                name={t("discountRate")}
                value={values.discountRate}
                onChange={handleInputChange}
                error={Boolean(errors.discountRate)}
                helperText={errors.discountRate}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="taxType">Tax</StyledFormLabel>
              <StyledTextField
                id="taxType"
                name={t("taxType")}
                fullWidth
                select
                value={values.taxType}
                onChange={calculateTotal}
                error={Boolean(errors.taxType)}
                helperText={errors.taxType}
              >
                {taxData.map((val) => {
                  return (
                    <MenuItem key={val.id} value={val.id}>
                      {val.taxType}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="tax">{t("Tax_Amount")}</StyledFormLabel>
              <StyledTextField
                id="taxRate"
                name={t("taxRate")}
                fullWidth
                value={taxdetails.taxValue}
                onChange={handleInputChange}
                error={Boolean(errors.taxRate)}
                helperText={errors.taxRate}
              ></StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productSalesPrice">
                {t("Sales_Price")}
                {/* {Boolean(errors.productcategory) && */}
                {/* } */}
              </StyledFormLabel>
              <StyledTextField
                name={t("productSalesPrice")}
                fullWidth
                value={values.productSalesPrice}
                error={Boolean(errors.productSalesPrice)}
                helperText={errors.productSalesPrice}
              ></StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}></Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}></Grid>
            <Grid item={true} md={6} sm={6} xs={12}>
              <StyledImageUploadWrapper>
                <label htmlFor="image-upload">
                  <StyledInput
                    accept="image/*"
                    id="image-upload"
                    type="file"
                    multiple
                    onChange={handleImageItem}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                  />
                  <StyledIconWrapper>
                    <img src={camera} alt="Camera" />
                  </StyledIconWrapper>
                </label>
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  {t("Add_Photo")}
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(15, 15, 15, 0.5)",
                    mx: 2,
                    fontSize: "12px",
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, max size of 3.1 MB
                </Typography>
              </StyledImageUploadWrapper>
              <Grid container item={true} xs={12}>
                {files.map((file, id) => {
                  return (
                    <StyledIconWrapper
                      sx={{
                        position: "relative",
                        margin: "25px 5px 5px 5px",
                        width: "147px",
                        height: "140px",
                        backgroundImage: `url(${file.image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      key={id + 1}
                      onClick={() => {
                        setIconFlag(id);
                      }}
                    >
                      <IconButton
                        size="large"
                        sx={{
                          position: "absolute",
                          right: "2%",
                          top: "3%",
                          width: "36px",
                          height: "36px",
                          background: "rgba(15, 15, 15, 0.5)",
                          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                          borderRadius: "10px",

                          color: "white",
                          "&:hover": {
                            backgroundColor: "#0957DD",
                          },
                        }}
                      >
                        <CloseIcon
                          sx={{ width: "20px", height: "20px" }}
                          onClick={() => {
                            deleteImage(id, file);
                          }}
                        />
                      </IconButton>
                    </StyledIconWrapper>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>

          {/* </Grid> */}
          <Box id="button-box">
            <Link to="/product/" className="link" id="linkcancel">
              <Button variant="outlined" size="large" id="b1">
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
            </Link>
            <Button
              variant="contained"
              size="large"
              id="submitButton"
              type="submit"
            >
              <strong>{button}</strong>
            </Button>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
