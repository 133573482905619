import React, { useEffect, useState } from "react";
import { Space, Table, Checkbox } from "antd";

import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "antd"
import { Alert } from "@mui/material";
import { toast } from "react-toastify";
import { Modal, Box } from "@mui/material";
import {
  addPermissionRole,
  getPageList,
  getPagePermissionList,
} from "../../state/action/roleAction";

function RoleList() {
  const { state } = useLocation();

  const roleId = state?.id;
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState({ loading: true, error: false });
  const [page, setPage] = useState(1);
  const [pagesList, setPagesList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [finalList, setFinalList] = useState([]);
  const [statusModal, setStatusModal] = useState(false);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const handleCheckboxes = (index, purpose) => {
    setFinalList((previousData) => {
      var newClone = [...previousData];
      newClone[index][purpose] = newClone[index][purpose] === 1 ? 2 : 1;
      return newClone;
    });
  };

  const modify = () => {
    var newClone = [...finalList];
    newClone = newClone.map((list) => {
      delete list.status;
      // delete list.id;
      delete list.pageName;
      return list;
    });
    return newClone;
  };

  console.log("finalList", finalList);

  const pagesListColumns = [
    {
      title: <span className="table-column">S.No</span>,
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },

    {
      title: <span className="table-column">Page Name</span>,
      dataIndex: "pageName",
      key: "pageName",
    },
    {
      title: <span className="table-column">Read</span>,
      dataIndex: "readP",
      key: "readP",
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Checkbox
              style={{ color: "red" }}
              checked={record && record.readP === 1}
              onChange={(e) => handleCheckboxes(index, "readP")}
            />
          </Space>
        );
      },
    },
    {
      title: <span className="table-column">Write</span>,
      dataIndex: "writeP",
      key: "writeP",
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Checkbox
              checked={record && record.writingP === 1}
              onChange={(e) => handleCheckboxes(index, "writingP")}
            />
          </Space>
        );
      },
    },
    {
      title: <span className="table-column">Delete</span>,
      dataIndex: "deleteP",
      key: "deleteP",
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Checkbox
              checked={record && record.deleteP === 1}
              onChange={(e) => handleCheckboxes(index, "deleteP")}
            />
          </Space>
        );
      },
    },
  ];

  const getPagesListAPI = async () => {
    try {
      const queryString = `?limit=10&page=${page}`;
      var response = await getPageList(queryString);
      if (response && response.status) {
        setStatus({ loading: false, error: false });
        setPagesList(response.data);
      } else {
        setStatus({ loading: false, error: false });
        setPagesList([]);
      }
    } catch (error) {
      setStatus({ loading: false, error: false });
      setPagesList([]);
    }
  };

  const initialization = () => {
    getPagesListAPI();
    getPagePermissionListAPI(page);
  };
  useEffect(() => {
    initialization();
  }, []);

  const openStatusModal = () => {
    setStatusModal(true);
  };
  const closeStatusModal = () => {
    setStatusModal(false);
  };

  const mergePages_Permissions = () => {
    var clonePages = [...pagesList];
    pagesList.map((pages, outerIndex) => {
      const pageId = pages.id;

      const index = permissionList.findIndex((list) => list.pageId === pageId);
      if (index === -1) {
        console.log("index", index);
        var element = { readP: 2, writingP: 2, deleteP: 2, pageId: pages.id, roleId: roleId };
        delete clonePages[outerIndex].id;
      } else {
        console.log("index1", index);
        var element = permissionList[index];
        delete element.createdAt;
        delete element.updatedAt;
        delete element.slug;
      }
      clonePages[outerIndex] = { ...clonePages[outerIndex], ...element };
    });
    setFinalList(clonePages);
  };

  useEffect(() => {
    console.log("object,pagesList", pagesList);
    if (pagesList.length !== 0) {
      mergePages_Permissions();
    }
  }, [pagesList, permissionList]);

  const getPagePermissionListAPI = async () => {
    try {
      const body = {
        roleId: roleId,
      };
      var response = await getPagePermissionList(body);
      if (response && response.status) {
        setStatus({ loading: false, error: false });
        setPermissionList(response.data);
      } else {
        setStatus({ loading: false, error: false });
        setPermissionList([]);
      }
    } catch (error) {
      setStatus({ loading: false, error: false });
    }
  };

  const onUpdatePermission = async () => {
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };
    const body = {
      permission: modify(),
    };

    const response = await addPermissionRole(body);
    if (response && response.status) {
      toast.success(response.message, toastType);
      initialization();
      navigate("/roleListss");
    } else {
      toast.error(response.message, toastType);
    }
    closeStatusModal();
  };

  return (
    <>
      <div className="card">
        <div className="card__body">
          {status.error ? (
            <Alert severity="error"> Not Found</Alert>
          ) : (
            finalList && (
              <>
                <Table
                  columns={pagesListColumns}
                  dataSource={finalList}
                  pagination={false}
                  loading={status.loading}
                />

                <br />
                <Button
                  className="add-button"
                  onClick={() => {
                    openStatusModal();
                  }}
                  style={{ backgroundColor: "#E64A08", color: "#FFFFFF" }}
                >
                  Submit
                </Button>
              </>
            )
          )}
        </div>
        <br />
      </div >
      {statusModal && (
        <Modal
          open={statusModal}
          // onClose={handleClose}
          disableAutoFocus={true}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 450,
              bgcolor: "#FFFFFF",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <p className="modal-title"> Update Status </p>
            <p style={{ fontSize: "16px" }}>
              Are you sure you want to Update ?
            </p>

            <br />
            <div className="form-button-container">
              <button className="form-action-button" onClick={closeStatusModal}>
                Cancel
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className="form-action-button-submit"
                onClick={() => {
                  onUpdatePermission();
                }}
              >
                YES
              </button>
            </div>
          </Box>
        </Modal>
      )
      }
    </>
  );
}

export default RoleList;
