import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import TaxList from "./TaxList";
import AddTAX from "./addTax";

const TaxPage = () => {
  const [refresh, setRefresh] = useState(false);
  const { t }=useTranslation();
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const [addTax, setAddTax] = useState(false);
  const openTaxModal = () => {
    setAddTax(true);
  };
  const closeTaxModal = () => {
    setAddTax(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
          {t("Tax_List")}
        </Typography>
        <Button
          sx={{
            backgroundColor: "#F06237",
            color: "white",
            borderRadius: "10px",
            // padding: "15px 20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            "&:hover": {
              backgroundColor: "#F06237",
            },
          }}
          onClick={openTaxModal}
          startIcon={<Add />}
          color="primary"
        >
          {t("Add_Tax")}
        </Button>
      </Stack>
      <AddTAX
        open={addTax}
        setOpen={setAddTax}
        handleClickOpen={openTaxModal}
        handleClose={closeTaxModal}
        refreshScreen={refreshScreen}
      />
      <Box sx={{ mt: 4 }}>
        <TaxList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default TaxPage;
