import React from "react";
import { Navigate } from "react-router-dom";

import { useSelector } from "react-redux";

function PrivateRoute({ children }) {
  // console.log("children", children);
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // const isAuthenticated = true;
  // return isAuthenticated ? children : <Navigate to="/login" />;

  const token = useSelector((state) => state.auth.token);
  return token ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
