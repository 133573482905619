import React, { useState, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,

} from "@mui/material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Grid,
  TableCell,

} from "@mui/material";
import {
  Box,
  TextField,
  FormLabel,
} from "@mui/material";

//  modals with respect to menu
import axiosInstance from "../../utils/AxiosInstace";
import { useForm, Form } from "../../utils/useForms";
import { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add 
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",

  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));
const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px"
}));
const FlexBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))
const initialValues = {

};


export default function PaymentReceivedReport() {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);
  const [paymentreceived, setPaymentReceived] = useState([]);
  const [totaldetails, setTotalDetails] = useState([]);
  const [fromdate, setFromDate] = useState(new Date());
  const [todate, setToDate] = useState(new Date());
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultValue = new Date(date).toISOString().split("T")[0];
  var somenextDate = new Date();
  var numberOfDaysToAdd = 3;
  var date = somenextDate.setDate(somenextDate.getDate() + numberOfDaysToAdd);
  var seconddefaultValue = new Date(date).toISOString().split("T")[0];
  const navigate = useNavigate();
  const now1 = new Date();
  const firstDay = new Date(now1.getFullYear(), now1.getMonth(), 1);
  const now2 = new Date();
  const lastDay = new Date(now2.getFullYear(), now2.getMonth() + 1, 0);
  const{t}=useTranslation();

  useEffect(() => {

    if (firstDay != "" && lastDay != "") {
      getAllPaymentReceived();
    }

  }, [values])
  const changeDate = (e) => {

    setValues({ ...values, 'fromDate': e.target.value })
  }
  const endDate = (e) => {
    setValues({ ...values, 'toDate': e.target.value })
  }

  const getAllPaymentReceived = async (e) => {
    const payload = {
      "fromDate": values.fromDate,
      "toDate": values.toDate

    }
    console.log("payload", payload)
    await axiosInstance
      .post(`/api/v1/getpayment`, payload).then((res) => {

        setTotalDetails(res.data)
        setPaymentReceived(res.data.data)
      }).catch((e) => {
        setPaymentReceived([])

        console.log("error found", e)
      })
  }




  return (
    <>
      <div style={{ marginLeft: "40%" }}>
        <h1 className='purchaseOrderheadder-left' style={{ fontSize: "26px", fontWeight: "normal" }}> {t("Payment_Received")}</h1>

        <Grid item sx={{ m: 1, ml: 0 }} container md={12} xs={12} style={{ display: "flex", marginRight: "70px" }}>
          <Grid item sx={{ m: 1 }} md={4.5} xs={12} style={{ margin: "10px" }}>
            <StyledFormLabel htmlFor="productGroup"
            >
              {t("From_Date")}
            </StyledFormLabel>
            <StyledTextField
              style={{
                height: "33px",
                borderRadius: "10px",
                fontSize: "13px",

              }}
              id="date"
              type="date"
              name={t("fromdate")}
              value={values.fromDate}
              defaultValue={moment(firstDay).format('YYYY-MM-DD')}
              onChange={changeDate}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sx={{ m: 1 }} md={4.5} xs={12}>
            <StyledFormLabel htmlFor="productGroup"
            >
              {t("To_Date")}
            </StyledFormLabel>
            <StyledTextField
              style={{
                height: "33px",
                borderRadius: "10px",
                fontSize: "13px",

              }}
              id="date"
              type="date"
              name={("todate")}
              defaultValue={moment(lastDay).format('YYYY-MM-DD')}
              value={values.toDate}
              onChange={endDate}
              onBlur={getAllPaymentReceived}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* <Grid >
            <Button
              sx={{

                height: "34px",
                backgroundColor: "#F06237",
                color: "white",
                borderRadius: "10px",
                padding: "5px 25px",
                marginTop: "26px",
                "&:hover": {
                  backgroundColor: "#cf5025",
                },
              }}
              color="primary"
            >
              <span style={{ fontSize: "14px" }}>Click</span>
            </Button>
          </Grid> */}
        </Grid>
      </div>
      <TableContainer
        component={Paper}
        sx={{ height: "auto" }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("CUSTOMER_NAME")}</StyledTableCell>
              <StyledTableCell >{t("PAYMENT_AMOUNT")}</StyledTableCell>
              <StyledTableCell>{t("PAYMENT_MODE")}</StyledTableCell>
              <StyledTableCell> {t("PAYMENT_DATE")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentreceived.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",

                  }} onClick={() => {
                    navigate(`/customer/details`, {
                      state: {
                        user: row,
                      }
                    })
                  }} >

                  <Typography sx={{ ml: 2, fontSize: "14px" }}>{row.customerName}</Typography>
                  {/* </Stack> */}
                </StyledTableCell>
                <TableCell >{row.paymentAmount}</TableCell>
                <TableCell>{row.paymentMode}</TableCell>
                <TableCell>{row.paymentDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)", marginTop: "20px" }}></div>
      <FlexBox px={2}
        style={{

          marginLeft: '70%',
        }}
      >
        <Box display="flex">
          <Box pr={4}
            style={{ marginLeft: "80px" }} >
            <Typography sx={{ mb: 4 }}>{t("Total")}</Typography>
          </Box>
          <div
            style={{ marginLeft: "80px" }}
          >
            <Typography sx={{ mb: 6, color: "red" }}><b>{totaldetails.total}</b></Typography>
          </div>
        </Box>
      </FlexBox>
    </>
  )

}
