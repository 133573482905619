import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useState } from "react";
import Notification from "../../utils/Notification";
import { useTranslation } from "react-i18next";
// import link
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  FormLabel,
  MenuItem,
  Input,
  Tooltip,
  Grid,
  Button,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const strong = {
  color: "black",
};
const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  supplierName: "",
  companyName: "",
  supplierEmail: "",
  companyEmail: "",
  companyPhoneNumber: "",
  mobileNumber: "",
  address: "",
  billingAddress: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  billingCity: "",
  billingState: "",
  billingCountry: "",
  billingzipCode: "",
  accountName: "",
  accountNumber: "",
  ifscCode: "",
};

export default function CustomizedInputs(props) {
  const location = useLocation();
  const navigate = useNavigate();
  let id = location.state ? location.state.user.id : "";
  const button = id ? "Save" : "Add Supplier";
  const headder = id ? "Edit Supplier" : "Add Supplier";
  const [statelist, setStatelist] = useState([]);
  const [billingstatelist, setBillingStatelist] = useState([]);
  const [countrylist, setCountrylist] = useState([]);
  const [address, setAddress] = useState({});
  const [billingaddress, setBillingAddress] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const { t }=useTranslation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    GetSupplier();
    getCountry();
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("mobileNumber" in fieldValues) {
      if (fieldValues.mobileNumber !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.mobileNumber)) {
          temp.mobileNumber = "";
        } else {
          temp.mobileNumber = "Only Number allow";
        }
      } else {
        temp.mobileNumber = "This field is required.";
      }
    }
    if ("zipCode" in fieldValues) {
      if (fieldValues.zipCode !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.zipCode)) {
          temp.zipCode = "";
        } else {
          temp.zipCode = "Only Number allow";
        }
      } else {
        temp.zipCode = "This field is required.";
      }
    }
    if ("companyPhoneNumber" in fieldValues) {
      if (fieldValues.companyPhoneNumber !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.companyPhoneNumber)) {
          temp.companyPhoneNumber = "";
        } else {
          temp.companyPhoneNumber = "Only Number allow";
        }
      } else {
        temp.companyPhoneNumber = "This field is required.";
      }
    }
    if ("accountNumber" in fieldValues) {
      if (fieldValues.accountNumber !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.accountNumber)) {
          temp.accountNumber = "";
        } else {
          temp.accountNumber = "Only Number allow";
        }
      } else {
        temp.accountNumber = "This field is required.";
      }
    }
    if ("billingzipCode" in fieldValues) {
      if (fieldValues.billingzipCode !== "") {
        if (/^[0-9\s]*$/.test(fieldValues.billingzipCode)) {
          temp.billingzipCode = "";
        } else {
          temp.billingzipCode = "Only Number allow";
        }
      } else {
        temp.billingzipCode = "This field is required.";
      }
    }
    if ("supplierEmail" in fieldValues) {
      if (fieldValues.supplierEmail !== "") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            fieldValues.supplierEmail
          )
        ) {
          temp.supplierEmail = "";
        } else {
          temp.supplierEmail = "Only Email allow";
        }
      } else {
        temp.supplierEmail = "This field is required.";
      }
    }
    if ("companyEmail" in fieldValues) {
      if (fieldValues.companyEmail !== "") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            fieldValues.companyEmail
          )
        ) {
          temp.companyEmail = "";
        } else {
          temp.companyEmail = "Only Email allow";
        }
      } else {
        temp.companyEmail = "This field is required.";
      }
    }
    if ("accountName" in fieldValues) {
      if (fieldValues.accountName !== "") {
        if (/^[A-Za-z\s]{1,20}$/.test(fieldValues.accountName)) {
          temp.accountName = "";
        } else {
          temp.accountName = "Only characters allow";
        }
      } else {
        temp.accountName = "This field is required.";
      }
    }

    if ("swiftCode" in fieldValues) {
      if (fieldValues.swiftCode !== "") {
        if (
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{8,11}$/.test(
            fieldValues.swiftCode
          )
        ) {
          temp.swiftCode = "";
        } else {
          temp.swiftCode = " alphanumeric & minium 8 to 11 digits allow";
        }
      } else {
        temp.swiftCode = "This field is required.";
      }
    }
    if ("ifscCode" in fieldValues) {
      if (fieldValues.ifscCode !== "") {
        if (
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{1,14}$/.test(
            fieldValues.ifscCode
          )
        ) {
          temp.ifscCode = "";
        } else {
          temp.ifscCode = "alphanumeric & maximum 14 digits allow";
        }
      } else {
        temp.ifscCode = "This field is required.";
      }
    }
    if ("bankName" in fieldValues) {
      if (fieldValues.bankName !== "") {
        if (/^[a-zA-Z0-9\s]{1,50}$/.test(fieldValues.bankName)) {
          temp.bankName = "";
        } else {
          temp.bankName = "Maximum 50 characters allow";
        }
      } else {
        temp.bankName = "This field is required.";
      }
    }
    if ("vatNumber" in fieldValues) {
      if (fieldValues.vatNumber !== "") {
        if (
          /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{1,14}$/.test(
            fieldValues.vatNumber
          )
        ) {
          temp.vatNumber = "";
        } else {
          temp.vatNumber = " alphanumeric & maximum 14 digits allow";
        }
      } else {
        temp.vatNumber = "This field is required.";
      }
    }
    if ("supplierName" in fieldValues) {
      if (fieldValues.supplierName !== "") {
        if (/^[a-zA-Z0-9\s]{1,50}$/.test(fieldValues.supplierName)) {
          temp.supplierName = "";
        } else {
          temp.supplierName = "number or characters and 50 digits allow only";
        }
      } else {
        temp.supplierName = "This field is required.";
      }
    }

    if ("companyName" in fieldValues)
      temp.companyName = fieldValues.companyName
        ? ""
        : "This field is required.";
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";
    if ("billingAddress" in fieldValues)
      temp.billingAddress = fieldValues.billingAddress
        ? ""
        : "This field is required.";
    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : "This field is required.";
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "This field is required.";
    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : "This field is required.";

    if ("billingCity" in fieldValues)
      temp.billingCity = fieldValues.billingCity
        ? ""
        : "This field is required.";
    if ("billingState" in fieldValues)
      temp.billingState = fieldValues.billingState
        ? ""
        : "This field is required.";
    if ("billingCountry" in fieldValues)
      temp.billingCountry = fieldValues.billingCountry
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      supplierName: values.supplierName,
      companyName: values.companyName,
      supplierEmail: values.supplierEmail,
      companyEmail: values.companyEmail,
      companyPhoneNumber: values.companyPhoneNumber,
      mobileNumber: values.mobileNumber,
      address: {
        addressType: 1,
        address: address.address,
        state: address.state,
        city: address.city,
        zipCode: Number(address.zipCode),
        country: parseInt(address.country),
      },
      billingAddress: {
        addressType: "2",
        address: billingaddress.billingAddress,
        state: billingaddress.billingState,
        city: billingaddress.billingCity,
        zipCode: Number(billingaddress.billingzipCode),
        country: parseInt(billingaddress.billingCountry),
      },
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      ifscCode: values.ifscCode,
      vatNumber: values.vatNumber,
      bankName: values.bankName,
      swiftCode: values.swiftCode,
      // "createdBy": userid
    };
    if (validate()) {
      if (id) {
        await axiosInstance
          .patch(`api/v1/supplierupdate/${id}`, values)
          .then((res) => {
            if (res.data.status == true) {
              setNotify({
                isOpen: true,
                message: "Supplier Updated Successfully",
                type: "success",
              });
              setTimeout(() => {
                navigate("/supplier/edit");
              }, 500);
            }
          })
          .catch((e) => {
            console.log("error found");
          });
      } else {
        await axiosInstance
          .post(`api/v1/supplieradd`, payload)
          .then((res) => {
            console.log("------------------------------add", res.data);
            if (res.data.status == true) {
              setNotify({
                isOpen: true,
                message: "Supplier Added Successfully",
                type: "success",
              });
              setTimeout(() => {
                navigate("/supplier/add");
              }, 500);
            }
          })
          .catch((e) => {
            console.log("error found", e.response.data.message);
            let msg = e.response.data.message;
            setErrorMessage(msg);
            setNotify({
              isOpen: true,
              message: e.response.data.message,
              type: "warning",
            });
            setTimeout(() => {
              navigate("/supplier/edit");
            }, 10000);
          });
      }
    }
  };

  const getStateListData = (e) => {
    setAddress({
      ...address,
      country: e.target.value,
    });
    axiosInstance
      .get(`/api/v1/allstate/${e.target.value}`)
      .then((res) => {
        setStatelist(res.data.data);
      })
      .catch((e) => {
        console.log("error found");
      });
  };
  const getBillingStateListData = (e) => {
    setBillingAddress({
      ...billingaddress,
      country: e.target.value,
    });
    axiosInstance
      .get(`/api/v1/allstate/${e.target.value}`)
      .then((res) => {
        setBillingStatelist(res.data.data);
      })
      .catch((e) => {
        console.log("error found");
      });
  };
  const GetSupplier = async () => {
    await axiosInstance
      .get(`api/v1/supplierdetails/${id}`)
      .then((res) => {
        setValues(res.data.data);
        let c_address = res.data.data.address;
        let b_address = res.data.data.billingAddress;
        if (c_address.country != "") {
          {
            axiosInstance
              .get(`/api/v1/allstate/${parseInt(c_address.country)}`)
              .then((res) => {
                setStatelist(res.data.data);
              })
              .catch((e) => {
                console.log("error found");
              });
          }
        }
        if (b_address.country != "") {
          {
            axiosInstance
              .get(`/api/v1/allstate/${parseInt(b_address.country)}`)
              .then((res) => {
                setBillingStatelist(res.data.data);
              })
              .catch((e) => {
                console.log("error found");
              });
          }
        }
        setAddress(c_address);
        setBillingAddress(b_address);
      })
      .catch((e) => {
        console.log("error found");
      });
  };
  const getCountry = async (e) => {
    await axiosInstance
      .get(`/api/v1/allcountry`)
      .then((res) => {
        setCountrylist(res.data.data);
      })
      .catch((e) => {
        console.log("error found");
      });
  };

  return (
    <>
      <h1
        style={{
          fontWeight: "600",
          fontSize: "20px",
          marginBottom: "4px",
        }}
      >
        {" "}
        <strong>{headder}</strong>{" "}
      </h1>

      <p
        style={{
          fontWeight: "500",
          fontSize: "12px",
          lineHeight: "34px",
          marginTop: "0px",
        }}
      >
        Suppliers | <span style={{ color: "black" }}>{headder}</span>
      </p>

      <Form onSubmit={handleSubmit}>
        <Box
          style={{
            backgroundColor: "white",
            borderRadius: "12px",
            padding: "10px",
          }}
        >
          <Grid item md={12} sm={8} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="supplierName">
                {t("Supplier_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("supplierName")}
                value={values.supplierName}
                onChange={(e) => {
                  setValues({ ...values, supplierName: e.target.value });
                }}
                error={Boolean(errors.supplierName)}
                helperText={errors.supplierName}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="companyName">
                {t("Company_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("companyName")}
                value={values.companyName}
                onChange={(e) => {
                  setValues({ ...values, companyName: e.target.value });
                }}
                error={Boolean(errors.companyName)}
                helperText={errors.companyName}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="supplierEmail">
                {t("supplier_Email")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("supplierEmail")}
                value={values.supplierEmail}
                onChange={(e) => {
                  setValues({ ...values, supplierEmail: e.target.value });
                }}
                error={Boolean(errors.supplierEmail)}
                helperText={errors.supplierEmail}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="companyEmail">
                {t("Company_Email")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("companyEmail")}
                value={values.companyEmail}
                onChange={(e) => {
                  setValues({ ...values, companyEmail: e.target.value });
                }}
                error={Boolean(errors.companyEmail)}
                helperText={errors.companyEmail}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="companyPhoneNumber">
                {t("Company_Phone_Number")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("companyPhoneNumber")}
                value={values.companyPhoneNumber}
                onChange={(e) => {
                  setValues({ ...values, companyPhoneNumber: e.target.value });
                }}
                error={Boolean(errors.companyPhoneNumber)}
                helperText={errors.companyPhoneNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="mobileNumber">
                {t("Mobile_Number")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("mobileNumber")}
                value={values.mobileNumber}
                onChange={(e) => {
                  setValues({ ...values, mobileNumber: e.target.value });
                }}
                error={Boolean(errors.mobileNumber)}
                helperText={errors.mobileNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="vatNumber">{t("VAT_Number")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("vatNumber")}
                value={values.vatNumber}
                onChange={(e) => {
                  setValues({ ...values, vatNumber: e.target.value });
                }}
                error={Boolean(errors.vatNumber)}
                helperText={errors.vatNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={11.5} xs={12}>
              <hr />
              <StyledFormLabel htmlFor="address">{t("Address")}</StyledFormLabel>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="address">{t("Address")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("address")}
                value={address.address}
                onChange={(e) => {
                  setAddress({ ...address, address: e.target.value });
                }}
                error={Boolean(errors.address)}
                helperText={errors.address}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="country">{t("Country")}</StyledFormLabel>
              <StyledTextField
                id="country"
                name={t("country")}
                fullWidth
                select
                value={parseInt(address.country)}
                onChange={getStateListData}
                error={Boolean(errors.country)}
                helperText={errors.country}
              >
                {countrylist ? (
                  countrylist.map((val) => {
                    return (
                      <MenuItem key={val.id} value={val.id}>
                        {val.name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <h1>Data Loading</h1>
                )}
              </StyledTextField>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="state">{t("State")}</StyledFormLabel>
              <StyledTextField
                id="state"
                name={t("state")}
                fullWidth
                select
                value={parseInt(address.state)}
                onChange={(e) => {
                  setAddress({ ...address, state: e.target.value });
                }}
                error={Boolean(errors.state)}
                helperText={errors.state}
              >
                {statelist.map((val) => {
                  return (
                    <MenuItem key={val.id} value={val.id}>
                      {val.name}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="city">{t("City")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("city")}
                value={address.city}
                onChange={(e) => {
                  setAddress({ ...address, city: e.target.value });
                }}
                error={Boolean(errors.city)}
                helperText={errors.city}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="zipCode">{t("PinCode")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("zipCode")}
                value={address.zipCode}
                onChange={(e) => {
                  setAddress({ ...address, zipCode: e.target.value });
                }}
                error={Boolean(errors.zipCode)}
                helperText={errors.zipCode}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={11.5} xs={12}>
              <hr />
              <StyledFormLabel htmlFor="address">
                {t("Billing_Address")}
              </StyledFormLabel>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="billingAddress">
                Address
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("billingAddress")}
                value={billingaddress.address}
                onChange={(e) => {
                  setBillingAddress({
                    ...billingaddress,
                    address: e.target.value,
                  });
                }}
                error={Boolean(errors.billingAddress)}
                helperText={errors.billingAddress}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="billingCountry">
                {t("Country")}
              </StyledFormLabel>
              <StyledTextField
                id="billingCountry"
                name={t("billingCountry")}
                fullWidth
                select
                value={parseInt(billingaddress.country)}
                onChange={getBillingStateListData}
                error={Boolean(errors.billingCountry)}
                helperText={errors.billingCountry}
              >
                {countrylist ? (
                  countrylist.map((val) => {
                    return (
                      <MenuItem key={val.id} value={val.id}>
                        {val.name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <h1>Data Loading</h1>
                )}
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="billingState">{t("State")}</StyledFormLabel>
              <StyledTextField
                id="billingState"
                name={t("billingState")}
                fullWidth
                select
                value={parseInt(billingaddress.state)}
                onChange={(e) => {
                  setBillingAddress({
                    ...billingaddress,
                    state: e.target.value,
                  });
                }}
                error={Boolean(errors.billingState)}
                helperText={errors.billingState}
              >
                {billingstatelist.map((val) => {
                  return (
                    <MenuItem key={val.id} value={val.id}>
                      {val.name}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="billingCity">{t("City")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("billingCity")}
                value={billingaddress.city}
                onChange={(e) => {
                  setBillingAddress({
                    ...billingaddress,
                    city: e.target.value,
                  });
                }}
                error={Boolean(errors.billingCity)}
                helperText={errors.billingCity}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="billingzipCode">
                {t("PinCode")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("billingzipCode")}
                value={billingaddress.zipCode}
                onChange={(e) => {
                  setBillingAddress({
                    ...billingaddress,
                    zipCode: e.target.value,
                  });
                }}
                error={Boolean(errors.billingzipCode)}
                helperText={errors.billingzipCode}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={11.5} xs={12}>
              <hr />
              <StyledFormLabel htmlFor="bankAccount">
                {t("Bank_Account_Details")}
              </StyledFormLabel>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="accountName">
                {t("Account_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={("accountName")}
                value={values.accountName}
                onChange={(e) => {
                  setValues({ ...values, accountName: e.target.value });
                }}
                error={Boolean(errors.accountName)}
                helperText={errors.accountName}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="accountNumber">
                {t("Account_Number")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("accountNumber")}
                value={values.accountNumber}
                onChange={(e) => {
                  setValues({ ...values, accountNumber: e.target.value });
                }}
                error={Boolean(errors.accountNumber)}
                helperText={errors.accountNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="bankName">{("Bank_Name")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("bankName")}
                value={values.bankName}
                onChange={(e) => {
                  setValues({ ...values, bankName: e.target.value });
                }}
                error={Boolean(errors.bankName)}
                helperText={errors.bankName}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="ifscCode">IFSC/MICR</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("ifscCode")}
                value={values.ifscCode}
                onChange={(e) => {
                  setValues({ ...values, ifscCode: e.target.value });
                }}
                error={Boolean(errors.ifscCode)}
                helperText={errors.ifscCode}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="swiftCode">{t("Swift_Code")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("swiftCode")}
                value={values.swiftCode}
                onChange={(e) => {
                  setValues({ ...values, swiftCode: e.target.value });
                }}
                error={Boolean(errors.swiftCode)}
                helperText={errors.swiftCode}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginTop="20px"
            borderRadius="12px"
            padding="20px"
          >
            <Link
              to="/supplier/"
              style={{
                textDecoration: "none",
              }}
            >
              <Button
                variant="outlined"
                size="large"
                style={{
                  border: "1px solid  #282822",
                  borderRadius: "10px",
                  fontSize: 16,
                  color: "black",
                  marginRight: "20px",
                  transition:
                    "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
            </Link>
            <Button
              variant="contained"
              size="large"
              type="submit"
              style={{
                backgroundColor: "#f06237",
                borderRadius: "10px",
              }}
            >
              <strong style={{ color: "white" }}>{button}</strong>
            </Button>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
