import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import axiosInstance from "../../utils/AxiosInstace";
import Notification from "../../utils/Notification"
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
    Typography,
    IconButton,
    TextField, FormLabel, Tooltip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import camera from "../../assets/camera.svg";
import { useForm } from "../../utils/useForms";
import { toast } from "react-toastify";
import Backdrop from '../Backdrop/loader';
import { stockAddApi, stockListApi } from '../../state/action/stockAction'
import { id } from "date-fns/locale";

const StyledFormLabel = styled(FormLabel)(() => ({
    display: "block",
    fontWeight: "bold",
    color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
    marginTop: "10px",
    ".MuiOutlinedInput-root": {
        borderRadius: "10px",
    },
    ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
        padding: '14px',

    }
}));
const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "206px",
    borderRadius: "10px",
    textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
    position: "relative",

    margin: "20px auto",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "rgba(15, 15, 15, 0.05)",

    img: {
        marginTop: "30px",
    },
}));

const StyledInput = styled("input")({
    display: "none",
});
const StyledRequired = styled("span")({
    color: "red",
    marginLeft: "2px",
    fontSize: "20px",
});

const StyledRemoveButton = styled(Box)({
    color: "red",
    display: "inline",
    "&:hover": {
        cursor: "pointer",
    },
});

const AddStock = (props) => {
    const dispatch = useDispatch();
    const { open, refreshScreen, handleClose, stock } = props;
    const { t } = useTranslation();

    const [isdisabled, setIsDisabled] = useState(false);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [productlist, setproductList] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    let id = location.state;
    const [image, setImage] = useState('');
    const resetImage = () => {
        setImage('');
    };
    const reset = () => {
        resetForm();
    };

    const initialValues = {
        productName: "",
        quantity: "",
    };

    const validate = (fieldValues = values) => {
        console.log("fieldValues", fieldValues);
        let temp = { ...errors };
        if ("productName" in fieldValues)
            temp.productName = fieldValues.productName ? "" : "This field is required.";
        if ("quantity" in fieldValues)
            //only for number allowed regex = /^[0-9\b]+$/ 
            if (fieldValues.quantity == "") {
                temp.quantity = "This field is required.";
            }
            else if (fieldValues.quantity < 0) {
                temp.quantity = "Quantity should be greater than 0.";
            }
            else if (/^[0-9\b]+$/.test(fieldValues.quantity) == false) {
                temp.quantity = "Only number allowed.";
            }
            else {
                temp.quantity = "";
            }
        setErrors({
            ...temp,
        });
        console.log("temp", temp);
        console.log("errors", errors);

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });


    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    useEffect(() => {

        console.log("propsStock1", props.stock)
        if (props.stock && props.stock !== -1) {
            console.log("propsStock", props.stock)
            setValues(props.stock)
        }
        getProduct()
    }, [props])
    const getProduct = async (e) => {
        await axiosInstance
            .get(`api/v1/allproductdropdown`).then((res) => {
                setproductList(res.data.data)
            }).catch((e) => {
                //  console.log("error found")
            })

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const toastType = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        };



        if (validate()) {
            let product_id;
            productlist.forEach((temp) => {
                if (temp.productName == values.productName) {
                    product_id = temp.id
                }
            })
            const payload = {
                "productId": product_id,
                "quantity": values.quantity,

            }
            if (props.stock && props.stock !== -1) {
                await axiosInstance
                    .patch(`api/v1/updatestock/${props.stock.id}`, payload).then((res) => {
                        if (res.data.status == true) {
                            toast.success(res.data.message, toastType);
                            reset();
                            handleClose();
                            refreshScreen();
                        }
                        else {
                            toast.error(res.data.message, toastType);
                        }
                    }).catch((e) => {
                        console.log("error found")
                    })
            }
            else {
                await axiosInstance
                    .post(`api/v1/addstock`, payload,).then((res) => {
                        console.log("resStock", res)
                        if (res.data.status === true) {
                            close();
                            toast.success("Stock Added Successfully", toastType);
                            refreshScreen();

                        }
                        else {
                            toast.error(res.data.message, toastType);
                        }
                    }).catch((e) => {
                        console.log("error found", e.response.data.message)
                    })
            }
        }
    };


    const close = () => {
        reset();
        handleClose();
        setIsDisabled(false);
    };


    return (
        <>
            <Backdrop open={openBackdrop} />

            <Dialog
                open={open}
                fullWidth={true}
            >
                <DialogTitle
                    sx={{
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    {props.stock && props.stock !== -1 ? t('Edit') : t('Add')
                    } {t("Stock")}
                    <Tooltip title="Close">
                        <IconButton onClick={close}>
                            <CloseIcon
                            />
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit} id="addNewStock">
                        <Grid container xs={12} spacing={2} style={{ justifyContent: "center" }} >

                            {!props.stock ?
                                <Grid item sx={{ m: 1 }} md={9} xs={12}>
                                    <StyledFormLabel htmlFor="productName">
                                        {t("Product_Name")}
                                    </StyledFormLabel>
                                    <Autocomplete
                                        disablePortal
                                        selectOnFocus
                                        select
                                        clearOnBlur
                                        handleHomeEndKeys
                                        name="productName"
                                        value={values.uom}
                                        labelId="productName"
                                        options={productlist}
                                        error={Boolean(errors.productName)}
                                        helperText={errors.productName}
                                        onChange={(e) => {
                                            setValues({ ...values, 'productName': e.target.innerText })
                                        }}
                                        getOptionLabel={(option) =>
                                            option ? option.productName : ""
                                        }
                                        renderInput={(params) => (
                                            <StyledTextField
                                                name="budget"
                                                labelId="budget-input"
                                                fullWidth
                                                sx={{ justifyContent: 'center' }}
                                                value={values.productName}
                                                {...params} required
                                            />
                                        )}
                                        Icon={<SearchIcon />}
                                    />
                                </Grid>
                                :
                                <Grid item sx={{ m: 1 }} md={9} xs={12}>
                                    <StyledFormLabel htmlFor="productName">
                                        {t("Product_Name")}
                                    </StyledFormLabel>
                                    <StyledTextField
                                        name={t("productName")}
                                        fullWidth
                                        inputProps={{ readOnly: true }}
                                        value={values.productName}
                                        onChange={handleInputChange}
                                        error={Boolean(errors.productName)}
                                        helperText={errors.productName}
                                    >

                                    </StyledTextField>
                                </Grid>
                            }



                            <Grid item sx={{ m: 1 }} md={9} xs={12}>
                                <StyledFormLabel htmlFor="quantity">
                                    {t("Quantity")}
                                </StyledFormLabel>
                                <StyledTextField
                                    name={t("quantity")}
                                    fullWidth
                                    value={values.quantity}
                                    onChange={handleInputChange}
                                    error={Boolean(errors.quantity)}
                                    helperText={errors.quantity}
                                >
                                </StyledTextField>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions sx={{ margin: "15px" }}>
                    <Button
                        sx={{
                            backgroundColor: "white",
                            color: "black",
                            border: "1px solid #0F0F0F80",
                            borderRadius: "10px",
                            padding: "10px 25px",
                            "&:hover": {
                            },
                        }}
                        onClick={close}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#E64A08",
                            color: "white",
                            borderRadius: "10px",
                            padding: "10px 25px",
                            "&:hover": {
                                backgroundColor: "#E64A08",
                            },
                        }}
                        disabled={isdisabled}
                        type="submit"
                        form="addNewStock"
                    >
                        {props.stock && props.stock !== -1 ? t("Save") : t("Add")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Notification notify={notify} setNotify={setNotify} />

        </>
    );
};

export default AddStock;
