import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ADMIN_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CHANGE_PASSWORD,
} from "../action-types/authActionTypes";
import Cookies from "universal-cookie";
import handleAuthToken from "../../utils/handleAuthToken";
import axiosInstance from "../../utils/AxiosInstace";
const cookies = new Cookies();

export const loginUser = (formData, rememberMe) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axiosInstance.post("api/v1/adminlogin", formData, config);
    if (res.data && res.status == 200) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data && res.data.data,
      });
      if (rememberMe == true) {
        cookies.set("myEmail", formData.email, {
          path: process.env.REACT_APP_URL,
        });
        cookies.set("myPassword", formData.password, {
          path: process.env.REACT_APP_URL,
        });
      }
    } else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }

    return res.data;
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    return err;
  }
};


export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    axiosInstance.defaults.headers.common[
      "x-auth-token"
    ] = `${localStorage.token}`;
  }
  try {
    const res = await axiosInstance.post("/api/v1//aadminfindbytoken");
    if (res.data && res.data.status) {
      dispatch({
        type: ADMIN_LOADED,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });


    }
    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};
