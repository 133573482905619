import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Stack,
  Grid,
  IconButton,
  TextField,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
// import { editUserStatusApi } from "../../state/action/taxAction";
import Backdrop from "../Backdrop/loader";
import { useTranslation } from "react-i18next";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "rgba(15, 15, 15, 0.5)",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiInput-root": {},
}));

const HSNDetails = (props) => {
  const { user, open, handleClose } = props;
  var { id, fname, lname, profile_image, email, mobile, gender, isActive } =
    user;
  fname = fname ? fname : "-";
  lname = lname ? lname : "-";
  email = email ? email : "-";

  const [isStatusEditing, setStatusEditing] = useState(false);
  const startStatusEditing = () => {
    setStatusEditing(true);
  };
  const stopStatusEditing = () => {
    setStatusEditing(false);
  };
  const [currentStatus, setCurrentStatus] = useState("");
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const handleCurrentStatus = async (event) => {
    // var value = event.target.value;
    // var body = {
    //   isActive: value,
    // };
    // setOpenBreakdrop(true);
    // const res = await editUserStatusApi(id, body);
    // if (res.status) {
    //   const { isActive: active } = res.data;
    //   setCurrentStatus(active);
    //   setOpenBreakdrop(false);
    // }
  };

  const getGender = (value) => {
    const genders = {
      1: "Female",
      2: "Male",
    };
    return genders[value] ? genders[value] : "-";
  };
  const statusList = [
    {
      name: "Pending",
      value: 0,
    },
    {
      name: "Active",
      value: 1,
    },
    {
      name: "Rejected",
      value: 2,
    },
    {
      name: "Delete",
      value: 3,
    },
  ];
  const getStatus = (value) => {
    const status = {
      0: "Pending",
      1: "Active",
      2: "Rejected",
      3: "Delete",
    };
    return status[value];
  };
  useEffect(() => {
    setCurrentStatus(isActive);
  }, [isActive]);

  const [statusMenu, setStatusMenu] = useState(true);
  const { t }=useTranslation();

  const openStatusMenu = () => {
    setStatusMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };

  const isProfileImageValid = (profile) => {
    return profile && !profile.includes("null") && profile.includes("https")
      ? true
      : false;
  };
  return (
    <div>
      <Backdrop open={openBackdrop} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {user.relation ? "Family Member Details" : "User Details"}
          <Tooltip title="Close">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={0}>
            <Grid item sx={{ mt: 1 }} md={3} sm={4} xs={12}>
              <Stack
                sx={{
                  mt: 2,
                  padding: "20px ",
                  border: "1px solid rgba(15, 15, 15, 0.15)",
                  borderRadius: "10px",
                }}
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                {isProfileImageValid(profile_image) ? (
                  <img
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    sx={{ mx: 0.5 }}
                    alt="User Profile"
                    src={profile_image}
                  />
                ) : (
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                )}
              </Stack>
            </Grid>
            <Grid md={9} sm={8} xs={12} spacing={1}>
              <Grid
                container
                xs={12}
                sx={{ m: 1 }}
                style={{ alignItems: "center" }}
              >
                {user.relation ? (
                  <Grid item sx={{ m: 1 }} xs={5.6}>
                    <StyledFormLabel htmlFor="fname">{t("Name")} </StyledFormLabel>
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      variant="standard"
                      fullWidth
                      id="fname"
                      name={t("fname")}
                      defaultValue={user.name}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item sx={{ m: 1 }} xs={5.6}>
                      <StyledFormLabel htmlFor="fname">
                        {t("First_Name")}{" "}
                      </StyledFormLabel>
                      <StyledTextField
                        inputProps={{ readOnly: true }}
                        variant="standard"
                        fullWidth
                        id="fname"
                        name={("fname")}
                        defaultValue={fname}
                      />
                    </Grid>
                    <Grid item sx={{ m: 1 }} xs={5.6}>
                      <StyledFormLabel htmlFor="lname">
                        {t("Last_Name")}{" "}
                      </StyledFormLabel>
                      <StyledTextField
                        inputProps={{ readOnly: true }}
                        variant="standard"
                        fullWidth
                        id="lname"
                        name="lname"
                        defaultValue={lname}
                      />
                    </Grid>
                  </>
                )}

                <Grid item sx={{ m: 1 }} xs={5.6}>
                  <StyledFormLabel htmlFor="email">{t("Email")}</StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="email"
                    defaultValue={email}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} xs={5.6}>
                  <StyledFormLabel htmlFor="mobile">{t("Mobile")}</StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="mobile"
                    defaultValue={mobile}
                  />
                </Grid>
                {!user.relation ? (
                  <>
                    <Grid sx={{ m: 1 }} xs={5.6}>
                      <StyledFormLabel htmlFor="gender">
                        {t("Gender")}{" "}
                      </StyledFormLabel>
                      <StyledTextField
                        inputProps={{ readOnly: true }}
                        variant="standard"
                        fullWidth
                        id="gender"
                        name="gender"
                        defaultValue={getGender(gender)}
                      />
                    </Grid>
                    <Grid sx={{ m: 1 }} xs={5.6}>
                      <StyledFormLabel htmlFor="status">
                        {t("Status")}{" "}
                      </StyledFormLabel>
                      {isStatusEditing ? (
                        <Select
                          variant="standard"
                          fullWidth
                          id="status"
                          name="status"
                          value={currentStatus}
                          open={statusMenu}
                          onOpen={openStatusMenu}
                          onClose={() => {
                            closeStatusMenu();
                            stopStatusEditing();
                          }}
                          onChange={handleCurrentStatus}
                        >
                          {statusList.map((value) => {
                            return (
                              <MenuItem key={value.key} value={value.value}>
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      ) : (
                        <StyledTextField
                          inputProps={{ readOnly: true }}
                          variant="standard"
                          fullWidth
                          id="status"
                          name="{t(status)}"
                          value={getStatus(currentStatus)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" sx={{ mb: 1 }}>
                                <IconButton
                                  onClick={() => {
                                    startStatusEditing();
                                    openStatusMenu();
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "#F06237",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#F06237",
              },
            }}
            onClick={handleClose}
          >
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HSNDetails;
