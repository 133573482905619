import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { tableCellClasses } from "@mui/material/TableCell";
import UserDeleteModal from "./deleteProduct";
import Backdrop from "../Backdrop/loader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
} from "@mui/material";
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import filterIcon from "../../assets/filter.svg";
import { useForm } from "../../utils/useForms";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  getProductList,
  categoryDropdown,
} from "../../state/action/productAction";
import { CSVLink, CSVDownload } from "react-csv";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const data = [
  {
    categoryName: "",
    productName: "",
    productSize: "",
    Specification: "",
    ProductCode: "",
    ProductPrice: "",
    Description: "",
    UnitofMeasurement: "",
  },
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
}));

const initialValues = {
  CategoryName: "",
  productName: "",
  fSalesPrice: "",
  tSalesPrice: "",
};

const validate = (values) => {
  const errors = {};
};
const UsersList = (props) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const { t } = useTranslation();
  const [productList, setproductList] = useState([]);
  const [deleteUser, setDeleteUser] = useState(false);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [totalorder, setTotalorder] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const navigate = useNavigate();

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  const fileReader = new FileReader();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { state } = useLocation();

  useEffect(() => {
    getAllProduct();
  }, [search, page, filterData, refresh]);

  useEffect(() => {
    if (state && state.categoryId) {
      const payload = {
        ...values,
        CategoryName: state.categoryId,
      };
      setValues(payload);
      setFilterData(!filterData);
      setOpenBreakdrop(true);
    }
  }, [state]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    const res = await categoryDropdown();
    if (res && res.status == true) {
      setCategoryList(res.data);
    } else {
      console.log("error found");
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const onSearch = async (searchvalue) => {
    setSearch(searchvalue);
  };
  const getAllProduct = async () => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let queryString = `?page=${page}&search=${search}${string}&limit=10`;

    if (state && state.categoryId) {
      queryString = `?page=${page}&limit=10&search=${search}&CategoryName=${state.categoryId}`;
    }
    var formdata = new FormData();
    formdata.append("PZIJWJSWDID", page);
    formdata.append("token", "12HGVFRGHK");

    const res = await getProductList(formdata);
    if (res && res.status == true) {
      setproductList(res.Data);
      setRecords(res.Data?.length);
      setTotalorder(res.Total_Record);
      let pagecount = Math.ceil(res.Total_Record / 10);
      setTotalPages(pagecount);
      setTimeout(() => {
        setOpenBreakdrop(false);
      }, 500);
    } else {
      setOpenBreakdrop(false);
    }
    setFilter(false);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleDeleteUser = () => {
    setDeleteUser(true);
  };
  const closeDeleteUser = () => {
    setDeleteUser(false);
  };

  const filterProduct = async (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <div>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography
            sx={{ fontSize: "22px", fontWeight: "bold", fontWeight: "600" }}
          >
            {t("Products")}
          </Typography>
          {/* <Box
            marginTop="20px"
            borderRadius="12px"
            sx={{ marginLeft: "505px" }}
          >
            <Button
              sx={{
                height: "34px",
                backgroundColor: "#F06237",
                color: "white",
                borderRadius: "10px",
                padding: "5px 25px",
                "&:hover": {
                  backgroundColor: "#cf5025",
                },
              }}
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}
            >
              <span style={{ fontSize: "14px" }}>{t("Upload")}</span>
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                width: "100%",
                height: "12%",
                marginTop: "10px",
                marginLeft: "9px",
              }}
            >
              <CSVLink
                data={data}
                // target="_blank"
                style={{ textDecorationLine: "none" }}
              >
                <Button sx={{ color: "black", marginTop: "-2px" }}>
                  {t("Demo_CSV")}
                </Button>
              </CSVLink>
            </Menu>
          </Box> */}
          <Box marginTop="20px" borderRadius="12px">
            {/* <Button
              sx={{
                height: "34px",
                backgroundColor: "#F06237",
                color: "white",
                borderRadius: "10px",
                padding: "5px 25px",
                "&:hover": {
                  backgroundColor: "#cf5025",
                },
              }}
              startIcon={<Add />}
              color="primary"
              onClick={() => {
                navigate("/product/add");
              }}
            >
              {t("Add_Products")}
            </Button> */}
          </Box>
        </Stack>

        {/* <UsersList /> */}
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "30px",
        }}
      >
        {/* search bar and filter button */}
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          {/* <Form  > */}

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "30px",
              }}
              placeholder={t("Search…")}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => onSearch(e.target.value)}
            />
          </Search>
          {/* </Form> */}

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={filterProduct}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Filter")}
                </Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="name">
                  {" "}
                  {t("Product_Name")}
                </StyledFormLabel>
                <StyledTextField
                  placeholder={t("Enter_Product_Name")}
                  fullWidth
                  id="productName"
                  name={t("productName")}
                  value={values.productName}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="CategoryName">
                  {" "}
                  {t("Category")}
                </StyledFormLabel>
                <select
                  style={{
                    width: "100%",
                    height: "50px",
                    border: "1px solid rgba(15, 15, 15, 0.15)",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    fontSize: "15px",
                    color: "black",
                    fontWeight: "400",
                    backgroundColor: "white",
                  }}
                  id="CategoryName"
                  name={t("CategoryName")}
                  value={values.CategoryName}
                  onChange={handleInputChange}
                >
                  <option value="">{t("Select_Category")}</option>
                  {categoryList.map((item) => (
                    <option value={item.id}>{item.productCategory}</option>
                  ))}
                </select>
              </Box>
              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="fSalesPrice">
                  {t("From_Product_Sales_Price")}
                </StyledFormLabel>
                <StyledTextField
                  placeholder={t("Enter_Product_Sales_Price")}
                  fullWidth
                  id="fSalesPrice"
                  name={t("fSalesPrice")}
                  value={values.fSalesPrice}
                  onChange={handleInputChange}
                />
              </Box>
              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="tSalesPrice">
                  {t("To_Product_Sales_Price")}
                </StyledFormLabel>
                <StyledTextField
                  placeholder={t("Enter_Product_Sales_Price")}
                  fullWidth
                  id="tSalesPrice"
                  name={t("tSalesPrice")}
                  value={values.tSalesPrice}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />

              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper} sx={{ height: "auto" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("IMAGE")}</StyledTableCell>
                <StyledTableCell> {t("PRODUCT_NAME")}</StyledTableCell>
                <StyledTableCell>{t("CATEGORY_NAME")}</StyledTableCell>
                <StyledTableCell>{t("MRP_PRICE")}</StyledTableCell>
                <StyledTableCell>{t("SALES_PRICE")}</StyledTableCell>
                <StyledTableCell>{t("CREATED_DATE")}</StyledTableCell>
                {/* <StyledTableCell>{t("ACTION")}</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {productList?.length === 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    {t("No_records_to_display")}
                  </TableCell>
                </TableRow>
              ) : (
                productList?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      navigate(`/product/details`, { state: row.id });
                    }}
                  >
                    <StyledTableCell>
                      <img src={row.image} width="30px" />
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      <Typography sx={{ ml: 2, fontSize: "14px" }}>
                        {row.name}
                      </Typography>
                      {/* </Stack> */}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.category_name}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.price}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.special_price}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(row.createdAt).format("LLL")}
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <UserDeleteModal
          userlist={getAllProduct}
          page={page}
          userid={user.id}
          user={setUser}
          open={deleteUser}
          handleClose={closeDeleteUser}
        />
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {t("Showing")} {records} of {totalorder} {t("Results")}
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default UsersList;
