import React, { useState } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { Box } from "@mui/material";
import avtarimage from "../../../assets/avatar.svg";
// import state from "../../../state/store"
import { useSelector } from 'react-redux'
import { store } from "../../../state/store"
import {
  Logout as LogoutIcon,
} from "@mui/icons-material";
// import use effect 
import { useEffect } from "react";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import LanguageSelect from "../../../Langauge";



import { ListItemIcon, ListItemText } from "@mui/material";
import {
  Search as SearchIcon,
  Notifications as NotificationsIcon,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
// import eye from "../../../assets/Eye.svg";
// import edit from "../../../assets/Edit.svg";
// import deleteIcon from "../../../assets/Delete.svg";
import { useFormik } from "formik";
import {




  Stack,
  Pagination,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { logout } from "../../../state/action/authAction";
import {
  Drawer,
  Divider,

  TextField,
  FormLabel,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

// import "./header.css";
import {
  AppBar,
  Toolbar,
  InputBase,
  IconButton,
  Badge,
  Button,
  Avatar,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
// const formik = useFormik({
//   initialValues,
//   onSubmit: (values) => {
//   },
// });

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

}));
const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  // height: "30px",
  // ".MuiOutlinedInput-root": {
  //     borderRadius: "10px",
  // },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

// const dispatch = useDispatch();
// const { auth } = store.getState()
// const reduxData = auth.user;
// const username = reduxData ? reduxData.firstName : '';
// const userimage = reduxData ? reduxData.image : '';

const Header = (props) => {



  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [admin, setAdmin] = useState({});
  const [filter, setFilter] = useState(false);
  const open = Boolean(anchorEl);
  const { t}=useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let timeout = setInterval(() => {
      let { auth } = store.getState();
      if (auth.user) {
        // if data is on store then we have to update the state of user with userdata and stop the timeout
        setAdmin(auth.user);
        stoptimeout()
      }
    }, 500);
    function stoptimeout() {
      clearInterval(timeout);
    }
  }, [])
  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
  };
  return (
    <AppBar
      position="static"
      sx={{
        mt: 2,
        backgroundColor: "transparent",
        boxShadow: "none",

      }}
    >
      <Toolbar sx={{ paddingLeft: "5px !important" }}>
        <Search >
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            sx={{
              height: "40px",
            }}
            placeholder={t("Search…")}
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <LanguageSelect />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", }}>
          <IconButton
            color="inherit"
            sx={{
              marginRight: "30px",
              ".MuiBadge-badge": {
                backgroundColor: "#e64a08",
              },
            }}
          // onClick={openFilterDrawer}

          >
            <Badge badgeContent={8} >
              {/* <NotificationsIcon fontSize="large" /> */}
              <NotificationsNoneIcon fontSize="large" style={{ color: 'black', height: "28px" }} />
            </Badge>
          </IconButton>
          <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
            <form >
              <Box
                sx={{
                  width: 220,
                  padding: "10px 20px",
                  color: "black"
                }}
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ color: "black" }}
                >
                  <Typography sx={{ fontWeight: "bold", }}>{t("Filter")}</Typography>
                  <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                    <CloseIcon />
                  </IconButton>
                </Stack>

                {/* <Box sx={{ margin: "8px auto" }}> */}
                <StyledFormLabel htmlFor="name"> {t("Supplier_Name")}</StyledFormLabel>
                <StyledTextField
                  // InputProps={{
                  //     startAdornment: (
                  //         <InputAdornment position="start">
                  //             <SearchIcon />
                  //         </InputAdornment>
                  //     ),
                  // }}
                  placeholder={t("Search_Name")}
                  fullWidth
                  id="name"
                  name={t("name")}
                // value={formik.values.name}
                // onChange={formik.handleChange}
                />
                {/* </Box> */}
                <Divider sx={{ mt: 2 }} />
                <Box sx={{ margin: "8px auto" }}>
                  <StyledFormLabel htmlFor="email">{t("Product_Code")}</StyledFormLabel>
                  <StyledTextField
                    type="email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t("Search_Code")}
                    fullWidth
                    id="email"
                    name={t("email")}
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  />
                </Box>
                <Divider sx={{ mt: 2 }} />

                <Box sx={{ margin: "8px auto" }}>
                  <StyledFormLabel htmlFor="phone_number">
                    {t("Product_Name")}
                  </StyledFormLabel>
                  <StyledTextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search Product Name"
                    fullWidth
                    id="phone_number"
                    name={t("phone_number")}
                  // value={formik.values.phone_number}
                  // onChange={formik.handleChange}
                  />
                </Box>

                <Divider sx={{ mt: 2 }} />
                <Box sx={{ margin: "8px auto" }}>
                  <StyledFormLabel>{t("Payment_Status")}</StyledFormLabel>
                  <FormGroup sx={{ mt: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                        // checked={formik.values.approved_status}
                        // onChange={formik.handleChange}
                        />
                      }
                      name={t("Closed")}
                      label={t("Closed")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={formik.values.pending_status}
                          // onChange={formik.handleChange}
                          name={t("Pending")}
                        />
                      }
                      label={t("Pending")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={formik.values.rejected_status}
                          // onChange={formik.handleChange}
                          name={t("partial")}

                        />
                      }
                      inputProps={{
                        fontSize: "12px"
                      }}


                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={formik.values.rejected_status}
                          // onChange={formik.handleChange}
                          name={t("paid")}
                        />
                      }
                      label={t("Paid")}
                    />
                  </FormGroup>
                </Box>
              </Box>
              <Stack
                sx={{ margin: "100px 20px 10px 20px" }}
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid #0F0F0F80",
                    borderRadius: "10px",
                    padding: "10px 25px",
                    "&:hover": {
                      // backgroundColor: "#60579A",
                    },
                  }}
                  onClick={closeFilterDrawer}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#F06237",
                    borderRadius: "10px",
                    color: "white",
                    padding: "10px 25px",
                    "&:hover": {
                      backgroundColor: "#F06237",
                    },
                  }}
                  type={t("submit")}
                >
                 {t("Filter")}
                </Button>
              </Stack>
            </form>
          </Drawer>

          <Button
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              color: "black",
              height: "40px",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
            onClick={handleClick}
          >
            <Avatar
              //sx={{ mx: 0.5 }}
              alt=""
              img src={admin.image}

            />
            <Typography sx={{ mx: 0.5, fontSize: "12px" }}>{admin.firstName}</Typography>
            {open ? (
              <ExpandLess sx={{ mx: 0.5 }} />
            ) : (
              <ExpandMore sx={{ mx: 0.5 }} />
            )}
          </Button>
          <Menu
            sx={{ mt: 0.3 }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* <MenuItem sx={{ mt: 1 }} onClick={handleClose}>
              <ListItemIcon>
                <img src={eye} alt="View Details" />
              </ListItemIcon>
              <ListItemText>View Details</ListItemText>
            </MenuItem> */}
            <MenuItem sx={{ width: "180px" }} onClick={() => dispatch(logout())}>
              <ListItemIcon>
                {/* <img src={edit} alt="Edit" /> */}
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>{t("logout")}</ListItemText>
            </MenuItem>
            {/* <MenuItem sx={{ mt: 1 }} onClick={handleClose}>
              <ListItemIcon>
                <img src={deleteIcon} alt="Delete" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem> */}

            {/* <MenuItem sx={{ mt: 1 }} onClick={handleClose}>
              <ListItemIcon>
                <img src={block} alt="Block" />
              </ListItemIcon>
              <ListItemText>Block</ListItemText>
            </MenuItem> */}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
