import { Box } from "@mui/material";
import React from "react";
import UserList from "./UserList";

const UserManagementList = () => {
  return (
    <Box>
      <UserList />
    </Box>
  );
};

export default UserManagementList;
