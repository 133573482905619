import React from "react";
import { useState,useEffect } from "react";

import {
    Grid,
    Box,
    Container,
    Typography,
    InputAdornment,
} from "@mui/material";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { TextField, IconButton, Tooltip, Button, Stack } from "@mui/material";
import { ConstructionOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { styled, useTheme, useMediaQuery } from "@mui/material";
import RectangleGlass from "../../assets/Asia-12.png";
import { Redirect, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'material-react-toastify';
// import { ToastContainer, toast } from "react-toastify";
import Notification from "../../utils/Notification";
import camera from "../../assets/camera.svg"
import Asia01 from "../../assets/Asia-01.png";
import { useTranslation } from "react-i18next";

import 'material-react-toastify/dist/ReactToastify.css';


const StyledLeftSide = styled("div")(() => ({
    borderRadius: "10px",
    height: "40vh",
    position: "relative",
    marginTop: "-287px"
}));
const StyledRequired = styled("span")({
    color: "red",
    marginLeft: "2px",
    fontSize: "14px",
});

const StyledRightSide = styled("div")(() => ({
    height: "88vh",
}));
const StyledLabel = styled("label")(() => ({
    display: "inline-block",
    fontWeight: "300",
    fontSize: "14px",
    mt: 1,
    borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
    backgroundColor: "#f06237",
    color: "white",
    fontSize: "16px",
    padding: "8px 20px",
    "&:hover": {
        backgroundColor: "#cf5025",
        color: "white",
    },
}));
const StyledIconWrapper = styled(Box)(() => ({
    margin: "20px auto",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    width: "80px",
    height: "80px",
    borderRadius: "10px",
    backgroundColor: "rgba(15, 15, 15, 0.05)",

    img: {
        marginTop: "30px",
    },
}));
const StyledInput = styled("input")({
    display: "none",

});
const StyledImageUploadWrapper = styled(Box)(() => ({
    marginTop: "16px",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    height: "206px",
    borderRadius: "10px",
    textAlign: "center",
    width: "100%"
}));
const ForgetPassword = () => {

    const lg = useMediaQuery("@media (max-width:900px)");
    const xs = useMediaQuery("@media (max-width:600px)");
    const { t}= useTranslation();
    const navigate = useNavigate();
    
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
      });

return(
    <div>
            <ToastContainer />
            <Grid container spacing={1} sx={{ backgroundColor: "#F8F8F8;" ,paddingTop:"0px" }}>
            <Grid item lg={5} md={5} sm={5} xs={12} >
                    <StyledLeftSide>
                        <Box
                            sx={{
                                position: "fixed",
                                borderRadius: "10px",
                                height: "-webkit-fill-available",
                                margin: lg ? "30px" : "40px",
                                color: "white",
                                padding: lg ? "0px 30px" : "0px 50px",
                            }}
                        >
                           
                        </Box>
                        <img
                            src={Asia01}
                            style={{ "width": "300px",
                                "height": "100px",
                                "margin-top": "316px",
                                "margin-left": "80%"}}
                            alt="Login Background"
                        />
                    </StyledLeftSide>
                </Grid>
                
              
                <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px",marginLeft:"400px" }}>
                    <StyledRightSide>
                        <Container sx={xs ? {} : { pt: "20%" }}>
                            <Box sx={{ mb: 3 }}>
                                <Typography
                                    component={"h4"}
                                    sx={{
                                        display: "inline-block",
                                        fontSize: "36px",
                                        fontWeight: "600",
                                    }}
                                >
                                    {t('Information')}
                                </Typography>
                                <br />
                               
                            </Box>
                            <form autoComplete="off">
                                <Box sx={{ mt: 2 }}>
                                    <StyledLabel>{t("FIRST_NAME")}</StyledLabel>
                                    <TextField
                                        name={t("fname")}
                                       
                                        sx={{ mt: 1, borderRadius: "8px" }}
                                        size="small"
                                        fullWidth
                                    />
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <StyledLabel> {t("LAST_NAME")}</StyledLabel>
                                    <TextField
                                        name={t("lname")}
                                     
                                        sx={{ mt: 1, borderRadius: "8px" }}
                                        size="small"
                                        fullWidth

                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start" sx={{ m: 0 }}>
                                                    <Tooltip
                                                        // title={
                                                        //     showPassword ? "Hide Password" : "Show Password"
                                                        // }
                                                    >
                                                        <IconButton>
                                                            {/* {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )} */}
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                <Grid item sx={{ m: 1 }} sm={11.5} xs={12} container spacing={1}>
                            {/* <Grid item md={12} container sm={6} xs={12}> */}
                            <StyledLabel>{t("COMPANY_LOGO")}</StyledLabel>
                            <StyledImageUploadWrapper sx={{}}>
                                <label htmlFor="image-upload">
                                    <StyledInput
                                        accept="image/*"
                                        id="image-upload"
                                        type="file"
                                        multiple
                                      

                                    />
                                    <StyledIconWrapper
                                    >
                                        <img src={camera} alt="Camera" />
                                    </StyledIconWrapper>
                                </label>
                                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                                    {t("Drop_Company_Logo_Here")} or
                                    <StyledRequired>  {t("Browse")}
                                    </StyledRequired>
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "rgba(15, 15, 15, 0.5)",
                                        mx: 2,
                                        fontSize: "12px",
                                    }}
                                >
                                    Allowed *.jpeg, *.jpg, *.png,<br /> max size of 3.1 MB
                                </Typography>
                            </StyledImageUploadWrapper>
                            <Grid item sx={{ my: 1.5 }} container spacing={1}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >

                               </Grid>

                            {/* </Grid> */}
                        </Grid>
                                <Stack
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    sx={{ mt: 2 }}
                                >
                                   
                                    <Button
                                        onClick={() => {
                                            navigate("/forget");
                                        }}
                                        sx={{ textTransform: "capitalize" }}
                                    >
                                       
                                    </Button>
                                </Stack>
                                <StyledLogin
                                    sx={{ mt: 3 ,marginTop:"0px"}}
                                    type="submit"
                                >
                                    {t("Next")}
                                </StyledLogin>
                               
                                  
                               
                            </form>
                        </Container>
                    </StyledRightSide>
                </Grid>
            </Grid>
            <Notification notify={notify} setNotify={setNotify} />
        </div>

)

                         }
export default ForgetPassword;