import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Card } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import logo from "../../assets/logo.png";
import { useTranslation } from "react-i18next";

import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TableContainer,
} from "@mui/material";

// import "../../../productPage/product"
import { useState, useEffect } from "react";
import { Grid, Button, Stack, Typography, IconButton } from "@mui/material";
// import link
import { Link } from "react-router-dom";
// import avtar
import Avatar from "@mui/material/Avatar";
import avtarimage from "../../assets/Rectangle752.png";
import axiosInstance from "../../utils/AxiosInstace";
import { useLocation } from "react-router-dom";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import { tableCellClasses } from "@mui/material/TableCell";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { textAlign } from "@mui/system";
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const strong = {
  color: "black",
};

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));
const StyledRequired = styled("span")({
  color: "rgba(240, 98, 55, 1)",
  marginLeft: "2px",
  fontSize: "16px",
});
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(224, 224, 224, 1)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    height: "30px",
  },
}));

const orderStatus = (status) => {
  const statusColors = {
    0: {
      key: "Draft",
      backgroundColor: "#ede7dd",
      color: "#fc7e00",
    },
    1: {
      key: "Confirmed",
      backgroundColor: "#def7ec",
      color: "#0e9f6e",
    },
    2: {
      key: "Closed",
      backgroundColor: "rgba(240, 68, 56, 0.05)",
      color: "#f05252;",
    },
  };

  return (
    <StyledChip
      style={{
        width: "81%",
        height: "23px",
        fontSize: "12px",
      }}
      sx={statusColors[status]}
      label={statusColors[status].key}
    />
  );
};
const productStatus = (productstatus) => {
  const statusColors = {
    0: {
      key: "Closed",
      backgroundColor: "rgba(240, 68, 56, 0.05)",
      color: "#f05252;",
    },
    1: {
      key: "Partial",
      backgroundColor: "#ede7dd",
      color: "#fc7e00",
    },
    2: {
      key: "Pending",
      backgroundColor: "#fdf6b2",
      color: "#c27803",
    },
    3: {
      key: "Paid",
      backgroundColor: "#def7ec",
      color: "#0e9f6e",
    },
  };

  return (
    <StyledChip
      style={{
        width: "73%",
        height: "23px",
        fontSize: "12px",
      }}
      sx={statusColors[productstatus]}
      label={statusColors[productstatus].key}
    />
  );
};

export default function ViewInvoice() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { t } = useTranslation();

  const pdfExportComponent = React.useRef(null);

  const [deleteUser, setDeleteUser] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [custdata, setCustData] = useState({});
  const [productdata, setProductData] = useState([]);
  const [productdetails, setProductDetails] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [addressdata, setAddressData] = useState({});
  const [billingaddressdata, setBillingAddressData] = useState({});
  const [orderData, setorderData] = useState([]);
  let { id } = useParams();
  const paymentStatus = (paymentstatus) => {
    const statusColors = {
      // 0: {
      //     key: "Closed",
      //     backgroundColor: "rgba(240, 68, 56, 0.05)",
      //     color: "#f05252;",
      // },
      1: {
        key: "Partial",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      2: {
        key: "Pending",
        backgroundColor: "#fdf6b2",
        color: "#c27803",
      },
      3: {
        key: "Paid",
        backgroundColor: "#def7ec",
        color: "#0e9f6e",
      },
    };

    return (
      <StyledChip
        style={{
          width: "91%",
          height: "23px",
          fontSize: "12px",
        }}
        sx={statusColors[paymentstatus]}
        label={statusColors[paymentstatus].key}
      />
    );
  };

  const GetCustomerInvoice = async () => {
    await axiosInstance

      .post(`api/v1/salesorderinvoice/${state}`)
      .then((res) => {
        setInvoice(res.data.data);
        let customer_data = res.data.data.customer;

        let product_data = res.data.data.products;
        console.log("222222.", product_data[0].productName);
        setProductData(product_data);
        console.log(".......", customer_data);
        setCustData(customer_data);
        let address_data = res.data.data.customer.address;
        setAddressData(address_data);
        let bil_address_data = res.data.data.customer.billingAddress;
        setBillingAddressData(bil_address_data);
      })
      .catch((e) => {
        console.log("error found");
      });
  };
  const GetSaleOrder = async () => {
    await axiosInstance
      .get(`/api/v1/salesorderdetail/${state}`)
      .then((res) => {
        console.log(res.data.data);
        let quant = res.data.data.products[0];
        setQuantity(quant);
        setorderData(res.data.data);
      })
      .catch((e) => {
        console.log("error found");
      });
  };
  let productDetails = async (product) => {
    await axiosInstance
      .get(`/api/v1/salesorderdetail/${state}`)
      .then((res) => {
        // if (res.data.status == "success") {

        res.data.data.productQuantity = 1;
        // setProductdata([])
        // setProductdata((prev) => [...prev, res.data.data])
        const responseProductData = res.data.data;
        setProductDetails((prev) => [...prev, res.data.data]);
      })
      .catch((e) => {
        console.log("error found");
      });
  };

  useEffect(() => {
    GetCustomerInvoice();
    GetSaleOrder();
    // productDetails();
  }, []);
  const downloadPdf = async () => {
    if (pdfExportComponent.current) {
      // navigate(-1);
      //how to change download pdf file name
      await pdfExportComponent.current.save(
        "Invoice.pdf" + ".pdf" + "?download=true"
      );
    }
  };
  const handleDeleteUser = () => {
    setDeleteUser(true);
  };

  const closeDeleteUser = () => {
    setDeleteUser(false);
  };
  return (
    <>
      <div id="invoice-container">
        <div>
          <h1 id="headder-left-side-first"> {t("Invoice")} </h1>
          <p id="headder-left-side-second">
            Order |{" "}
            <span style={{ color: "black", fontSize: "16px" }}>
              {t("View_Invoice")}
            </span>
          </p>
        </div>
        <Box marginTop="20px" borderRadius="12px">
          <Button
            variant="contained"
            onClick={() => {
              downloadPdf();
            }}
            size="large"
            id="download-invoice-button"
          >
            <strong
              style={{ color: "white", fontSize: "13px", fontWeight: "500" }}
            >
              {t("Download_Invoice")}
            </strong>
          </Button>
        </Box>
      </div>

      <PDFExport ref={pdfExportComponent} paperSize="A4" scale={0.7}>
        <Card
          style={{
            padding: "20px",
            borderRadius: "12px",
          }}
        >
          <Grid item md={12} sm={8} xs={12} container spacing={1}>
            <Grid item md={6} xs={12}>
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h6" id="invoive-headder">
                  {t("Invoice")}
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  variant="h6"
                  id="invoive-headder-second"
                  style={{ width: "100px" }}
                >
                  {t("Status")}
                  <StyledRequired id="invoive-headder-second-panding">
                    {t("pending")}
                  </StyledRequired>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
              style={{
                textAlign: "right",
              }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <img src={logo} alt="logo" id="invoice-headder-image" />
              </Grid>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                style={{
                  marginTop: "20px",
                }}
              >
                <Typography variant="h6" id="invoive-headder-second">
                  1901 Thornridge Cir. Shiloh, Hawaii 81063
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            md={12}
            sm={8}
            xs={12}
            container
            spacing={1}
            style={{
              marginTop: "20px",
            }}
          >
            <Grid item md={3} xs={12}>
              <Typography variant="h6" id="invoice-date">
                {t("Date")}{" "}
              </Typography>
              <Typography variant="h6" id="invoice-date-value">
                {invoice.invoiceDate}
              </Typography>
            </Grid>
            <Grid item md={4.5} xs={12}>
              <Typography variant="h6" id="invoice-number">
                {t("Invoice_Number")}{" "}
              </Typography>
              <Typography variant="h6" id="invoice-number-value">
                {invoice.invoiceNo}
              </Typography>
            </Grid>
            <Grid item md={4.5} xs={12}>
              <Typography variant="h6" id="invoice-to">
                {t("Invoice_TO")}{" "}
              </Typography>
              <Typography variant="h6" id="invoice-to-name">
                {" "}
                {addressdata.address +
                  ", " +
                  addressdata.countryName +
                  ", " +
                  addressdata.stateName +
                  ", " +
                  addressdata.city +
                  ", " +
                  addressdata.zipCode}{" "}
              </Typography>
              <Typography
                variant="h6"
                id="invoice-to-address"
                style={{ color: "black" }}
              >
                {billingaddressdata.address +
                  ", " +
                  billingaddressdata.countryName +
                  ", " +
                  billingaddressdata.stateName +
                  ", " +
                  billingaddressdata.city +
                  ", " +
                  billingaddressdata.zipCode}
              </Typography>
            </Grid>
          </Grid>

          <TableContainer id="table-container">
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ textAlign: "center", fontSize: "12px" }}
                  >
                    {" "}
                    {t("PRODUCT_NAME")}
                  </StyledTableCell>

                  <StyledTableCell>{t("QUANTITY")}</StyledTableCell>
                  <StyledTableCell>{t("RATE")}</StyledTableCell>
                  {/* <StyledTableCell>ORDER STATUS</StyledTableCell> */}
                  {/* <StyledTableCell>PAYMENT STATUS</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody style={{ color: "black" }}>
                {productdata.map((row, index) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.productName}
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      {row.quantity}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.price}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <div
              style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}
            ></div>
            <FlexBox px={2} id="purchase-order-invoice-footer">
              <Box display="flex">
                <Box pr={6}>
                  <Typography
                    id="purchase-order-details-footer-subtotal"
                    sx={{ my: 2 }}
                  >
                    {t("Subtotal:")}
                  </Typography>
                  <Typography
                    id="purchase-order-details-footer-tax"
                    sx={{ mb: 2 }}
                  >
                    {t("TAX")}
                  </Typography>
                  <Typography
                    id="purchase-order-details-footer-shipping"
                    sx={{ mb: 2 }}
                  >
                    {t("Discount")}
                  </Typography>
                </Box>
                <div
                  style={{
                    marginLeft: "21px",
                  }}
                >
                  <Typography
                    id="purchase-order-details-footer-subtotal-value"
                    sx={{ my: 2 }}
                  >
                    <b>{invoice.subtotal}</b>
                  </Typography>
                  <Typography
                    id="purchase-order-details-footer-tax-value"
                    sx={{ mb: 2 }}
                  >
                    GST {invoice.taxValue}
                  </Typography>
                  <Typography
                    id="purchase-order-details-footer-shipping-value"
                    sx={{ mb: 2 }}
                  >
                    {" "}
                    $08.00
                  </Typography>
                </div>
              </Box>
            </FlexBox>

            <div
              style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}
            ></div>

            <FlexBox px={2} id="purchase-order-invoice-footer">
              <Box display="flex">
                <Box pr={6}>
                  <Typography sx={{ mb: 2 }}>{t("Total")}</Typography>
                </Box>
                <div id="purchase-order-details-footer-total-value">
                  <Typography sx={{ mb: 2 }}>
                    <b>{invoice.payableAmount}</b>
                  </Typography>
                </div>
              </Box>
            </FlexBox>
          </TableContainer>
        </Card>
      </PDFExport>
    </>
  );
}
