
import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
// import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
// import "../../../productPage/product"
import { Card } from '@mui/material';
import { useState } from 'react';
// import link 
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TextField, FormLabel, MenuItem, Input, Tooltip, Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import { borderRadius } from '@mui/system';

import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect, } from 'react';
import { useTranslation } from 'react-i18next';






const strong = {
  color: 'black',
}







const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));

const initialValues = {
  supplierName: "",
  companyName: "",
  email: "",
  phoneNumber: "",
  mobileNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  accountName: "",
  accountNumber: "",
  sortCode: "",
  ifscCode: ""
};

export default function CustomizedInputs(props) {
  const navigate = useNavigate();
  let { id } = useParams();
  const { t }=useTranslation();
  const button = id ? "Save" : "Add Supplier"
  const headder = id ? "Edit supplier" : "Add new supplier"


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    console.log("-----------------------------------------------")

    // if ("mobile" in fieldValues) {
    //   if (fieldValues.mobile !== "") {
    //     if (/^[0-9\s]*$/.test(fieldValues.mobile)) {
    //       temp.mobile = "";
    //     } else {
    //       temp.mobile = "Only Number allow";
    //     }
    //   } else {
    //     temp.mobile = "This field is required.";
    //   }
    // }

    if ("supplierName" in fieldValues)
      temp.supplierName = fieldValues.supplierName ? "" : "This field is required.";
    if ("companyName" in fieldValues)
      temp.companyName = fieldValues.companyName ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "This field is required.";
    if ("phoneNumber" in fieldValues)
      temp.phoneNumber = fieldValues.phoneNumber ? "" : "This field is required.";
    if ("mobileNumber" in fieldValues)
      temp.mobileNumber = fieldValues.mobileNumber ? "" : "This field is required.";
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";
    if ("accountName" in fieldValues)
      temp.accountName = fieldValues.accountName ? "" : "This field is required.";
    if ("sortCode" in fieldValues)
      temp.sortCode = fieldValues.sortCode ? "" : "This field is required.";
    if ("accountNumber" in fieldValues)
      temp.accountNumber = fieldValues.accountNumber ? "" : "This field is required.";
    if ("ifscCode" in fieldValues)
      temp.ifscCode = fieldValues.ifscCode ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (id) {
        await axiosInstance
          .patch(`api/v1/supplierupdate/${id}`, values).then((res) => {
            console.log("------------------------------update", res.data.status)

            if (res.data.status == true) {
              navigate('/supplier');
            }
          }).catch((e) => {
            console.log("error found")
          })
      }
      else {
        await axiosInstance
          .post(`api/v1/supplieradd`, values).then((res) => {
            console.log("------------------------------add", res.data)
            if (res.data.status == true) {
              navigate('/supplier');

            }
          }).catch((e) => {
            console.log("error found")
          })
      }



      // } else {
      //   console.log("values else --------------------------", values);
      // }
    };
  }

  const GetSupplier = async () => {

    await axiosInstance
      .get(`api/v1/supplierdetails/${id}`).then((res) => {
        setValues(res.data.data)
        // console.log("(((((((((((((((((((((((((((((((((((((((((((", res.data.data.id)
      }).catch((e) => {
        console.log("error found")
      })

  }

  useEffect(() => {
    GetSupplier()
  }, [])


  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h1 style={{
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px",
            marginBottom: "4px",
          }}> Edit Sales Order</h1>

          <p style={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "34px",
            marginTop: "0px",
          }} >Order  |  <span style={{ color: "rgb(166 166 166)" }}> {t("Edit_Sales_Order_Details")}</span></p>
        </div>
        <Box

          marginTop="20px"
          borderRadius="12px"
        >
          <Button variant="outlined" size="large"
          onClick={() => {
            navigate('/order')
          }
          }

            style=
            {{
              border: '1px solid  #282822',
              borderRadius: '10px',
              fontSize: 16,
              color: 'black',
              marginRight: '15px',
              transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            }}
          >
            <strong style={{ color: "black" }}>{this("Cancle")}</strong>
          </Button>

          <Link to={`/supplier/add/${id}`}
            style={{
              textDecoration: 'none',
            }}
          >
            <Button variant="contained" size="large"
              style={{
                backgroundColor: '#f06237',
                borderRadius: '10px',
              }}
            >
              <strong style={{ color: "white" }}>{t("Save")}</strong>
            </Button>
          </Link>
        </Box>

      </div>


      <Form onSubmit={handleSubmit} >

        <Box
          style={{
            backgroundColor: "white",
            borderRadius: "12px",
            padding: "10px"
          }}
        >

          <Grid item md={12} sm={8} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productGroup">
                {t("Customer_Name")}
                {/* {Boolean(errors.productcategory) && */}
                {/* } */}
              </StyledFormLabel>
              <StyledTextField
                name={t("productGroup")}
                fullWidth
                select
                value={values.productGroup}
                onChange={handleInputChange}
                error={Boolean(errors.productGroup)}
                helperText={errors.productGroup}
              >
                <MenuItem key="admin" value="admin">
                  {t("admin")}
                </MenuItem>
                <MenuItem key="customer" value="customer">
                  {t("customer")}
                </MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productName">
                {t("Product_Name")}
              </StyledFormLabel>
              <StyledTextField
                name={t("productName")}
                fullWidth
                select
                value={values.productGroup}
                onChange={handleInputChange}
                error={Boolean(errors.productGroup)}
                helperText={errors.productGroup}
              >
                <MenuItem key="admin" value="admin">
                  {t("admin")}
                </MenuItem>
                <MenuItem key="customer" value="customer">
                  {t("customer")}
                </MenuItem>
              </StyledTextField>
            </Grid>
         
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="phone_number">
              {t("Rate")}


              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="productDescription"
                value={values.productDescription}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="phone_number">
                 {t("Quantity")}


              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("productDescription")}
                value={values.productDescription}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="phone_number">
              {("Discount")}


              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("productDescription")}
                value={values.productDescription}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="phone_number">
              {t("Amount")}


              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("productDescription")}
                value={values.productDescription}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              />
            </Grid>
           
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="phone_number">
              {t("Tax")}


              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("productDescription")}
                value={values.productDescription}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              />
            </Grid>
             <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="phone_number">
              {t("Subtotal")}


              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={("productDescription")}
                value={values.productDescription}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="phone_number">
              {t("Shipping_Charges")}


              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={("productDescription")}
                value={values.productDescription}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="phone_number">
              {t("Total")}


              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={("productDescription")}
                value={values.productDescription}
                onChange={handleInputChange}
                error={Boolean(errors.productDescription)}
                helperText={errors.productDescription}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productGroup">
              {t("Order_Place_Via")}

             
              </StyledFormLabel>
              <StyledTextField
                name={("productGroup")}
                fullWidth
                select
                value={values.productGroup}
                onChange={handleInputChange}
                error={Boolean(errors.productGroup)}
                helperText={errors.productGroup}
              >
                <MenuItem key="admin" value="admin">
                  {t("admin")}
                </MenuItem>
                <MenuItem key="customer" value="customer">
                  {t("customer")}
                </MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productGroup">
              {t("Order_Status")}

             
              </StyledFormLabel>
              <StyledTextField
                name='{t("productGroup")}'
                fullWidth
                select
                value={values.productGroup}
                onChange={handleInputChange}
                error={Boolean(errors.productGroup)}
                helperText={errors.productGroup}
              >
                <MenuItem key="admin" value="admin">
                  return
                </MenuItem>
                <MenuItem key="customer" value="customer">
                  t{("delivered")}
                </MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="productGroup">
              {("Payment_Status")}

             
              </StyledFormLabel>
              <StyledTextField
                name={("productGroup")}
                fullWidth
                select
                value={values.productGroup}
                onChange={handleInputChange}
                error={Boolean(errors.productGroup)}
                helperText={errors.productGroup}
              >
                <MenuItem key="admin" value="admin">
                  {t("paid")}
                </MenuItem>
                <MenuItem key="customer" value="customer">
                  {t("unpaid")}
                </MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item sx={{ m: 1, marginBottom:"100px" }} md={12} xs={12}>
                            <StyledFormLabel htmlFor="productDescription">
                                {t("Product_Description")}

                                {/* {Boolean(errors.mobile) && ( */}

                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                multiline
                                rows={2}
                                name={("productDescription")}
                                value={values.productDescription}
                                onChange={handleInputChange}
                                error={Boolean(errors.productDescription)}
                                helperText={errors.productDescription}
                            />
                        </Grid>



          </Grid>

        </Box>

      </Form>

    </>

  );
}

