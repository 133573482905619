import axios from "axios";
import axiosInstance from "../../utils/AxiosInstace";
import {
  GET_CUSTOMERS,
  GET_CUSTOMER,
} from "../action-types/customerActionTypes";

export const getCustomers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/customerdropdown");
    if (res && res.data && res.data.status) {
      dispatch({
        type: GET_CUSTOMERS,
        payload: res.data && res.data && res.data.data,
      });
    }

    return res.data.data;
  } catch (err) {
    return err;
  }
};

//add customer

export const addCustomer = async (data) => {
  try {
    const res = await axiosInstance.post("/api/v1/customeradd", data);
    console.log("res", res.data);

    if (res && res.data && res.data.status === true) {
      console.log("success");
      return res.data;
    } else {
      console.log("error");

      return res.data;
    }
  } catch (err) {
    console.log("object");
    return err;
  }
};

//customerr details

export const getCustomerDetails = async (id) => {
  try {
    const res = await axiosInstance.get(`api/v1/customerdetails/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//get country

export const getCountry = async () => {
  try {
    const res = await axiosInstance.get("/api/v1/allcountry");
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

export const getStateList = async (id) => {
  try {
    const res = await axiosInstance.get(`/api/v1/allstate/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

export const getBillingStateList = async (id) => {
  try {
    const res = await axiosInstance.get(`/api/v1/allstate/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

export const updateCustomer = async (data, id) => {
  try {
    const res = await axiosInstance.patch(`/api/v1/customerupdate/${id}`, data);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//get all customer

export const getAllCustomerList = async (page, string, search1) => {
  try {
    const res = await axiosInstance.get(
      `api/v1/customerSearch?limit=10&page=${page}${string}&search=${search1}`
    );
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};
