import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ReactFileReader from "react-file-reader";
import { CSVLink, CSVDownload } from "react-csv";
import {
  getExportLeadData,
  getImportLeadData,
} from "../../state/action/leadAction";
import { useForm } from "../../utils/useForms";

const initialValues = {
  leadCSVFile: "",
};

const data = [{ firstname: "", lastname: "", email: "" }];

const UploadButton = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true);

  const [anchorEls, setAnchorEls] = React.useState(null);
  const opens = Boolean(anchorEls);
  const handleClicks = (event) => {
    setAnchorEls(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEls(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getExportLeadListData = async () => {
    const res = await getExportLeadData();
    if (res.status === true) {
    }
  };

  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("leadCSVFile", e.target.files[0]);
    const res = await getImportLeadData(formData);
    console.log("res", res);
    if (res && res.status === true) {
      props.getleadList();
    } else {
    }
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  return (
    <Box>
      <Button
        sx={{
          height: "34px",
          backgroundColor: "#F06237",
          color: "white",
          borderRadius: "10px",
          padding: "5px 25px",
          "&:hover": {
            backgroundColor: "#cf5025",
          },
          marginLeft: "-162px;",
        }}
        onClick={handleClicks}
        endIcon={<ArrowDropDownIcon />}
      >
        <span style={{ fontSize: "14px" }}>{t("Import CSV")}</span>
      </Button>

      <Button
        sx={{
          height: "34px",
          backgroundColor: "#F06237",
          color: "white",
          borderRadius: "10px",
          padding: "5px 25px",
          "&:hover": {
            backgroundColor: "#cf5025",
          },
          marginLeft: "22px",
        }}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        <span style={{ fontSize: "14px" }}>{t("Export CSV")}</span>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          width: "100%",
          height: "12%",
          marginTop: "10px",
          marginLeft: "5px",
        }}
      >
        <CSVLink
          data={data}
          // target="_blank"
          style={{ textDecorationLine: "none" }}
        >
          <Button sx={{ color: "black", marginTop: "-2px" }}>
            {" "}
            {t("Demo_CSV")}
          </Button>
        </CSVLink>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorEls}
        open={opens}
        onClose={handleCloses}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          width: "100%",
          height: "12%",
          marginTop: "10px",
          marginLeft: "5px",
        }}
      >
        <input
          type={"file"}
          accept={".csv"}
          onChange={handleUploadFile}
        ></input>
      </Menu>
    </Box>
  );
};

export default UploadButton;
