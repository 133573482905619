import React from "react";
import { Box } from "@mui/material";
import AccountList from "../Account/AccountList";

const Account = () => {
  return (
    <Box>
      <AccountList />
    </Box>
  );
};

export default Account;
