import axiosInstance from "../../utils/AxiosInstace";

// add campaginemail

export const AddCampagin = async (user) => {
  try {
    const response = await axiosInstance.post(`/api/v1/addBulkEmail`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// list campaginemail

export const getCampaginList = async (queryString) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/getBulkEmailList` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//dropdown

export const getLeadContactList = async (user) => {
  try {
    const response = await axiosInstance.post(
      `/api/v1/getLeadAndContact`,
      user
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
