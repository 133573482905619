import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../customerPage/style/customerlist.css";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import filterIcon from "../../assets/filter.svg";
// import UserDeleteModal from "./customerDelete";
import axiosInstance from "../../utils/AxiosInstace";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useForm, Form } from "../../utils/useForms";
import { tableCellClasses } from "@mui/material/TableCell";
import { getAllCustomerList } from "../../state/action/customerAction";
import { useTranslation } from "react-i18next";
import { getLeadList } from "../../state/action/leadAction";
import { getCampaginList } from "../../state/action/campaginAction";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const validate = (values) => {
  const errors = {};
};
const initialValues = {};

const row = [
  {
    id: 1,
    type: "contact",
    count: "3",
  },
];

const CampaginList = (props) => {
  const { t } = useTranslation();

  const [allUsers, setAllUsers] = useState([]);
  const [campaginList, setCampaginList] = useState([]);
  const [deleteUser, setDeleteUser] = useState(false);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [user, setUser] = useState([]);
  const [search1, setsearch1] = useState("");
  const [records, setRecords] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const [filterData, setFilterData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  //lead List
  const getcampaginLists = async (e) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&limit=${rowsPerPage}&search=${search1}${string}`;

    const res = await getCampaginList(queryString);
    if (res.status === true) {
      setCampaginList(res.data);
      setRecords(res.totlaEmails);
      setTotalPages(Math.ceil(res.totlaEmails / rowsPerPage));
      setFilter(false);
    }
  };

  useEffect(() => {
    getcampaginLists();
  }, [page, search1, filterData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const navigate = useNavigate();

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };

  const LeadStatus = (status) => {
    const statusColors = {
      1: {
        key: "Hot",
        color: "black",
        backgroundColor: "white",
      },
      2: {
        key: "Cold",
        color: "black",
        backgroundColor: "white",
      },
      3: {
        key: "Natural",
        color: "black",
        backgroundColor: "white",
      },
    };
    return (
      <StyledChip
        style={{
          width: "85%",
          height: "23px",
          fontSize: "12px",
        }}
        sx={statusColors[status]}
        label={statusColors[status]?.key}
      />
    );
  };

  return (
    <>
      <div>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              fontWeight: "600",
              paddingLeft: "10px",
            }}
          >
            {t("Campagin")}
          </Typography>

          <Box marginTop="20px" borderRadius="12px">
            <Button
              sx={{
                height: "34px",
                backgroundColor: "#F06237",
                color: "white",
                borderRadius: "10px",
                padding: "5px 25px",
                "&:hover": {
                  backgroundColor: "#cf5025",
                },
              }}
              startIcon={<Add />}
              color="primary"
              onClick={() => {
                navigate("/addCampagin");
              }}
            >
              <span style={{ fontSize: "14px" }}>{t("Create_Campagin")}</span>
            </Button>
          </Box>
        </Stack>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "30px",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Form>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{
                  height: "30px",
                }}
                placeholder={t("Search…")}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => {
                  setsearch1(e.target.value);
                  setPage(1);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Search>
          </Form>
        </Stack>
        <TableContainer component={Paper} sx={{ height: "auto" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead style={{ fontSize: "14px" }}>
              <TableRow>
                <StyledTableCell>{t("Type")}</StyledTableCell>
                <StyledTableCell> {t("Count")}</StyledTableCell>
                <StyledTableCell>{t("Date")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaginList.length == 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    {t("No_records_to_display")}
                  </TableCell>
                </TableRow>
              ) : (
                campaginList.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        cursor: "pointer",
                      },
                    }}
                  >
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.type}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.count}
                    </StyledTableCell>
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {moment(row.createdAt).format("MM-DD-YYYY LT")}
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {" "}
          Showing {page * rowsPerPage > records
            ? records
            : page * rowsPerPage}{" "}
          of {records} Results
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default CampaginList;
