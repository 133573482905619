import axiosInstance from "../../utils/AxiosInstace";

export const getPagePermissionList = async (body) => {
  try {
    const res = await axiosInstance.post(`/api/v1/pagePermissionList`, body);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const getPageList = async () => {
  try {
    const res = await axiosInstance.get(`/api/v1/pageList`);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const addPermissionRole = async (body) => {
  try {
    const res = await axiosInstance.post(`/api/v1/addPermission`, body);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

///role list

export const getAllRoles = async (queryString) => {
  try {
    const res = await axiosInstance.get(`/api/v1/getRoleList` + queryString);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

//update role
export const updateRoleStatus = async (id, body) => {
  try {
    const res = await axiosInstance.post(`/api/v1/updateRole/${id}`, body);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

//add role

export const createRole = async (data) => {
  try {
    const res = await axiosInstance.post(`/api/v1/addRole`, data);

    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
