import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "../productPage/productList";
import { useState } from "react";
import "../customerPage/style/addCustomer.css";
import { Link, useNavigate } from "react-router-dom";
import { TextField, FormLabel, Grid, Button, Typography } from "@mui/material";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getCustomerDetails,
  getCountry,
  getStateList,
  addCustomer,
  updateCustomer,
} from "../../state/action/customerAction";

import {
  addaccount,
  AccountTypedropdown,
  AccountOwnerDropdown,
  // getAccountDetails,
  updateAcount,
} from "../../state/action/accountaction";
import { getAccountDetails } from "../../state/action/accountaction";
import { leadOwnerDropdown } from "../../state/action/leadAction";

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  accountName: "",
  accountNumber: "",
  accountType: "",
  annualRevenue: "",
  email: "",
  phone: "",
  website: "",
  billingStreet: "",
  billingCity: "",
  billingState: "",
  billingCountry: "",
  billingPostalCode: "",
  shippingStreet: "",
  shippingCity: "",
  shippingState: "",
  shippingCountry: "",
  shippingPostalCode: "",
  description: "",
  ownerId: "",
  status: "",
  createAt: Date.now(),
};

const leadType = [
  {
    id: 1,
    name: "xyz",
  },
];
const leadstatus = [
  {
    id: 1,
    leadStatuss: "hot",
  },
  {
    id: 2,
    leadStatuss: "cold",
  },
  {
    id: 3,
    leadStatuss: "natural",
  },
];

const AddAccount = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [countrylist, setCountrylist] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [edit, setIsEdit] = useState(false);

  const [AccountType, setAccountTypedropdown] = useState();
  const [leadOwner, setLeadOwner] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = async (e) => {
    const res = await getCountry();
    if (res.status === true) {
      setCountrylist(res.data);
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("accountName" in fieldValues)
      temp.accountName = fieldValues.accountName
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      accountType: values.accountType,
      annualRevenue: values.annualRevenue,
      email: values.email,
      phone: values.phone,
      website: values.website,
      billingStreet: values.billingStreet,
      billingCity: values.billingCity,
      billingState: values.billingState,
      billingCountry: values.billingCountry,
      billingPostalCode: values.billingPostalCode,
      shippingStreet: values.shippingStreet,
      shippingCity: values.shippingCity,
      shippingState: values.shippingState,
      shippingCountry: values.shippingCountry,
      shippingPostalCode: values.shippingPostalCode,
      description: values.description,
      ownerId: values.ownerId,
      status: values.status,
      createdBy: "1",
    };
    if (edit) {
      const res = await updateAcount(state.account, payload);
      if (res && res.status === true) {
        setNotify({
          isOpen: true,
          message: res.message,
          type: "success",
        });
        setTimeout(() => {
          navigate("/account");
        }, 1000);
      } else {
        setNotify({
          isOpen: true,
          message: res.message,
          type: "warning",
        });
      }
    } else {
      const res = await addaccount(payload);
      if (res && res.status === true) {
        setNotify({
          isOpen: true,
          message: res.message,
          type: "success",
        });
        setTimeout(() => {
          navigate("/account");
        }, 1000);
      } else {
        setNotify({
          isOpen: true,
          message: res.message,
          type: "warning",
        });
      }
    }
  };

  useEffect(() => {
    if (state) {
      getDetails(state.account);
      setIsEdit(true);
    }
  }, [state]);

  const getDetails = async () => {
    const res = await getAccountDetails(state.account);

    if (res.status === true) {
      setValues({
        accountName: res.data.accountName,
        accountNumber: res.data.accountNumber,
        accountType: res.data.accountType,
        annualRevenue: res.data.annualRevenue,
        email: res.data.email,
        phone: res.data.phone,
        website: res.data.website,
        billingStreet: res.data.billingStreet,
        billingCity: res.data.billingCity,
        billingState: res.data.billingState,
        billingCountry: res.data.billingCountry,
        billingPostalCode: res.data.billingPostalCode,
        shippingStreet: res.data.shippingStreet,
        shippingCity: res.data.shippingCity,
        shippingState: res.data.shippingState,
        shippingCountry: res.data.shippingCountry,
        shippingPostalCode: res.data.shippingPostalCode,
        description: res.data.description,
        ownerId: res.data.ownerId.id,
        status: res.data.status,
        createdBy: "1",
      });
    }
  };

  //account type

  const getAccountTypeList = async (e) => {
    const res = await AccountTypedropdown();
    if (res.status === true) {
      setAccountTypedropdown(res.data);
    }
  };
  useEffect(() => {
    getAccountTypeList();
  }, []);

  // account owner
  const getLeadOwnerList = async (e) => {
    const res = await leadOwnerDropdown();
    if (res.status === true) {
      setLeadOwner(res.data);
    }
  };

  useEffect(() => {
    getLeadOwnerList();
  }, []);

  return (
    <>
      <div className="purchaseOrderheadder">
        <div>
          <h1 className="purchaseOrderheadder-left">
            {" "}
            {edit ? t("Edit_Account") : t("Add_Account")}
          </h1>
          <p
            className="purchaseOrderheadder-right"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/account");
            }}
          >
            {" "}
            {t("Account")} |
            <span
              className="purchaseOrderheadder-right-span"
              style={{ color: "black" }}
            >
              {" "}
              {edit ? t("Edit_Account_Details") : t("Add_Account_Details")}
            </span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Box className="b1">
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Typography>{t("AccountInformation")}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="ownerId">
                {t("Lead_owner")}
              </StyledFormLabel>
              <select
                id="ownerId"
                name="ownerId"
                fullWidth
                select
                value={values.ownerId}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Lead_Owner")}</option>

                {leadOwner.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="accountName">
                {t("Account_name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="accountName"
                value={values.accountName}
                error={errors.accountName}
                helperText={errors.accountName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="accountType">
                {t("accountType")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="accountType"
                value={values.accountType}
                onChange={handleInputChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="annualRevenue">
                {t("Annual_Revenue")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="annualRevenue"
                value={values.annualRevenue}
                onChange={handleInputChange}
              />
            </Grid>{" "}
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="phone">{t("Phone")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name="phone"
                value={values.phone}
                onChange={handleInputChange}
              />
            </Grid>{" "}
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="website">
                {t("Website")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="website"
                value={values.website}
                onChange={handleInputChange}
              />
            </Grid>{" "}
          </Grid>

          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <hr />
              <Typography>{t("addressInformation")}</Typography>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="billingStreet">
                {t("Billing_Street")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="billingStreet"
                value={values.billingStreet}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="shippingStreet">
                {t("Shipping_Street")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="shippingStreet"
                value={values.shippingStreet}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="billingCity">
                {t("Billing_City")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="billingCity"
                value={values.billingCity}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="shippingCity">
                {t("Shipping_City")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="shippingCity"
                value={values.shippingCity}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="billingState">
                {t("Billing_State")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="billingState"
                value={values.billingState}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="shippingState">
                {t("Shipping_State")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="shippingState"
                value={values.shippingState}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="billingCountry">
                {t("Billing_Country")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="billingCountry"
                value={values.billingCountry}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="shippingCountry">
                {t("Shipping_Country")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="shippingCountry"
                value={values.shippingCountry}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="billingPostalCode">
                {t("Billing_PostalCode")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="billingPostalCode"
                value={values.billingPostalCode}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="shippingPostalCode">
                {t("Shipping_PostalCode")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="shippingPostalCode"
                value={values.shippingPostalCode}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <hr />
              <Typography>{t("description")}</Typography>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="description">
                {t("account_description")}
              </StyledFormLabel>
              <StyledTextFields
                id="outlined-multiline-flexible"
                multiline
                fullWidth
                maxRows={3}
                name="description"
                value={values.description}
                onChange={handleInputChange}
              />
            </Grid>{" "}
          </Grid>
          <Box className="customerBox">
            <div>
              <Button
                variant="outlined"
                size="large"
                className="buttonCancel"
                onClick={() => {
                  navigate("/account");
                }}
                style={{
                  borderRadius: "10px",
                  width: "40%",
                  height: "94%",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Save")}
                </strong>
              </Button>
            </div>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};
export default AddAccount;
