import React from "react"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const options = {
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: false,

    scales: {
        x: {
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
                drawOnChartArea: false,
            },
        },
        // y1: {
        //   type: 'linear',
        //   display: true,
        //   position: 'right',
        //   grid: {
        //     drawOnChartArea: false,
        //   },
        // },
    },
};


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
    labels,
    datasets: [
        {
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgba(240, 98, 55, 1)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            yAxisID: 'y',
            tension: 0.5,
        },
    ],
};
export default function Bar() {
    return (
        <>
            <Line options={options} data={data} width='500px' height='250px' />
        </>
    )
}
