import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Card } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

// import "../../../productPage/product"
import { useState, useEffect } from 'react';
import { Grid, Button, Stack, Typography, IconButton } from '@mui/material';
// import link 
import { Link } from 'react-router-dom';
// import avtar
import Avatar from '@mui/material/Avatar';
// import avtarimage from "../../assets/Rectangle752.png";
import axiosInstance from "../../utils/AxiosInstace";
import { Routes, Route, useParams ,useNavigate,useLocation} from 'react-router-dom';
import product_image from "../../assets/Rectangle753.png";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Chip,


} from "@mui/material";



const strong = {
    color: 'black',
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: "#0F0F0F80",
}));
const FlexBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
  }))


const StyledChip = styled(Chip)(() => ({
    borderRadius: "10px",
    fontSize: "14px",
}));


export default function FormPropsTextFields() {
    const [deleteUser, setDeleteUser] = useState(false);
    const [supplierdata, setSupplierdata] = useState({});
    const [allUsers, setAllUsers] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { t }=useTranslation();
 
    let { id } = useParams();

    const GetSupplier = async () => {
        await axiosInstance
            .get(`api/v1/supplierdetails/${id}`).then((res) => {
                setSupplierdata(res.data.data)
                // setAllUsers(res.data.data)



            }).catch((e) => {
                console.log("error found")
            })
    }
    useEffect(() => {

        GetSupplier();
    }, [])
    const handleDeleteUser = () => {
        setDeleteUser(true);
    };
    const closeDeleteUser = () => {
        setDeleteUser(false);
        //  also close menu
        // handleClose();
    };
    return (

        < >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <h1 style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "24px",
                        marginBottom: "8px",
                    }}> Order </h1>
                </div>
                {/* <Box borderRadius="12px"
                >
                    <Link to={`/order/invoice/${id}`}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        <Button variant="contained" size="large"
                            style={{
                                backgroundColor: '#f06237',
                                borderRadius: '10px',
                            }}
                        >
                            <strong style={{ color: "white",fontWeight:'500',fontSize:"16px" }}>Convert to Invoice</strong>
                        </Button>
                    </Link>
                </Box> */}

            </div>
            <Card style={{
                padding: "20px",
                borderRadius: "12px",
                marginTop: "20px",

            }}>
                <Grid item md={12} sm={8} xs={12} container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "24px",
                                    lineHeight: "32px",

                                }} >
                                {t("Sales_Order")}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    color: "rgba(40, 45, 38, 0.5)",
                                    lineHeight: "34px",

                                }}
                            >
                                {t("Sales_Order")} #478963
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="p"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    color: "rgba(40, 45, 38, 0.5)",
                                    lineHeight: "34px",

                                }}
                            >
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                style={{
                                    display: "inline-block",
                                    width: "60px",
                                    height: "25px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    background: "grey",
                                    color:"white",
                                    textAlign:"center"
                                }}
                            >
                               Drafts
                            </Typography>
                        </Grid>

                        <Grid container md={12} sm={12} xs={12}>
                            <Grid item md={3} sm={12} xs={12}
                                style={{
                                    borderLeft: "4px solid rgba(240, 98, 55, 1)",
                                }}
                            >
                                <Typography variant="h6"
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        lineHeight: "24px",
                                        marginTop: "8px",
                                        marginLeft: "8px",
                                        color:"#A9A9A9"

                                    }}
                                >
                                    {t("REFERENCE#")}
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                               
                                    
                                    <strong style={{
                                        fontStyle: "normal",
                                         fontSize: "14px",
                                        //  marginTop: "10px",
                                         fontWeight:"normal",
                                         lineHeight: "34px",
                                        marginTop: "8px",
                                        marginLeft: "10px"
                                        
                                    }}>REF-SO-00</strong>
                                
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}
                                style={{
                                    borderLeft: "4px solid rgba(240, 98, 55, 1)",
                                }}
                            >
                                <Typography variant="h6"
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        lineHeight: "24px",
                                        marginTop: "8px",
                                        marginLeft: "8px",
                                        color:"#A9A9A9"

                                    }}
                                >
                                   {t("ORDER_DATE")}
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                               
                                    
                                    <strong style={{
                                        fontStyle: "normal",
                                         fontSize: "14px",
                                        //  marginTop: "10px",
                                         fontWeight:"normal",
                                         lineHeight: "34px",
                                        marginTop: "8px",
                                        marginLeft: "10px",
                                        
                                        
                                    }}>3 Jun 2022</strong>
                                
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}
                                style={{
                                    borderLeft: "4px solid rgba(240, 98, 55, 1)",
                                }}
                            >
                                <Typography variant="h6"
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        lineHeight: "24px",
                                        marginTop: "8px",
                                        marginLeft: "8px",
                                        color:"#A9A9A9"

                                    }}
                                >
                                  {t("EXPECTED_SHIPPMENT_DATE")}
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                               
                                    
                                    <strong style={{
                                        fontStyle: "normal",
                                         fontSize: "14px",
                                        //  marginTop: "10px",
                                         fontWeight:"normal",
                                         lineHeight: "34px",
                                        marginTop: "8px",
                                        marginLeft: "10px"
                                        
                                    }}>5 Feb 2023</strong>
                                
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}
                                style={{
                                    borderLeft: "4px solid rgba(240, 98, 55, 1)",
                                }}
                            >
                                <Typography variant="h6"
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        lineHeight: "24px",
                                        marginTop: "8px",
                                        marginLeft: "8px",
                                        color:"#A9A9A9"

                                    }}
                                >
                                   {t("DELIVERY_METHOD")}
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                               
                                    
                                    <strong style={{
                                        fontStyle: "normal",
                                         fontSize: "14px",
                                        //  marginTop: "10px",
                                         fontWeight:"normal",
                                         lineHeight: "34px",
                                        marginTop: "8px",
                                        marginLeft: "10px"
                                        
                                    }}>Bike</strong>
                                
                            </Grid>
                            <Grid item md={3} sm={12} xs={12}
                                style={{
                                    borderLeft: "4px solid rgba(240, 98, 55, 1)",
                                }}
                            >
                                <Typography variant="h6"
                                    style={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "12px",
                                        lineHeight: "24px",
                                        marginTop: "8px",
                                        marginLeft: "8px",
                                        color:"#A9A9A9"

                                    }}
                                >
                                   {t("SALES_PERSON")}
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                               
                                    
                                    <strong style={{
                                        fontStyle: "normal",
                                         fontSize: "14px",
                                        //  marginTop: "10px",
                                         fontWeight:"normal",
                                         lineHeight: "34px",
                                        marginTop: "8px",
                                        marginLeft: "10px"
                                        
                                    }}></strong>
                                
                            </Grid>
</Grid>

                    </Grid>



                    <Grid item md={6} xs={12}
                        style={{
                            textAlign: "right",
                        }}
                    >

                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                
                            >
                             <Link to="/customer/add" style={{
            color: "white",
            textDecoration: "none",
            color:"#F06237"
          }}>
            
              {t("Registered_Customer")}
            
          </Link>

                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    lineHeight: "34px",
                                    marginTop: "8px",
                                    color: "rgba(40, 45, 38, 0.5)",
                                }}
                            >
                               {t("BILLING_ADDRESS")}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    lineHeight: "34px",
                                    color: "black"
                                }}
                            >
                                debbie.baker@example.com
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    lineHeight: "34px",
                                    color: "black"
                                }}
                            >
                                4517 Washington Ave. Manchester, Kentucky 39495
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    lineHeight: "34px",
                                    marginTop: "8px",
                                    color: "rgba(40, 45, 38, 0.5)",
                                }}
                            >
                             {t("SHIPPING_ADDRESS")}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    lineHeight: "34px",
                                    color: "black"
                                }}
                            >
                                debbie.baker@example.com
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    lineHeight: "34px",
                                    color: "black"
                                }}
                            >
                                4517 Washington Ave. Manchester, Kentucky 39495
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <TableContainer
          component={Paper}
          sx={{ height: "auto" }}
        > */}
                <Table stickyHeader aria-label="simple table"
                    style={{ marginTop: "25px" }}
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t("Product_Name")}</StyledTableCell>
                            <StyledTableCell>{t("Product_NO.")}</StyledTableCell>
                            <StyledTableCell>{t("Customer_Name")}</StyledTableCell>
                            <StyledTableCell>{t("Rate")}</StyledTableCell>
                            <StyledTableCell>{t("Quantity")}</StyledTableCell>
                            <StyledTableCell>{t("Total")}</StyledTableCell>
                            <StyledTableCell>{t("Order_Place_via")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {allUsers.map((row, index) => ( */}
                            <TableRow
                                //   key={row.name}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Stack flexDirection="row" alignItems="center">
                                        <Avatar
                                            component="span"
                                            variant="rounded"
                                            alt="profile_image"
                                            src={product_image}
                                        />
                                        <Typography sx={{ ml: 2 }}>{t("max")}</Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>478963</TableCell>
                                <TableCell>{t("Wade_Warren")}</TableCell>
                                <TableCell>38.5%</TableCell>
                                <TableCell>
                                <StyledChip sx={{ backgroundColor: "rgba(18, 183, 106, 0.05)",
            color: "#12B76A"}} label={"1"}/>
                                </TableCell>
                                <TableCell>$50.0</TableCell>
                                <TableCell>{t('Online')}</TableCell>
                            </TableRow>
                       
                        {/* ))} */}
                    </TableBody>
                </Table>
                <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)",marginTop:"5px" }}></div>

                <FlexBox px={2} 
                style={{
                    marginLeft:'486px',}}
                >
              <Box display="flex">
                <Box pr={6}>
                  <Typography sx={{ my: 2 }}>{t("Subtotal:")}</Typography>
                  <Typography sx={{ mb: 2 }}>{t("TAX")}</Typography>
                  <Typography sx={{ mb: 2 }}>{t("Shipping_Charges")}</Typography>
           
                </Box>
                <div
                style={{
                    marginLeft:"21px"
                }}
                >
                  <Typography sx={{ my: 2 }}><b>$119.00</b></Typography>
                  <Typography sx={{ mb: 2 }}><b>GST (08%)</b></Typography>
                  <Typography sx={{ mb: 2 }}><b>  $08.00  </b></Typography>
            
                </div>
              </Box>
            </FlexBox>

            <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)",marginTop:"5px" }}></div>

                <FlexBox px={2} 
                style={{
                    marginLeft:'486px',
                marginTop:"20px"}}
                >
              <Box display="flex">
                <Box pr={6}>
        
                    <Typography sx={{ mb: 2 }}>{t("Total")}</Typography>
           
                </Box>
                <div
                style={{
                    marginLeft:"116px"
                }}
                >
             
                  <Typography sx={{ mb: 2 }}><b>$08.00 </b></Typography>

                </div>
              </Box>
            </FlexBox>


            </Card>

        </>
    );
}
