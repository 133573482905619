import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "./style/addUpdate.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, FormLabel, Grid, Button } from "@mui/material";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TableCell, Avatar, Chip, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  fontWeight: "400",
  color: "rgba(15, 15, 15, 0.5)",
}));

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));
const StyledInput = styled("input")({
  display: "none",
});
const StyledDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  background: "#FFFFFF",
  boxShadow:
    " 0px 20px 94px rgba(16, 24, 40, 0.01), 0px 8px 30px rgba(16, 24, 40, 0.01)",
  borderRadius: "12px !important",
  padding: "22px",
  width: "100%",
  height: "100%",
});
const StyledImg = styled("img")({
  borderRadius: "12px !important",
  height: "350px",
  width: "450px",
});
const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
  width: "100%",
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const strong = {
  color: "black",
};
const tableborder = {
  borderBottom: "none",
};

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "14px",
});
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "3px",
    height: "40px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  productGroup: "abc",
  productCode: "",
  productName: "",
  productDescription: "",
  productPrice: "",
  productSalesPrice: "",
  discountType: "",
  discountRate: "",
  productQuantity: "",
  productSpecification: "",
  discount: "",
  taxType: "",
  taxRate: "",
  currency: "",
};

export default function FormPropsTextFields(props) {
  const location = useLocation();
  const [image, setImage] = useState([]);
  const [files, setFiles] = useState([]);
  const [productimage, setProductImage] = useState([]);
  const [productdata, setProductdata] = useState({});
  const [clone, setClone] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state, pathname } = useLocation();
  useEffect(() => {
    GetProductDetails();
  }, []);

  const GetProductDetails = async () => {
    await axiosInstance
      .get(`/api/v1/productdetail/${state}`)
      .then((res) => {
        setProductImage(res.data.data.productImage[0]);
        setProductdata(res.data.data);
        let prodImages = [];
        res.data.data.productImage.filter((data) =>
          prodImages.push(data.productImage)
        );
        setImage(prodImages);
        setFiles(prodImages);
      })
      .catch((e) => {
        console.log("error found");
      });
  };
  let discountType;
  discountType = productdata.discountType === 1 ? "Flat" : "Percentage";
  const handleClone = () => {
    setClone(true);
  };
  const closeClone = () => {
    setClone(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "4px",
            }}
          >
            {" "}
            {t("View_Product_Detail")}{" "}
          </h1>

          <p
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "34px",
              marginTop: "0px",
            }}
          >
            {t("Product")} |{" "}
            <span style={{ color: "black" }}>{t("View_Product_Detail")}</span>
          </p>
        </div>
        <Box marginTop="20px" borderRadius="12px">
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
            }}
            onClick={() => {
              // navigate(`/product`)
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>

          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#f06237",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate(`/product/edit`, { state: productdata.id });
            }}
          >
            <strong style={{ color: "white" }}>{t("Edit")}</strong>
          </Button>

          {/* </Link> */}
        </Box>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <StyledDiv>
            <Grid className="pd_slider_and_details_grid" container xs={12}>
              <Grid
                item
                xs={12}
                lg={6}
                spacing={2}
                style={{ paddingRight: "22px" }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Carousel
                    className="pd_img_slider"
                    navButtonsProps={{
                      style: {
                        background: "rgba(15, 15, 15, 0.5)",
                        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                        transform: " matrix(-1, 0, 0, 1, 0, 0)",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px",
                        gap: "8px",
                        width: "36px",
                        height: "36px",
                      },
                    }}
                    indicatorIconButtonProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    NextIcon={<ChevronLeftIcon />}
                    PrevIcon={<ChevronRightIcon />}
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}
                  >
                    {files.map((imgsrc, i) => (
                      <StyledImg key={i} src={imgsrc} alt={imgsrc} />
                    ))}
                  </Carousel>
                </Grid>

                <Grid
                  container
                  item
                  sx={{ my: 1.5 }}
                  className="pd_parentspecialborderGrid_img"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {files.map((item, i) =>
                    i < 3 ? (
                      <Grid item>
                        {" "}
                        <img
                          className="pd_product_img"
                          key={i}
                          src={item}
                          alt={item}
                        />{" "}
                      </Grid>
                    ) : (
                      ""
                    )
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Grid className="pd_details_desc_grid">
                  <Grid>
                    <h1 className="pd_h1">{productdata.productName}</h1>
                    <h5 className="pd_h1">
                      {t("Product_Quantity:")}{productdata.productQuantity}
                    </h5>

                    <StyledRequired className="pd_p">
                      {" "}
                      {t("Category")} : {productdata.CategoryName}{" "}
                    </StyledRequired>
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "34px",
                        marginTop: "0px",
                        color: "rgb(166 166 166)",
                      }}
                    >
                      {t("Product_Code")} :{" "}
                      <span style={{ color: "rgba(40, 45, 38, 1)" }}>
                        {" "}
                        {productdata.productCode}|{" "}
                      </span>
                      {t("Created_Date")} :
                      <span style={{ color: "rgba(40, 45, 38, 1)" }}>
                        {" "}
                        {productdata.createdAt}{" "}
                      </span>
                    </p>
                  </Grid>
                  <Grid container className="pd_parentspecialborderGrid">
                    <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p className="pd_p">{t("Sales_Price")}</p>
                      <h1 className="pd_h3">{productdata.productSalesPrice}</h1>
                    </Grid>
                    <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p className="pd_p">{t("MRP_Price")}</p>
                      <h1 className="pd_h3">{productdata.productPrice}</h1>
                    </Grid>
                    <Grid item xs={3.7} className="pd_speicalborderGrid">
                      <p className="pd_p">{t("Product_Code")}</p>
                      <h1 className="pd_h3">{productdata.productCode}</h1>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  {/* {/ <p className="pd_p">Description</p> /} */}
                  <StyledRequired className="pd_p">{t("Description")}</StyledRequired>

                  <p className="pd_p">{productdata.productDescription}</p>
                </Grid>
              </Grid>
            </Grid>

            <Grid container md={12}>
              <Grid item xs={12} md={4}>
                <h1 className="pd_h2">{t("Product_Details:")}</h1>
                <Grid container>
                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">{t("Sales_UOM")}</h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h6 className="product_detail_dark_color">
                      {productdata.uomName}
                    </h6>
                  </Grid>

                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">{t("Quantity")}</h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h6 className="product_detail_dark_color">
                      {productdata.productQuantity}
                    </h6>
                  </Grid>
                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">{t("Currency")}</h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h6 className="product_detail_dark_color">
                      {productdata.currencyName}
                    </h6>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  borderLeft: "1px solid rgba(40, 45, 38, 0.15)",
                  paddingLeft: "20px",
                }}
              >
                <h1 className="pd_h2">{t("Product_Specification:")}</h1>
                <Grid container>
                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">
                      {t("Specification")}
                    </h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h6 className="product_detail_dark_color">
                      {productdata.productSpecification}
                    </h6>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  borderLeft: "1px solid rgba(40, 45, 38, 0.15)",
                  paddingLeft: "30px",
                }}
              >
                <h1 className="pd_h2">{t("Product_Vs_Charges:")}</h1>
                <Grid container>
                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">{t("TAX_Type")}</h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h1 className="product_detail_dark_color">
                      {productdata.TaxName}
                    </h1>
                  </Grid>
                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">
                      {t("Discount_Type")}
                    </h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h6 className="product_detail_dark_color">
                      {discountType}
                    </h6>
                  </Grid>
                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">
                      {t("Discount_Value")}
                    </h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h6 className="product_detail_dark_color">
                      {productdata.discountRate}
                    </h6>
                  </Grid>
                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">{t("Description")}</h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h6 className="product_detail_dark_color">{t("State_GST")}</h6>
                  </Grid>
                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">{t("TAX_On")}</h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h6 className="product_detail_dark_color">{t("Basic_Amount")}</h6>
                  </Grid>
                  <Grid item xs={6.3}>
                    <h1 className="product_detail_light_color">{t("TAX_Rate")}</h1>
                  </Grid>
                  <Grid item xs={5.7}>
                    <h6 className="product_detail_dark_color">
                      {productdata.taxRate}
                    </h6>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledDiv>
        </Grid>
      </Box>
    </>
  );
}
