import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./style/customerlist.css";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  Pagination,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import filterIcon from "../../assets/filter.svg";
import UserDeleteModal from "./customerDelete";
import axiosInstance from "../../utils/AxiosInstace";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useForm, Form } from "../../utils/useForms";
import { tableCellClasses } from "@mui/material/TableCell";
import { getAllCustomerList } from "../../state/action/customerAction";
import { useTranslation } from "react-i18next";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "11px",
    paddingTop: "3px",
    paddingBottom: "3px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
}));

const validate = (values) => {
  const errors = {};
};
const initialValues = {};

const UsersList = (props) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);
  const { t } = useTranslation();

  const [allUsers, setAllUsers] = useState([]);
  const [deleteUser, setDeleteUser] = useState(false);
  const [filter, setFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPages] = useState(1);
  const [user, setUser] = useState([]);
  const [search1, setsearch1] = useState("");
  const [records, setRecords] = useState(0);
  const [totalorder, setTotalorder] = useState(0);
  const [filterData, setFilterData] = useState(true);

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  const navigate = useNavigate();

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    getAllCustomer();
  }, [page, search1, filterData]);

  const onSearch = async (searchvalue) => {
    setsearch1(searchvalue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };
  const getAllCustomer = async () => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    const res = await getAllCustomerList(page, string, search1);
    console.log("res", res);

    if (res.status === true) {
      setAllUsers(res.data);
      setRecords(res.data.length);
      setTotalorder(res.totalcustomer);
      setFilter(false);
      let pagecount = Math.ceil(res.totalcustomer / 10);
      setTotalPages(pagecount);
    } else {
      setAllUsers([]);
    }
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleDeleteUser = () => {
    setDeleteUser(true);
    console.log("inside customer", deleteUser);
  };
  const closeDeleteUser = () => {
    setDeleteUser(false);
  };

  return (
    <>
      <div>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              fontWeight: "600",
              paddingLeft: "10px",
            }}
          >
            {t("Customers")}
          </Typography>
          {editPermissions && (
            <Link
              to="/customer/add"
              style={{
                color: "white",
                textDecoration: "none",
              }}
            >
              <Button
                sx={{
                  height: "34px",
                  backgroundColor: "#F06237",
                  color: "white",
                  borderRadius: "10px",
                  padding: "5px 25px",
                  "&:hover": {
                    backgroundColor: "#cf5025",
                  },
                }}
                startIcon={<Add />}
                color="primary"
              >
                <span style={{ fontSize: "14px" }}>{t("Add_Customer")}</span>
              </Button>
            </Link>
          )}
        </Stack>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
          marginTop: "30px",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Form>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{
                  height: "30px",
                }}
                placeholder={t("Search…")}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            </Search>
          </Form>
          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <Form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Filter")}
                </Typography>
                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer}>
                  <CloseIcon />
                </IconButton>
              </Stack>

              {/* <Box sx={{ margin: "8px auto" }}> */}
              <StyledFormLabel htmlFor="name">
                {" "}
                {t("Customer_Name")}
              </StyledFormLabel>
              <StyledTextField
                placeholder={t("Search_Name")}
                fullWidth
                id="customerName"
                name={t("customerName")}
                value={values.customerName}
                onChange={handleInputChange}
              />

              <Divider sx={{ mt: 2 }} />

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="customerEmail">
                  {t("Customer_Email")}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Customer_Email")}
                  fullWidth
                  id="customerEmail"
                  name={t("customerEmail")}
                  value={values.customerEmail}
                  onChange={handleInputChange}
                />
              </Box>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="mobileNumber">
                  {t("Mobile_Number")}
                </StyledFormLabel>
                <StyledTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Mobile_Number")}
                  fullWidth
                  id="mobileNumber"
                  name={t("mobileNumber")}
                  value={values.mobileNumber}
                  onChange={handleInputChange}
                />
              </Box>
              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {},
                }}
                onClick={closeFilterDrawer}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </Form>
        </Drawer>
        <TableContainer component={Paper} sx={{ height: "auto" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead style={{ fontSize: "14px" }}>
              <TableRow>
                <StyledTableCell>{t("CUSTOMER_NAME")}</StyledTableCell>
                <StyledTableCell>{t("MOBILE_NUMBER")}</StyledTableCell>
                <StyledTableCell> {t("CUSTOMER_EMAIL")}</StyledTableCell>
                <StyledTableCell> {t("CREATED_AT")}</StyledTableCell>
                <StyledTableCell>{t("ACTION")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allUsers.length == 0 ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="7"
                  >
                    {t("No_records_to_display")}
                  </TableCell>
                </TableRow>
              ) : (
                allUsers.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      navigate(`/customer/details`, {
                        state: row.id,
                      });
                    }}
                  >
                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.customerName}
                    </StyledTableCell>

                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.mobileNumber}
                    </StyledTableCell>

                    <StyledTableCell style={{ cursor: "pointer" }}>
                      {row.customerEmail}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(row.createdAt).format("LLL")}
                    </StyledTableCell>

                    <StyledTableCell
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Box>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <div>
                              <MoreVertIcon {...bindTrigger(popupState)} />
                              <Menu
                                {...bindMenu(popupState)}
                                PaperProps={{
                                  sx: {
                                    marginTop: "13px",
                                    minWidth: "10em",
                                  },
                                }}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                              >
                                {readPermissions && (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close();
                                      setUser(row);
                                      navigate(`/customer/details`, {
                                        state: row.id,
                                      });
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={eye} alt="View Details/" />
                                    </ListItemIcon>

                                    {t("View_Customer")}
                                  </MenuItem>
                                )}

                                {editPermissions && (
                                  <MenuItem
                                    onClick={() => {
                                      popupState.close();
                                      setUser(row);
                                      navigate(`/customer/add`, {
                                        state: {
                                          user: row,
                                        },
                                      });
                                    }}
                                  >
                                    <ListItemIcon>
                                      <img src={edit} alt="Edit/" />
                                    </ListItemIcon>
                                    {t("Edit")}
                                  </MenuItem>
                                )}
                              </Menu>
                            </div>
                          )}
                        </PopupState>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <UserDeleteModal
          userlist={getAllCustomer}
          page={page}
          userid={user.id}
          user={setUser}
          open={deleteUser}
          handleClose={closeDeleteUser}
        />
      </div>
      {/* pagination */}
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {t("Showing")} {records} of {totalorder} {t("Results")}
        </div>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
};

export default UsersList;
