import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import Notification from "../../utils/Notification"
import { useForm } from "../../utils/useForms";
import Backdrop from "../Backdrop/loader";
import { addTaxApi } from "../../state/action/taxAction";
import axiosInstance from "../../utils/AxiosInstace";


const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "500",
  color: "black",
  fontSize: "13px",
}));

const StyledRequired = styled("span")({
  color: "red",
  marginLeft: "2px",
  fontSize: "20px",
});

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",

  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const AddTax = (props) => {
  const { open, refreshScreen, handleClose, hsn } = props;
  const [isdisabled, setIsDisabled] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const { t } = useTranslation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const reset = () => {
    resetForm();
  };

  const isEditAction = hsn && typeof hsn === "object" ? true : false;
  var initialValues = {
    taxType: "",
    taxValue: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("taxType" in fieldValues) {
      if (/^[a-zA-Z]+$/.test(fieldValues.taxType) === false) {
        temp.taxType = "Only Character are allowed";
      }
      else if (fieldValues.taxType == "") {
        temp.taxType = "This field is required.";
      }
      else {
        temp.taxType = "";
      }
    }
    if ("taxValue" in fieldValues) {
      if (/^[0-9]+$/.test(fieldValues.taxValue) === false) {
        temp.taxValue = "Only Number are allowed";
      }
      else if (fieldValues.taxValue == "") {
        temp.taxValue = "This field is required.";
      }
      else {
        temp.taxValue = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    if (isEditAction) {
      setValues(hsn);
    }
  }, [props]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };


    if (validate()) {
      if (props.hsn) {
        await axiosInstance
          .post(`api/v1/updatetax/${props.hsn.id}`, values).then((res) => {
            if (res.data.status == true) {
              handleClose();
              refreshScreen();
              toast.success(res.data.message, toastType);
            }
          }).catch((e) => {
            console.log("error found");
            toast.error(e.response.data.message, toastType);

          })
      }
      else {
        let res;
        await axiosInstance
          .post(`api/v1/addtax`, values).then((response) => {
            console.log("resposmse", response.data)
            if (response && response.data.status === true) {
              setOpenBreakdrop(false)
              handleClose();
              toast.success(response.data.message, toastType);
              refreshScreen();
            }
            else {
              setOpenBreakdrop(false)
              toast.error(response.message, toastType);
            }
          }).catch((e) => {
            console.log("error found", e.response)
            toast.error(e.response.data.message, toastType);
          })
      }
    }
  }

  const close = () => {
    reset();
    handleClose();
    setIsDisabled(false);
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <Dialog open={open} fullWidth={true}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isEditAction ? t("Edit") : t("Add")} {t("Tax")}
          <Tooltip title="Close">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} id="addNewTax">
            <Grid container md={12} spacing={2}>
              <Grid item sm={6} md={8} xs={12}>
                <StyledFormLabel htmlFor="taxType">
                  {t("Name")}
                  {Boolean(errors.taxType) && (
                    <StyledRequired> * </StyledRequired>
                  )}
                </StyledFormLabel>
                <StyledTextField
                  fullWidth
                  // disabled={isEditAction ? true : false}
                  id="taxType"
                  name={("taxType")}
                  type="text"
                  value={values.taxType}
                  onChange={handleInputChange}
                  error={Boolean(errors.taxType)}
                  helperText={errors.taxType}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
            <Grid container md={12} spacing={2} mt={1}>
              <Grid item md={8} sm={6} xs={12}>
                <StyledFormLabel htmlFor="taxValue">
                  {t("Rate")} (%)
                  {Boolean(errors.taxValue) && (
                    <StyledRequired> * </StyledRequired>
                  )}
                </StyledFormLabel>
                <StyledTextField
                  fullWidth
                  id="taxValue"
                  name="taxValue"
                  type="text"
                  value={values.taxValue}
                  // onChange={handleInputChange}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  error={Boolean(errors.taxValue)}
                  helperText={errors.taxValue}
                  inputProps={{ maxLength: 5 }}
                />
              </Grid>


            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              paddingLeft: "25px",
              paddingRight: "25px",
              "&:hover": {},
            }}
            onClick={close}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: "#F06237",
              color: "white",
              borderRadius: "10px",
              paddingLeft: "25px",
              paddingRight: "25px",
              "&:hover": {
                backgroundColor: "#F06237",
              },
            }}
            disabled={isdisabled}
            type="submit"
            form="addNewTax"
          >
            {isEditAction ? t("Save") : t("Add")}
          </Button>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotif y={setNotify} />

    </>
  );
};

export default AddTax;
