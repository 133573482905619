import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Stack,
  Grid,
  IconButton,
  TextField,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";


const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "rgba(15, 15, 15, 0.5)",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiInput-root": {},
}));

const CateogryDetails = (props) => {
  const { category, open, handleClose } = props;
  const { t }=useTranslation();

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {t("Category_Details")}
          <Tooltip title="Close">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={0}>
            <Grid item sx={{ mt: 1 }} md={3} sm={4} xs={12}>
              <Stack
                sx={{
                  mt: 2,
                  padding: "20px ",
                  border: "1px solid rgba(15, 15, 15, 0.15)",
                  borderRadius: "10px",
                }}
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <img
                  src={category.image}
                  style={{ width: "100px", height: "100px" }}
                  alt={category.name}
                />
              </Stack>
            </Grid>
            <Grid md={9} sm={8} xs={12} spacing={1}>
              <Grid
                container
                xs={12}
                sx={{ m: 1 }}
                style={{ alignItems: "center" }}
              >
                <Grid item sx={{ m: 1 }} xs={5.6}>
                  <StyledFormLabel htmlFor="name">{t("Name")} </StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="name"
                    name={t("name")}
                    defaultValue={category.name}
                  />
                </Grid>
                <Grid item sx={{ m: 1 }} xs={5.6}>
                  <StyledFormLabel htmlFor="type">{t("Type")}</StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="type"
                    defaultValue={category.type}
                  />
                </Grid>
              </Grid>

              <Grid item sx={{ m: 1 }} xs={12}>
                <Grid sx={{ m: 1 }} xs={6}>
                  <StyledFormLabel htmlFor="status">{t("Status")} </StyledFormLabel>
                  <StyledTextField
                    inputProps={{ readOnly: true }}
                    variant="standard"
                    fullWidth
                    id="status"
                    name={t("status")}
                    defaultValue={category.status ? "Active" : "inActive"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "#0957dd",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#0957dd",
              },
            }}
            onClick={handleClose}
          >
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CateogryDetails;
