import React from "react";
import { useState, useEffect } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { TextField, IconButton, Tooltip, Button, Stack } from "@mui/material";
import {
  ConstructionOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { styled, useTheme, useMediaQuery } from "@mui/material";
import RectangleGlass from "../../assets/Asia-12.png";
import { Redirect, useNavigate, Link } from "react-router-dom";
import Asia01 from "../../assets/Asia-01.png";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "material-react-toastify";
// import { ToastContainer, toast } from "react-toastify";
import Notification from "../../utils/Notification";
import { useTranslation } from "react-i18next";

import "material-react-toastify/dist/ReactToastify.css";

const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  height: "60vh",
  position: "relative",
  marginTop: "-287px",
}));

const StyledRightSide = styled("div")(() => ({
  height: "88vh",
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#f06237",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#cf5025",
    color: "white",
  },
}));
const ForgetPassword = () => {
  const lg = useMediaQuery("@media (max-width:900px)");
  const xs = useMediaQuery("@media (max-width:600px)");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  return (
    <div>
      <ToastContainer />
      <Grid container spacing={1} sx={{ backgroundColor: "#F8F8F8;" }}>
        <Grid item lg={5} md={5} sm={5} xs={12}>
          <StyledLeftSide>
            <Box
              sx={{
                position: "fixed",
                borderRadius: "10px",
                height: "-webkit-fill-available",
                margin: lg ? "30px" : "40px",
                color: "white",
                padding: lg ? "0px 30px" : "0px 50px",
              }}
            ></Box>
            <img
              src={Asia01}
              style={{
                width: "300px",
                height: "100px",
                "margin-top": "316px",
                "margin-left": "80%",
              }}
              alt="Login Background"
            />
          </StyledLeftSide>
        </Grid>

        <Grid
          item
          lg={5}
          md={5}
          sm={5}
          xs={12}
          sx={{ margin: "10px 20px", marginLeft: "400px" }}
        >
          <StyledRightSide>
            <Container sx={xs ? {} : { pt: "20%", paddingTop: "0px" }}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  component={"h4"}
                  sx={{
                    display: "inline-block",
                    fontSize: "28px",
                    fontWeight: "400",
                  }}
                >
                  {t("Create_Password")}
                </Typography>
                <br />
              </Box>
              <form autoComplete="off">
                <Box sx={{ mt: 2 }}>
                  <StyledLabel> {t("New_Password")}</StyledLabel>
                  <TextField
                    name={t("password1")}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <StyledLabel> {t("confirm_Password")}</StyledLabel>
                  <TextField
                    name={t("password2")}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start" sx={{ m: 0 }}>
                          <Tooltip
                          // title={
                          //     showPassword ? "Hide Password" : "Show Password"
                          // }
                          >
                            <IconButton>
                              {/* {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )} */}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ mt: 2 }}
                >
                  <Button
                    onClick={() => {
                      navigate("/forget");
                    }}
                    sx={{ textTransform: "capitalize" }}
                  ></Button>
                </Stack>
                <StyledLogin sx={{ mt: 3 }} type="submit">
                  {t("Set_Password")}
                </StyledLogin>
                <a
                  href="#"
                  style={{ marginLeft: "250px", marginTop: "80px" }}
                  onclick="history.back()"
                >
                  {t("Go_Back")}
                </a>
              </form>
            </Container>
          </StyledRightSide>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default ForgetPassword;
