import { useEffect, useState } from "react";
import React from "react";
import axiosInstance from "../../utils/AxiosInstace";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { TextField, FormLabel, MenuItem, Input } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserDelete = (props) => {
  const Navigate = useNavigate();
  const { userlist, userid, page, id } = props;
  const deleteSupplier = async () => {
    if (userid) {
      await axiosInstance
        .delete(`/api/v1/supplierdelete/${userid}`)
        .then((res) => {
          userlist(page);
          console.log("11111111111", userlist);
          window.location.reload(false);
          //Navigate("/supplier")
        })
        .catch((e) => {
          console.log("error found");
        });
    }
    if (id) {
      console.log("22222", id);
      await axiosInstance
        .delete(`/api/v1/supplierdelete/${id}`)
        .then((res) => {
          // userlist(page);
          //handleClose();
          Navigate("/supplier");
        })
        .catch((e) => {
          console.log("error found");
        });
    }
  };

  const { user, open, handleClose } = props;
  const { t }=useTranslation();

  useEffect(() => {}, []);
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>Delete</DialogTitle>

        <DialogContent>
          Are you sure you want to delete this user ?
        </DialogContent>
        <DialogActions
          sx={{
            margin: "15px",
            marginRight: "57px",
          }}
        >
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                // backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: "#F06237",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#db511f",
              },
            }}
            onClick={deleteSupplier}
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDelete;
