import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import button1 from "../../assets/_Button.svg";
import button2 from "../../assets/_Button(1).svg"
import button3 from "../../assets/_Button(2).svg"
import button4 from "../../assets/_Button(3).svg"
import Avatar from '@mui/material/Avatar';
import "./dashboard.css";
import Pie from "../chart/lineChart2"
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from "react-i18next";


import CircularProgressWithLabel from "../dashboardPage/progressbar.jsx"
import {
  Drawer,
  Grid,
  Divider,
  Stack,
  TextField,
  FormLabel,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Line from "../chart/lineChart";
import PieChart from "../chart/lineChart2";
import { fontSize } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import {
  InputBase,
  Button,

  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,

} from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from "@mui/material/styles";
// import Line from "../chart/lineChart";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add 
    "&:hover": {
      color: "#E64A08",
    },
  },
}));




const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  // },
  '&:last-child td, &:last-child th': {
    border: 0,
  },

}));


export default function Dashboard() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <div style={{ borderTop: "1px solid #C0C0C0",width:"100%" }}></div>
      <Grid item md={12} sm={8} xs={12} container spacing={1} style={{
        paddingTop: "16px", paddingBottom: "21px"
      }}>
        <Grid item sx={{ ml: 1 }} md={8} sm={8} xs={12} container spacing={1}>
          <Typography fontWeight={400} fontSize="36px" style={{ fontSize: "20px" }}>{t("Sales_Activity")}</Typography>

        </Grid>
        <Grid item md={3} sm={8} xs={12} container spacing={1}>
          <Typography fontWeight={400} fontSize="36px" style={{ fontSize: "18px" }}>{t("Inventory_Summary")}</Typography>

        </Grid>
        <Grid item md={8} sm={8} xs={12} container spacing={1}>

          <Grid item sx={{ m: 1 }} md={2.6} xs={12} style={{ paddingLeft: "0px" }}>
            <Card style={{ borderRadius: "6px",height: "157px",width: "153px"}} >
              <Box sx={{
                display: 'flex', justifyContent: "center", textAlign: "center",paddingTop:"16px"
              }} >
                <Stack >
                  <Typography fontWeight={400} fontSize="36px" style={{ color: "#2485E8" }}>4</Typography>
                  <Typography fontWeight={400} fontSize="12px" style={{ color: "#8d99ae" }} >{t("Qty")}</Typography>
                  <Typography variant="body2" color="text.secondary" fontSize="12px" fontWeight={600} style={{ marginTop: "20px", color: "#495569" }}>
                    {t("TO_BE_PACKED")}
                  </Typography>
                </Stack>
              </Box>
            </Card>

          </Grid>

          <Grid item sx={{ m: 1 }} md={2.6} xs={12} style={{ paddingLeft: "0px" }}>
            <Card style={{ borderRadius: "6px", height: "157px",width: "153px"}} >
              <Box sx={{
                display: 'flex', justifyContent: "center", textAlign: "center" ,paddingTop:"16px"
              }} >
                <Stack >
                  <Typography fontWeight={400} fontSize="36px" style={{ color: "#DB584B" }}>1</Typography>
                  <Typography fontWeight={400} fontSize="12px" style={{ color: "#8d99ae" }} >{t("Pkgs")}</Typography>
                  <Typography variant="body2" color="text.secondary" fontSize="12px" fontWeight={600} style={{ marginTop: "20px", color: "#495569" }}>
                    {t("TO_BE_SHIPPED")}
                  </Typography>
                </Stack>
              </Box>
            </Card>

          </Grid>

          <Grid item sx={{ m: 1 }} md={2.6} xs={12} style={{ paddingLeft: "0px" }}>
            <Card style={{ borderRadius: "6px",height: "157px",width: "153px" }} >
              <Box sx={{
                display: 'flex', justifyContent: "center", textAlign: "center" ,paddingTop:"16px"
              }} >
                <Stack >
                  <Typography fontWeight={400} fontSize="36px" style={{ color: "#28A86F" }}>0</Typography>
                  <Typography fontWeight={400} fontSize="12px" style={{ color: "#8d99ae" }} >{t("Pkgs")}</Typography>
                  <Typography variant="body2" color="text.secondary" fontSize="12px" fontWeight={600} style={{ marginTop: "20px", color: "#495569" }}>
                    {t("TO_BE_DELIVERED")}
                  </Typography>
                </Stack>
              </Box>
            </Card>

          </Grid>

          <Grid item sx={{ m: 1 }} md={2.6} xs={12} style={{ paddingLeft: "0px" }}>
            <Card style={{ borderRadius: "6px", height: "157px",width: "153px"}} >
              <Box sx={{
                display: 'flex', justifyContent: "center", textAlign: "center"
                ,paddingTop:"16px"
              }} >
                <Stack >
                  <Typography fontWeight={400} fontSize="36px" style={{ color: "#DDB751" }}>4</Typography>
                  <Typography fontWeight={400} fontSize="12px" style={{ color: "#8d99ae" }} >{t("Qty")}</Typography>
                  <Typography variant="body2" color="text.secondary" fontSize="12px" fontWeight={600} style={{ marginTop: "20px", color: "#495569" }}>
                    {t("TO_BE_INVOICED")}
                  </Typography>
                </Stack>
              </Box>
            </Card>

          </Grid>
 </Grid>
        
        <Grid item md={4} sm={8} xs={12} container spacing={1}>

          <div className="rectangle" >
            <Typography fontWeight={400} fontSize="13px" style={{ color: "black", marginTop: "10px" ,paddingTop:"9px"}} >{t("QUANTITY_IN_HAND")}</Typography>
            <Typography fontWeight={400} fontSize="22px" style={{ color: "balck", borderLeft: "1px solid #8d99ae", paddingLeft: "12px", paddingRight: "10px",paddingTop:"10px" }} >1774</Typography>

          </div>
          <div className="rectangle"
          >
            <Typography fontWeight={400} fontSize="13px" style={{ color: "black", marginTop: "10px",paddingTop:"9px" }} >{t("QUANTITY_TO_BE_RECEIVED")}</Typography>
            <Typography fontWeight={400} fontSize="22px" style={{ color: "balck", borderLeft: "1px solid #8d99ae", paddingLeft: "12px", paddingRight: "10px",paddingTop:"10px" }} >106</Typography>

          </div>


        </Grid>



      </Grid>
      <div style={{ borderTop: "1px solid #C0C0C0",width:"100%" }}></div>

      {/* <Grid container sx={{ mt: 3 }} spacing={3} mb={4}>
        <Grid item lg={6} md={6} xs={12}>
          <Card sx={{ py: 3 }} elevation={3}>
            <Line />
          </Card>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Card sx={{ py: 2.8 }} elevation={3}>
            <PieChart />
          </Card>
        </Grid>
      </Grid> */}

      <Grid container sx={{ mt: 3 }} md={12} spacing={3} mb={4} style={{marginTop:"8px",marginBottom:"-10px"}}>
        <Grid item lg={6} md={6} xs={12} >
          <Box style={{ border: "0.5px solid #8d99ae", height: "163px", padding: "10px",backgroundColor:"white" }}>
            <Typography fontWeight={400}  style={{ color: "black ", marginTop: "3px", paddingBottom: "10px", borderBottom: "1px solid #8d99ae",fontSize:"14px", fontWeight:"bold"}} >{t("PRODUCT_DETAILS")}</Typography>


            <Grid container md={6} style={{
              borderRight: "1px solid #8d99ae",
              // paddinTop: "10px",
              paddingBottom: "22px"
            }} >

              <Grid item xs={10}  >
                <h1 className="product_detail_light_color" style={{
                  color: "red",
                  fontSize: "17px",
                  paddingTop: "10px",
                }}>{t("Low_Stock_Items")}</h1>
              </Grid>
              <Grid item xs={1}  >
                <h1 className="product_detail_dark_color" >0</h1>
              </Grid>
              <Grid item xs={10} >
                <h1 className="product_detail_light_color" style={{
                  fontSize: "16px",
                  paddingTop: "10px",
                }} >{t("All_Item_Groups")}</h1>
              </Grid>
              <Grid item xs={1}  >
                <h6 className="product_detail_dark_color">0</h6>
              </Grid>
              <Grid item xs={10} >
                <h1 className="product_detail_light_color" style={{
                  fontSize: "16px",
                  paddingTop: "10px",
                }}>{t("All_Items")}</h1>
              </Grid>
              <Grid item xs={1}  >
                <h6 className="product_detail_dark_color">7</h6>
              </Grid>

            </Grid>
            <Grid container md={6}  >
            </Grid>

          </Box>
        </Grid>



        <Grid item lg={6} md={12} xs={12}>
          <Box style={{ border: "0.5px solid #8d99ae", height: "163px", padding: "10px",backgroundColor:"white" }}>
            <Stack flexDirection="row" justifyContent="space-between" style={{ borderBottom: "1px solid #8D99AE" }}>
              <Typography fontWeight={400}  style={{ color: "black", marginTop: "3px" ,fontWeight:"bold",fontSize:"14px", }} >{t("TOP_SELLING_ITEMS")}</Typography>

              <Box sx={{ maxWidth: 110 }}>
                <FormControl fullWidth
                  inputProps={{
                    disableUnderline: true
                  }}>
                  <NativeSelect
                    defaultValue={30}
                    inputProps={{
                      id: 'uncontrolled-native',
                      disableUnderline: true,
                    }}

                  >
                    <option value={10}>{t("Today")}</option>
                    <option value={20}>{t("Yesterday")}</option>
                    <option value={30}>{t("This_Week")}</option>
                    <option value={30}>{t("This_Month")}</option>
                    <option value={30}>{t("This_Year")}</option>


                  </NativeSelect>
                </FormControl>
              </Box>

            </Stack>
            {/* <Typography fontWeight={400} fontSize="13px" style={{ color: "black", marginTop: "10px"}} >TOP SELLING ITEMS</Typography>
            <Box sx={{ maxWidth: 110 }}>
              <FormControl fullWidth
                inputProps={{
                  disableUnderline: true
                }}>
                <NativeSelect
                  defaultValue={30}
                  inputProps={{
                    id: 'uncontrolled-native',
                    disableUnderline: true,
                  }}

                >
                  <option value={10}>Today</option>
                  <option value={20}>Yesterday</option>
                  <option value={30}>This Week</option>
                  <option value={30}>This Month</option>
                  <option value={30}>This Year</option>


                </NativeSelect>
              </FormControl>
            </Box>
 */}



          </Box>

        </Grid>
      </Grid>




      <Grid container sx={{ mt: 3 }} md={12} spacing={3} mb={4}>
        <Grid item lg={4} md={4} xs={12} >
          <Box style={{ border: "0.5px solid #8d99ae", height: "220px", padding: "10px" ,backgroundColor:"white"}}>
            <Stack flexDirection="row" justifyContent="space-between" style={{ borderBottom: "1px solid #8D99AE" }}>
              <Typography fontWeight={400}  style={{ color: "black", marginTop: "3px",fontWeight:"bold",fontSize:"14px" }} >{t("PURCHASE_ORDER")}</Typography>

              <Box sx={{ maxWidth: 110 }}>
                <FormControl fullWidth
                  inputProps={{
                    disableUnderline: true
                  }}>
                  <NativeSelect
                    defaultValue={30}
                    inputProps={{
                      id: 'uncontrolled-native',
                      disableUnderline: true,
                    }}

                  >
                    <option value={10}>{t("Today")}</option>
                    <option value={20}>{t("Yesterday")}</option>
                    <option value={30}>{t("This_Week")}</option>
                    <option value={30}>{t("This_Month")}</option>
                    <option value={30}>{t("This_Year")}</option>


                  </NativeSelect>
                </FormControl>
              </Box>

            </Stack>
            <Box style={{
              textAlign: "center",
              marginTop: "30px",
            }}>
              <Typography fontWeight={400} fontSize="16px" style={{ color: "black", marginTop: "10px" }} >{t("Quantity_Ordered")}</Typography>
              <Typography fontWeight={400} fontSize="16px" style={{ color: "black", marginTop: "10px" }} >0</Typography>

            </Box>
            <div style={{ borderTop: "1px solid #C0C0C0",width:"100%" }}></div>
            <Box style={{
              textAlign: "center",
              marginTop: "30px",
            }}>
              <Typography fontWeight={400} fontSize="16px" style={{ color: "black", marginTop: "10px" }} >{t("Total_Cost")}</Typography>
              <Typography fontWeight={400} fontSize="16px" style={{ color: "black", marginTop: "10px" }} >$ 0.00</Typography>

            </Box>
          </Box>
        </Grid>



        <Grid item lg={8} md={12} xs={12}>
          <Box style={{ border: "0.5px solid #8d99ae", height: "220px", padding: "10px",backgroundColor:"white" }}>
            <Stack flexDirection="row" justifyContent="space-between" style={{ borderBottom: "1px solid #8D99AE" }}>
              <Typography fontWeight={400}  style={{ color: "black", marginTop: "3px" ,fontWeight:"bold",fontSize:"14px"}} >{t("SALES_ORDER")}</Typography>

              <Box sx={{ maxWidth: 110 }}>
                <FormControl fullWidth
                  inputProps={{
                    disableUnderline: true
                  }}>
                  <NativeSelect
                    defaultValue={30}
                    inputProps={{
                      id: 'uncontrolled-native',
                      disableUnderline: true,
                    }}

                  >
                    <option value={10}>{t("Today")}</option>
                    <option value={20}>{t("Yesterday")}</option>
                    <option value={30}>{t("This_Week")}</option>
                    <option value={30}>{t("This_Month")}</option>
                    <option value={30}>{t("This_Year")}</option>


                  </NativeSelect>
                </FormControl>
              </Box>

            </Stack>


            <Table stickyHeader aria-label={t("simple_table")}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">{t("Channel")}</StyledTableCell>
                  <StyledTableCell align="center">{t("Draft")}</StyledTableCell>
                  {/* <StyledTableCell>HSN Code</StyledTableCell> */}
                  <StyledTableCell align="center">{t("Confirmed")}</StyledTableCell>
                  <StyledTableCell align="right">{t("Packed")}</StyledTableCell>
                  <StyledTableCell align="right">{t("Shipped")}</StyledTableCell>
                  <StyledTableCell align="right">{t("Invoiced")}</StyledTableCell>


                </TableRow>
              </TableHead>
              <TableBody id="accordian">
                {/* {hsn.map((row, index) => {
                  var { id, taxValue, taxType } =
                    row; */}
                {/* return ( */}
                <>
                  <StyledTableRow>
                    <StyledTableCell align="left" component="th" scope="row">
                      {1}
                    </StyledTableCell>
                    <StyledTableCell align="center">{t("savan")}</StyledTableCell>
                    {/* <TableCell>{hsnCode}</TableCell> */}
                    <StyledTableCell align="center">%</StyledTableCell>
                    {/* <TableCell>{category}</TableCell> */}
                    {/* <TableCell>{getStatus(status)}</TableCell> */}

                  </StyledTableRow>
                </>
                {/* ); */}
                {/* })} */}
              </TableBody>
            </Table>


          </Box>

        </Grid>
      </Grid>
      <Grid item lg={8} md={12} xs={12}>
        <Box style={{ border: "0.5px solid #8d99ae", height: "400px", padding: "10px", backgroundColor:"white"}}>
          <Stack flexDirection="row" justifyContent="space-between" style={{ borderBottom: "1px solid #8D99AE" }}>
            <Typography fontWeight={400} style={{ color: "black", marginTop: "3px",fontWeight:"bold" ,fontSize:"14px" }} >{t("SALES_ORDER_SUMMARY")} (IN $)</Typography>

            <Box sx={{ maxWidth: 110 }}>
              <FormControl fullWidth
                inputProps={{
                  disableUnderline: true
                }}>
                <NativeSelect
                  defaultValue={30}
                  inputProps={{
                    id: 'uncontrolled-native',
                    disableUnderline: true,
                  }}

                >
                  <option value={10}>{t("Today")}</option>
                  <option value={20}>{t("Yesterday")}</option>
                  <option value={30}>{t("This_Week")}</option>
                  <option value={30}>{t("This_Month")}</option>
                  <option value={30}>{t("This_Year")}</option>


                </NativeSelect>
              </FormControl>
            </Box>

          </Stack>

          <Grid container spacing={3}>
            <Grid item lg={8} md={6} xs={12}>
              <Line />
            </Grid>
            <Grid item lg={4} md={6} xs={12} style={{
              borderleft: "1px solid red",
            }}>
              <Typography fontWeight={400} fontSize="13px" style={{ color: "black", marginTop: "3px" }} >{t("Total_Sales")}</Typography>

              <Grid item sx={{ m: 1 }} md={8} xs={12}>
                <Card style={{ borderRadius: "12px", }}>
                  <Box sx={{ p: 2, display: 'flex' }}>

                    <span class="dot"></span>
                    <Stack spacing={0.5} style={{
                      marginLeft: "15px"
                    }}>
                      <Typography fontWeight={400} fontSize="13px" >{t("DIRECT_SALES")}</Typography>
                      <Typography variant="body2" color="text.secondary" fontSize="20px" fontWeight={600}>
                        $12,500
                      </Typography>
                    </Stack>
                  </Box>
                </Card>

              </Grid>
            </Grid>
          </Grid>






        </Box>

      </Grid>

    </>

  );
}

