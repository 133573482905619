import { Box } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import Dashboard from "../../dashboardPage/dashboard";
import Customer from "../../customerPage/customerList";
import Product from "../../../component/productPage/productList";
import Order from "../../orderPage/orderPageList";
import Payment from "../../paymentPage/payment";
import Credit from "../../Credit/credit";
import Invoicelist from "../../invoice/invoicelist";
import Stock from "../../stockPage/stock";
import Supplier from "../../supplierPage/supplierList";
import Supplieradd from "../../supplierPage/addsupplier";
import Supplierdetails from "../../supplierPage/viewdetails";
import Supplierdelete from "../../supplierPage/supplierDelete";
import Customeradd from "../../customerPage/addcustomer";
import Customerdetails from "../../customerPage/viewdetails";
import Customerdelete from "../../customerPage/customerDelete";
import Productdelete from "../../productPage/deleteProduct";
import Productdetails from "../../productPage/viewDetails";
import Productadd from "../../productPage/addUpdate";
import PaymentAdd from "../../paymentPage/paymentAdd";
import CreditAdd from "../../Credit/creditAdd";
import Setting from "../../settingPage/setting";
import Settingedit from "../../settingPage/editPage";
import Productedit from "../../productPage/productUpdate";
import Supplieredit from "../../supplierPage/supplierUpdate";
import Orderupdate from "../../orderPage/orderUpdate";
import Purchaseorderrderdetails from "../../purchaseOrderPage/purchaseOrderDetails";
import Orderadd from "../../orderPage/orderAdd";
import ReportSalesCustomer from "../../reports/salesCustomerReport";
import ReportSalesItem from "../../reports/saleByItem";
import PaymentReceivedReport from "../../reports/paymentReceivedReport";
import PurchaseByItemReport from "../../reports/purchaseByItem";
import OrderPayment from "../../orderPage/orderPayment";
import ReportChart from "../../reports/ReportChart";
import Purchaseorder from "../../purchaseOrderPage/purchaseOrderList";
import Purchaseorderadd from "../../purchaseOrderPage/purchaseOrderAdd";
import Purchaseorderdetails from "../../purchaseOrderPage/purchaseOrderDetails";
import PurchaseOrderInvoice from "../../purchaseOrderPage/purchaseOrderInvoice";
import Orderdetails from "../../orderPage/orderDetails";
import OrderInvoice from "../../orderPage/orderInvoice";
import ViewInvoice from "../../invoice/viewInvoice";
import Tax from "../../Tax/Tax";
import Category from "../../Category/Category";
import Warehouse from "../../warehouse/warehouseList";
import Warehouseadd from "../../warehouse/addWarehouse";
import PurchaseOrderPaymentList from "../../purchaseOrderPage/purchaseOrderPaymentList";
import PurchaseOrderInvoiceList from "../../purchaseOrderPage/purchaseOrderInvoiceList";
import PurchaseOrderPaymentadd from "../../purchaseOrderPage/purchaseOrderPaymentAdd";
import PurchaseBySuppierReport from "../../reports/purchaseSuppierReport";
import Lead from "../../Lead/Lead";
import AddLead from "../../Lead/AddLead";
import LeadDetails from "../../Lead/LeadDetails";
import Meeting from "../../Meeting/Meeting";
import AddMeeting from "../../Meeting/AddMeeting";
import Participant from "../../Meeting/Participant";
import MeetingDetails from "../../Meeting/MettingsDetetails";
import Account from "../../Account/Account";
import AccountDetails from "../../Account/AccountDetails";
import AddAccount from "../../Account/AddAccount";
import Contact from "../../Contact/Contact";
import AddContact from "../../Contact/AddContact";
import ContactDetails from "../../Contact/ContactDetails";
import Convert from "../../Lead/Convert";
import BulkEmail from "../../BulkEmailMarketing/BulkEmail";
import AddCampagin from "../../BulkEmailMarketing/AddCampagin";
import CampginDetails from "../../BulkEmailMarketing/CampaginDetails";
import Role from "../../Role/AddRole/Roles";
import Permission from "../../Role/Permission";
import UserManagement from "../../UseManagement/UserManagement";
import ChangePassword from "../../UseManagement/ChangePassword";
import { useSelector } from "react-redux";

import { NearMeDisabledSharp } from "@mui/icons-material";
import AddUsers from "../../UseManagement/AddUsers";

import { useNavigate } from "react-router-dom";

const Main = (props) => {
  const pages = [
    {
      path: "/",
      title: "Dashboard",
      element: <Dashboard {...props} />,
      name: "/dashboard",
    },
    {
      path: "/dashboard",
      title: "Dashboard",
      element: <Dashboard />,
      name: "/dashboard",
    },
    {
      path: "/customer",
      title: "Customer",
      element: <Customer />,
      name: "/customer",
    },
    {
      path: "/product",
      title: "Product",
      element: <Product />,
      name: "/product",
    },
    { path: "/order", title: "Order", element: <Order />, name: "/order" },
    {
      path: "/payment",
      title: "Payment",
      element: <Payment />,
      name: "/payment",
    },
    { path: "/credit", title: "Credit", element: <Credit />, name: "/credit" },
    { path: "/stock", title: "Stock", element: <Stock />, name: "/stock" },
    {
      path: "/supplier",
      title: "Supplier",
      element: <Supplier />,
      name: "/supplier",
    },
    {
      path: "/supplier/add",
      title: "Supplier Add",
      element: <Supplieradd />,
      name: "/supplier",
    },
    {
      path: "/payment/add",
      title: "Payment Add",
      element: <PaymentAdd />,
      name: "/payment",
    },
    {
      path: "/credit/creditAdd",
      title: "Credit Add",
      element: <CreditAdd />,
      name: "/credit",
    },
    {
      path: "/supplier/details",
      title: "Supplier Details",
      element: <Supplierdetails />,
      name: "/supplier",
    },
    {
      path: "/supplier/edit",
      title: "Supplier Edit",
      element: <Supplieredit />,
      name: "/supplier",
    },
    {
      path: "/supplier/delete",
      title: "Supplier Delete",
      element: <Supplierdelete />,
      name: "/supplier",
    },
    {
      path: "/product/delete",
      title: "Product Delete",
      element: <Productdelete />,
      name: "/product",
    },
    {
      path: "/customer/add",
      title: "Customer Add",
      element: <Customeradd />,
      name: "/customer",
    },
    {
      path: "/product/edit",
      title: "Product Edit",
      element: <Productedit />,
      name: "/product",
    },
    {
      path: "/customer/details",
      title: "Customer Details",
      element: <Customerdetails />,
      name: "/customer",
    },
    {
      path: "/customer/delete",
      title: "Customer Delete",
      element: <Customerdelete />,
      name: "/customer",
    },
    {
      path: "/product/details",
      title: "Product Details",
      element: <Productdetails />,
      name: "/product",
    },
    {
      path: "/product/add",
      title: "Product Add",
      element: <Productadd />,
      name: "/product",
    },
    {
      path: "/product/add/:id",
      title: "Product Add",
      element: <Productadd />,
      name: "/product",
    },
    {
      path: "/setting/edit",
      title: "Setting Edit",
      element: <Settingedit />,
      name: "/setting",
    },
    {
      path: "/order/edit/:id",
      title: "Order Edit",
      element: <Orderupdate />,
      name: "/selesOrder",
    },

    {
      path: "/order/details/:id",
      title: "Order Details",
      element: <Orderdetails />,
      name: "/selesOrder",
    },
    {
      path: "/invoice/invoicelist",
      title: "Invoice List",
      element: <Invoicelist />,
      name: "/invoice",
    },
    {
      path: "order/add",
      title: "Order Add",
      element: <Orderadd />,
      name: "/selesOrder",
    },
    {
      path: "/purchaseorder/add",
      title: "Purchase Order Add",
      element: <Purchaseorderadd />,
      name: "/purchaseOrder",
    },
    {
      path: "/orderPage/orderdetails",
      title: "Order Details",
      element: <Orderdetails />,
      name: "/selesOrder",
    },

    {
      path: "/setting",
      title: "Setting",
      element: <Setting />,
      name: "/setting",
    },
    {
      path: "/invoice",
      title: "Invoice",
      element: <Invoicelist />,
      name: "/invoice",
    },
    { path: "/tax", title: "Tax", element: <Tax />, name: "/tax" },
    {
      path: "/category",
      title: "Category",
      element: <Category />,
      name: "/category",
    },
    {
      path: "/warehouse",
      title: "Warehouse",
      element: <Warehouse />,
      name: "/warehouse",
    },
    {
      path: "/purchaseorder",
      title: "Purchase Order",
      element: <Purchaseorder />,
      name: "/purchaseOrder",
    },
    { path: "/lead", title: "Lead", element: <Lead />, name: "/lead" },
    {
      path: "/meeting",
      title: "Meeting",
      element: <Meeting />,
      name: "/meeting",
    },
    {
      path: "/account",
      title: "Account",
      element: <Account />,
      name: "/account",
    },
    {
      path: "/contact",
      title: "Contact",
      element: <Contact />,
      name: "/contact",
    },
    {
      path: "/bulkemail",
      title: "Bulk Email",
      element: <BulkEmail />,
      name: "/bulkemail",
    },
    { path: "/role", title: "Role", element: <Role />, name: "/role" },
    {
      path: "/permission",
      title: "Permission",
      element: <Permission />,
      name: "/permission",
    },
    {
      path: "/usermanagement",
      title: "User Management",
      element: <UserManagement />,
      name: "/userManagement",
    },

    {
      path: "/orderPage/orderInvoice",
      title: "Order Invoice",
      element: <OrderInvoice />,
      name: "/invoice",
    },

    {
      path: "/invoice/viewInvoice",
      title: "View Invoice",
      element: <ViewInvoice />,
      name: "/invoice",
    },
    {
      path: "/salesbycustomerReport",
      title: "Sales By Customer Report",
      element: <ReportSalesCustomer />,
      name: "/report",
    },
    {
      path: "/salesbyItemReport",
      title: "Sales By Item Report",
      element: <ReportSalesItem />,
      name: "/report",
    },
    {
      path: "/paymentReceivedReport",
      title: "Payment Received Report",
      element: <PaymentReceivedReport />,
      name: "/report",
    },
    {
      path: "/purchaseByItem",
      title: "Purchase By Item Report",
      element: <PurchaseByItemReport />,
      name: "/report",
    },
    {
      path: "/purchaseBySupplier",
      title: "Purchase By Supplier Report",
      element: <PurchaseBySuppierReport />,
      name: "/report",
    },
    {
      path: "/orderPage/invoice",
      title: "Order Invoice",
      element: <OrderInvoice />,
      name: "/invoice",
    },
    {
      path: "/salesbycustomerReport",
      title: "Sales By Customer Report",
      element: <ReportSalesCustomer />,
      name: "/report",
    },
    // { path: "/salesbyItemsReport", title: "Sales By Item Report", element: <SalesByItemReport  />, name: "/salesbyItemsReport" },
    {
      path: "/reports",
      title: "Reports",
      element: <ReportChart />,
      name: "/reports",
    },
    {
      path: "/orderPage/Orderpayment",
      title: "Order Payment",
      element: <OrderPayment />,
      name: "/orderPayment",
    },
    {
      path: "/purchaseOrder/details",
      title: "Purchase Order Details",
      element: <Purchaseorderdetails />,
      name: "/purchaseOrder",
    },
    {
      path: "/purchaseOrder/invoice",
      title: "Purchase Order Invoice",
      element: <PurchaseOrderInvoice />,
      name: "/bill",
    },
    {
      path: "/purchaseOrder/invoiceList",
      title: "Purchase Order Invoice List",
      element: <PurchaseOrderInvoiceList />,
      name: "/bill",
    },
    {
      path: "/purchaseOrder/payment",
      title: "Purchase Order Payment",
      element: <PurchaseOrderPaymentList />,
      name: "/purchaseOrderPayment",
    },
    {
      path: "/purchaseOrder/paymentadd",
      title: "Purchase Order Payment Add",
      element: <PurchaseOrderPaymentadd />,
      name: "/purchaseOrderPayment",
    },
    { path: "/tax", title: "Tax", element: <Tax />, name: "/tax" },
    {
      path: "/category",
      title: "Category",
      element: <Category />,
      name: "/category",
    },
    {
      path: "/warehouse",
      title: "Warehouse",
      element: <Warehouse />,
      name: "/warehouse",
    },
    {
      path: "/warehouse/add",
      title: "Warehouse Add",
      element: <Warehouseadd />,
      name: "/warehouse",
    },
    { path: "/lead", title: "Lead", element: <Lead />, name: "/lead" },
    {
      path: "/addLead",
      title: "Add Lead",
      element: <AddLead />,
      name: "/lead",
    },
    {
      path: "/leaddetails",
      title: "Lead Details",
      element: <LeadDetails />,
      name: "/lead",
    },
    { path: "/convert", title: "Convert", element: <Convert />, name: "/lead" },
    {
      path: "/meeting",
      title: "Meeting",
      element: <Meeting />,
      name: "/meeting",
    },
    {
      path: "/addmeeting",
      title: "Add Meeting",
      element: <AddMeeting />,
      name: "/meeting",
    },
    {
      path: "/participant",
      title: "Participant",
      element: <Participant />,
      name: "/meeting",
    },
    {
      path: "/meetingdetails",
      title: "Meeting Details",
      element: <MeetingDetails />,
      name: "/meeting",
    },
    {
      path: "/account",
      title: "Account",
      element: <Account />,
      name: "/account",
    },
    {
      path: "/addaccount",
      title: "Add Account",
      element: <AddAccount />,
      name: "/account",
    },
    {
      path: "/accountdetails",
      title: "Account Details",
      element: <AccountDetails />,
      name: "/account",
    },
    {
      path: "/contact",
      title: "Contact",
      element: <Contact />,
      name: "/contact",
    },
    {
      path: "/addcontact",
      title: "Add Contact",
      element: <AddContact />,
      name: "/contact",
    },
    {
      path: "/contactdetails",
      title: "Contact Details",
      element: <ContactDetails />,
      name: "/contact",
    },
    {
      path: "/campaginList",
      title: "Campagin List",
      element: <BulkEmail />,
      name: "/campagin",
    },
    {
      path: "/addcampagin",
      title: "Add Campagin",
      element: <AddCampagin />,
      name: "/campagin",
    },
    {
      path: "/campginDetails",
      title: "Campagin Details",
      element: <CampginDetails />,
      name: "/campagin",
    },
    {
      path: "/roleListss",
      title: "Role List",
      element: <Role />,
      name: "/role",
    },
    {
      path: "/addpermission",
      title: "Add Permission",
      element: <Permission />,
      name: "/role",
    },
    {
      path: "/users",
      title: "User Management",
      element: <UserManagement />,
      name: "/user",
    },
    {
      path: "/addUsers",
      title: "Add User",
      element: <AddUsers />,
      name: "/user",
    },

    {
      path: "/changePassowrd",
      title: "Change Password",
      element: <ChangePassword />,
      name: "/user",
    },
  ];

  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  return (
    <Box
      component="main"
      sx={{ backgroundColor: "revert", mt: 2, padding: "0px 20px 50px 30px" }}
    >
      <Routes>
        {pages.map((list, index) => {
          const { name, path, element } = list;
          const permission =
            user &&
            user.rolePermission &&
            user.rolePermission.find((permission) => {
              return permission.pageName === name;
            });

          if (permission && permission.readP == 1) {
            return <Route key={path} path={path} element={element} />;
          } else {
            return (
              <Route key={index} path={path} element={<h1>Not Found</h1>} />
            );
          }
        })}
      </Routes>
    </Box>
  );
};

export default Main;
