import * as React from "react";
import Box from "@mui/material/Box";
import { TextField, FormLabel, Button, Tab } from "@mui/material";

import { Card } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import axiosInstance from "../../utils/AxiosInstace";
import { useNavigate, useLocation } from "react-router-dom";
import { getCustomerDetails } from "../../state/action/customerAction";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArticleIcon from "@mui/icons-material/Article";
import LinkIcon from "@mui/icons-material/Link";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import ContactInformationDetails from "./ContactInformationDeatils";
import ContactHistoryLineDetails from "./ContactHistoryLineDetails";

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const strong = {
  color: "black",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const ContactDetails = () => {
  const location = useLocation();
  const [deleteUser, setDeleteUser] = useState(false);
  const [customerdata, setCustomerdata] = useState([]);
  const [addressdata, setAddressdata] = useState([]);
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { t } = useTranslation();
  const GetCustomer = async () => {
    const res = await getCustomerDetails(state);
    if (res && res.status === true) {
      setCustomerdata(res.data);
      setAddressdata(res.data.address);
    }
  };

  useEffect(() => {
    GetCustomer();
  }, []);

  return (
    <>
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h1
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "4px",
              }}
            >
              {" "}
              {t("View_Contact")}{" "}
            </h1>

            <p
              style={{
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "34px",
                marginTop: "0px",
                cursor: "pointer",
                paddingTop: "5px",
                paddingBottom: "5px",
                hover: {
                  color: "#E64A08",
                },
              }}
              onClick={() => {
                navigate("/contact");
              }}
            >
              {t("Contact")} |{" "}
              <span style={{ color: "black", cursor: "pointer" }}>
                {" "}
                {t("View_Contact")}
              </span>
            </p>
          </div>
          <Box marginTop="20px" borderRadius="12px">
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#F4F6FF",
                borderRadius: "10px",
                marginRight: "10px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <strong style={{ color: "black" }}>{t("Back")}</strong>
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                backgroundColor: "#f06237",
                borderRadius: "10px",
              }}
              onClick={() => {
                navigate(`/addContact`, { state: customerdata.id });
              }}
            >
              <strong style={{ color: "white" }}>{t("Edit")}</strong>
            </Button>
          </Box>
        </div>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="OverView" value="1" />
                <Tab label="TimeLine" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ContactInformationDetails />
            </TabPanel>
            <TabPanel value="2">
              <ContactHistoryLineDetails />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default ContactDetails;
