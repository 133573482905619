import handleAuthToken from "../../utils/handleAuthToken";
import axios from "axios";

export const getTax = () => async () => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`api/v1/gettax`);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const addTaxApi = (values) => async () => {
  if (localStorage.token) {
    handleAuthToken(localStorage.token);
  }
  try {
    console.log("valuew", typeof (values))
    const res = await axios.post(`api/v1/addtax`, values);
    console.log("working", res)
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

