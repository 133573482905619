import axios from "axios";
import { useSelector } from "react-redux";

export const IsRead = (pageNames) => {
  const { user } = useSelector((state) => state.auth);

  const isRead =
    user &&
    user.rolePermission &&
    user.rolePermission.find((permission) => {
      if (permission.readP == 1) {
        const index = pageNames.indexOf(permission.pageName);
        if (index > -1) {
          return true;
        }
        return false;
      }
      return false;
    });
  return isRead;
};

// const elementIndex = () => {
//   const { user } = useSelector((state) => state.auth);

//   user && user.permissions.findIndex((item) => item.pageSlug === pageName);
//   if (elementIndex === -1) {
//     return false;
//   } else {
//     if (
//       user &&
//       user.permissions &&
//       user.permissions[elementIndex].readP === 1
//     ) {
//       return true;
//     } else {
//       return false;
//     }
//   }
// };

export const IsWrite = (pageNames) => {
  const { user } = useSelector((state) => state.auth);

  const elementIndex =
    user &&
    user.rolePermission &&
    user.rolePermission.findIndex((item) => item.pageName === pageNames);

  if (elementIndex === -1) {
    return false;
  } else {
    if (
      user &&
      user.rolePermission &&
      user.rolePermission[elementIndex].writingP === 1
    ) {
      return true;
    } else {
      return false;
    }
  }
};

export const IsDelete = (pageNames) => {
  const { user } = useSelector((state) => state.auth);
  const elementIndex =
    user &&
    user.rolePermission &&
    user.rolePermission.findIndex((item) => item.pageName === pageNames);
  if (elementIndex === -1) {
    return false;
  } else {
    if (
      user &&
      user.rolePermission &&
      user.rolePermission[elementIndex].deleteP === 1
    ) {
      return true;
    } else {
      return false;
    }
  }
};
