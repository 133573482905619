import "./App.css";
// sidebar import
import React, { useEffect } from "react";
// import Sidebar from "./component//Layout/Sidebar/sidebar"
import Layout from "./component/Layout/index";
import PrivateRoute from "./component/privateRoute/privateRoute";
import { Routes, Route, Router, useLocation } from "react-router-dom";
import Login from "./component/loginPage/login";
import Details from "./component/loginPage/details.jsx";
import Forget from "./component/forgetPassword/fogetPassword.jsx";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./state/action/authAction";
import ForgetPassword from "./component/loginPage/passsword.jsx";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, []);


  return (
    <>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forget" element={<Forget />} />
        <Route exact path="/forgetpassword" element={<ForgetPassword />} />
        <Route exact path="/details" element={<Details />} />


        <Route
          path="*"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </>
  );
}

export default App;
