import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    labels: ['1'],
    datasets: [
        {
            label: '# of Votes',
            data: [12],
            backgroundColor: ['#61CDBB'],
            // borderColor: [
            //   'rgba(255, 99, 132, 1)',
            //   'rgba(54, 162, 235, 1)',
            //   'rgba(255, 206, 86, 1)',
            //   'rgba(75, 192, 192, 1)',
            //   'rgba(153, 102, 255, 1)',
            // ],
            // borderWidth: 1,
        },
    ],
};

export default function PieChart() {
    return (
        <>
            <Pie data={data} width='100px' height="150px"
                options={{ maintainAspectRatio: false }} />

        </>
    )
}
