import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "../productPage/productList";
import { useState } from "react";
import "../customerPage/style/addCustomer.css";
import { Link, useNavigate } from "react-router-dom";
import {
  TextField,
  FormLabel,
  Grid,
  Button,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  addAdmins,
  roleDropdown,
  updateAdmin,
} from "../../state/action/adminAction";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  password: "",
};

export default function CustomizedInputs(props) {
  const { t } = useTranslation();
  const { state } = useLocation();

  const navigate = useNavigate();
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [roles, setRoles] = useState([]);
  const [adminlist, setAdminList] = useState([]);
  const [edit, setIsEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (state && state?.user?.id) {
      setValues({
        firstName: state?.user?.firstName,
        lastName: state?.user?.lastName,
        email: state?.user?.email,
        role: state?.user?.roleId,
      });
      setIsEdit(true);
    }
  }, [state]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validatePassword = (fieldValues, temp, key, value) => {
    if (value !== "") {
      if (value.length < 8) {
        temp[key] = "Password  must be 8 charecter";
      } else {
        if (value.length > 128) {
          temp[key] = "Password  must be less than 128 charecter";
        } else {
          if (!/[0-9]/.test(value)) {
            temp[key] = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(value)) {
              temp[key] = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(value)) {
                temp[key] = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp[key] = "";
              }
            }
          }
        }
      }
    } else {
      temp[key] = "This field is required.";
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("firstName" in fieldValues)
      temp.firstName = fieldValues.firstName ? "" : "This field is required.";
    if ("lastName" in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "This field is required.";
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if ("role" in fieldValues)
      temp.role = fieldValues.role ? "" : "This field is required.";

    if ("password" in fieldValues) {
      validatePassword(fieldValues, temp, "password", fieldValues.password);
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        role: values.role,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
      };

      if (edit) {
        const res = await updateAdmin(state?.user.id, payload);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          setTimeout(() => {
            navigate("/users");
          }, 1000);
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "warning",
          });
        }
      } else {
        const res = await addAdmins(payload);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          setTimeout(() => {
            navigate("/users");
          }, 1000);
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "warning",
          });
        }
      }
    }
  };

  const roleDropDownList = async () => {
    const res = await roleDropdown();
    if (res && res.status === true) {
      setRoles(res.data);
    }
  };
  useEffect(() => {
    roleDropDownList();
  }, []);

  return (
    <>
      <div className="purchaseOrderheadder">
        <div>
          <h1 className="purchaseOrderheadder-left">
            {" "}
            {edit ? t("Edit User") : t("Add User")}
          </h1>
          <p
            className="purchaseOrderheadder-right"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/users");
            }}
          >
            {" "}
            {t("User")} |
            <span
              className="purchaseOrderheadder-right-span"
              style={{ color: "black" }}
            >
              {" "}
              {edit ? t("Edit User Details") : t("Add User Details")}
            </span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Box className="b1">
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Typography>{t("User Information")}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1, marginTop: "22px" }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="zipCode">First Name</StyledFormLabel>
              <StyledTextField
                fullWidth
                inputProps={{ maxLength: 10 }}
                name="firstName"
                value={values.firstName}
                onChange={handleInputChange}
                error={errors.firstName}
                helperText={errors.firstName}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Box sx={{ marginTop: "13px" }}>
                <StyledFormLabel htmlFor="zipCode">Last Name</StyledFormLabel>
                <StyledTextField
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  name="lastName"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={errors.lastName}
                  helperText={errors.lastName}
                />
              </Box>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="zipCode">Email</StyledFormLabel>
              <StyledTextField
                fullWidth
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="role">Role</StyledFormLabel>
              <select
                id="role"
                name="role"
                fullWidth
                select
                value={values.role}
                onChange={handleInputChange}
                // onChange={(e) => {
                //   getStateListData(e.target.value);
                // }}

                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">Select Role</option>

                {roles.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.roleName}
                    </option>
                  );
                })}

                {Boolean(errors.role) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.role}
                  </p>
                ) : (
                  ""
                )}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              {!edit ? (
                <Box sx={{ marginTop: "13px" }}>
                  <StyledFormLabel htmlFor="zipCode">PassWord</StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    name="password"
                    value={values.password}
                    onChange={handleInputChange}
                    error={errors.password}
                    type={showPassword ? "text" : "password"}
                    helperText={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start" sx={{ m: 0 }}>
                          <Tooltip
                            title={
                              showPassword ? "Hide Password" : "Show Password"
                            }
                          >
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              ) : null}
            </Grid>

            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              {edit ? (
                <Button
                  onClick={() => {
                    navigate("/changePassowrd");
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  <Link to="/changePassowrd">{t("Change Password")} ?</Link>
                </Button>
              ) : null}
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "74px",
                borderRadius: "12px",
                padding: "20px",
                marginLeft: "785px",
              }}
            >
              <div>
                <Button
                  variant="outlined"
                  size="large"
                  className="buttonCancel"
                  onClick={() => {
                    navigate("/users");
                  }}
                  style={{
                    borderRadius: "10px",
                    width: "40%",
                    height: "94%",
                  }}
                >
                  <strong style={strong}>{t("Cancel")}</strong>
                </Button>{" "}
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    backgroundColor: "#f06237",
                    borderRadius: "10px",
                  }}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <strong className="purchaseOrderheadder-box-button2-strong">
                    {t("Save")}
                  </strong>
                </Button>
              </div>
            </Box>
          </Grid>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
