import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import {
    Card, MenuItem,
} from '@mui/material';
import { Navigate } from "react-router-dom";
import { Link, useNavigate, useParams } from 'react-router-dom';
import "./style/addWarehouse.css"
import { styled } from "@mui/material/styles";
import { useForm, Form } from "../../utils/useForms";
import {
    TextField, FormLabel, Tooltip
} from "@mui/material";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../utils/AxiosInstace";
import { store } from "../../state/store";
import { useTranslation } from "react-i18next";

const StyledTextField = styled(TextField)(() => ({
    marginTop: "3px",
    ".MuiOutlinedInput-root": {
        borderRadius: "10px",
        height: "33px",
        paddingTop: "0px",

    },
    ".MuiOutlinedInput-input": {
        fontSize: "13px",
    },
}));
const StyledFormLabel = styled(FormLabel)(() => ({
    display: "block",
    fontWeight: "bold",
    color: "black",
    fontSize: "13px",
}));

const strong = {
    color: 'black',
}

const WarehouseAdd = () => {
    const [statelist, setStatelist] = useState([]);
    const [countrylist, setCountrylist] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    let id = location.state ? location.state.user.id : "";
    const button = id ? "Save" : "Add Warehouse"
    const headder = id ? "Edit Warehouse" : "Add Warehouse"

    const initialValues = {
        companyName: "",
        companyEmail: "",
        personalEmail: "",
        mobileNumber: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
        website: "",
    };

    const validate = (fieldValues = values) => {

        let temp = { ...errors }
        if ('companyName' in fieldValues)
            temp.companyName = fieldValues.companyName ? "" : "This field is required."
        if ('companyEmail' in fieldValues) {
            //email validation regex =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fieldValues.companyEmail) === false) {
                temp.companyEmail = "Please enter valid email address."
            }
            else if (fieldValues.companyEmail === "") {
                temp.companyEmail = "This field is required."
            }
            else {
                temp.companyEmail = ""
            }
        }

        if ('personalEmail' in fieldValues) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fieldValues.personalEmail) === false) {
                temp.personalEmail = "Please enter valid email address."
            }
            else if (fieldValues.personalEmail === "") {
                temp.personalEmail = "This field is required."
            }
            else {
                temp.personalEmail = ""
            }
        }

        if ('mobileNumber' in fieldValues) {
            //mobile number validation regex =  /^[0-9]{10}$/
            if (/^[0-9\b]+$/.test(fieldValues.mobileNumber) === false) {
                temp.mobileNumber = "Please enter valid mobile number."
            }
            else if (fieldValues.mobileNumber === "") {
                temp.mobileNumber = "This field is required."
            }
            else {
                temp.mobileNumber = ""
            }
        }
        if ('street1' in fieldValues) {
            //street1 validation regex =  /^[a-zA-Z0-9\s,'-]*$/
            if (/^[a-zA-Z0-9\s,'-]*$/.test(fieldValues.street1) === false) {
                temp.street1 = "Please enter valid street1."
            }
            else if (fieldValues.street1 === "") {
                temp.street1 = "This field is required."
            }
            else {
                temp.street1 = ""
            }

        }
        if ('street2' in fieldValues) {
            //street2 validation regex =  /^[a-zA-Z0-9\s,'-]*$/
            if (/^[a-zA-Z0-9\s,'-]*$/.test(fieldValues.street2) === false) {
                temp.street2 = "Please enter valid street2."
            }
            else if (fieldValues.street2 === "") {
                temp.street2 = "This field is required."
            }
            else {
                temp.street2 = ""
            }
        }
        if ('city' in fieldValues) {
            //regex for city = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/
            if (/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/.test(fieldValues.city) === false) {
                temp.city = "Please enter valid city."
            }
            else if (fieldValues.city === "") {
                temp.city = "This field is required."
            }
            else {
                temp.city = ""
            }
        }
        if ('state' in fieldValues)
            temp.state = fieldValues.state ? "" : "This field is required."
        if ('country' in fieldValues) {
            if (fieldValues.country === "") {
                temp.country = "This field is required."
            }
            else {
                temp.country = ""
            }
        }
        if ('zipCode' in fieldValues) {
            //regex for zip code = /^[0-9]{6}$/
            if (/^[0-9\b]+$/.test(fieldValues.zipCode) === false) {
                temp.zipCode = "Please enter valid zip code."
            }
            else if (fieldValues.zipCode === "") {
                temp.zipCode = "This field is required."
            }
            else {
                temp.zipCode = ""
            }

        }
        if ('website' in fieldValues) {
            //regex for website = /^(http(s)?:\/\/)?((w){3}.)?w{3}.[a-zA-Z0-9]+.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
            if (fieldValues.website === "") {
                temp.website = "This field is required."
            }
            else {
                temp.website = ""
            }
        }

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")


    }

    useEffect(() => {
        getCountry()
    }, [])

    const getCountry = async (e) => {
        await axiosInstance
            .get(`/api/v1/allcountry`).then((res) => {
                setCountrylist(res.data.data)
            }).catch((e) => {
                console.log("error found")
            })

    }

    const getStateListData = (e) => {
        setValues({
            ...values,
            country: e.target.value,
        });
        axiosInstance
            .get(`/api/v1/allstate/${e.target.value}`).then((res) => {
                setStatelist(res.data.data)
            }).catch((e) => {
                console.log("error found")
            })
    }



    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);
    const Navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { auth } = store.getState()
        const userid = auth?.user?.id;
        var formData = new FormData();
        formData.append('companyName', values.companyName);
        formData.append('companyEmail', values.companyEmail);
        formData.append('personalEmail', values.personalEmail);
        formData.append('mobileNumber', values.mobileNumber);
        formData.append('street1', values.street1);
        formData.append('street2', values.street2);
        formData.append('city', values.city);
        formData.append('state', values.state);
        formData.append('country', values.country);
        formData.append('zipCode', values.zipCode);
        formData.append('website', values.website);

        if (validate()) {

            await axiosInstance
                .post(`/api/v1/adminregister`, formData).then((res) => {
                    Navigate("/warehouse");
                }
                ).catch((e) => {
                    console.log("error found")
                }
                )
        }
    }


    return (
        <>
            <div
                id="warehouseheadder"
            >
                <div>
                    <h1 id='warehouseheadder-left'> {t("Add_Warehouse")}</h1>
                    <p id='warehouseheadder-right'> {t("Warehouse")} |
                        <span id='warehouseheadder-right-span'>  {t("Add_Warehouse_Details")}</span>
                    </p>
                </div>
            </div>

            <Form onSubmit={handleSubmit} >
                <Card style={{ padding: "16px" }}>

                    <Grid item lg={12} spacing={3} container md={12} xs={12}>

                        <Grid item lg={4} md={4} xs={12}>
                            <StyledFormLabel htmlFor="companyName">
                                {t("Warehouse_Name")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("companyName")}
                                value={values.companyName}
                                onChange={handleInputChange}
                                error={Boolean(errors.companyName)}
                                helperText={errors.companyName}
                            />
                        </Grid>

                        <Grid item md={4} lg={4} xs={12}>
                            <StyledFormLabel htmlFor="mobileNumber">
                                {t('Phone_Number')}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("mobileNumber")}
                                value={values.mobileNumber}
                                onChange={handleInputChange}
                                error={Boolean(errors.mobileNumber)}
                                helperText={errors.mobileNumber}
                            />
                        </Grid>

                        <Grid item md={4} lg={4} xs={12}>
                            <StyledFormLabel htmlFor="website">
                                {t("Company_Website")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("website")}
                                value={values.website}
                                onChange={handleInputChange}
                                error={Boolean(errors.website)}
                                helperText={errors.website}
                            />
                        </Grid>


                        <Grid item md={6} lg={6} xs={12}>
                            <StyledFormLabel htmlFor="companyEmail">
                                {t("Comapany_Email")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("companyEmail")}
                                value={values.companyEmail}
                                onChange={handleInputChange}
                                error={Boolean(errors.companyEmail)}
                                helperText={errors.companyEmail}
                            />
                        </Grid>


                        <Grid item md={6} lg={6} xs={12}>
                            <StyledFormLabel htmlFor="personalEmail">
                                {t("Personal_Email")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("personalEmail")}
                                value={values.personalEmail}
                                onChange={handleInputChange}
                                error={Boolean(errors.personalEmail)}
                                helperText={errors.personalEmail}
                            />
                        </Grid>

                        <Grid item sx={{ m: 1 }} md={11.5} xs={12}>
                            <hr />
                            <StyledFormLabel htmlFor="address">
                                {t("Address")}
                            </StyledFormLabel>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <StyledFormLabel htmlFor="street1">
                                {t("Street_1")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("street1")}
                                value={values.street1}
                                onChange={handleInputChange}
                                error={Boolean(errors.street1)}
                                helperText={errors.street1}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <StyledFormLabel htmlFor="street2">
                                {t("Street_2")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("street2")}
                                value={values.street2}
                                onChange={handleInputChange}
                                error={Boolean(errors.street2)}
                                helperText={errors.street2}
                            />
                        </Grid>

                        <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
                            <StyledFormLabel htmlFor="country">
                                {t("Country")}
                            </StyledFormLabel>
                            <StyledTextField
                                id="country"
                                name={t("country")}
                                fullWidth
                                select
                                value={values.country}
                                onChange={(e) => {
                                    getStateListData(e);
                                    handleInputChange(e);
                                }}
                                error={Boolean(errors.country)}
                                helperText={errors.country}
                            >
                                {countrylist.map((val) => {
                                    return (
                                        <MenuItem key={val.id} value={val.id}>
                                            {val.name}
                                        </MenuItem>
                                    )
                                })}
                            </StyledTextField>

                        </Grid>

                        <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
                            <StyledFormLabel htmlFor="state">
                                {t("State")}
                            </StyledFormLabel>
                            <StyledTextField
                                id="state"
                                name={t("state")}
                                fullWidth
                                select
                                value={values.state}
                                onChange={handleInputChange}
                                error={Boolean(errors.state)}
                                helperText={errors.state}
                            >
                                {statelist.map((val) => {
                                    return (
                                        <MenuItem key={val.id} value={val.id}>
                                            {val.name}
                                        </MenuItem>
                                    )
                                })}
                            </StyledTextField>
                        </Grid>
                        <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
                            <StyledFormLabel htmlFor="city">
                                {t("City")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("city")}
                                value={values.city}
                                onChange={handleInputChange}
                                error={Boolean(errors.city)}
                                helperText={errors.city}
                            />
                        </Grid>
                        <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
                            <StyledFormLabel htmlFor="zipCode">
                                {t("Zip_Code")}
                            </StyledFormLabel>
                            <StyledTextField
                                fullWidth
                                name={t("zipCode")}
                                value={values.zipCode}
                                onChange={handleInputChange}
                                error={Boolean(errors.zipCode)}
                                helperText={errors.zipCode}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        marginTop="20px"
                        borderRadius="12px"
                        padding="20px"
                    >
                        <Link to="/warehouse/" style={{
                            textDecoration: 'none',
                        }}><Button variant="outlined" size="large"
                            style=
                            {{
                                border: '1px solid  #282822',
                                borderRadius: '10px',
                                fontSize: 16,
                                color: 'black',
                                marginRight: '20px',
                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                            }}
                        >
                                <strong style={strong}>{t("Cancel")}</strong>
                            </Button> </Link>
                        <Button variant="contained" size="large"
                            type='submit'
                            style={{
                                backgroundColor: '#f06237',
                                borderRadius: '10px',
                            }}
                        >
                            <strong style={{ color: "white" }}>{button}</strong>
                        </Button>
                    </Box>
                </Card>
            </Form>






        </>
    );
}
export default WarehouseAdd;