import React from 'react'
import { Avatar, Backdrop, CircularProgress } from "@mui/material";

const BackdropComponent = (props) => {
    const { open } = props;
    return (
        <Backdrop
            id="special"
            sx={{
                color: "#fff",
                zIndex: "2222",
            }}
            open={open}
        >
            <CircularProgress />
        </Backdrop>
    );
};
export default BackdropComponent;
