import axiosInstance from "../../utils/AxiosInstace";

//add admin

export const addAdmins = async (data) => {
  try {
    const response = await axiosInstance.post(
      `/api/v1/adminCreateByAdmin`,
      data
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//list
export const getAdminsList = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/getAllAdmins`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//update admin

export const updateAdmin = async (id, data) => {
  try {
    const res = await axiosInstance.post(
      `/api/v1/adminUpdateByAdmin/${id}`,
      data
    );
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//role dropdown
export const roleDropdown = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/getRoleDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//change password

export const PasswordChange = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/api/v1/changePassword`,
      payload
    );
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};
