import React, { useState } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import StockList from "./stocklist";
import AddStock from './stockAdd';
import { useTranslation } from 'react-i18next';

const StockPage = () => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const refreshScreen = () => {
    setRefresh(!refresh);
  };
  const [addStock, setAddStock] = useState(false);
  const openStockModal = () => {
    setAddStock(true);
  };
  const closeStockModal = () => {
    setAddStock(false);
  };
  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
          {t("Stock")}
        </Typography>
        <Button
          sx={{
            backgroundColor: "#F06237",
            color: "white",
            borderRadius: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            "&:hover": {
              backgroundColor: "#F06237",
            },
          }}
          onClick={openStockModal}
          startIcon={<Add />}
          color="primary"
        >
          {t("Add_Stock")}
        </Button>
      </Stack>
      <AddStock
        open={addStock}
        setOpen={setAddStock}
        handleClickOpen={openStockModal}
        handleClose={closeStockModal}
        refreshScreen={refreshScreen}
      />
      <Box sx={{ mt: 4 }}>
        <StockList refresh={refresh} refreshScreen={refreshScreen} />
      </Box>
    </>
  );
};
export default StockPage;
