import { Box, Grid } from "@mui/material";
import Sidebar from "./Sidebar/sidebar";
import Header from "./Header/header";
import React from "react";
import Main from "./Main/main";

const LayoutPage = (props) => {
    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "17%", height: "100vh", overflowY: "visible" }}>
                <Sidebar {...props} />
            </Box>
          
            <Box style={{
                width: "83%",
                flexDirection: "column", flexGrow: "1", backgroundColor: "#E6E6E6", overflowY: "scroll",
}}>
            <Box
                sx={{
                    
                        backgroundColor: "",
                }}
            >
                <Header {...props} />
                
            </Box>
            <Box
                sx={{
                   
                    backgroundColor: " #E6E6E6",
                }}
            >
            
                <Main {...props} />
            </Box>
            </Box>
        </Box>
    );
};

export default LayoutPage;
