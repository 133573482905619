import {
    GET_CUSTOMERS,
    
    GET_CUSTOMER,
    
   
} from "../action-types/customerActionTypes.js";
const initialState = {
    customers: [],
    customer: {},
   
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CUSTOMERS:
            return {
                ...state,
                customers: payload
            }
       
        case GET_CUSTOMER:

            return {
                ...state,
                customer: action.payload
            }
       
       
        
                
        default:
            return state
    }
}