import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "../productPage/productList";
import { useState } from "react";
import "../customerPage/style/addCustomer.css";
import { Link, useNavigate } from "react-router-dom";
import { TextField, FormLabel, Grid, Button, Typography } from "@mui/material";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getCustomerDetails,
  getCountry,
  getStateList,
  addCustomer,
  updateCustomer,
} from "../../state/action/customerAction";
import {
  AddContactss,
  AddUser,
  getAccountDrodwnList,
  getContactDetails,
  leadTypeDropdown,
  updateContact,
} from "../../state/action/contactAction";
import { leadOwnerDropdown } from "../../state/action/leadAction";
import { async } from "q";

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  contactOwnerId: "",
  accountId: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  leadSource: "",
  street: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  description: "",
  createdBy: "",
  leadStatus: "",
};

const leadSource = [
  {
    id: 1,
    sourceName: "facebook",
  },
  {
    id: 2,
    sourceName: "instagram",
  },
  {
    id: 3,
    sourceName: "website",
  },
  {
    id: 4,
    sourceName: "partner",
  },
];

const leadstatus = [
  {
    id: 1,
    leadStatuss: "hot",
  },
  {
    id: 2,
    leadStatuss: "cold",
  },
  {
    id: 3,
    leadStatuss: "natural",
  },
];

const AddContact = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [countrylist, setCountrylist] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [leadOwner, setLeadOwner] = useState([]);
  const [edit, setIsEdit] = useState(false);
  const [accountList, setAccountList] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    getCountryList();
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("customerName" in fieldValues) {
      if (fieldValues.customerName !== "") {
        if (/^[a-zA-Z0-9\s]{1,50}$/.test(fieldValues.customerName)) {
          temp.customerName = "";
        } else {
          temp.customerName = "number or characters and 50 digits allow only";
        }
      } else {
        temp.customerName = "This field is required.";
      }
    }
    if ("vatNumber" in fieldValues) {
      if (fieldValues.vatNumber !== "") {
        if (/^[a-zA-Z0-9]{1,14}$/.test(fieldValues.vatNumber)) {
          temp.vatNumber = "";
        } else {
          temp.vatNumber = " alphanumeric & maximum 14 digits allow";
        }
      } else {
        temp.vatNumber = "This field is required.";
      }
    }
    if ("swiftCode" in fieldValues) {
      if (fieldValues.swiftCode !== "") {
        temp.swiftCode = "";
      } else {
        temp.swiftCode = "This field is required.";
      }
    }

    if ("mobileNumber" in fieldValues) {
      if (fieldValues.mobileNumber !== "") {
        if (/^\d{10,15}$/.test(fieldValues.mobileNumber)) {
          temp.mobileNumber = "";
        } else {
          temp.mobileNumber = "10 to 15 digits Number allow";
        }
      } else {
        temp.mobileNumber = "This field is required.";
      }
    }

    if ("accountNumber" in fieldValues) {
      if (fieldValues.accountNumber !== "") {
        temp.accountNumber = "";
      } else {
        temp.accountNumber = "This field is required.";
      }
    }

    if ("ifscCode" in fieldValues) {
      if (fieldValues.ifscCode !== "") {
        temp.ifscCode = "";
      } else {
        temp.ifscCode = "This field is required.";
      }
    }

    if ("customerEmail" in fieldValues) {
      if (fieldValues.customerEmail !== "") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            fieldValues.customerEmail
          )
        ) {
          temp.customerEmail = "";
        } else {
          temp.customerEmail = "Enter valid Email";
        }
      } else {
        temp.customerEmail = "This field is required.";
      }
    }

    if ("accountName" in fieldValues) {
      if (fieldValues.accountName !== "") {
        temp.accountName = "";
      } else {
        temp.accountName = "This field is required.";
      }
    }
    if ("bankName" in fieldValues) {
      if (fieldValues.bankName !== "") {
        temp.bankName = "";
      } else {
        temp.bankName = "This field is required.";
      }
    }

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "This field is required.";
    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
    // return true
  };

  const getCountryList = async (e) => {
    const res = await getCountry();
    if (res.status === true) {
      setCountrylist(res.data);
    }
  };

  //account Dropdown
  const getAccountDrodwn = async (e) => {
    const res = await getAccountDrodwnList();
    if (res.status === true) {
      setAccountList(res.data);
    }
  };

  useEffect(() => {
    getAccountDrodwn();
  }, []);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        contactOwnerId: values.contactOwnerId,
        accountId: values.accountId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        leadSource: values.leadSource,
        state: values.state,
        country: values.country,
        street: values.street,
        city: values.city,
        zipCode: values.zipCode,
        description: values.description,
        createdBy: "1",
        createdAt: Date.now(),
        status: "1",
      };

      if (edit) {
        const res = await updateContact(data, state.user);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          setTimeout(() => {
            navigate("/contact");
          }, 1000);
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "warning",
          });
        }
      } else {
        const res = await AddContactss(data);
        if (res && res.status === true) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
          setTimeout(() => {
            navigate("/contact");
          }, 1000);
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "warning",
          });
        }
      }
    }
  };
  useEffect(() => {
    if (state) {
      getContactDetailsss(state);
      setIsEdit(true);
    }
  }, [state]);

  const getContactDetailsss = async () => {
    const res = await getContactDetails(state.user);

    if (res.status === true) {
      setValues({
        contactOwnerId: res.data.contactOwnerId,
        accountId: res.data.accountId,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        phone: res.data.phone,
        leadSource: res.data.leadSource,
        state: res.data.state.id,
        country: res.data.country.id,
        street: res.data.street,
        city: res.data.city,
        zipCode: res.data.zipCode,
        description: res.data.description,
        // leadStatus: res.data.leadStatus,
        createdAt: Date.now(),
      });
    }
  };

  const getStateListData = async (e) => {
    setValues({
      ...values,
      country: e,
    });
    const res = await getStateList(e);

    if (res.status === true) {
      setStatelist(res.data);
    }
  };

  //lead Owner
  const getLeadOwnerList = async (e) => {
    const res = await leadOwnerDropdown();
    if (res.status === true) {
      setLeadOwner(res.data);
    }
  };

  useEffect(() => {
    getLeadOwnerList();
  }, []);

  return (
    <>
      <div className="purchaseOrderheadder">
        <div>
          <h1 className="purchaseOrderheadder-left">
            {" "}
            {edit ? t("Edit_Contact") : t("Add_Contact")}
          </h1>
          <p
            className="purchaseOrderheadder-right"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/contact");
            }}
          >
            {" "}
            {t("Contact")} |
            <span
              className="purchaseOrderheadder-right-span"
              style={{ color: "black" }}
            >
              {" "}
              {edit ? t("Edit_Contact_Details") : t("Add_Contact_Details")}
            </span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Box className="b1">
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Typography>{t("contactInformation")}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="firstName">
                {t("firstName")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="firstName"
                value={values.firstName}
                onChange={handleInputChange}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="lastName">
                {t("lastName")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="lastName"
                value={values.lastName}
                onChange={handleInputChange}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="email">{t("Email")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="contactPhonenumber">
                {t("Contact_Phone_Number")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="phone"
                value={values.phone}
                onChange={handleInputChange}
                error={Boolean(errors.phone)}
                helperText={errors.phone}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="accountId">
                {t("Account")}
              </StyledFormLabel>
              <select
                id="accountId"
                name="accountId"
                fullWidth
                select
                value={values.accountId}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Account")}</option>

                {accountList.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.accountName}
                    </option>
                  );
                })}

                {Boolean(errors.leadSource) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.leadSource}
                  </p>
                ) : (
                  ""
                )}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="leadSource">
                {t("Contact_Source")}
              </StyledFormLabel>
              <select
                id="leadSource"
                name="leadSource"
                fullWidth
                select
                value={values.leadSource}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Lead_Source")}</option>

                {leadSource.map((val) => {
                  return (
                    <option key={val.sourceName} value={val.sourceName}>
                      {val.sourceName}
                    </option>
                  );
                })}

                {Boolean(errors.leadSource) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.leadSource}
                  </p>
                ) : (
                  ""
                )}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="contactOwnerId">
                {t("Lead_owner")}
              </StyledFormLabel>
              <select
                id="contactOwnerId"
                name="contactOwnerId"
                fullWidth
                select
                value={values.contactOwnerId}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Contact_Owner")}</option>

                {leadOwner.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>

          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <hr />
              <Typography>{t("addressInformation")}</Typography>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="street">{t("Street")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name="street"
                value={values.street}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="country">
                {t("Country")}
              </StyledFormLabel>
              <select
                id="country"
                name="country"
                fullWidth
                select
                value={values.country}
                onChange={(e) => {
                  getStateListData(e.target.value);
                }}
                error={Boolean(errors.country)}
                helperText={errors.country}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Country")}</option>

                {countrylist.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="state">{t("State")}</StyledFormLabel>
              <select
                id="state"
                name="state"
                fullWidth
                select
                value={values.state}
                onChange={handleInputChange}
                error={Boolean(errors.state)}
                helperText={errors.state}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_State")}</option>

                {statelist.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="city">{t("City")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name="city"
                value={values.city}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="zipCode">
                {t("ZipCode")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                inputProps={{ maxLength: 10 }}
                name="zipCode"
                value={values.zipCode}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <hr />
              <Typography>{t("description")}</Typography>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <StyledFormLabel htmlFor="description">
                {t("contact_description")}
              </StyledFormLabel>
              <StyledTextFields
                id="outlined-multiline-flexible"
                multiline
                fullWidth
                maxRows={3}
                name="description"
                value={values.description}
                onChange={handleInputChange}
              />
            </Grid>{" "}
          </Grid>
          <Box className="customerBox">
            <div>
              <Button
                variant="outlined"
                size="large"
                className="buttonCancel"
                onClick={() => {
                  navigate("/contact");
                }}
                style={{
                  borderRadius: "10px",
                  width: "40%",
                  height: "94%",
                }}
              >
                <strong style={strong}>{t("Cancel")}</strong>
              </Button>{" "}
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Save")}
                </strong>
              </Button>
            </div>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default AddContact;
