import axiosInstance from "../../utils/AxiosInstace";

export const categoryListApi = async (page, search, string) => {
  try {
    const res = await axiosInstance.get(
      `/api/v1/categorylist?limit=10&page=${page}${string}&search=${search}`
    );
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const categoryAddApi = async (values) => {
  try {
    const res = await axiosInstance.post(`api/v1/categoryadd`, values);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};

export const updateCategoryApi = async (id, values) => {
  try {
    const res = await axiosInstance.put(`api/v1/categoryupdate/${id}`, values);
    return res.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
};
