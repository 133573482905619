import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  AddLeadConvert,
  getAccountList,
  getCustomerDropdown,
} from "../../state/action/leadAction";
import Notification from "../../utils/Notification";
import { useForm } from "../../utils/useForms";
import Controls from "../control/Control";

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const strong = {
  color: "black",
};
const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  email: "",
  type: "",
  htmlData: "",
};

const types = [{ id: "1", title: "Add to existing Account" }];

const data = [{ id: "1", title: "Create New Account" }];

const Convert = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [selectedNew, setSelectedNew] = useState("");
  const [accountList, setAccountList] = useState("");
  const [contactList, setContactList] = useState("");

  const [accountexit, setAccountExit] = useState("");
  const [accountready, setAccountReady] = useState([]);

  const [CheckData, setCheckData] = useState("");
  const [deleteId, setDeleteId] = useState(null);

  const [customerList, setCustomerList] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [openSwitchModel, setOpenSwitchModel] = useState(false);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const handleChangeSelect = (e) => {
    setSelected(e.target.value);
  };

  //convert List
  const getaccountList = async (e) => {
    const res = await getAccountList(state?.lead);

    if (res) {
      setAccountList(res?.accountName);
      setAccountExit(res?.accountexist);
      setContactList(res?.contactName);
      setAccountReady(res?.data);
      setNotify({
        isOpen: true,
      });
      setTimeout(() => {}, 1000);
    } else {
      setNotify({
        isOpen: true,
      });
    }
  };

  useEffect(() => {
    getaccountList(state?.lead);
  }, [state?.lead]);

  //new data

  const handleChangeSelectNew = (e) => {
    setSelectedNew(e.target.value);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "This field is required.";
    if ("lastName" in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "This field is required.";

    if ("customerType" in fieldValues)
      temp.customerType = fieldValues.customerType
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleLeadToContact = async (e) => {
    const data = {
      createdBy: 1,
      newAccount: 1,
      accountId: accountexit ? CheckData : "",
      attechmentTransfer: 1,
      // customerType: values.customerType,
    };

    const res = await AddLeadConvert(state?.lead, data);
    if (res.status === true) {
      navigate("/contact");
    }
  };

  //typedropdown

  const getCustomerList = async (e) => {
    const data = await getCustomerDropdown();
    if (data.status) {
      setCustomerList(data.customerType);
    }
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const hanldeChangeCheck = (e) => {
    setCheckData(e.target.value);
  };

  return (
    <Box>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
          marginTop: "15px",
          boxShadow: "none",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography> {t("Convert_Lead")}</Typography>
            </Grid>

            {accountexit ? (
              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <span sx={{ marginLeft: "10px" }}>
                    {" "}
                    <Controls.RadioGroup
                      name="type"
                      onChange={(e) => {
                        handleInputChange(e);
                        handleChangeSelect(e);
                        handleSwitchModel();
                        getaccountList();
                      }}
                      value={values.type}
                      items={types}
                      isRowDir={true}
                    />
                  </span>
                  {accountList}
                </div>
              </Grid>
            ) : (
              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <span sx={{ marginLeft: "10px" }}>
                    {" "}
                    <Typography>Create New Account: {accountList}</Typography>
                  </span>
                </div>
              </Grid>
            )}

            {accountexit ? (
              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <span sx={{ marginLeft: "10px" }}>
                    {" "}
                    <Controls.RadioGroup
                      name="data"
                      onChange={(e) => {
                        handleInputChange(e);
                        handleChangeSelectNew(e);
                      }}
                      value={values.data}
                      items={data}
                      isRowDir={true}
                    />
                  </span>
                </div>

                {selectedNew ? (
                  <Typography>
                    A new Contact will be created for the Account.
                  </Typography>
                ) : null}
              </Grid>
            ) : (
              <Grid item sx={{ m: 1 }} md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <span sx={{ marginLeft: "10px" }}>
                    {" "}
                    <Typography>Create New Contact : {contactList}</Typography>
                  </span>
                </div>
              </Grid>
            )}
          </Grid>
          {selected && accountList ? (
            <Modal
              open={openSwitchModel}
              onClose={handleCloseSwitch}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                >
                  <Table
                    aria-label="simple table"
                    sx={{ paddingLeft: "0!important" }}
                  >
                    <TableHead sx={{ paddingLeft: "0!important" }}>
                      <TableRow
                        sx={{
                          backgroundColor: "#f6f9fb",
                          paddingLeft: "0!important",
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        ></TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Account Name
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Account Type
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Phone
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "#fff",
                        paddingLeft: "0!important",
                      }}
                    >
                      {accountready?.map((row, key) => {
                        return (
                          <TableRow>
                            <TableCell>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={CheckData}
                                onChange={hanldeChangeCheck}
                              >
                                <FormControlLabel
                                  value={row.id}
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            </TableCell>
                            <TableCell>{row.accountName}</TableCell>
                            <TableCell>{row?.accountType}</TableCell>
                            <TableCell>{row?.phone}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Modal>
          ) : null}
        </Box>

        <Box className="customerBox">
          <div>
            <Button
              variant="contained"
              size="large"
              type="submit"
              style={{
                backgroundColor: "#f06237",
                borderRadius: "10px",
              }}
              onClick={handleLeadToContact}
            >
              <strong className="purchaseOrderheadder-box-button2-strong">
                {t("Convert")}
              </strong>
            </Button>
          </div>
        </Box>
      </Card>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default Convert;
