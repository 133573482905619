import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "../src/assets/locales/en/translation.json";
import translationAR from "../src/assets/locales/ar/translation.json";
import translationFR from "../src/assets/locales/fr/translation.json";
import translationIT from "../src/assets/locales/it/translation.json";

const fallbackLng = ["it"];
const availableLanguages = ["en", "it"];

const resources = {
    en: {
        translation: translationEN
    },
    ar: {
        translation: translationAR
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    }

};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,

        detection: {
            checkWhitelist: true
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false
        },
        //set main language to it
        lng: "it"
    });

export default i18n;
