import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useState } from "react";
import { store } from "../../state/store";
import Notification from "../../utils/Notification";
import { Link, useNavigate } from "react-router-dom";
import { TextField, FormLabel, MenuItem, Grid, Button } from "@mui/material";
import { useForm, Form } from "../../utils/useForms";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  supplierAdd,
  supplierDetails,
  getStateList,
  supplierUpdate,
  getAllCountry,
} from "../../state/action/supplierAction";

const strong = {
  color: "black",
};
const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));
const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  supplierName: "",
  supplierEmail: "",
  mobileNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",

  accountName: "",
  accountNumber: "",
  ifscCode: "",
  vatNumber: "",
  bankName: "",
  swiftCode: "",
};

export default function CustomizedInputs(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [statelist, setStatelist] = useState([]);
  const [countrylist, setCountrylist] = useState([]);
  const [id, setId] = useState("");
  const [edit, setEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    if (state && state.user && state.user.id) {
      getSupplierDetails(state.user.id);
      setId(state.user.id);
      setEdit(true);
    }
  }, [state]);

  const getSupplierDetails = async (ids) => {
    try {
      const res = await supplierDetails(ids);
      if (res && res.status) {
        getStateListData(res.data.address.country);
        setValues({
          ...res.data,
          supplierName: res.data.supplierName,
          supplierEmail: res.data.supplierEmail,
          mobileNumber: res.data.mobileNumber,
          address: res.data.address.address,
          city: res.data.address.city,
          country: Number(res.data.address.country),
          state: Number(res.data.address.state),
          zipCode: res.data.address.zipCode,
          creditLimit: res.data.creditLimit,
          creditDays: res.data.creditDays,
          accountName: res.data.accountName,
          accountNumber: res.data.accountNumber,
          ifscCode: res.data.ifscCode,
          vatNumber: res.data.vatNumber,
          bankName: res.data.bankName,
          swiftCode: res.data.swiftCode,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("supplierName" in fieldValues) {
      if (fieldValues.supplierName !== "") {
        if (/^[a-zA-Z0-9\s]{1,50}$/.test(fieldValues.supplierName)) {
          temp.supplierName = "";
        } else {
          temp.supplierName = "number or characters and 50 digits allow only";
        }
      } else {
        temp.supplierName = "This field is required.";
      }
    }
    if ("vatNumber" in fieldValues) {
      if (fieldValues.vatNumber !== "") {
        if (/^[a-zA-Z0-9]{1,14}$/.test(fieldValues.vatNumber)) {
          temp.vatNumber = "";
        } else {
          temp.vatNumber = " alphanumeric & maximum 14 digits allow";
        }
      } else {
        temp.vatNumber = "This field is required.";
      }
    }
    if ("swiftCode" in fieldValues) {
      if (fieldValues.swiftCode !== "") {
        temp.swiftCode = "";
      } else {
        temp.swiftCode = "This field is required.";
      }
    }

    if ("mobileNumber" in fieldValues) {
      if (fieldValues.mobileNumber !== "") {
        if (/^[0-9]+$/.test(fieldValues.mobileNumber)) {
          temp.mobileNumber = "";
        } else {
          temp.mobileNumber = "Only numbers are allowed";
        }
      } else {
        temp.mobileNumber = "This field is required.";
      }
    }
    if ("zipCode" in fieldValues) {
      if (fieldValues.zipCode !== "") {
        if (/^[0-9]{4,10}$/.test(fieldValues.zipCode)) {
          temp.zipCode = "";
        } else {
          temp.zipCode = "Only numbers are allowed";
        }
      } else {
        temp.zipCode = "This field is required.";
      }
    }

    if ("accountNumber" in fieldValues) {
      if (fieldValues.accountNumber !== "") {
        temp.accountNumber = "";
      } else {
        temp.accountNumber = "This field is required.";
      }
    }

    if ("ifscCode" in fieldValues) {
      if (fieldValues.ifscCode !== "") {
        temp.ifscCode = "";
      } else {
        temp.ifscCode = "This field is required.";
      }
    }

    if ("supplierEmail" in fieldValues) {
      if (fieldValues.supplierEmail !== "") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            fieldValues.supplierEmail
          )
        ) {
          temp.supplierEmail = "";
        } else {
          temp.supplierEmail = "Enter valid Email";
        }
      } else {
        temp.supplierEmail = "This field is required.";
      }
    }

    if ("accountName" in fieldValues) {
      if (fieldValues.accountName !== "") {
        temp.accountName = "";
      } else {
        temp.accountName = "This field is required.";
      }
    }
    if ("bankName" in fieldValues) {
      if (fieldValues.bankName !== "") {
        temp.bankName = "";
      } else {
        temp.bankName = "This field is required.";
      }
    }

    if ("creditDays" in fieldValues) {
      let regex = /^[0-9]*$/;
      if (fieldValues.creditDays !== "") {
        if (regex.test(fieldValues.creditDays)) {
          temp.creditDays = "";
        } else {
          temp.creditDays = "Only Number allow";
        }
      } else {
        temp.creditDays = "This field is required.";
      }
    }

    if ("creditLimit" in fieldValues) {
      let regex = /^[0-9]+(\.[0-9]{1,2})?$/;
      if (fieldValues.creditLimit !== "") {
        if (regex.test(fieldValues.creditLimit)) {
          temp.creditLimit = "";
        } else {
          temp.creditLimit = "Only Number allow";
        }
      } else {
        temp.creditLimit = "This field is required.";
      }
    }

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : "This field is required.";
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "This field is required.";
    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { auth } = store.getState();
    const userid = auth.user.id;

    e.preventDefault();
    const payload = {
      supplierName: values.supplierName,
      supplierEmail: values.supplierEmail,
      mobileNumber: values.mobileNumber,
      address: [
        {
          addressType: 1,
          address: values.address,
          state: values.state,
          city: values.city,
          zipCode: Number(values.zipCode),
          country: values.country,
        },
      ],
      creditDays: Number(values.creditDays),
      creditLimit: Number(values.creditLimit),

      accountName: values.accountName,
      accountNumber: values.accountNumber,
      ifscCode: values.ifscCode,
      vatNumber: values.vatNumber,
      bankName: values.bankName,
      swiftCode: values.swiftCode,
      createdBy: userid,
    };
    if (validate()) {
      if (edit) {
        const res = await supplierUpdate(id, payload);
        if (res.status == true) {
          setNotify({
            isOpen: true,
            message: "Supplier Updated Successfully",
            type: "success",
          });
          setTimeout(() => {
            navigate("/supplier");
          }, 500);
        } else {
          setNotify({
            isOpen: true,
            message: res.message || "Something went wrong",
            type: "warning",
          });
        }
      } else {
        const res = await supplierAdd(payload);
        if (res.status == true) {
          setNotify({
            isOpen: true,
            message: "Supplier Added Successfully",
            type: "success",
          });
          setTimeout(() => {
            navigate("/supplier");
          }, 500);
        } else {
          setNotify({
            isOpen: true,
            message: res.message || "Something went wrong",
            type: "warning",
          });
        }
      }
    }
  };

  const getStateListData = async (e) => {
    setValues({
      ...values,
      country: e,
    });
    const res = await getStateList(e);
    if (res.status == true) {
      setStatelist(res.data);
    }
  };

  const getCountry = async (e) => {
    const res = await getAllCountry();
    if (res.status == true) {
      setCountrylist(res.data);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {" "}
          <h1
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "4px",
            }}
          >
            {" "}
            <strong>{id ? t("Edit_Supplier") : t("Add_Supplier")} </strong>{" "}
          </h1>
          <p
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "34px",
              marginTop: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/supplier");
            }}
          >
            {t("Suppliers")} |{" "}
            <span style={{ color: "black" }}>
              {id ? t("Edit_Supplier") : t("Add_Supplier")}{" "}
            </span>
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Box
          style={{
            backgroundColor: "white",
            borderRadius: "12px",
            padding: "10px",
          }}
        >
          <Grid item md={12} sm={8} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="supplierName">
                {t("Supplier_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={"supplierName"}
                value={values.supplierName}
                inputProps={{ maxLength: 50 }}
                onChange={handleInputChange}
                error={Boolean(errors.supplierName)}
                helperText={errors.supplierName}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="supplierEmail">
                {t("supplier_Email")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="supplierEmail"
                value={values.supplierEmail}
                onChange={handleInputChange}
                error={Boolean(errors.supplierEmail)}
                helperText={errors.supplierEmail}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="mobileNumber">
                {t("Mobile_Number")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("mobileNumber")}
                value={values.mobileNumber}
                inputProps={{ maxLength: 10 }}
                onChange={handleInputChange}
                error={Boolean(errors.mobileNumber)}
                helperText={errors.mobileNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="vatNumber">
                {t("VAT/TIN")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("vatNumber")}
                value={values.vatNumber}
                inputProps={{ maxLength: 14 }}
                onChange={handleInputChange}
                error={Boolean(errors.vatNumber)}
                helperText={errors.vatNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="creditLimit">
                {t("Credit_Limit")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="creditLimit"
                value={values.creditLimit}
                inputProps={{ maxLength: 14 }}
                onChange={handleInputChange}
                error={Boolean(errors.creditLimit)}
                helperText={errors.creditLimit}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="creditDays">
                {t("Credit_Days")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("creditDays")}
                value={values.creditDays}
                inputProps={{ maxLength: 14 }}
                onChange={handleInputChange}
                error={Boolean(errors.creditDays)}
                helperText={errors.creditDays}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={11.5} xs={12}>
              <hr />
              <StyledFormLabel htmlFor="address">
                {t("Address")}
              </StyledFormLabel>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="address">
                {t("Address")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("address")}
                value={values.address}
                onChange={handleInputChange}
                error={Boolean(errors.address)}
                helperText={errors.address}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="country">
                {t("Country")}
              </StyledFormLabel>
              <select
                id="country"
                name="country"
                fullWidth
                select
                value={values.country}
                onChange={(e) => {
                  getStateListData(e.target.value);
                }}
                error={Boolean(errors.country)}
                helperText={errors.country}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Country")}</option>
                {countrylist.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="state">{t("State")}</StyledFormLabel>
              <select
                id="state"
                name={t("state")}
                fullWidth
                select
                value={values.state}
                onChange={handleInputChange}
                error={Boolean(errors.state)}
                helperText={errors.state}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">Select State</option>
                {statelist.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="city">{t("City")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name="city"
                value={values.city}
                onChange={handleInputChange}
                error={Boolean(errors.city)}
                helperText={errors.city}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="zipCode">
                {t("ZipCode")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={"zipCode"}
                value={values.zipCode}
                inputProps={{ minLength: 4, maxLength: 10 }}
                onChange={handleInputChange}
                error={Boolean(errors.zipCode)}
                helperText={errors.zipCode}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={11.5} xs={12}>
              <hr />
              <StyledFormLabel htmlFor="bankAccount">
                {t("Bank_Account_Details")}
              </StyledFormLabel>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="accountName">
                {t("Account_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("accountName")}
                value={values.accountName}
                inputProps={{ maxLength: 20 }}
                onChange={handleInputChange}
                error={Boolean(errors.accountName)}
                helperText={errors.accountName}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="accountNumber">
                {t("Account_Number")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("accountNumber")}
                value={values.accountNumber}
                inputProps={{ minLength: 7, maxLength: 14 }}
                onChange={handleInputChange}
                error={Boolean(errors.accountNumber)}
                helperText={errors.accountNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="bankName">
                {t("Bank_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("bankName")}
                value={values.bankName}
                inputProps={{ maxLength: 20 }}
                onChange={handleInputChange}
                error={Boolean(errors.bankName)}
                helperText={errors.bankName}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="ifscCode">
                {t("IFSC/MICR")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={"ifscCode"}
                value={values.ifscCode}
                inputProps={{ maxLength: 14 }}
                onChange={handleInputChange}
                error={Boolean(errors.ifscCode)}
                helperText={errors.ifscCode}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="swiftCode">
                {t("Swift_Code")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="swiftCode"
                value={values.swiftCode}
                inputProps={{ minLength: 8, maxLength: 11 }}
                onChange={handleInputChange}
                error={Boolean(errors.swiftCode)}
                helperText={errors.swiftCode}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            justifyContent="flex-end"
            marginTop="20px"
            borderRadius="12px"
            padding="20px"
          >
            <div>
              <Link
                to="/supplier/"
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  style={{
                    border: "1px solid  #282822",
                    borderRadius: "10px",
                    fontSize: 16,
                    color: "black",
                    marginRight: "20px",
                    transition:
                      "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                  }}
                >
                  <strong style={strong}>{t("Cancel")}</strong>
                </Button>{" "}
              </Link>
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
              >
                <strong style={{ color: "white" }}>
                  {id ? t("Update") : t("Save")}
                </strong>
              </Button>
            </div>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
