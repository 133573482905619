import axiosInstance from "../../utils/AxiosInstace";

//supplier add
export const supplierAdd = async (data) => {
  try {
    const res = await axiosInstance.post("/api/v1/supplieradd", data);
    console.log("res", res);
    if (res && res.data && res.data.status === true) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//supplier update
export const supplierUpdate = async (id, data) => {
  try {
    const res = await axiosInstance.patch(`/api/v1/supplierupdate/${id}`, data);
    console.log("res", res);
    if (res && res.data && res.data.status === true) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//supplier delete
export const supplierDelete = (id) => {
  try {
    const res = axiosInstance.delete(`/api/v1/supplierdelete/${id}`);
    if (res && res.data && res.data.status === true) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//supplier list
export const supplierList = async (page, search, string) => {
  try {
    const res = await axiosInstance.get(
      `api/v1/suppierlist?limit=10&page=${page}&search=${search}${string}`
    );
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//supplier details
export const supplierDetails = async (id) => {
  try {
    const res = await axiosInstance.get(`/api/v1/supplierdetails/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

export const getStateList = async (id) => {
  try {
    const res = await axiosInstance.get(`/api/v1/allstate/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

export const getBillingStateList = async (id) => {
  try {
    const res = await axiosInstance.get(`/api/v1/allstate/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//get country
export const getAllCountry = async () => {
  try {
    const res = await axiosInstance.get("/api/v1/allcountry");
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};
