import * as React from "react";
import Box from "@mui/material/Box";
import { TextField, FormLabel, Grid, Typography, Button } from "@mui/material";

import { Card } from "@mui/material";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAccountDetails } from "../../state/action/accountaction";
// import {getAccountdetails}from "../../state/action/accountaction"

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const strong = {
  color: "black",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const AccountDetails = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const { t } = useTranslation();
  const [accountdata, setaccountdata] = useState([]);
  const [accountOwner, setAccountOwner] = useState([]);

  // const handleChange =(event,newValue)=>{
  //   setValue(newValue);
  // }

  //details

  const GetAccount = async () => {
    const res = await getAccountDetails(state?.account);
    if (res && res.status === true) {
      setaccountdata(res.data);
      setAccountOwner(res?.data?.ownerId);
    }
  };

  useEffect(() => {
    GetAccount(state?.account);
  }, [state?.account]);

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "4px",
            }}
          >
            {" "}
            {t("View_Account")}{" "}
          </h1>

          <p
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "34px",
              marginTop: "0px",
              cursor: "pointer",
              paddingTop: "5px",
              paddingBottom: "5px",
              hover: {
                color: "#E64A08",
              },
            }}
            onClick={() => {
              navigate("/account");
            }}
          >
            {t("Account")} |{" "}
            <span style={{ color: "black", cursor: "pointer" }}>
              {" "}
              {t("View_Account")}
            </span>
          </p>
        </div>
        <Box marginTop="20px" borderRadius="12px">
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#f06237",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate(`/addaccount`, {
                state: state,
              });
            }}
          >
            <strong style={{ color: "white" }}>{t("Edit")}</strong>
          </Button>
        </Box>
      </div>
      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography> {t("AccountInformation")}</Typography>
            </Grid>
            <Grid
              item
              sx={{ m: 1, display: "flex", justifyContent: "end" }}
              md={5.5}
              xs={12}
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Account_owner")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>
                    {accountOwner?.firstName + " " + accountOwner?.lastName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Account_name")} :
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.accountName}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ m: 1, display: "flex", justifyContent: "end" }}
              md={5.5}
              xs={12}
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Account_Type")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.accountType}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ m: 1, display: "flex", justifyContent: "end" }}
              md={5.5}
              xs={12}
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Annual_Revenue")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.annualRevenue}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Phone")} :
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.phone}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Website")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.website}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography> {t("addressInformation")}</Typography>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Billing_Street")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.billingStreet}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Shipping_Street")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.shippingStreet}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Billing_City")} :
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.billingCity}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Shipping_City")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.shippingCity}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Billing_State")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.billingState}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Shipping_State")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.shippingState}</Typography>
                </Grid>
              </Grid>
            </Grid>{" "}
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Billing_Country")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.billingCountry}</Typography>
                </Grid>
              </Grid>
            </Grid>{" "}
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Shipping_Country")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.shippingCountry}</Typography>
                </Grid>
              </Grid>
            </Grid>{" "}
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Billing_Code")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.billingPostalCode}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("Shipping_Code")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.shippingPostalCode}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Typography> {t("description")}</Typography>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      color: "rgba(40, 45, 38, 0.5)",
                    }}
                  >
                    {t("account_description")}:
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography>{accountdata?.description}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

export default AccountDetails;
