import { useState, useEffect } from "react";
import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { TextField, IconButton, Tooltip, Button, Stack } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled, useTheme, useMediaQuery } from "@mui/material";
import RectangleGlass from "../../assets/Asia-12.png";
import { Redirect, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { loginUser } from "../../state/action/authAction";
import "react-toastify/dist/ReactToastify.css";
import Notification from "../../utils/Notification";
import "material-react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import Backdrop from "../Backdrop/loader";
import { useTranslation } from "react-i18next";

const StyledLeftSide = styled("div")(() => ({
  borderRadius: "10px",
  height: "88vh",
  position: "relative",
}));

const StyledRightSide = styled("div")(() => ({
  height: "88vh",
}));
const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));
const StyledLogin = styled(Button)(() => ({
  backgroundColor: "#f06237",
  color: "white",
  fontSize: "16px",
  padding: "8px 20px",
  "&:hover": {
    backgroundColor: "#cf5025",
    color: "white",
  },
}));

const cookies = new Cookies();
const Login = () => {
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);

  const dispatch = useDispatch();
  const lg = useMediaQuery("@media (max-width:900px)");
  const xs = useMediaQuery("@media (max-width:600px)");
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const { t } = useTranslation();

  if (isAuthenticated) {
    navigate("/");
  }

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const { email, password } = formData;
  const handleChange = (e) => {
    console.log("e.target.value", e.target.value, e.target.name);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      email == "" ||
      password == "" ||
      email == undefined ||
      password == undefined
    ) {
      setNotify({
        isOpen: true,
        message: "Please fill all the fields",
        type: "error",
      });
    }
    if ((email, password)) {
      const response = await dispatch(loginUser(formData, rememberMe));
      setOpenBreakdrop(true);
      console.log("response", response);
      if (response && response.status == true) {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "success",
        });
        setTimeout(() => {
          navigate("/dashboard");
          setOpenBreakdrop(false);
        }, 1200);
      } else if (response && response.response.data.status === false) {
        setNotify({
          isOpen: true,
          message: response.response.data.message,
          type: "error",
        });
        setOpenBreakdrop(false);
      }
    }
  };

  useEffect(() => {
    const userDetails = localStorage.getItem("token");
    const userEmail = cookies.get("myEmail");
    const userPassword = cookies.get("myPassword");
    if (userEmail !== "" && userPassword !== "") {
      setFormData({ email: userEmail, password: userPassword });
    }
  }, []);
  return (
    <div>
      <Backdrop open={openBackdrop} />

      <Grid container spacing={1} sx={{ backgroundColor: "#F8F8F8;" }}>
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledLeftSide>
            <Box
              sx={{
                position: "absolute",
                borderRadius: "10px",
                height: "-webkit-fill-available",
                margin: lg ? "30px" : "40px",
                color: "white",
                padding: lg ? "0px 30px" : "0px 50px",
              }}
            >
              <h1>
                {t("Welcome_to")} <br /> {t("Asia_pacific")}
              </h1>
              <p>
                {t(
                  "Our_approach_to_sourcing_is_simple…_We_offer_the_finest_quality_materials_and_a_final_product_that_delivers_with_impact_!"
                )}
              </p>
            </Box>
            <img
              src={RectangleGlass}
              style={{ width: "100%", height: "100%" }}
              alt="Login Background"
            />
          </StyledLeftSide>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={12} sx={{ margin: "35px 20px" }}>
          <StyledRightSide>
            <Container sx={xs ? {} : { pt: "20%" }}>
              <Box sx={{ mb: 3 }}>
                <Typography
                  component={"h4"}
                  sx={{
                    display: "inline-block",
                    fontSize: "36px",
                    fontWeight: "600",
                  }}
                >
                  {t("Login")}
                </Typography>
                <br />
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {t("Login_to_manage_your_account")}
                </Typography>
              </Box>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Box sx={{ mt: 2 }}>
                  <StyledLabel>{t("Email")}</StyledLabel>
                  <TextField
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <StyledLabel>{t("Password")}</StyledLabel>
                  <TextField
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    sx={{ mt: 1, borderRadius: "8px" }}
                    size="small"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start" sx={{ m: 0 }}>
                          <Tooltip
                            title={
                              showPassword ? "Hide Password" : "Show Password"
                            }
                          >
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={{ mt: 2 }}
                >
                  <FormGroup>
                    <FormControlLabel
                      label={t("Remember_me")}
                      control={
                        <Checkbox
                          style={{
                            color: "#F06237",
                            borderColor: "black",
                          }}
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                      }
                    />
                  </FormGroup>
                  <Button
                    onClick={() => {
                      navigate("/forget");
                    }}
                    sx={{ textTransform: "capitalize" }}
                  >
                    <Link to="/forget">{t("Forgot_Password")} ?</Link>
                  </Button>
                </Stack>
                <StyledLogin sx={{ mt: 3 }} type="submit">
                  {t("Login")}
                </StyledLogin>
              </form>
            </Container>
          </StyledRightSide>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};
export default Login;
