import { Box } from "@mui/material";
import React from "react";
import PermissionLists from "./PermissionList";

const Role = () => {
  return (
    <Box>
      <PermissionLists />
    </Box>
  );
};

export default Role;
