import * as React from 'react';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Grid, Button, Stack, Typography, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import axiosInstance from "../../utils/AxiosInstace";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Edit as EditIcon } from "@mui/icons-material";
import Notification from "../../utils/Notification"
import { useTranslation } from 'react-i18next';
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    Chip,
    Select,
    MenuItem,
} from "@mui/material";
import "./OrderStyle/OrderDetails.css";
const strong = {
    color: 'black',
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "white",
        color: theme.palette.common.black,
        fontSize: "12px",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "12px",
        paddingTop: "5px",
        paddingBottom: "5px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },

}));
const FlexBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))


const StyledChip = styled(Chip)(() => ({
    borderRadius: "10px",
    fontSize: "14px",
}));


export default function FormPropsTextFields() {
    let supplierNames = "";
    const [deleteUser, setDeleteUser] = useState(false);
    const [orderProductData, setorderProductData] = useState([]);
    const [orderData, setorderData] = useState([]);
    const [productkey, setProductKey] = useState([]);
    const location = useLocation();
    const [customerData, setcustomerData] = useState({});
    const [supplshippingAddress, setshippingAddress] = useState({});
    const [currentStatus, setCurrentStatus] = useState("");
    const [statusMenu, setStatusMenu] = useState(true);
    const { t } = useTranslation();
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [billingAddress, setbillingAddress] = useState({});
    const [isStatusEditing, setStatusEditing] = useState(false);
    const startStatusEditing = () => {
        setStatusEditing(true);
    };
    const stopStatusEditing = () => {
        setStatusEditing(false);
    };
    // let id = location.state ? location.id : "";
    const { state, pathname } = useLocation()
    const navigate = useNavigate();
    const handleCurrentStatus = async (event) => {
        const toastType = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        };

        var value = event.target.value;
        var body = {
            status: value,
        };
        await axiosInstance
            .post(`/api/v1/salesorderstatus/${state}`, body)
            .then((res) => {

                if (res && res.status) {
                    setCurrentStatus(value);
                    console.log("res", value);
                    toast.success("order Status Change successfully", toastType);
                } else {
                    toast.error(res.message, toastType);
                }
            })
            .catch((err) => {
                toast.error(err.message, toastType);
            }
            );
    };

    const openStatusMenu = () => {
        setStatusMenu(true);
    };
    const closeStatusMenu = () => {
        setStatusMenu(false);
    };

    const GetpurchaseOrder = async () => {
        await axiosInstance
            .get(`/api/v1/salesorderdetail/${state}`).then((res) => {
                setorderProductData(res.data.data.products);
                setProductKey(res.data.data.products[0].id)
                setcustomerData(res.data.data.customer);
                setshippingAddress(res.data.data.customer.address)
                setbillingAddress(res.data.data.customer.billingAddress)
                setorderData(res.data.data);
                setCurrentStatus(res.data.data.status);

            }).catch((e) => {
                // console.log("error found")
            })
    }

    const orderStatusChange = async (event) => {
        let toastType = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        };
        //api call
        await axiosInstance
            .post(`/api/v1/salesorderstatus/${state}`, {
                status: 1
            })
            .then((res) => {
                if (res && res.status) {
                    setNotify({
                        isOpen: true,
                        message: "order Status Change successfully",
                        type: "success",
                    });
                    setTimeout(() => {
                        // navigate('orderPage/orderdetails')
                        // same page in navigate with out refresh
                        navigate('/order', { state: state })

                    }, 1000);
                } else {
                    setNotify({
                        isOpen: true,
                        message: res.message,
                        type: "error",
                    });
                }
            })

            .catch((err) => {
                toast.error(err.message, toastType);
            }
            );
    };






    useEffect(() => {
        GetpurchaseOrder();

    }, [])
    const handleDeleteUser = () => {
        setDeleteUser(true);
    };
    const closeDeleteUser = () => {
        setDeleteUser(false);
    };
    const statusList = [
        { name: "Draft", value: 0 },
        {
            name: "Confermed",
            value: 1,
        },
        {
            name: "Closed",
            value: 2,
        },
    ];
    const orderStatus = (status) => {
        const statusColors = {
            0: {

                key: "Draft",
                backgroundColor: "#ede7dd",
                color: "#fc7e00",
            },
            1: {

                key: "Confirmed",
                backgroundColor: "#def7ec",
                color: "#0e9f6e",
            },
            2: {
                key: "Closed",
                backgroundColor: "rgba(240, 68, 56, 0.05)",
                color: "#f05252;",
            },
        };
        return <StyledChip style={{
            width: "30%",
            height: "23px",
            fontSize: "10px",
        }} sx={statusColors[status]} label={statusColors[status] ? statusColors[status].key : ""} />;
    }

    const productStatus = (productstatus) => {
        const statusColors = {
            0: {
                key: "Closed",
                backgroundColor: "rgba(240, 68, 56, 0.05)",
                color: "#f05252;",
            },
            1: {
                key: "Partial",
                backgroundColor: "#ede7dd",
                color: "#fc7e00",
            },
            2: {
                key: "Pending",
                backgroundColor: "#fdf6b2",
                color: "#c27803",
            },
            3: {
                key: "Paid",
                backgroundColor: "#def7ec",
                color: "#0e9f6e",
            },
        };

        return <StyledChip style={{
            width: "30%",
            height: "23px",
            fontSize: "10px",
        }} sx={statusColors[productstatus]} label={statusColors[productstatus] ? statusColors[productstatus].key : ""} />;
    }

    return (

        < >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <h1 style={{
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "22px",
                        marginBottom: "8px",
                    }}> {t("Order")} </h1>
                </div>
                <Box borderRadius="12px"
                >
                    {orderData.status == 0 ? <Button variant="contained" size="large"
                        style={{
                            backgroundColor: '#f06237',
                            borderRadius: '10px',
                            marginRight: '10px',
                        }}
                        onClick={(e) => {

                            orderStatusChange(e);

                        }}
                    >
                        <strong style={{ color: "white", fontWeight: '500', fontSize: "13px" }}>{t("MARK_AS_CONFIRMED")}</strong>
                    </Button>
                        : null}


                    {orderData.status != 0 ? <Button variant="contained" size="large"
                        style={{
                            backgroundColor: '#f06237',
                            borderRadius: '10px',
                        }}
                        onClick={() => {

                            navigate(`/orderPage/orderInvoice`, { state: orderData.id })

                        }}
                    >
                        <strong style={{ color: "white", fontWeight: '500', fontSize: "13px" }}>{orderData.invoiceId == 0 ? t("Convert_to_Invoice") : t("View_Invoice")}</strong>
                    </Button>
                        : null}



                    {/* </Link> */}
                </Box>

            </div>
            <Card style={{
                padding: "20px",
                borderRadius: "12px",
                marginTop: "20px",

            }}>
                <Grid item md={12} sm={8} xs={12} container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"

                                className="purchase-order-details-title">
                                {t("SALES_ORDER")}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id="purchase-order-details-number" >
                                {orderData.orderNumber}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>


                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6"
                                id='purchase-order-details-status'
                            >
                                {t("Status")}
                            </Typography>
                        </Grid>


                        <Grid container md={12} sm={12} xs={12}>
                            <Grid item md={3} sm={12} xs={12}
                                style={{
                                    borderLeft: "4px solid rgba(240, 98, 55, 1)",
                                }}
                            >
                                <Typography variant="h6" sx={{ mt: 0.5, ml: 1, }}
                                    id='purchase-order-details-status-value'>
                                    {t("Order_Status")}
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                                <Typography variant="h6"
                                    id='purchase-order-details-status-value2'>
                                    {orderStatus(orderData.status)}
                                </Typography>

                            </Grid>

                            <Grid item md={3} sm={12} xs={12}
                                style={{
                                    borderLeft: "4px solid rgba(240, 98, 55, 1)",
                                }}>
                                <Typography variant="h6" id='purchase-order-details-status-value'>
                                    {t('Payment_Status')}
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                                <Typography variant="h6"
                                    id='purchase-order-details-status-value2'>
                                    {productStatus(orderData.paymentStatus)}
                                </Typography>
                            </Grid>
                            <Grid item md={3.3} sm={12} xs={12} >
                                <Typography variant="h6"
                                    id='purchase-order-details-status-value33'>
                                    {t('Order_Date')}
                                </Typography>
                            </Grid>

                            <Grid item md={8.5} sm={12} xs={12}>
                                <Typography variant="h6"
                                    id="purchase-order-details-status-value3">
                                    {orderData.orderDate}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={6} xs={12} id="purchase-order-details-right-side" >
                        <Grid item md={12} sm={12} xs={12}>

                            <Typography variant="h6" id="title-vendor-name" >
                                {t("Customer_Name:")} {customerData.customerName}
                            </Typography>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id="text-right1">
                                {t('Company_Email:')}  {customerData.companyEmail}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id="title-vendor-name" >
                                {t("Billing_Address")}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id='text-right2'>
                                {billingAddress.address}
                            </Typography>
                            <Typography variant="h6" id='text-right3'>
                                {billingAddress.city},
                                {billingAddress.state},
                            </Typography>
                            <Typography variant="h6" id='text-right4'>
                                {billingAddress.country}
                            </Typography>
                            <Typography variant="h6" id='text-right4'>
                                {billingAddress.zipCode}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id="title-vendor-name" >
                                {t("Shipping_Address")}
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="h6" id='text-right2'>
                                {supplshippingAddress.address}
                            </Typography>
                            <Typography variant="h6" id='text-right3'>
                                {supplshippingAddress.city},
                                {supplshippingAddress.state},
                            </Typography>
                            <Typography variant="h6" id='text-right4'>
                                {supplshippingAddress.country}
                            </Typography>
                            <Typography variant="h6" id='text-right4'>
                                {supplshippingAddress.zipCode}
                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>

                <Table stickyHeader aria-label="simple table"
                    style={{ marginTop: "25px" }}
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t("PROUDUCT_NAME")}</StyledTableCell>
                            <StyledTableCell align="center">{t("QUANTITY")}</StyledTableCell>
                            <StyledTableCell align="center">{t("RETURN_QUANTITY")}</StyledTableCell>
                            <StyledTableCell align="center">{t("RATE")}</StyledTableCell>
                            <StyledTableCell align="right">{t("AMOUNT")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderProductData.map((row, index) => (
                            <StyledTableRow
                                key={row.name}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    // hover color add
                                }} onClick={() => {
                                    navigate(`/product/details`, { state: row.productId })
                                }} >
                                <StyledTableCell component="th" scope="row">
                                    <Stack flexDirection="row" alignItems="center">
                                        <Avatar
                                            component="span"
                                            variant="rounded"
                                            alt="profile_image"
                                            src={row.productImage}
                                        />
                                        <Typography sx={{ ml: 2, fontSize: "12px" }} onClick={() => {
                                            navigate(`/product/details`, {
                                                state: { user: row, }
                                            })

                                        }}>{row.productName}</Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                                <StyledTableCell align="center">{row.returnQuantity}</StyledTableCell>
                                <StyledTableCell align="center">{row.price}</StyledTableCell>
                                <StyledTableCell align='right'>{(row.quantity * row.price) - (row.returnQuantity * row.price)}</StyledTableCell>
                            </StyledTableRow >
                        ))}

                    </TableBody>
                </Table>
                <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}></div>
                <FlexBox px={2} id="purchase-order-details-footer" >
                    <Box display="flex">
                        <Box pr={6}>
                            <Typography id="purchase-order-details-footer-subtotal" sx={{ my: 2 }}>{t("Subtotal:")}</Typography>
                            <Typography id="purchase-order-details-footer-tax" sx={{ mb: 2 }} style={{ marginTop: "11px" }}>{t("Other_Charges")}</Typography>
                            <Typography id="purchase-order-details-footer-shipping" sx={{ mb: 2, }} style={{ marginTop: "10px" }}>{t("Discount")}</Typography>
                            <Typography id="purchase-order-details-footer-shipping" sx={{ mb: 2, }} style={{ marginTop: "9px" }}>{t("Adjustment")}</Typography>

                        </Box>
                        <div
                            style={{
                                marginLeft: "21px"
                            }}
                        >
                            <Typography id="purchase-order-details-footer-subtotal-value" sx={{ my: 2 }}><b>{orderData.subtotal}</b></Typography>
                            <Typography id="purchase-order-details-footer-tax-value" sx={{ mb: 2 }} style={{ marginTop: "15px" }}>{orderData.otherCharges}</Typography>
                            <Typography id="purchase-order-details-footer-shipping-value" sx={{ mb: 2, }} style={{ marginTop: "20px" }}>{orderData.discountValue}</Typography>
                            <Typography id="purchase-order-details-footer-shipping-value" sx={{ mb: 2, }} style={{ marginTop: "22px" }}>{orderData.adjustment}</Typography>
                        </div>
                    </Box>
                </FlexBox>

                <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)" }}></div>

                <FlexBox px={2} id="purchase-order-details-footer"  >
                    <Box display="flex">
                        <Box pr={6}>
                            <Typography sx={{ mb: 2 }} >{t("Total")}</Typography>
                        </Box>
                        <div id="purchase-order-details-footer-total-value" style={{ marginLeft: "51px" }} >
                            <Typography sx={{ mb: 2 }}><b>{orderData.payableAmount - parseInt(orderData.adjustment)} </b></Typography>
                        </div>
                    </Box>
                </FlexBox>
            </Card>
            <Notification notify={notify} setNotify={setNotify} />

        </>
    );
}