import axiosInstance from "../../utils/AxiosInstace";

//getLeadTypeDropdown
export const leadTypeDropdown = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/getLeadTypeDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// add lead

export const AddUser = async (user) => {
  try {
    const response = await axiosInstance.post(`/api/v1/addLead`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//get lead list
export const getLeadList = async (queryString) => {
  try {
    const response = await axiosInstance.get(`/api/v1/getLead` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//getCountryDropdown
export const countryDropdown = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/countryDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//getStateDropdown
export const getStateList = async (id) => {
  try {
    const res = await axiosInstance.get(`/api/v1/stateDropdown/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//getLeadOwnerDropdown
export const leadOwnerDropdown = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/adminDropDown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//lead details
export const getLeadDetails = async (id) => {
  try {
    const res = await axiosInstance.get(`api/v1/getLeadById/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//lead Update

export const updateLead = async (data, id) => {
  try {
    const res = await axiosInstance.post(`/api/v1/updateLead/${id}`, data);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//lead notes
export const AddNotes = async (user) => {
  try {
    const response = await axiosInstance.post(`/api/v1/addNotes`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//lead list notes

//lead details
export const getLeadNotes = async (id) => {
  try {
    const res = await axiosInstance.get(`api/v1/getNotes/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//account exsting

//get account lead list
export const getAccountList = async (id) => {
  try {
    const response = await axiosInstance.get(`/api/v1/convertAccount/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//lead to contact

export const AddLeadConvert = async (id, user) => {
  try {
    const response = await axiosInstance.post(
      `/api/v1/convertToContact/${id}`,
      user
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//image upload
export const AddImage = async (user) => {
  try {
    const response = await axiosInstance.post(`/api/v1/fileAttachment`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// notes delete

export const notesDelete = async (id, data) => {
  try {
    const response = await axiosInstance.post(
      `/api/v1/deleteNotes/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//notes update

export const updateNote = async (id, data) => {
  try {
    const res = await axiosInstance.put(`/api/v1/updateNotes/${id}`, data);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//lead list file image

//lead notes
export const getImageList = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/getFiles`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//customertypedropdown

export const getCustomerDropdown = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/customerTypeDropdown`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//delete file

export const fileDelete = async (id) => {
  try {
    const res = await axiosInstance.delete(`/api/v1/deleteFiles/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//export lead data

export const getExportLeadData = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/exportLeadData`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getImportLeadData = async (data) => {
  try {
    const response = await axiosInstance.post(`/api/v1/importLeadData`, data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
