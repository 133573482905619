import React from "react";
import {
  Grid,
  Button,
  Stack,
  Typography,
  styled,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import { store } from "../../state/store";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import {
  ConstructionOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useState, useEffect } from "react";
import { TextField, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useForm, Form } from "../../utils/useForms";
import { useTranslation } from "react-i18next";
import { useTransition } from "react";

const adminimage = {
  width: "120px",
  height: "120px",
  borderRadius: "200px",
};

const StyledLabel = styled("label")(() => ({
  display: "inline-block",
  fontWeight: "500",
  fontSize: "14px",
  mt: 1,
  borderRadius: "8px",
}));

const strong = {
  color: "white",
  fontSize: "16px",
  fontWeight: "400px",
};
const Linkstyle = {
  color: "black",
  textDecoration: "none",
};

const initialValues = {
  currentpassword: "",
  newpassword: "",
};

export default function Setting() {
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (validate()) {
    //     console.log("working.........................................")
    // }
  };

  const validate = (fieldValues = values) => {
    console.log("working......................");
    let temp = { ...errors };
    // if ("mobile" in fieldValues) {
    //   if (fieldValues.mobile !== "") {
    //     if (/^[0-9\s]*$/.test(fieldValues.mobile)) {
    //       temp.mobile = "";
    //     } else {
    //       temp.mobile = "Only Number allow";
    //     }
    //   } else {
    //     temp.mobile = "This field is required.";
    //   }
    // }

    if ("currentpassword" in fieldValues)
      temp.currentpassword = fieldValues.currentpassword
        ? ""
        : "This field is required.";
    if ("newpassword" in fieldValues)
      temp.newpassword = fieldValues.newpassword
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const [showPassword, setShowPassword] = useState(false);

  const { auth } = store.getState();
  const reduxData = auth.user;
  const username = reduxData ? reduxData.firstName : "";
  const userimage = reduxData ? reduxData.image : "";
  const useremail = reduxData ? reduxData.email : "";
  const { t } = useTranslation();
  // const username = reduxData ? reduxData.name : '';

  console.log("ser''''--------------------------------------------", reduxData);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Typography
        sx={{ fontSize: "28px", fontWeight: "bold", fontWeight: "600" }}
      >
        {t("Settings")}
      </Typography>
      <Card
        style={{
          height: "450px",
          marginTop: "20px",
        }}
      >
        <Grid
          item
          md={12}
          sm={8}
          xs={12}
          container
          spacing={1}
          style={{
            marginTop: "10px",
            marginRight: "15px",
            marginLeft: "15px",
          }}
        >
          <Grid
            item
            sx={{ m: 1 }}
            md={5.5}
            xs={12}
            style={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              textAlign: "center",
              padding: "40px",
              borderRadius: "10px",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <Avatar
                            alt="Remy Sharp"
                            src={userimage}
                            sx={{ width: 56, height: 56 }}
                        /> */}
              <img src={userimage} alt="" srcset="" style={adminimage} />
            </Box>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              {username}
            </Typography>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "34px",
                marginTop: "0px",
                color: "rgb(166 166 166)",
              }}
            >
              {useremail}
            </p>
            <Link to="edit" style={Linkstyle}>
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
              >
                <span style={{ color: "white", fontWeight: "400px" }}>
                  {t("Edit")}
                </span>
              </Button>
            </Link>
          </Grid>

          <Grid
            item
            sx={{ m: 1 }}
            md={5.5}
            xs={12}
            style={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",

              // textAlign: "center",
              padding: "20px",
            }}
          >
            <Form onSubmit={handleSubmit}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
              >
                {t("Change_Password")}
              </Typography>

              <Box sx={{ mt: 2 }}>
                <StyledLabel>{t("Current_Password")}</StyledLabel>

                <TextField
                  name={t("currentpassword")}
                  placeholder={t("Enter_Current_Password")}
                  // value={formData.password}
                  // onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  sx={{ mt: 1, borderRadius: "8px" }}
                  size="small"
                  fullWidth
                  error={Boolean(errors.currentpassword)}
                  helperText={errors.currentpassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" sx={{ m: 0 }}>
                        <Tooltip
                          title={
                            showPassword ? "Hide Password" : "Show Password"
                          }
                        >
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <StyledLabel>{t("New_Password")}</StyledLabel>

                <TextField
                  name={t("newpassword")}
                  placeholder={t("Enter_New_Password")}
                  // value={formData.password}
                  // onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  sx={{ mt: 1, borderRadius: "8px" }}
                  size="small"
                  fullWidth
                  error={Boolean(errors.newpassword)}
                  helperText={errors.newpassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" sx={{ m: 0 }}>
                        <Tooltip
                          title={
                            showPassword ? "Hide Password" : "Show Password"
                          }
                        >
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                  marginTop: "26px",
                  fontWeight: "400px",
                }}
              >
                <span style={strong}>{t("Change_Password")}</span>
              </Button>
            </Form>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
