
import React, { useState, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";
import Backdrop from "../Backdrop/loader";

import {
    Search as SearchIcon,
    MoreVert as MoreVertIcon,
    Close as CloseIcon,
} from "@mui/icons-material";
import {
    InputBase,
    Button,
    Typography,
    IconButton,
    Stack,
    Pagination,
} from "@mui/material";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Avatar,
    Chip,
    Menu,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Switch
} from "@mui/material";
import {
    Drawer,
    Divider,
    Box,
    TextField,
    FormLabel,
    InputAdornment,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
// icons for user menu
import eye from "../../assets/eye.svg";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import invoice from "../../assets/Icon.svg";
// import block from "../../../assets/block.svg";
import { GetSuppliers } from "../../state/action/supplierAction"
import { useSelector, useDispatch } from "react-redux"
//  filter icons
import filterIcon from "../../assets/filter.svg";
//  modals with respect to menu
import productViewModel from "./orderUpdate";
import productDeleteModel from "./orderDelete";
import { useFormik } from "formik";
import axiosInstance from "../../utils/AxiosInstace";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import user1 from "../../assets/user1.png";
import user2 from "../../assets/user2.png";
// import { Route, Switch } from "";
import { useNavigate, useLocation } from "react-router-dom";
import { tableCellClasses } from '@mui/material/TableCell';
import { useForm, Form } from "../../utils/useForms";
import {useTranslation} from "react-i18next";




const Search = styled("div")(({ theme }) => ({
    position: "relative",
    color: "black",
    border: "1px solid rgba(15, 15, 15, 0.15)",
    borderRadius: "10px",
    backgroundColor: "white",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        paddingRight: "15px",
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgb(241 241 241)",
        color: theme.palette.common.black,
        fontSize: "11px",
        paddingTop: "3px",
        paddingBottom: "3px",
        position: "sticky",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "12px",
        paddingTop: "5px",
        paddingBottom: "5px",
        // hover color add 
        "&:hover": {
            color: "#E64A08",
        },
    },
}));



const StyledFormLabel = styled(FormLabel)(() => ({
    display: "block",
    fontWeight: "bold",
    color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
    marginTop: "10px",
    // height: "30px",
    // ".MuiOutlinedInput-root": {
    //     borderRadius: "10px",
    // },
}));
const StyledChip = styled(Chip)(() => ({
    // borderRadius: "10px",
    fontSize: "14px",
}));

const validate = (values) => {
    const errors = {};
}


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    '&:last-child td, &:last-child th': {
        border: 0,
    },

}));
const initialValues = {
    supplierName: "",
    companyName: "",
    orderNumber: "",
    fAmount: "",
    tAmount: "",


};



const UsersList = (props) => {

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);
    
    const {t} = useTranslation();



    const [anchorEl, setAnchorEl] = useState(null);
    const [currentUser, setCurrentUser] = useState(-1);
    const [allPayment, setAllPayment] = useState([]);
    const [userView, setUserView] = useState(false);
    const [deleteUser, setDeleteUser] = useState(false);
    const [filter, setFilter] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPages] = useState(1);
    const [user, setUser] = useState([]);
    const [search1, setsearch1] = useState("");
    const [records, setRecords] = useState(0);
    const [totalPayment, setTotalorder] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [filterData, setFilterData] = useState(true);

    let id = location;
    useEffect(() => {
        getAllProduct(page);
    }, [page, search1, filterData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFilterData(!filterData)
    }

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const onSearch = async (searchvalue) => {
        console.log("searcjh", searchvalue)

        setsearch1(searchvalue);
    }

    const getAllProduct = async () => {
        let string = "";
        let i = 0;
        for (let [key, value] of Object.entries(values)) {
            string += `&${key}=${value}`;
            i++;
        }
        await axiosInstance
            .post(`api/v1/purchaseOrderPaymentList?limit=10&page=${page}${string}&search=${search1}`).then((res) => {
                setAllPayment(res.data.data)
                setRecords(res.data.data.length);
                setTotalorder(res.data.totalPayment)
                let pagecount = Math.ceil((res.data.totalPayment) / 10)
                setTotalPages(pagecount)
                setOpenBreakdrop(false);
                setFilter(false);
            }).catch((e) => {
                console.log("error found")
            })
    }


    const openFilterDrawer = () => {
        setFilter(true);
    };
    const closeFilterDrawer = () => {
        setFilter(false);
        resetForm();
        setFilterData(!filterData);
    };
    const closeFilterDrawer1 = () => {

        setFilter(false);
    };



    const handleDeleteUser = () => {
        setDeleteUser(true);
    };
    const closeDeleteUser = () => {
        setDeleteUser(false);
    };

    return (
        <>
            <Backdrop open={openBackdrop} />

            <div>
                <Stack flexDirection="row" justifyContent="space-between">
                    <Typography sx={{ fontSize: "22px", fontWeight: "bold", fontWeight: '600', paddingLeft: "10px" }}>
                        {t("All_Payments")}
                    </Typography>
                    <Link to="/purchaseorder/paymentadd" style={{
                        color: "white",
                        textDecoration: "none",
                    }}>
                        <Button
                            sx={{
                                height: "34px",
                                backgroundColor: "#F06237",
                                color: "white",
                                borderRadius: "10px",
                                padding: "5px 25px",
                                "&:hover": {
                                    backgroundColor: "#cf5025",
                                },
                            }}
                            startIcon={<Add />}
                            color="primary"
                        >
                            <span style={{ fontSize: "14px" }}>{t("Add_Payment")}</span>
                        </Button>
                    </Link>
                </Stack>
            </div>
            <div
                style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    color: "black",
                    marginTop: "30px",
                }}
            >
                <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    sx={{ padding: "20px 0px" }}
                >
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            sx={{
                                height: "30px",
                            }}
                            placeholder="Search…"
                            inputProps={{ "aria-label": "search" }}
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </Search>

                    <Button
                        sx={{
                            border: "1px solid rgba(15, 15, 15, 0.15)",
                            borderRadius: "10px",
                            mr: 2,
                            padding: "5px 20px",
                            color: "black",
                            fontWeight: "400",
                            fontSize: "12px",
                            height: "30px",
                        }}
                        endIcon={
                            <IconButton sx={{ ml: 3 }}>
                                <img src={filterIcon} alt="Filter Options" />
                            </IconButton>
                        }
                        onClick={openFilterDrawer}
                    >
                        {t("Filter")}
                    </Button>
                </Stack>
                <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
                    <form onSubmit={handleSubmit}>
                        <Box
                            sx={{
                                width: 220,
                                padding: "10px 20px",
                                color: "black"
                            }}
                        >
                            <Stack
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                                style={{ color: "black" }}
                            >
                                <Typography sx={{ fontWeight: "bold", }}>{t("Filter")}</Typography>
                                <IconButton sx={{ color: "black" }} onClick={closeFilterDrawer1}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>

                            {/* <Box sx={{ margin: "8px auto" }}> */}
                            <StyledFormLabel htmlFor="supplierName"> {t("Supplier_Name")}</StyledFormLabel>
                            <StyledTextField
                                placeholder={t("Search_Name")}
                                fullWidth
                                id="supplierName"
                                name={t("supplierName")}
                                value={values.supplierName}
                                onChange={handleInputChange}
                            />
                            <Divider sx={{ mt: 2 }} />
                            <Box sx={{ margin: "8px auto" }}>
                                <StyledFormLabel htmlFor="email">{t("Company_Name")}</StyledFormLabel>
                                <StyledTextField
                                    type="text"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder={t("Search_Company_Name")}
                                    fullWidth
                                    id="companyName"
                                    name={t("companyName")}
                                    value={values.companyName}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Divider sx={{ mt: 2 }} />

                            <Box sx={{ margin: "8px auto" }}>
                                <StyledFormLabel htmlFor="orderNumber">
                                    {t("ORDER_NUMBER")}
                                </StyledFormLabel>
                                <StyledTextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder={t("Search_order_number")}
                                    fullWidth
                                    id="orderNumber"
                                    name={t("orderNumber")}
                                    value={values.orderNumber}
                                    onChange={handleInputChange}
                                />
                            </Box>

                            <Divider sx={{ mt: 2 }} />
                            <Box sx={{ margin: "8px auto" }}>
                                <StyledFormLabel htmlFor="fAmount">
                                    {t("Minimum_Amount")}
                                </StyledFormLabel>
                                <StyledTextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Search Amount"
                                    fullWidth
                                    id="fAmount"
                                    name={t("fAmount")}
                                    value={values.fAmount}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Divider sx={{ mt: 2 }} />

                            <Box sx={{ margin: "8px auto" }}>
                                <StyledFormLabel htmlFor="tAmount">
                                    {t("Maximum_Amount")}
                                </StyledFormLabel>
                                <StyledTextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Search Amount"
                                    fullWidth
                                    id="tAmount"
                                    name="tAmount"
                                    value={values.tAmount}
                                    onChange={handleInputChange}
                                />
                            </Box>

                        </Box>
                        <Stack
                            sx={{ margin: "100px 20px 10px 20px" }}
                            flexDirection="row"
                            justifyContent="space-between"
                        >
                            <Button
                                sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                    border: "1px solid #0F0F0F80",
                                    borderRadius: "10px",
                                    padding: "10px 25px",
                                    "&:hover": {
                                        // backgroundColor: "#60579A",
                                    },
                                }}
                                onClick={closeFilterDrawer}
                            >
                                {t("RESET")}
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: "#F06237",
                                    borderRadius: "10px",
                                    color: "white",
                                    padding: "10px 25px",
                                    "&:hover": {
                                        backgroundColor: "#F06237",
                                    },
                                }}
                                type="submit"
                            >
                                {t("Filter")}
                            </Button>
                        </Stack>
                    </form>
                </Drawer>
                {/* <TableContainer component={Paper} id="tableContainer"> */}
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>#</StyledTableCell>
                            <StyledTableCell>{t("SUPPLIER_NAME")}</StyledTableCell>
                            <StyledTableCell>{t("COMPANY_NAME")}</StyledTableCell>
                            <StyledTableCell>{t("ORDER_NUMBER")}</StyledTableCell>
                            <StyledTableCell>{t("PAYMENT_AMOUNT")}</StyledTableCell>
                            <StyledTableCell> {t("DATE")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {


                            allPayment.length === 0 ? (
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            color: "silver",
                                            textAlign: "center",
                                            paddingTop: "90px",
                                            borderBottom: "none",
                                            fontSize: "30px",
                                        }}
                                        colSpan="7"
                                    >
                                        {t("No_records_to_display")}
                                    </TableCell>
                                </TableRow>
                            ) : (


                                allPayment.map((row, index) => (
                                    <StyledTableRow
                                        key={index}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <StyledTableCell component="th" scope="row" >
                                            <Typography sx={{ ml: 2, fontSize: "14px" }}>{index + 1}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell >{row.supplierName}</StyledTableCell>
                                        <StyledTableCell >{row.companyName}</StyledTableCell>
                                        <StyledTableCell>{row.orderNumber}</StyledTableCell>
                                        <StyledTableCell>{row.paymentAmount}</StyledTableCell>
                                        <StyledTableCell>{row.paymentDate}</StyledTableCell>


                                    </StyledTableRow>
                                ))
                            )}
                    </TableBody>
                </Table>
                {/* </TableContainer> */}

                <productDeleteModel
                    userlist={getAllProduct}
                    page={page}
                    userid={user.id}
                    user={setUser}
                    open={deleteUser}
                    handleClose={closeDeleteUser}
                />
            </div>
            {/* pagination */}
            <Stack
                sx={{ mt: 4, pb: 2 }}
                flexDirection="row"
                justifyContent="space-between"
            >
                <div>
                    Showing {records} of {totalPayment} Results
                </div>
                <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                />
            </Stack>
        </>
    );
};

export default UsersList;
