import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  TextField,
  FormLabel,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import camera from "../../assets/camera.svg";
import { useForm } from "../../utils/useForms";
import Backdrop from "../Backdrop/loader";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../Langauge.js";

import {
  updateCategoryApi,
  categoryAddApi,
} from "../../state/action/categoryAction";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "14px",
  },
}));

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));
const StyledIconWrapper = styled(Box)(() => ({
  position: "relative",
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledInput = styled("input")({
  display: "none",
});

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});


const AddCategory = (props) => {

  const { open, refreshScreen, handleClose, category, setNotify } = props;
  const { t } = useTranslation();


  const [isdisabled, setIsDisabled] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const location = useLocation();
  let id = location.state;

  const [image, setImage] = useState("");
  const resetImage = () => {
    setImage("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };
  const initialValues = {
    productCategory: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("productCategory" in fieldValues)
      if (/^[a-zA-Z0-9 ]*$/.test(fieldValues.productCategory) === false) {
        //special character not allowed regex = /^[a-zA-Z0-9 ]*$/
        temp.productCategory = "Special characters are not allowed";
      } else if (fieldValues.productCategory.length > 80) {
        temp.productCategory =
          "Category name should be less than 80 characters";
      } else if (fieldValues.productCategory == "") {
        temp.productCategory = "Category name is required";
      } else {
        temp.productCategory = "";
      }
    if ("categoryImage" in fieldValues)
      temp.categoryImage = fieldValues.categoryImage
        ? ""
        : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  useEffect(() => {
    if (props.category && props.category !== -1) {
      setValues(props.category);
      setImage(props.category.image);
    }
  }, [props]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastType = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    };
    if (validate()) {
      if (props.category) {
        let formData = new FormData();
        formData.append("productCategory", values.productCategory);
        if (values.image && values.image.name) {
          formData.append("categoryImage", values.image);
        }
        const res = await updateCategoryApi(props.category.id, formData);
        console.log("res", res);
        if (res.status == true) {
          handleClose();
          refreshScreen();
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
        }
      } else {
        let formData = new FormData();
        formData.append("productCategory", values.productCategory);
        if (values.image && values.image.name) {
          formData.append("categoryImage", values.image);
        } else {
          setNotify({
            isOpen: true,
            message: "Please select image",
            type: "error",
          });
        }

        const res = await categoryAddApi(formData);

        if (res.status == true) {
          handleClose();
          refreshScreen();
          reset();
          setNotify({
            isOpen: true,
            message: res.message,
            type: "success",
          });
        } else {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
        }
      }
    }
  };

  const close = () => {
    reset();
    handleClose();
    setIsDisabled(false);
  };
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, image: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <Dialog open={open} fullWidth={true}>
        <DialogTitle
          sx={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {props.category && props.category !== -1 ? t("Edit_Category") : t("Add_Category")}
          <Tooltip title="Close">
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} id="addNewCategory">
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item={true}
                md={12}
                container
                xs={12}
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Grid item={true} md={8} xs={12}>
                  <StyledImageUploadWrapper>
                    <label htmlFor="image-upload">
                      <StyledInput
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        onChange={handleImageItem}
                        onClick={(event) => {
                          event.currentTarget.value = null;
                        }}
                      />
                      <StyledIconWrapper
                        sx={
                          image === ""
                            ? {}
                            : {
                              backgroundImage: `url(${image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }
                        }
                      >
                        {image === "" && <img src={camera} alt="Camera" />}
                      </StyledIconWrapper>
                    </label>
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      {t('Add_Photo')}
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(15, 15, 15, 0.5)",
                        mx: 2,
                        fontSize: "12px",
                      }}
                    >
                      {t("Allowed_*.jpeg,_*.jpg,_*.png,_max_size_of_3.1_MB")}
                    </Typography>
                    {image !== "" && (
                      <StyledRemoveButton
                        onClick={(e) => {
                          e.preventDefault();
                          removeImage();
                        }}
                      >
                        {t('Remove')}
                      </StyledRemoveButton>
                    )}
                  </StyledImageUploadWrapper>
                </Grid>
              </Grid>
              <Grid
                item={true}
                md={12}
                sm={12}
                xs={12}
                container
                style={{ alignItems: "center", justifyContent: "center" }}
              >

                <Grid item={true} md={8} xs={12}>
                  <StyledFormLabel htmlFor="productCategory">
                    {t("Category_Name")}
                  </StyledFormLabel>
                  <StyledTextField
                    fullWidth
                    id="productCategory"
                    name="productCategory"
                    placeholder={t("please_enter_name")}
                    type="text"
                    value={values.productCategory}
                    inputProps={{
                      maxLength: 81,
                    }}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    error={Boolean(errors.productCategory)}
                    helperText={errors.productCategory}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ margin: "15px" }}>
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {},
            }}
            onClick={close}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: "#E64A08",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#E64A08",
              },
            }}
            disabled={isdisabled}
            type="submit"
            form="addNewCategory"
          >
            {props.category && props.category !== -1 ? t("Save") : t("Add")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCategory;
