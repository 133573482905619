import React from "react";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import Box from '@mui/material/Box';
import { TextField, FormLabel, MenuItem, Input, Tooltip, Grid, Button, Stack, Typography, IconButton } from "@mui/material";
import { Link } from 'react-router-dom';
import './report.css'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PaymentIcon from '@mui/icons-material/Payment';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { useTranslation } from "react-i18next";
import {

  ListItemIcon,
} from "@mui/material";



export default function Reports() {
  const { t}=useTranslation();

  return (
    <div>
      <div
        className="purchaseOrderheadder"
      >
        <div>
          <h1 className='purchaseOrderheadder-left' style={{ fontSize: "22px", fontWeight: "bold", fontWeight: '600', marginBottom: "20px" }}> {t("Reports")}</h1>

        </div>

      </div>
      <Grid style={{ backgroundColor: "white" }}>
        <Box className="b1">


          <Grid item md={12} sm={8} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>


              <MenuItem>
                <ListItemIcon>
                  <AddShoppingCartIcon />
                </ListItemIcon>
                {t("Sales")}
              </MenuItem>
              <hr style={{ width: "300px" }} />

              <Link to='/salesbycustomerReport' style={{ textDecoration: 'none' }}>




                <MenuItem style={{ color: "#F06237" }} >


                  <ListItemIcon>
                    <StarBorderIcon style={{ color: "grey" }} />
                  </ListItemIcon>

                  {t("Sales_By_Customer")}
                </MenuItem>
              </Link>



              <Link to='/salesbyItemReport' style={{ textDecoration: 'none' }}>
                <MenuItem style={{ color: "#F06237" }}>
                  <ListItemIcon>
                    <StarBorderIcon style={{ color: "grey" }} />
                  </ListItemIcon>{t("Sales_By_Item")}
                </MenuItem>

              </Link>

              {/* <MenuItem style={{color:"#F06237"}}>
        <ListItemIcon>
             <StarBorderIcon style={{color:"grey"}} />
        </ListItemIcon>Order Fulfillment By Item
    </MenuItem> */}



            </Grid>
            {/* <Grid item sx={{ m: 1 }} md={3.7} xs={12}>



<MenuItem>
<ListItemIcon>
  <InventoryIcon />
</ListItemIcon>
Inventory
</MenuItem>
<hr style={{width:"300px"}}/>

<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Inventory Summary
</MenuItem>

<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Inventory Valuation summary
</MenuItem>

<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Product Sales Report
</MenuItem>




</Grid> */}
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <MenuItem>

                <ListItemIcon>
                  <WorkOutlineIcon />
                </ListItemIcon>
                {t("Purchase_and_Expenses")}
              </MenuItem>
              <hr style={{ width: "300px" }} />
              <Link to='/purchaseByItem' style={{ textDecoration: 'none' }}>
                <MenuItem style={{ color: "#F06237" }}>
                  <ListItemIcon>
                    <StarBorderIcon style={{ color: "grey" }} />
                  </ListItemIcon>{t("Purchase_by_Item")}
                </MenuItem>
              </Link>

              <Link to='/purchaseBySupplier' style={{ textDecoration: 'none' }}>
                <MenuItem style={{ color: "#F06237" }}>
                  <ListItemIcon>
                    <StarBorderIcon style={{ color: "grey" }} />
                  </ListItemIcon>{t("Purchase_by_Supplier")}
                </MenuItem>
              </Link>

              {/* <MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Receive History
</MenuItem>
 */}




            </Grid>





            {/* <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
        <MenuItem>

<ListItemIcon>
  <CallReceivedIcon />
</ListItemIcon>
Receivables
</MenuItem>
<hr style={{width:"300px"}}/>
<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Customer Balances
</MenuItem>
<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Invoice Details
</MenuItem>
<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Sales order Details
</MenuItem>





        </Grid> */}

          </Grid>
        </Box>
        <Box className="b2">

          <Grid item md={12} sm={8} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <MenuItem>

                <ListItemIcon>
                  <PaymentIcon />
                </ListItemIcon>
                {t("Payment_Received")}
              </MenuItem>
              <hr style={{ width: "300px" }} />
              <Link to='/paymentReceivedReport' style={{ textDecoration: 'none' }}>
                <MenuItem style={{ color: "#F06237" }}>
                  <ListItemIcon>
                    <StarBorderIcon style={{ color: "grey" }} />
                  </ListItemIcon>{t("Payments_Received")}
                </MenuItem>
              </Link>



            </Grid>
            {/* <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
   
   
    <MenuItem>

<ListItemIcon>
<CurrencyExchangeIcon />
</ListItemIcon>
Payables
</MenuItem>
<hr style={{width:"300px"}}/>

<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Vendor Balances
</MenuItem>

<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Bill Details
</MenuItem>

<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Purchase order Details
</MenuItem>

<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Purchase order by Vendor
</MenuItem>




    </Grid> */}





            {/* <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
    <MenuItem>

<ListItemIcon>
<WorkOutlineIcon />
</ListItemIcon>
Purchase and Expenses
</MenuItem>
<hr style={{width:"300px"}}/>
<Link to='/purchaseByItem' style={{ textDecoration: 'none' }}>
<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Purchase by Item
</MenuItem>
</Link>

<MenuItem style={{color:"#F06237"}}>
<ListItemIcon>
<StarBorderIcon style={{color:"grey"}} />
</ListItemIcon>Receive History
</MenuItem>





    </Grid> */}

          </Grid>
        </Box>






      </Grid>

    </div>

  )

}






// export default reportChart;