import handleAuthToken from "../../utils/handleAuthToken";
import axios from "axios";

export const stockListApi = (pages) => async () => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`api/v1/stock?limit=10&page=${pages}`);
        return res.data;
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
};

export const stockAddApi = (values) => async () => {
    if (localStorage.token) {
        handleAuthToken(localStorage.token);
    }
    try {
        console.log("valuew", typeof (values))
        const res = await axios.post(`api/v1/addstock`, values);
        console.log("working222222", res.data)
        return res.data;
    } catch (error) {
        return { success: false, message: error.response.data.message };
    }
};

// export const updateCategoryApi = (values,id) => async () => {
//     if (localStorage.token) {
//         handleAuthToken(localStorage.token);
//     }
//     try {
//         console.log("valuew")
//         console.log("valuew", typeof (values))
//         const res = await axios.put(`api/v1/categoryupdate/${id}`, values);
//         console.log("workinggggggggg", res.data)
//         return res.data;
//     } catch (error) {
//         return { success: false, message: error.response.data.message };
//     }
// };


