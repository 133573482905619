import React from "react";
import { Box } from "@mui/material";
import MeetingList from "./MettingList";

const Meeting = () => {
  return (
    <Box>
      <MeetingList />
    </Box>
  );
};

export default Meeting;
