import { identifier } from "@babel/types";
import axiosInstance from "../../utils/AxiosInstace";

//getLeadTypeDropdown
export const leadTypeDropdown = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/getLeadTypeDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add contact
export const AddContactss = async (user) => {
  try {
    const response = await axiosInstance.post(`/api/v1/createContact`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//get contact list
export const getContactList = async (queryString) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/getContact` + queryString
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//lead details
export const getContactDetails = async (id) => {
  try {
    const res = await axiosInstance.get(`api/v1/getContactById/${id}`);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//contact update

export const updateContact = async (id, data) => {
  try {
    const res = await axiosInstance.put(`api/v1/updateContact/${id}`, data);
    if (res && res.data && res.data.status) {
      return res.data;
    }
  } catch (err) {
    return err;
  }
};

//get contact list dropdown
export const getAccountDrodwnList = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/getAccountDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};
