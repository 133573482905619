import React from "react";
import { Box } from "@mui/material";
import ContactList from "./ContactList";

const Contact = () => {
  return (
    <Box>
      <ContactList />
    </Box>
  );
};

export default Contact;
