import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "../productPage/productList";
import { useState } from "react";
import "../customerPage/style/addCustomer.css";
import { Link, useNavigate } from "react-router-dom";
import {
  TextField,
  FormLabel,
  Grid,
  Button,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  addAdmins,
  PasswordChange,
  roleDropdown,
  updateAdmin,
} from "../../state/action/adminAction";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledTextFields = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "100%",
    paddingTop: "0px",
    lineHeight: "30px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  password: "",
};

export default function CustomizedInputs(props) {
  const { t } = useTranslation();
  const { state } = useLocation();

  const navigate = useNavigate();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmNewPassword] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmNewPassword(!showConfirmPassword);
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validatePassword = (fieldValues, temp, key, value) => {
    if (value !== "") {
      if (value.length < 8) {
        temp[key] = "Password  must be 8 charecter";
      } else {
        if (value.length > 128) {
          temp[key] = "Password  must be less than 128 charecter";
        } else {
          if (!/[0-9]/.test(value)) {
            temp[key] = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(value)) {
              temp[key] = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(value)) {
                temp[key] = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp[key] = "";
              }
            }
          }
        }
      }
    } else {
      temp[key] = "This field is required.";
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("oldPassword" in fieldValues) {
      validatePassword(
        fieldValues,
        temp,
        "oldPassword",
        fieldValues.oldPassword
      );
    }
    if ("newPassword" in fieldValues) {
      validatePassword(
        fieldValues,
        temp,
        "newPassword",
        fieldValues.newPassword
      );
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      const response = await PasswordChange(payload);
      if (response && response.status === true) {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: response.message,
          type: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="purchaseOrderheadder">
        <div>
          <h1 className="purchaseOrderheadder-left"> </h1>
          <p
            className="purchaseOrderheadder-right"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/users");
            }}
          >
            {" "}
            <span
              className="purchaseOrderheadder-right-span"
              style={{ color: "black" }}
            >
              {" "}
            </span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Box className="b1">
          <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
            <Typography>{t("Change Password")}</Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <StyledFormLabel htmlFor="oldPassword">
                Old Password
              </StyledFormLabel>
              <StyledTextField
                inputProps={{ maxLength: 10 }}
                name="oldPassword"
                value={values.oldPassword}
                onChange={handleInputChange}
                error={Boolean(errors.oldPassword)}
                helperText={errors.oldPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" sx={{ m: 0 }}>
                      <Tooltip
                        title={
                          showNewPassword ? "Hide Password" : "Show Password"
                        }
                      >
                        <IconButton onClick={handleClickShowNewPassword}>
                          {showNewPassword ? (
                            <Visibility className="fontLight" />
                          ) : (
                            <VisibilityOff className="fontLight" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={12} xs={12}>
              <Box sx={{ marginTop: "13px" }}>
                <StyledFormLabel htmlFor="confirmPassword">
                  Confirm Password
                </StyledFormLabel>
                <StyledTextField
                  inputProps={{ maxLength: 10 }}
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleInputChange}
                  error={Boolean(errors.newPassword)}
                  helperText={errors.newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" sx={{ m: 0 }}>
                        <Tooltip
                          title={
                            showConfirmPassword
                              ? "Hide Password"
                              : "Show Password"
                          }
                        >
                          <IconButton onClick={handleClickShowConfirmPassword}>
                            {showConfirmPassword ? (
                              <Visibility className="fontLight" />
                            ) : (
                              <VisibilityOff className="fontLight" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "74px",
                borderRadius: "12px",
                padding: "20px",
                marginLeft: "785px",
              }}
            >
              <div>
                <Button
                  variant="outlined"
                  size="large"
                  className="buttonCancel"
                  onClick={() => {
                    navigate("/users");
                  }}
                  style={{
                    borderRadius: "10px",
                    width: "40%",
                    height: "94%",
                  }}
                >
                  <strong style={strong}>{t("Cancel")}</strong>
                </Button>{" "}
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{
                    backgroundColor: "#f06237",
                    borderRadius: "10px",
                  }}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <strong className="purchaseOrderheadder-box-button2-strong">
                    {t("Save")}
                  </strong>
                </Button>
              </div>
            </Box>
          </Grid>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
