import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Card } from "@mui/material";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import UserDeleteModal from "./supplierDelete";
import { useNavigate, useLocation } from "react-router-dom";
import { supplierDetails } from "../../state/action/supplierAction";
import { useTranslation } from "react-i18next";

export default function FormPropsTextFields() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [deleteUser, setDeleteUser] = useState(false);
  const [supplierdata, setSupplierdata] = useState({});
  const [addressdata, setAddressdata] = useState({});
  const [id, setId] = useState("");
  const [user, setUser] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (state && state.user && state.user.id) {
      GetSupplier(state.user.id);
      setId(state.user.id);
      setUser(state.user);
    }
  }, []);

  const GetSupplier = async (ids) => {
    const res = await supplierDetails(ids);
    if (res && res.data && res.status === true) {
      setSupplierdata(res.data);
      setAddressdata(res.data.address);
    }
  };

  const handleDeleteUser = () => {
    setDeleteUser(true);
  };
  const closeDeleteUser = () => {
    setDeleteUser(false);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "4px",
            }}
          >
            {" "}
            {t("View_Suppliers_Detail")}{" "}
          </h1>

          <p
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "34px",
              marginTop: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/supplier");
            }}
          >
            {t("Suppliers")} |{" "}
            <span style={{ color: "black" }}> {t("View_Suppliers_Detail")}</span>
          </p>
        </div>
        <Box marginTop="20px" borderRadius="12px">
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>

          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#f06237",
              borderRadius: "10px",
            }}
            onClick={() => {
              navigate(`/supplier/add`, {
                state: { user: user },
              });
            }}
          >
            <strong style={{ color: "white" }}>{t("Edit")}</strong>
          </Button>
        </Box>
      </div>
      <UserDeleteModal
        open={deleteUser}
        id={id}
        handleClose={closeDeleteUser}
      />

      <Card
        style={{
          padding: "20px",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        ></Box>
        <Box
          component="form"
          noValidate
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr 1fr" },
            gap: 3,
            marginTop: "35px",
            hover: {
              backgroundColor: "red",
              borderRadius: " 10px 10px 0px 0px",
            },
          }}
        >
          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {" "}
                {t("Name")}
              </span>
            }
            value={supplierdata.supplierName}
            defaultValue="savan"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />

          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("Supplier_Email")}
              </span>
            }
            value={supplierdata.supplierEmail}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />

          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("Mobile_Number")}
              </span>
            }
            value={supplierdata.mobileNumber}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("VAT_Number")}
              </span>
            }
            value={supplierdata.vatNumber}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("Credit_Limit")}
              </span>
            }
            value={supplierdata.creditLimit}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span
                style={{
                  color: "rgba(40, 45, 38, 0.5)",
                }}
              >
                {t("Credit_Days")}
              </span>
            }
            value={supplierdata.creditDays}
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </Box>
        <hr
          style={{
            marginTop: "20px",
            marginBottom: "30px",
          }}
        />
        <box>
          {
            <span
              style={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              {t("Address_Details")}:
            </span>
          }
          <br />
          <TextField
            style={{
              width: "100%",
            }}
            id="standard-read-only-input"
            value={
              addressdata.address +
              " ," +
              addressdata.countryName +
              " ," +
              addressdata.stateName +
              ", " +
              addressdata.city +
              ", " +
              addressdata.zipCode
            }
            defaultValue="null"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </box>

        <hr
          style={{
            marginTop: "20px",
            marginBottom: "30px",
          }}
        />

        <strong style={{ color: "black" }}>{t("Bank_Account_Detail")}:</strong>
        <Box
          component="form"
          noValidate
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr " },
            gap: 3,
            marginTop: "35px",
          }}
        >
          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                {" "}
                {t("Account_Name")}
              </span>
            }
            value={supplierdata.accountName}
            defaultValue="Curtis Weaver"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                {" "}
                {t("Bank_Name")}
              </span>
            }
            value={supplierdata.bankName}
            defaultValue="4140 Parker Rd. Allentown, New Mexico 31134"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                {" "}
                {t("Account_Number")}
              </span>
            }
            value={supplierdata.accountNumber}
            defaultValue="877"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}>
                {" "}
                {t("Swift_Code")}
              </span>
            }
            value={supplierdata.swiftCode}
            defaultValue="4140 Parker Rd. Allentown, New Mexico 31134"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </Box>
        <Box
          component="form"
          noValidate
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr " },
            gap: 3,
            marginTop: "35px",
            marginBottom: "100px",
          }}
        >
          <TextField
            id="standard-read-only-input"
            label={
              <span style={{ color: "rgba(40, 45, 38, 0.5)" }}> IFSC/MICR</span>
            }
            value={supplierdata.ifscCode}
            defaultValue="4140 Parker Rd. Allentown, New Mexico 31134"
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            variant="standard"
          />
        </Box>
      </Card>
    </>
  );
}
