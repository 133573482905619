import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  InputBase,
  Button,
  Typography,
  IconButton,
  Stack,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Divider,
  Box,
  TextField,
  FormLabel,
  InputAdornment,
  Pagination,
} from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import edit from "../../assets/edit.svg";
import filterIcon from "../../assets/filter.svg";
import CategoryDetailsModal from "./CategoryDetails";
import AddCategory from "./AddCategory";
import { useForm } from "../../utils/useForms";
import Backdrop from "../Backdrop/loader";
import { tableCellClasses } from "@mui/material/TableCell";
import axiosInstance from "../../utils/AxiosInstace";
import { categoryListApi } from "../../state/action/categoryAction";
import { useLocation, useNavigate } from "react-router-dom";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  color: "black",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  borderRadius: "10px",
  backgroundColor: "white",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(241 241 241)",
    color: theme.palette.common.black,
    fontSize: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "sticky",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
    // hover color add
    "&:hover": {
      color: "#E64A08",
    },
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: "15px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
}));
const StyledTextField = styled(TextField)(() => ({
  marginTop: "10px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
}));
const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

export default function CategoryList(props) {
  const { refresh, refreshScreen, setNotify } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(-1);
  const [categoryView, setCategoryView] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [totalProducts, setTotalProducts] = useState(0);
  const [pagePerSize, setPagePerSize] = useState(10);

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const { t } = useTranslation();
  const location = useLocation();

  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  let getCat = async () => {
    try {
      let string = "";
      let i = 0;
      for (let [key, value] of Object.entries(values)) {
        if (i == 0) {
          string += `&${key}=${value}`;
        } else {
          string += `&${key}=${value}`;
        }
        i++;
      }

      const res = await categoryListApi(page, search, string);
      if (res && res.status === true) {
        setCategories(res.data);
        setFilter(false);
        let pagecount = Math.ceil(res.totalCategory / 10);
        setTotalRecords(pagecount);
        setTotalProducts(res.totalCategory);
      }
      setOpenBreakdrop(false);
    } catch (err) {
      toast.error("Something went wrong");
      setOpenBreakdrop(false);
    }
  };
  useEffect(() => {
    getCat();
    handleClose();
  }, [refresh, filterData, search, page]);

  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrentCategory(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const initialValues = {
    productCategory: "",
    status: "",
    categoryImage: "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("productCategory" in fieldValues)
      temp.productCategory = fieldValues.productCategory
        ? ""
        : "This field is required.";
    if ("categoryImage" in fieldValues)
      temp.categoryImage = fieldValues.categoryImage
        ? ""
        : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
    getCat();
  };

  const handlecategoryView = () => {
    setCategoryView(true);
  };
  const closecategoryView = () => {
    setCategoryView(false);
  };

  const handledeleteCategory = () => {
    setDeleteCategory(true);
  };
  const closedeleteCategory = () => {
    setDeleteCategory(false);
  };

  const openCategoryModal = () => {
    setAddCategory(true);
  };
  const closeCategoryModal = () => {
    setAddCategory(false);
  };
  const getStatus = (status) => {
    const statusColors = {
      1: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },

      0: {
        backgroundColor: "rgba(240, 68, 56, 0.05)",
        color: "#F04438;",
      },
    };
    return (
      <StyledChip
        sx={statusColors[status]}
        label={status ? "Active" : "InActive"}
      />
    );
  };

  return (
    <>
      <Backdrop open={openBackdrop} />

      <ToastContainer limit={1} />

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          color: "black",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{ padding: "20px 0px" }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              sx={{
                height: "30px",
              }}
              placeholder={t("Search…")}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
            />
          </Search>

          <Button
            sx={{
              border: "1px solid rgba(15, 15, 15, 0.15)",
              borderRadius: "10px",
              mr: 2,
              padding: "5px 20px",
              color: "black",
              fontWeight: "400",
              fontSize: "12px",
              height: "30px",
            }}
            endIcon={
              <IconButton sx={{ ml: 3 }}>
                <img src={filterIcon} alt="Filter Options" />
              </IconButton>
            }
            onClick={openFilterDrawer}
          >
            {t("Filter")}
          </Button>
        </Stack>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: 220,
                padding: "10px 20px",
                color: "black",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ color: "black" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("Filter")}
                </Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={closeFilterDrawer1}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <Box sx={{ margin: "8px auto" }}>
                <StyledFormLabel htmlFor="email">
                  {t("Category_Name")}
                </StyledFormLabel>
                <StyledTextField
                  type="name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Search_Category")}
                  fullWidth
                  id="productCategory"
                  name={t("productCategory")}
                  // value={formik.values.email}
                  value={values.productCategory}
                  // onChange={.handleChange}
                  onChange={handleInputChange}
                />
              </Box>

              <Divider sx={{ mt: 2 }} />
            </Box>
            <Stack
              sx={{ margin: "100px 20px 10px 20px" }}
              flexDirection="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid #0F0F0F80",
                  borderRadius: "10px",
                  padding: "10px 25px",
                  "&:hover": {
                    // backgroundColor: "#60579A",
                  },
                }}
                onClick={closeFilterDrawer}
              >
                {t("RESET")}
              </Button>
              <Button
                sx={{
                  backgroundColor: "#F06237",
                  borderRadius: "10px",
                  color: "white",
                  padding: "10px 25px",
                  "&:hover": {
                    backgroundColor: "#F06237",
                  },
                }}
                type="submit"
              >
                {t("Filter")}
              </Button>
            </Stack>
          </form>
        </Drawer>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("Category")}</StyledTableCell>
                <StyledTableCell>{t("Status")}</StyledTableCell>
                <StyledTableCell>{t("Date")}</StyledTableCell>
                <StyledTableCell>{t("Action")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((row, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      navigate(`/product`, {
                        state: { categoryId: row.id },
                      });
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <Stack flexDirection="row" alignItems="center">
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                          sx={{ mx: 0.5 }}
                          alt="Admin Profile"
                          src={row.image}
                        />
                        <Typography
                          style={{
                            fontSize: "12px",
                          }}
                          sx={{ ml: 2 }}
                        >
                          {row.productCategory.length > 15
                            ? row.productCategory.substr(0, 15) + "..."
                            : row.productCategory}
                        </Typography>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>{getStatus(row.isActive)}</StyledTableCell>
                    <StyledTableCell>
                      {moment(row.createdAt).format("LLL")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          const currentCategory = categories.find(
                            (role) => role.id === row.id
                          );
                          handleClick(e, currentCategory);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          sx={{ mt: 0.3 }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={() => {
            handleClose();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {editPermissions && (
            <MenuItem
              sx={{ mt: 1 }}
              onClick={() => {
                openCategoryModal();
              }}
            >
              <ListItemIcon>
                <img src={edit} alt="Edit" />
              </ListItemIcon>
              <ListItemText>{t("Edit")}</ListItemText>
            </MenuItem>
          )}
        </Menu>
        {categoryView && (
          <CategoryDetailsModal
            category={currentCategory}
            open={categoryView}
            setOpen={setCategoryView}
            handleClose={closecategoryView}
          />
        )}
        {addCategory && (
          <AddCategory
            category={currentCategory}
            open={addCategory}
            setOpen={setAddCategory}
            handleClickOpen={openCategoryModal}
            handleClose={closeCategoryModal}
            refreshScreen={refreshScreen}
            setNotify={setNotify}
          />
        )}
      </div>
      <Stack
        sx={{ mt: 4, pb: 2 }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {t("Showing")}{" "}
          {page * pagePerSize > totalProducts
            ? totalProducts
            : page * pagePerSize}{" "}
          {t("of")} {totalProducts} {t("Results")}
        </div>
        <Pagination
          count={totalRecords}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </>
  );
}
