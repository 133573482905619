import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axiosInstance from "../../utils/AxiosInstace";
import { Form, useForm } from "../../utils/useForms";
import Autocomplete from '@mui/material/Autocomplete';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Card,
    TextField,
    FormLabel,
    MenuItem,
    Grid,
    Button,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
} from "@mui/material";
import Notification from "../../utils/Notification";
import Backdrop from '../Backdrop/loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tableCellClasses } from '@mui/material/TableCell';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import { store } from "../../state/store";
import { useTranslation } from 'react-i18next';


const FlexBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}))

const StyledTextField = styled(TextField)(() => ({
    marginTop: "3px",
    ".MuiOutlinedInput-root": {
        borderRadius: "10px",
        height: "33px",
        paddingTop: "0px",

    },
    ".MuiOutlinedInput-input": {
        fontSize: "13px",
    },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
    display: "block",
    fontWeight: "500",
    color: "black",
    fontSize: "13px",
}));

const initialValues = {
    supplierId: "",
    status: "",
    products: [{ productId: "", quantity: "", price: "", tax: "", discount: "", productSalesPrice: "" }, { productId: "", quantity: "", "": 200, tax: "", discount: "", productSalesPrice: "" }],
    paymentStatus: "unpaid",
    createdBy: "",
};


export default function CustomizedInputs(props) {
    useEffect(() => {
        getCustomers()
        getProducts()
        currencyList()
        taxList()
        otherList()
    }, [])
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true);
    const navigate = useNavigate();
    const location = useLocation();
    const [customerlist, setCustomerlist] = useState([]);
    const [customerdata, setCustomerdata] = useState([]);
    const [productlist, setProductlist] = useState([]);
    const [productdata, setProductdata] = useState([]);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [currencyvalue, setCurrencyvalue] = useState(0);
    const [taxvalue, setTaxvalue] = useState(0);
    const [currencydetail, setCurrencydetail] = useState(0);
    let [Taxvaluepercentage, setTaxvaluepercentage] = useState([]);
    const [discount, setDiscount] = useState(0);
    const [date, setDate] = useState(new Date());
    const [discountType, setDiscountType] = useState(0);
    const [customerAddress, setCustomerAddress] = useState([]);
    const [shipping, setshipping] = useState([]);
    const [otherchargelist, setotherChargeList] = useState([]);
    const [values1, setvalues1] = useState("");
    const [amount1, setamount1] = useState("");
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [specificationarr, setSpecificationArr] = useState([{ type: "", value: "", amount: "", }]);
    const [refresh, setRefresh] = useState(false);
    const [addAdmin, setAddAdmin] = useState(false);
    const { t }=useTranslation();
    var someDate = new Date();
    var date1 = someDate.setDate(someDate.getDate());

    var defaultValue = new Date(date1).toISOString().split("T")[0];

    const openAdminModal = () => {
        setAddAdmin(true);
    };
    const closeAdminModal = () => {
        setAddAdmin(false);
    };
    const addClick = async (event) => {
        setSpecificationArr((s) => {
            return [
                ...s,
                {
                    taxType: "",
                    taxPercentage: "",
                    taxValue: "",
                },
            ];
        });
    };

    const changeHandler1 = (e, index) => {

        let newArr = [...productdata];
        newArr[index][e.target.name] = e.target.value;
        setProductdata(newArr);
    };


    const handleSpecificationChange = (e, index) => {
        e.preventDefault();
        setSpecificationArr((s) => {
            const newArr = s.slice()
            newArr[index]["type"] = e.target.value;
            setvalues1(otherchargelist.filter((x) => x.id == e.target.value)[0].value)
            newArr[index]["value"] = otherchargelist.filter((x) => x.id == e.target.value)[0].value
            setamount1(otherchargelist.filter((x) => x.id == e.target.value)[0].amount)
            newArr[index].amount = otherchargelist.filter((x) => x.id == e.target.value)[0].amount
            newArr[index]["amount"] = otherchargelist.filter((x) => x.id == e.target.value)[0].amount;
            return newArr;
        });
    };

    const deleteSelectedspecification = async (index) => {
        setSpecificationArr((preval) => {
            const newArr = preval.slice()
            newArr.splice(index, 1)
            return newArr
        });
    }
    const customerDetails = async (e) => {
        if (e) {
            await axiosInstance
                .get(`api/v1/customerdetails/${e.id}`).then((res) => {
                    setCustomerdata(res.data.data)
                    setCustomerAddress(res.data.data.address)
                }).catch((e) => {
                    // console.log("error found")
                })
        }
        else {
            setCustomerdata({})
        }
    }


    const otherList = async (currency) => {

        await axiosInstance
            .get(`api/v1/allCharges`).then((res) => {
                setotherChargeList(res.data.data)
            }).catch((e) => {
                // console.log("error found")
            })
    }
    const discounttypes = (e) => {
        setDiscountType(e)
    }

    const currencyList = async (currency) => {
        await axiosInstance
            .get(`/api/v1/getcurrency`).then((res) => {
                setCurrencyvalue(res.data.data)
            }).catch((e) => {
                // console.log("error found")
            })
    }

    const CurrencyValues = (values) => {
        setCurrencydetail(values)
    }

    const taxList = async (tax) => {
        await axiosInstance
            .get(`/api/v1/gettax`).then((res) => {
                setTaxvalue(res.data.data)
            }).catch((e) => {
                // console.log("error found")
            })
    }
    const getCustomers = async (e) => {
        await axiosInstance
            .get(`/api/v1/customerdropdown`).then((res) => {
                setCustomerlist(res.data.data)
            }).catch((e) => {
                console.log("error found")
            })
        
    }

    const getProducts = async (e) => {
        await axiosInstance
            .get(`api/v1/productdropdown`).then((res) => {
                setProductlist(res.data.data)
            }).catch((e) => {
                console.log("error found")
            })
    }

    let productDetails = async (product) => {
        if (product) {
            if (product.id) {
                await axiosInstance
                    .get(`/api/v1/productdetail/${product.id}`).then((res) => {
                        res.data.data.productQuantity = 1;
                        let productIndex = productdata.findIndex((x) => x.id == res.data.data.id);
                        if (productIndex != -1) {

                            productdata[productIndex].productQuantity = parseInt(productdata[productIndex].productQuantity) + 1;
                            setProductdata(productdata);
                        }
                        else {
                            setProductdata((s) => {
                                return [
                                    ...s,
                                    res.data.data,
                                ];

                            });
                        }


                    }).catch((e) => {
                        console.log("error found")
                    })
            }
        }
        else {
            setProductdata([])
        }
    }
    let taxPercentagevalue = async (e) => {
        if (e) {
            const states = {
                id: e.id,
                tax: e.value
            }
            if (Taxvaluepercentage.length > 0) {
                setTaxvaluepercentage((prev) => {
                    return prev.map((item) => {
                        if (item.id === states.id) {
                            item.tax = states.tax
                        }
                        return item
                    }
                    )
                }
                )
            }
            else {
                setTaxvaluepercentage(states)
            }
        }
    }



    let deleteItem = (id) => {
        setProductdata(productdata.filter((item) => item.id !== id))
    }
    let subtotal = productdata.reduce((acc, curr) => {
        return acc + curr.productSalesPrice * curr.productQuantity
    }, 0)

    let taxValue = specificationarr.reduce((acc, curr) => {
        return acc + curr.amount
    }, 0)

    let total = 0;

    Taxvaluepercentage = subtotal * (Taxvaluepercentage / 100);

    if (discountType === 1) {
        let discountt = (Number(subtotal) * Number(discount)) / 100
        total = subtotal - discountt + taxValue;;
    }
    else {
        total = subtotal - discount + taxValue;
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "rgba(40, 45, 38, 0.05)",
            color: "rgba(15, 15, 15, 0.5)",
            fontSize: "12px",
            paddingTop: "6px",
            paddingBottom: "6px",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            color: "rgba(40, 45, 38, 0.5)",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const openConfirmDialog = () => {
        setConfirmDialog(true);
    };
    const closeConfirmDialog = () => {
        setConfirmDialog(false);
    };
    const { auth } = store.getState()
    const userid = auth?.user?.id;

    const handleSubmit = async (e, f) => {
        const toastType = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        };
        e.preventDefault();
        const payload = {
            "customerId": customerdata.id,
            "status": f,
            "products": productdata,
            "tax": specificationarr,
            "paymentStatus": 2,
            "orderDate": date,
            "discountType": discountType ? discountType : 0,
            "discountValue": discount,
            "subtotal": subtotal,
            "payableAmount": total,
            "createdBy": userid
        }
        if (userid) {
            await axiosInstance
                .post(`/api/v1/addsalesorder`, payload).then((res) => {
                    console.log(res)
                    if (res.data.status === true) {
                        setValues(res.data.data)
                        navigate('/order', { state: { message: res.data.message } });
                    }
                }).catch((e) => {
                    console.log("error found", e.response.data.message);
                    setNotify({
                        isOpen: true,
                        message: e.response.data.message + ", please Check Store",
                        type: "error",
                    });
                })
        }
        else {

        }
    };

    return (
        <>
            <Backdrop open={openBackdrop} />

            <div
                className="purchaseOrderheadder"
            >
                <div>
                    <h1 className='purchaseOrderheadder-left'> {t('Add_Sales_Order')} </h1>
                    <p className='purchaseOrderheadder-right'> {t("Sales_Orders")} |
                        <span className='purchaseOrderheadder-right-span' style={{ color: "black" }}>  {t("Add_Order_Details")}</span>
                    </p>
                </div>
                <Box className="purchaseOrderheadder-box">
                    <Link to="/purchaseorder" style={{
                        textDecoration: "none",
                    }}>
                        <Button variant="outlined" size="large"
                            style=
                            {{
                                border: '1px solid  #282822',
                                borderRadius: '10px',
                                marginRight: '15px',
                                backgroundColor: '#E6E6E6',
                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                            }}
                        >
                            <strong className='purchaseOrderheadder-box-button1-strong' >{t("Cancel")}</strong>
                        </Button>
                    </Link>

                    <Button variant="contained" size="large"
                        style=
                        {{
                            border: '1px solid  #282822',
                            borderRadius: '10px',
                            marginRight: '15px',
                            backgroundColor: '#E6E6E6',
                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                        }}
                        // value="Submit"
                        onClick={(e) => {
                            handleSubmit(e, 0)
                        }}
                    >
                        <strong className='purchaseOrderheadder-box-button2-strong' style={{
                            color: "rgb(0,0,0)"
                        }}>{t("Save_As_Draft")}</strong>
                    </Button>
                    <Button variant="contained" size="large"
                        style=
                        {{
                            backgroundColor: '#f06237',
                            borderRadius: '10px',
                        }}
                        onClick={(e) => {
                            handleSubmit(e, 1)
                        }}
                    >
                        <strong className='purchaseOrderheadder-box-button2-strong'>{t("Save")}</strong>
                    </Button>
                </Box>
            </div>

            <Card
                id="purchaseOrder-card"

            >
                <Form>
                    <Grid item md={12} sm={8} xs={12} container spacing={1}>
                        <Grid item sx={{ m: 1 }} md={12} xs={12}>
                            <StyledFormLabel htmlFor="productGroup">
                                {t("Customer_Name")}
                            </StyledFormLabel>

                            <Autocomplete
                                disablePortal
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                placeholder={t("Search_Vendor")}
                                closeIcon={<closeIcon />}
                                labelId="customer-input"
                                options={customerlist}
                                onChange={(event, value) => {
                                    if (value) {
                                        customerDetails(value);
                                        setErrors({ ...errors, customername: "" });
                                    }
                                    else {
                                        setCustomerdata("");
                                        setErrors({ ...errors, suppliername: "please select a vendor" });
                                    }
                                }}

                                getOptionLabel={(option) =>
                                    option ? option.name : ""
                                }
                                renderInput={(params) => (
                                    <StyledTextField
                                        name={t("customername")}
                                        labelId="budget-input"
                                        fullWidth
                                        value={params.name}
                                        {...params}
                                        sx={{ justifyContent: 'center' }}
                                        error={errors.customername}
                                        helperText={errors.customername}
                                    />
                                )}
                                popupIcon={<ExpandMoreIcon />}
                            />
                        </Grid>
                    </Grid>


                    {customerdata.companyName ? <Grid item sx={{ m: 1 }} container md={11.7} spacing={1} xs={12}
                        className="purchaseOrder-card-supplierdata-grid"
                    >
                        <Grid item md={3.5} xs={12}>
                            <Grid item sx={{ m: 1, mt: 1 }} md={12} xs={12}>
                                <h1 className="purchaseOrder-card-supplierdata-grid-h1">{t("Name:")} </h1>
                                <span id='purchaseOrder-card-supplierdata-grid-span'>{customerdata.customerName}</span>
                            </Grid>
                        </Grid>

                        <Grid item md={3.5} xs={12}>
                            <Grid item sx={{ m: 1, mt: 1 }} md={12} xs={12} >
                                <h1 className="purchaseOrder-card-supplierdata-grid-h1" > {t("Address:")}</h1>
                                <Typography variant="h6" id='text-right2'>
                                    {customerAddress.address}
                                </Typography>
                                <Typography variant="h6" id='text-right3'>
                                    {customerAddress.city},
                                    {customerAddress.state},
                                </Typography>
                                <Typography variant="h6" id='text-right4'>
                                    {customerAddress.country}
                                </Typography>
                                <Typography variant="h6" id='text-right4'>
                                    {customerAddress.zipCode}
                                </Typography>

                            </Grid>
                        </Grid>
                        <Grid item md={3.5} xs={12}>
                            <Grid item sx={{ m: 1, mt: 1 }} md={12} xs={12}>
                                <h1 className="purchaseOrder-card-supplierdata-grid-h1"
                                >{t("Phone_Number:")}  </h1>
                                <span id='purchaseOrder-card-supplierdata-grid-span'
                                >{customerdata.companyPhoneNumber}</span>

                            </Grid>
                        </Grid>

                    </Grid>
                        : <div></div>}
                    <Grid item md={12} sm={8} xs={12} container spacing={1}>
                        <Grid item sx={{ m: 1 }} md={5.8} xs={12}>
                            <StyledFormLabel htmlFor="productGroup"
                            >
                                {t("Currency")}
                            </StyledFormLabel>

                            <Autocomplete
                                disablePortal
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                placeholder={t('Search_Vendor')}
                                labelId="customer-input"
                                options={currencyvalue}
                                onChange={(event, value) => {
                                    if (event) {
                                        CurrencyValues(value)
                                        setErrors({ ...errors, currency: "" })
                                    }
                                    else {
                                        setErrors({ ...errors, currency: "Please select currency" })
                                    }
                                }}
                                getOptionLabel={(option) =>
                                    option ? option.name : ""
                                }
                                renderInput={(params) => (
                                    <StyledTextField
                                        name={t("currency")}
                                        labelId="budget-input"
                                        error={errors.currency}
                                        helperText={errors.currency}
                                        fullWidth
                                        sx={{ justifyContent: 'center' }}
                                        value={params.name}
                                        {...params}
                                    />
                                )}
                                popupIcon={<ExpandMoreIcon />}
                            />
                        </Grid>
                        <Grid item sx={{ m: 1 }} md={5.5} xs={12}>
                            <StyledFormLabel htmlFor="productGroup"
                            >
                                {t("Date")}
                            </StyledFormLabel>
                            <StyledTextField
                                style={{
                                    height: "33px",
                                    borderRadius: "10px",
                                    fontSize: "13px",

                                }}
                                id="date"
                                type="date"
                                defaultValue={defaultValue}
                                onChange={(e) => {
                                    setDate(e.target.value)
                                }
                                }
                                error={errors.date}
                                helperText={errors.date}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* <hr /> */}
                    <Grid item sx={{ m: 1 }} container md={12} xs={12}>

                        <Grid item md={12} xs={12}>
                            <StyledFormLabel htmlFor="productGroup">
                                {t("Product")}
                            </StyledFormLabel>

                            <Autocomplete
                                disablePortal
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                placeholder={t('Search_Vendor')}
                                labelId="customer-input"
                                options={productlist}
                                onChange={(event, value) => {
                                    if (value) {
                                        productDetails(value)
                                        setErrors({ ...errors, product: "" })
                                    }
                                    else {
                                        setErrors({ ...errors, product: "Please select product" })
                                    }

                                }}
                                getOptionLabel={(option) =>
                                    option ? option.name : ""
                                }
                                renderInput={(params) => (
                                    <StyledTextField
                                        name={t("product")}
                                        labelId="budget-input"
                                        fullWidth
                                        error={errors.product}
                                        helperText={errors.product}
                                        sx={{ justifyContent: 'center' }}
                                        value={params.name}
                                        {...params}
                                    />
                                )}
                                popupIcon={<ExpandMoreIcon />}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} style={{
                        marginTop: "40px",
                    }}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead
                                STYLE={{ backgroundColor: "#f06237" }}
                            >
                                <TableRow>
                                    <StyledTableCell>{t("Product_Name")}</StyledTableCell>
                                    <StyledTableCell align="center">{t("Code")}</StyledTableCell>
                                    <StyledTableCell align="center" >{t("Quantity")}</StyledTableCell>
                                    <StyledTableCell align="center" >{t("Sales_Price")}</StyledTableCell>
                                    <StyledTableCell align="center" >{t("amount")}</StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ color: "black" }}>
                                {productdata.map((row, index) => (
                                    <StyledTableRow key={row.id} >
                                        <StyledTableCell component="th" scope="row" style={{ cursor: "pointer" }} onClick={() => {
                                            navigate(`/product/details`, {

                                                state: {

                                                    user: row,
                                                }

                                            })

                                        }}>
                                            {row.productName}

                                        </StyledTableCell>
                                        <StyledTableCell align="center">{row.productCode}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <TextField
                                                id="standard-basic"
                                                style={{
                                                    width: "71px",
                                                    marginLeft: "10px",
                                                    color: "black",

                                                }}
                                                InputProps={{ style: { height: "23px", fontSize: "12px", width: "70px" } }}

                                                onChange={(event) => changeHandler1(event, index)}
                                                name="productQuantity"
                                                value={row.productQuantity}
                                                size="small"
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="center"
                                        >{row.productSalesPrice}</StyledTableCell>
                                        <StyledTableCell align="center"
                                        >{row.productQuantity * row.productSalesPrice}</StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            onClick={() => { openConfirmDialog() }}
                                        >
                                            <IconButton aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                        <Dialog
                                            id="confirm-dialog"
                                            open={confirmDialog}
                                            onClose={() => closeConfirmDialog()}
                                            sx={{
                                                "&#confirm-dialog .MuiPaper-root": {
                                                    borderRadius: "15px",
                                                },
                                            }}
                                        >
                                            <DialogContent>
                                                Are you sure you want to Remove this item ?
                                            </DialogContent>
                                            <DialogActions
                                                sx={{
                                                    margin: "15px",
                                                    marginRight: "auto",
                                                    marginLeft: "auto",
                                                }}
                                            >
                                                <Button
                                                    sx={{
                                                        backgroundColor: "white",
                                                        color: "black",
                                                        border: "1px solid #0F0F0F80",
                                                        borderRadius: "10px",
                                                        padding: "10px 25px",
                                                        "&:hover": {
                                                        },
                                                    }}
                                                    onClick={() => closeConfirmDialog()}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    sx={{
                                                        backgroundColor: "#e64a08",
                                                        color: "white",
                                                        borderRadius: "10px",
                                                        padding: "10px 25px",
                                                        "&:hover": {
                                                            backgroundColor: "#e64a08",
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        closeConfirmDialog();
                                                        // close();
                                                        deleteItem(row.id);
                                                    }}
                                                >
                                                   {t("Confirm")}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <h4>{t("Other_Charges")}</h4>
                    {specificationarr.map((item, i) => {
                        return (
                            <>
                                <Grid container md={12} spacing={3} xs={12}>
                                    <Grid
                                        item
                                        xs={specificationarr.length - 1 === i ? 3.7 : 3.7}
                                    >
                                        <StyledFormLabel htmlFor="taxType">
                                            {t("type")}
                                        </StyledFormLabel>
                                        <StyledTextField
                                            name="type"
                                            id={i}
                                            fullWidth
                                            select
                                            value={otherchargelist.type}
                                            error={Boolean(errors.tax)}
                                            helperText={errors.tax}

                                            onChange={(event) => handleSpecificationChange(event, i, otherchargelist)}
                                        >
                                            {otherchargelist.map((val) => {
                                                return (
                                                    <MenuItem key={val.id} value={val.id}>
                                                        {val.type}
                                                    </MenuItem>
                                                )
                                            })}

                                            {/* })} */}
                                        </StyledTextField>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={specificationarr.length - 1 === i ? 3.7 : 3.7}
                                    >
                                        <StyledFormLabel htmlFor="chargesValue">
                                            {t("value")}
                                        </StyledFormLabel>
                                        <StyledTextField
                                            name="chargesValue"
                                            id={i}
                                            variant="outlined"
                                            size="small"
                                            inputProps={{
                                                id: i,
                                            }}
                                            type="text"
                                            fullWidth
                                            value={item.value}
                                            error={Boolean(errors.taxPercentage)}
                                            helperText={errors.taxPercentage}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={specificationarr.length - 1 === i ? 3.7 : 3.7}
                                    >
                                        <StyledFormLabel htmlFor="taxValue" >
                                            {t("amount")}
                                        </StyledFormLabel>
                                        <StyledTextField
                                            name="amount"
                                            value={item.amount}
                                            id={i}
                                            variant="outlined"
                                            size="small"
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={specificationarr.length - 1 === i ? 0.3 : 0.3}
                                    >
                                        {specificationarr.length > 1 ? (
                                            <Button
                                                type="button"
                                                sx={{ ml: 1 }}
                                                style={{
                                                    background: "",
                                                    marginTop: "19px",
                                                    marginLeft: "20px"
                                                }}
                                                onClick={() => {
                                                    deleteSelectedspecification(i)
                                                }}
                                            >
                                                <DeleteIcon style={{ color: "red" }} />
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                    {specificationarr.length - 1 === i ? (
                                        <Grid
                                            item
                                            xs={specificationarr.length > 1 ? 0.5 : 0.5}
                                        >
                                            <Button
                                                type="button"
                                                style={{
                                                    color: 'black',
                                                    marginTop: "20px",
                                                    marginLeft: "-50px",
                                                }}
                                                sx={{ mt: 1.3 }}
                                                onClick={addClick}
                                            >
                                                <AddIcon />
                                            </Button>

                                        </Grid>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                            </>
                        );
                    })}




                    <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)", marginTop: "20px" }}></div>

                    <FlexBox px={2}
                        style={{
                            marginLeft: '60%',
                        }}
                    >
                        <Box display="flex">
                            <Box pr={6}>
                                <Typography sx={{ my: 2, }} style={{
                                    fontSize: "13px",
                                }}>{t("Subtotal:")}</Typography>
                                <Typography sx={{ mb: 2 }} style={{
                                    fontSize: "13px",
                                }}>{t("TAX")}</Typography>
                                <Typography sx={{ mb: 1 }} style={{
                                    fontSize: "13px",
                                }}>{t("Discount")}</Typography>
                            </Box>
                            <div
                            >
                                <Typography sx={{ my: 2, marginLeft: "105px", fontSize: "13px" }}><b>{subtotal}</b></Typography>
                                <Typography sx={{ mb: 2, marginLeft: "105px", fontSize: "13px" }}><b>{taxValue}</b></Typography>
                                <Typography sx={{ mb: 2, fontSize: "13px" }}><Select
                                    style={
                                        {
                                            width: "113px",
                                            marginLeft: "-30px",
                                            height: "23px",
                                            color: "black",
                                            fontSize: "13px",
                                        }
                                    }
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    defaultValue={0}
                                    onChange={(event, value) => {
                                        discounttypes(value.props.value)
                                    }}
                                >
                                    <MenuItem value={0}>{t("Flat")}</MenuItem>
                                    <MenuItem value={1}>{t("percentage")} </MenuItem>
                                </Select>
                                    <TextField
                                        id="standard-basic"
                                        style={{
                                            width: "71px",
                                            marginLeft: "10px",
                                            color: "black",

                                        }}
                                        InputProps={{ style: { height: "23px", fontSize: "12px", width: "70px" } }}

                                        onChange={(e) => {
                                            setDiscount(e.target.value);
                                        }
                                        }
                                        value={discount}
                                        size="small"
                                    /></Typography>
                            </div>
                        </Box>
                    </FlexBox>
                    <div style={{ borderTop: "1px solid rgba(15, 15, 15, 0.15)", marginTop: "20px" }}></div>

                    <FlexBox px={2}
                        style={{
                            marginTop: "20px",
                            marginLeft: '60%',
                        }}
                    >
                        <Box display="flex">
                            <Box pr={4}
                            >
                                <Typography sx={{ mb: 4 }}>{t("Total")}</Typography>
                            </Box>
                            <div
                                style={{ marginLeft: "141px" }}
                            >
                                <Typography sx={{ mb: 6, color: "red" }}><b>{total} </b></Typography>
                            </div>
                        </Box>
                    </FlexBox>

                </Form>
            </Card>
            {/* </Form> */}
            <Notification notify={notify} setNotify={setNotify} />

        </>
    );
}
