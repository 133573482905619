import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import "../productPage/productList";
import { useState } from "react";
import "./style/addCustomer.css";
import { Link, useNavigate } from "react-router-dom";
import { TextField, FormLabel, Grid, Button } from "@mui/material";
import Notification from "../../utils/Notification";
import { store } from "../../state/store";
import { useForm, Form } from "../../utils/useForms";
import axiosInstance from "../../utils/AxiosInstace";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getCustomerDetails,
  getCountry,
  getStateList,
  addCustomer,
  updateCustomer,
} from "../../state/action/customerAction";

const strong = {
  color: "black",
};

const StyledTextField = styled(TextField)(() => ({
  marginTop: "3px",
  ".MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "33px",
    paddingTop: "0px",
  },
  ".MuiOutlinedInput-input": {
    fontSize: "13px",
  },
}));
const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  fontSize: "12px",
}));

const initialValues = {
  customerName: "",
  customerEmail: "",
  mobileNumber: "",
  address: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  vatNumber: "",
  creditLimit: "",
  creditDays: "",
  advancePayment: "",
  accountName: "",
  accountNumber: "",
  bankName: "",
  createdBy: "",
  ifscCode: "",
  swiftCode: "",
};

export default function CustomizedInputs(props) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { auth } = store.getState();

  const [countrylist, setCountrylist] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [edit, setIsEdit] = useState(false);
  const [id, setId] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    getCountryList();
  }, []);

  useEffect(() => {
    if (state) {
      getCustomerDetailss(state.user.id);
      setIsEdit(true);
      setId(state.user.id);
    }
  }, [state]);

  const getCustomerDetailss = async (id) => {
    const response = await getCustomerDetails(id);
    if (response.status === true) {
      getStateListData(response.data.address.country);
      setValues({
        customerName: response.data.customerName,
        customerEmail: response.data.customerEmail,
        mobileNumber: response.data.mobileNumber,
        address: response.data.address.address,
        city: response.data.address.city,
        state: Number(response.data.address.state),
        country: Number(response.data.address.country),
        zipCode: response.data.address.zipCode,
        vatNumber: response.data.vatNumber,
        creditLimit: response.data.creditLimit,
        creditDays: response.data.creditDays,
        advancePayment: response.data.advancePayment,
        accountName: response.data.accountName,
        accountNumber: response.data.accountNumber,
        bankName: response.data.bankName,
        createdBy: response.data.createdBy,
        ifscCode: response.data.ifscCode,
        swiftCode: response.data.swiftCode,
      });
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("customerName" in fieldValues) {
      if (fieldValues.customerName !== "") {
        if (/^[a-zA-Z0-9\s]{1,50}$/.test(fieldValues.customerName)) {
          temp.customerName = "";
        } else {
          temp.customerName = "number or characters and 50 digits allow only";
        }
      } else {
        temp.customerName = "This field is required.";
      }
    }
    if ("vatNumber" in fieldValues) {
      if (fieldValues.vatNumber !== "") {
        if (/^[a-zA-Z0-9]{1,14}$/.test(fieldValues.vatNumber)) {
          temp.vatNumber = "";
        } else {
          temp.vatNumber = " alphanumeric & maximum 14 digits allow";
        }
      } else {
        temp.vatNumber = "This field is required.";
      }
    }
    if ("swiftCode" in fieldValues) {
      if (fieldValues.swiftCode !== "") {
        temp.swiftCode = "";
      } else {
        temp.swiftCode = "This field is required.";
      }
    }

    if ("mobileNumber" in fieldValues) {
      if (fieldValues.mobileNumber !== "") {
        if (/^[0-9]+$/.test(fieldValues.mobileNumber)) {
          temp.mobileNumber = "";
        } else {
          temp.mobileNumber = "Only numbers are allowed";
        }
      } else {
        temp.mobileNumber = "This field is required.";
      }
    }
    if ("zipCode" in fieldValues) {
      if (fieldValues.zipCode !== "") {
        if (/^[0-9]{4,10}$/.test(fieldValues.zipCode)) {
          temp.zipCode = "";
        } else {
          temp.zipCode = "4  to 10 digits Only Number allow";
        }
      } else {
        temp.zipCode = "This field is required.";
      }
    }

    if ("accountNumber" in fieldValues) {
      if (fieldValues.accountNumber !== "") {
        temp.accountNumber = "";
      } else {
        temp.accountNumber = "This field is required.";
      }
    }

    if ("ifscCode" in fieldValues) {
      if (fieldValues.ifscCode !== "") {
        temp.ifscCode = "";
      } else {
        temp.ifscCode = "This field is required.";
      }
    }

    if ("customerEmail" in fieldValues) {
      if (fieldValues.customerEmail !== "") {
        if (
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            fieldValues.customerEmail
          )
        ) {
          temp.customerEmail = "";
        } else {
          temp.customerEmail = "Enter valid Email";
        }
      } else {
        temp.customerEmail = "This field is required.";
      }
    }

    if ("accountName" in fieldValues) {
      if (fieldValues.accountName !== "") {
        temp.accountName = "";
      } else {
        temp.accountName = "This field is required.";
      }
    }
    if ("bankName" in fieldValues) {
      if (fieldValues.bankName !== "") {
        temp.bankName = "";
      } else {
        temp.bankName = "This field is required.";
      }
    }

    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";

    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : "This field is required.";
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "This field is required.";
    if ("country" in fieldValues)
      temp.country = fieldValues.country ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    console.log("temp", temp);

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const getCountryList = async (e) => {
    const res = await getCountry();
    if (res.status === true) {
      setCountrylist(res.data);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      customerName: values.customerName,
      customerEmail: values.customerEmail,
      mobileNumber: values.mobileNumber,
      address: {
        addressType: 1,
        address: values.address,
        state: values.state,
        city: values.city,
        zipCode: Number(values.zipCode),
        country: values.country,
      },
      creditLimit: Number(values.creditLimit),
      creditDays: Number(values.creditDays),
      advancePayment: Number(values.advancePayment),
      accountName: values.accountName,
      accountNumber: values.accountNumber,
      bankName: values.bankName,
      swiftCode: values.swiftCode,
      ifscCode: values.ifscCode,
      vatNumber: values.vatNumber,
      // createdBy: userid,
    };

    try {
      if (validate()) {
        if (edit) {
          const res = await updateCustomer(payload, id);
          if (res && res.status === true) {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "success",
            });
            setTimeout(() => {
              navigate("/customer");
            }, 1000);
          } else {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "warning",
            });
          }
        } else {
          const res = await addCustomer(payload);
          if (res && res.status === true) {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "success",
            });
            setTimeout(() => {
              navigate("/customer");
            }, 1000);
          } else {
            setNotify({
              isOpen: true,
              message: res.message,
              type: "warning",
            });
          }
        }
      }
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "Something went wrong",
        type: "error",
      });
    }
  };

  const getStateListData = async (e) => {
    setValues({
      ...values,
      country: e,
    });
    const res = await getStateList(e);
    if (res.status === true) {
      setStatelist(res.data);
    }
  };


  return (
    <>
      <div className="purchaseOrderheadder">
        <div>
          <h1 className="purchaseOrderheadder-left">
            {" "}
            {edit ? t("Edit_Customer") : t("Add_Customer")}
          </h1>
          <p
            className="purchaseOrderheadder-right"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/customer");
            }}
          >
            {" "}
            {t("Customer")} |
            <span
              className="purchaseOrderheadder-right-span"
              style={{ color: "black", cursor: "pointer" }}
            >
              {" "}
              {edit ? t("Edit_Customer_Details") : t("Add_Customer_Details")}
            </span>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#F4F6FF",
              borderRadius: "10px",
              marginRight: "10px",
              height: "40px",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <strong style={{ color: "black" }}>{t("Back")}</strong>
          </Button>
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <Box className="b1">
          <Grid item md={12} sm={8} xs={12} container spacing={1}>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="customerName">
                {t("Customer_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("customerName")}
                inputProps={{ maxLength: 50 }}
                value={values.customerName}
                onChange={handleInputChange}
                error={Boolean(errors.customerName)}
                helperText={errors.customerName}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="customerEmail">
                {t("Customer_Email")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("customerEmail")}
                value={values.customerEmail}
                onChange={handleInputChange}
                error={Boolean(errors.customerEmail)}
                helperText={errors.customerEmail}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="mobileNumber">
                {t("Mobile_Number")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("mobileNumber")}
                value={values.mobileNumber}
                inputProps={{ maxLength: 10 }}
                onChange={handleInputChange}
                error={Boolean(errors.mobileNumber)}
                helperText={errors.mobileNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="vatNumber">
                {t("VAT/TIN")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("vatNumber")}
                inputProps={{ maxLength: 14 }}
                value={values.vatNumber}
                onChange={handleInputChange}
                error={Boolean(errors.vatNumber)}
                helperText={errors.vatNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="creditLimit">
                {t("Credit_Limit")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("creditLimit")}
                inputProps={{ maxLength: 14 }}
                value={values.creditLimit}
                onChange={handleInputChange}
                error={Boolean(errors.creditLimit)}
                helperText={errors.creditLimit}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="creditDays">
                {t("Credit_Days")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("creditDays")}
                inputProps={{ maxLength: 14 }}
                value={values.creditDays}
                onChange={handleInputChange}
                error={Boolean(errors.creditDays)}
                helperText={errors.creditDays}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="advancePayment">
                {t("Advance_Payment")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("advancePayment")}
                inputProps={{ maxLength: 14 }}
                value={values.advancePayment}
                onChange={handleInputChange}
                error={Boolean(errors.advancePayment)}
                helperText={errors.advancePayment}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={11.5} xs={12}>
              <hr />
              <StyledFormLabel htmlFor="address">
                {t("Address")}
              </StyledFormLabel>
            </Grid>
            <Grid item sx={{ m: 1 }} md={7.7} xs={12}>
              <StyledFormLabel htmlFor="address">
                {t("Address")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("address")}
                value={values.address}
                onChange={handleInputChange}
                error={Boolean(errors.address)}
                helperText={errors.address}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="country">
                {t("Country")}
              </StyledFormLabel>
              <select
                id="country"
                name={t("country")}
                fullWidth
                select
                value={values.country}
                onChange={(e) => {
                  getStateListData(e.target.value);
                }}
                error={Boolean(errors.country)}
                helperText={errors.country}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_Country")}</option>

                {countrylist.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.8} xs={12}>
              <StyledFormLabel htmlFor="state">{t("State")}</StyledFormLabel>
              <select
                id="state"
                name={t("state")}
                fullWidth
                select
                value={values.state}
                onChange={handleInputChange}
                error={Boolean(errors.state)}
                helperText={errors.state}
                style={{
                  width: "100%",
                  height: "33px",
                  borderRadius: "10px",
                  padding: "0 0.75rem",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
              >
                <option value="">{t("Select_State")}</option>

                {statelist.map((val) => {
                  return (
                    <option key={val.id} value={val.id}>
                      {val.name}
                    </option>
                  );
                })}
              </select>
            </Grid>

            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="city">{t("City")}</StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("city")}
                value={values.city}
                onChange={handleInputChange}
                error={Boolean(errors.city)}
                helperText={errors.city}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={3.7} xs={12}>
              <StyledFormLabel htmlFor="zipCode">
                {t("ZipCode")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                inputProps={{ maxLength: 10 }}
                name={t("zipCode")}
                value={values.zipCode}
                onChange={handleInputChange}
                error={Boolean(errors.zipCode)}
                helperText={errors.zipCode}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={11.5} xs={12}>
              <hr />
              <StyledFormLabel htmlFor="bankAccount">
                {t("Bank_Account_Details")}
              </StyledFormLabel>
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="accountName">
                {t("Account_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("accountName")}
                value={values.accountName}
                onChange={handleInputChange}
                error={Boolean(errors.accountName)}
                helperText={errors.accountName}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="accountNumber">
                {t("Account_Number")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name="accountNumber"
                inputProps={{ minLength: 7, maxLength: 14 }}
                value={values.accountNumber}
                onChange={handleInputChange}
                error={Boolean(errors.accountNumber)}
                helperText={errors.accountNumber}
              />
            </Grid>
            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="bankName">
                {t("Bank_Name")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("bankName")}
                inputProps={{ maxLength: 20 }}
                value={values.bankName}
                onChange={handleInputChange}
                error={Boolean(errors.bankName)}
                helperText={errors.bankName}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="ifscCode">
                {t("IFSC/MICR")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("ifscCode")}
                inputProps={{ maxLength: 14 }}
                value={values.ifscCode}
                onChange={handleInputChange}
                error={Boolean(errors.ifscCode)}
                helperText={errors.ifscCode}
              />
            </Grid>

            <Grid item sx={{ m: 1 }} md={5.7} xs={12}>
              <StyledFormLabel htmlFor="swiftCode">
                {t("Swift_Code")}
              </StyledFormLabel>
              <StyledTextField
                fullWidth
                name={t("swiftCode")}
                inputProps={{ minLength: 8, maxLength: 11 }}
                value={values.swiftCode}
                onChange={handleInputChange}
                error={Boolean(errors.swiftCode)}
                helperText={errors.swiftCode}
              />
            </Grid>
          </Grid>
          <Box className="customerBox">
            <div>
              <Link
                to="/customer/"
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  variant="outlined"
                  size="large"
                  className="buttonCancel"
                >
                  <strong style={strong}>{t("Cancel")}</strong>
                </Button>{" "}
              </Link>
              <Button
                variant="contained"
                size="large"
                type="submit"
                style={{
                  backgroundColor: "#f06237",
                  borderRadius: "10px",
                }}
              >
                <strong className="purchaseOrderheadder-box-button2-strong">
                  {t("Save")}
                </strong>
              </Button>
            </div>
          </Box>
        </Box>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
