import { useEffect, useState } from "react";
import React from "react";
import axiosInstance from "../../utils/AxiosInstace";
import { useSelector, useDispatch } from "react-redux";
import { store } from "../../state/store";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import camera from "../../assets/camera.svg";
import { TextField, FormLabel, MenuItem, Input } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";


const UserDelete = (props) => {
  const Navigate = useNavigate();
  const { userlist, userid, page, id } = props;
  const { t } = useTranslation();
  const deleteSupplier = async () => {
    if (userid) {
      await axiosInstance
        .delete(`/api/v1/customerdelete/${userid}`)
        .then((res) => {
          userlist(page);
          handleClose();
          // Navigate("/customer")
        })
        .catch((e) => {
          console.log("error found");
        });
    }
    if (id) {
      await axiosInstance
        .delete(`/api/v1/customerdelete/${id}`)
        .then((res) => {
          // userlist(page);
          handleClose();
          Navigate("/customer");
        })
        .catch((e) => {
          console.log("error found");
        });
    }
  };
 
  useEffect(() => {}, []);
  const { user, open, handleClose } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>{t("Delete")}</DialogTitle>

        <DialogContent>
          Are you sure you want to delete this user ?
        </DialogContent>
        <DialogActions
          sx={{
            margin: "15px",
            marginRight: "57px",
            justifyItems: "center",
          }}
        >
          <Button
            sx={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid #0F0F0F80",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                // backgroundColor: "#60579A",
              },
            }}
            onClick={handleClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              backgroundColor: "#F06237",
              color: "white",
              borderRadius: "10px",
              padding: "10px 25px",
              "&:hover": {
                backgroundColor: "#db511f",
              },
            }}
            onClick={deleteSupplier}
          >
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDelete;
