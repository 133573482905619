import React from "react";
import { Box } from "@mui/material";
import LeadList from "./LeadList";

const Lead = () => {
  return (
    <Box>
      <LeadList />
    </Box>
  );
};

export default Lead;
