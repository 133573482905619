import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Card } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils/AxiosInstace";
import { useTranslation } from "react-i18next";
import { IsDelete, IsRead, IsWrite } from "../../utils/handlePermission";

const WarehouseList = () => {
  const Navigate = useNavigate();
  const [warehouselist, setWarehouselist] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const { t } = useTranslation();

  const getWarehouse = async (e) => {
    await axiosInstance
      .get(`/api/v1/warehouselist`)
      .then((res) => {
        setWarehouselist(res.data.data);
      })
      .catch((e) => {
        console.log("error found");
      });
  };
  useEffect(() => {
    setOpenBreakdrop(true);

    getWarehouse();
  }, []);

  const location = useLocation();
  const deletePermissions = IsDelete(location.pathname);
  const editPermissions = IsWrite(location.pathname);
  const readPermissions = IsRead(location.pathname);

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
          {t("Warehouses")}
        </Typography>
        {editPermissions && (
          <Button
            sx={{
              backgroundColor: "#F06237",
              color: "white",
              borderRadius: "10px",
              // padding: "15px 20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              "&:hover": {
                backgroundColor: "#F06237",
              },
            }}
            onClick={() => {
              Navigate("/warehouse/add");
            }}
            startIcon={<Add />}
            color="primary"
          >
            {t("Add_Warehouse")}
          </Button>
        )}
      </Stack>

      <Grid container md={12} spacing={3}>
        {warehouselist.map((warehouse, index) => {
          return (
            <Grid item sx={{ mt: 3 }} md={6} xs={12}>
              <Card style={{ padding: "16px" }}>
                <Stack flexDirection="row" justifyContent="space-between">
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {warehouse.companyName}
                  </Typography>
                  {readPermissions && (
                    <Button
                      sx={{
                        backgroundColor: "#F06237",
                        color: "white",
                        borderRadius: "10px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        "&:hover": {
                          backgroundColor: "#F06237",
                        },
                      }}
                      onClick={() => {
                        Navigate(`/warehouse/add`, {
                          state: {
                            warehouseid: warehouse.id,
                          },
                        });
                      }}
                      color="primary"
                    >
                      {t("Edit")}
                    </Button>
                  )}
                </Stack>
                <Typography
                  style={{
                    marginTop: "16px",
                    color: "rgb(110 108 108 / 87%)",
                    lineHeight: "24px",
                  }}
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {warehouse.state}
                </Typography>
                <Typography
                  style={{
                    color: "rgb(110 108 108 / 87%)",
                    lineHeight: "24px",
                  }}
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {warehouse.country},
                </Typography>
                <Typography
                  style={{
                    color: "rgb(110 108 108 / 87%)",
                    lineHeight: "24px",
                  }}
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {warehouse.mobileNumber}
                </Typography>
                <Typography
                  style={{
                    color: "rgb(110 108 108 / 87%)",
                    lineHeight: "24px",
                  }}
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {warehouse.companyEmail}
                </Typography>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
export default WarehouseList;
